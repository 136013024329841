import React from "react";
import { Route, Routes } from "react-router-dom";

import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Error from "./Common/Components/Error";
import Profile from "./Common/Profile/Profile";
import Bulletin from "./Tabs/Bulletin/Bulletin";

import AddStudent from "./Tabs/Setup/Student/AddStudent";
import EditStudent from "./Tabs/Setup/Student/EditStudent";
import Student from "./Tabs/Setup/Student/Student";
import StudentHistoryData from "./Tabs/Setup/Student/StudentHistoryData";
import StudentHistoryTable from "./Tabs/Setup/Student/StudentHistoryTable";
import ViewStudent from "./Tabs/Setup/Student/ViewStudent";

import AddTeacher from "./Tabs/Setup/Teacher/AddTeacher";
import EditTeacher from "./Tabs/Setup/Teacher/EditTeacher";
import Teacher from "./Tabs/Setup/Teacher/Teacher";
import TeacherHistoryData from "./Tabs/Setup/Teacher/TeacherHistoryData";
import TeacherHistoryTable from "./Tabs/Setup/Teacher/TeacherHistoryTable";
import ViewTeacher from "./Tabs/Setup/Teacher/ViewTeacher";

import AddManager from "./Tabs/Setup/Manager/AddManager";
import EditManager from "./Tabs/Setup/Manager/EditManager";
import Manager from "./Tabs/Setup/Manager/Manager";
import ManagerHistoryData from "./Tabs/Setup/Manager/ManagerHistoryData";
import ManagerHistoryTable from "./Tabs/Setup/Manager/ManagerHistoryTable";
import ViewManager from "./Tabs/Setup/Manager/ViewManager";

import AddUser from "./Tabs/Setup/User/AddUser";
import EditUser from "./Tabs/Setup/User/EditUser";
import User from "./Tabs/Setup/User/User";
import ViewUser from "./Tabs/Setup/User/ViewUser";

import AddLocation from "./Tabs/Setup/Location/AddLocation";
import EditLocation from "./Tabs/Setup/Location/EditLocation";
import Location from "./Tabs/Setup/Location/Location";
import ViewLocation from "./Tabs/Setup/Location/ViewLocation";

import AddBatch from "./Tabs/Setup/Batch/AddBatch";
import Batch from "./Tabs/Setup/Batch/Batch";
import EditBatch from "./Tabs/Setup/Batch/EditBatch";
import ViewBatch from "./Tabs/Setup/Batch/ViewBatch";

import AddLevel from "./Tabs/Setup/Levels/AddLevel";
import EditLevel from "./Tabs/Setup/Levels/EditLevel";
import Level from "./Tabs/Setup/Levels/Level";
import ViewLevel from "./Tabs/Setup/Levels/ViewLevel";

import AddQuestion from "./Tabs/Setup/Question/AddQuestion";
import EditQuestion from "./Tabs/Setup/Question/EditQuestion";
import Question from "./Tabs/Setup/Question/Question";
import ViewQuestion from "./Tabs/Setup/Question/ViewQuestion";

import AddSlot from "./Tabs/Setup/Slots/AddSlot";
import EditSlot from "./Tabs/Setup/Slots/EditSlot";
import SlotsTable from "./Tabs/Setup/Slots/SlotsTable";

import TsProject from "./Tabs/Setup/Timesheet Projects/Projects/TsProject";
import TsProjectAdd from "./Tabs/Setup/Timesheet Projects/Projects/TsProjectAdd";
import TsProjectEdit from "./Tabs/Setup/Timesheet Projects/Projects/TsProjectEdit";
import TsProjectView from "./Tabs/Setup/Timesheet Projects/Projects/TsProjectView";

import TsTask from "./Tabs/Setup/Timesheet Projects/Tasks/TsTask";
import TsTaskAdd from "./Tabs/Setup/Timesheet Projects/Tasks/TsTaskAdd";
import TsTaskEdit from "./Tabs/Setup/Timesheet Projects/Tasks/TsTaskEdit";
import TsTaskView from "./Tabs/Setup/Timesheet Projects/Tasks/TsTaskView";

import AddTest from "./Tabs/Setup/Test/AddTest";
import EditTest from "./Tabs/Setup/Test/EditTest";
import Test from "./Tabs/Setup/Test/Test";
import ViewTest from "./Tabs/Setup/Test/ViewTest";


// import Preference from "./Tabs/Setup/Preference/Preference"
// import AddPreference from "./Tabs/Setup/Preference/AddPreference"
// import EditPreference from "./Tabs/Setup/Preference/EditPreference"
// import ViewPreference from "./Tabs/Setup/Preference/ViewPreference"

// import Role from "./Tabs/Setup/Role/Role"
// import AddRole from "./Tabs/Setup/Role/AddRole"
// import EditRole from "./Tabs/Setup/Role/EditRole"
// import ViewRole from "./Tabs/Setup/Role/ViewRole"

// import LandingPage from "./Tabs/Setup/Landing Page/LandingPage"

import ActivityLog from "./Tabs/System-Health/ActivityLog";
import ActivityTable from "./Tabs/System-Health/ActivityTable";

import Compose from "./Tabs/Message/Compose";
import Message from "./Tabs/Message/Message";
import ViewMessage from "./Tabs/Message/ViewMessage";

import StuTestHistory from "./Tabs/Student/All Students Test History/StuTestHistory";
import DownloadCertificate from "./Tabs/Student/Certificate/DownloadCertificate";
import LocationStudentTable from "./Tabs/Student/My Students/LocationStudentTable";
import AssignTest from "./Tabs/Student/Tests/AssignTest";
import UploadReport from "./Tabs/Student/Upload Folder/UploadReport";



import Abacus from "./Tabs/Test/Abacus/Abacus";
import MindMath from "./Tabs/Test/Mind Math/MindMath";
import AssignmentPreviewAndAttend from "./Tabs/Test/Question Generator/Assignments/AssignmentPreviewAndAttend";
import Generator from "./Tabs/Test/Question Generator/Generator";
import GeneratedTestExamPreview from "./Tabs/Test/Question Generator/Tests And Exams/GeneratedTestExamPreview";

import AssignmentSheet from "./Tabs/Test/Assignment Sheet/AssignmentSheet";
import ExamSheet from "./Tabs/Test/Exam Sheet/ExamSheet";
import NewExamSheet from "./Tabs/Test/Exam Sheet/NewExamSheet";
import NewTestSheet from "./Tabs/Test/Test Sheet/NewTestSheet";
import TestSheet from "./Tabs/Test/Test Sheet/TestSheet";

import AllTestTable from "./Tabs/Test/All Test Table/AllTestTable";
import AssignmentHistory from "./Tabs/Test/All Test Table/AssignmentHistory";
import ExamAndTestHistory from "./Tabs/Test/All Test Table/ExamAndTestHistory";
import ViewReport from "./Tabs/Test/All Test Table/ViewReport";

import LocationManagerProfile from "./Tabs/My-Profile/Location Manager Profile/LocationManagerProfile";
import StudentProfile from "./Tabs/My-Profile/Student Profile/StudentProfile";
import TeacherProfile from "./Tabs/My-Profile/Teacher Profile/TeacherProfile";

import EditEnquiry from "./Tabs/Crm/Old Enquiry Structure/Enquiry/EditEnquiry";
import Enquiry from "./Tabs/Crm/Old Enquiry Structure/Enquiry/Enquiry";
import ViewEnquiry from "./Tabs/Crm/Old Enquiry Structure/Enquiry/ViewEnquiry";

import AddItem from "./Tabs/Inventory/Inventory/AddItem";
import EditItem from "./Tabs/Inventory/Inventory/EditItem";
import Inventory from "./Tabs/Inventory/Inventory/Inventory";
import ViewItem from "./Tabs/Inventory/Inventory/ViewItem";

import AddTransaction from "./Tabs/Inventory/Transaction Log/AddTransaction";
import EditTransaction from "./Tabs/Inventory/Transaction Log/EditTransaction";
import TransactionTable from "./Tabs/Inventory/Transaction Log/TransactionTable";
import ViewTransaction from "./Tabs/Inventory/Transaction Log/ViewTransaction";

import Balance from "./Tabs/Inventory/Balance/Balance";
import ViewBalanceHistory from "./Tabs/Inventory/Balance/ViewBalanceHistory";
import ViewTransactionDetail from "./Tabs/Inventory/Balance/ViewTransactionDetail";

import GlobalSummary from "./Tabs/Timesheet/GlobalSummary";
import ReviewTimesheet from "./Tabs/Timesheet/ReviewTimesheet";
import Timesheet from "./Tabs/Timesheet/Timesheet";

import ActSummary from "./Tabs/Account/Account Summary/ActSummary";

import ActTransaction from "./Tabs/Account/Account Transactions/ActTransaction";
import AddActTrans from "./Tabs/Account/Account Transactions/AddActTrans";
import EditActTrans from "./Tabs/Account/Account Transactions/EditActTrans";

import AccountFee from "./Tabs/Account/Account Fee/AccountFee";
import AddAccountFee from "./Tabs/Account/Account Fee/AddAccountFee";
import AddFeeToAll from "./Tabs/Account/Account Fee/AddFeeToAll";
import EditAccountFee from "./Tabs/Account/Account Fee/EditAccountFee";

import GeneralEnquiryForm from "./Tabs/Crm/Old Enquiry Structure/General Enquiry Form/EnquiryForm";

import AdmissionDashboard from "./Tabs/Crm/New Enquiry Structure/Handling Admission/AdmissionDashboard";
import OnlineAdmissionFormComp from "./Tabs/Crm/New Enquiry Structure/Online Form/OnlineAdmissionFormComp";

import EnquiryDashboard from "./Tabs/Crm/New Enquiry Structure/Handling Enquiry/EnquiryDashboard";


import MySlots from "./Tabs/Crm/New Enquiry Structure/Handling Slots/MySlots";

import HelpVideos from "./Tabs/Help Videos/HelpVideos";
import Document from "./Tabs/Help/Document";
import Help from "./Tabs/Help/Help";

import ReleaseNote from "./Tabs/Whatsnew/ReleaseNote";

import ForgetPass from "./Login/ForgetPass";
import Login from "./Login/Login";
import ResetPass from "./Login/ResetPass";

import Protected from "./Protected";
// import Signup from "./Signup/Signup"


const App = () => {

  return (
    <>
      {/* <Detector
        render={({ online }) =>
          <Dialog
            open={!online}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                // Set 'open' to false, however you would do that with your particular code.
              }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title<War"><ErrorIcon /> There is no internet connection</DialogTitle>

            <DialogContent>

              <DialogContentText id="alert-dialog-description">
                The page cannot be processed. Make sure that your Wi-Fi or cellular data is turned on.
              </DialogContentText>
            </DialogContent>
          </Dialog>
        }
      /> */}

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={true}
        closeButton={false}
        transition={Slide}
        pauseOnHover
        theme="dark"
      />
      <Routes >
        <Route path="/bulletin" element={<Protected ><Bulletin /></Protected>} />
        <Route path="/profile" element={<Protected ><Profile /></Protected>} />
        <Route path="/student" element={<Protected ><Student /></Protected>} />
        <Route path="/add-student" element={<Protected ><AddStudent /></Protected>} />
        <Route path="/edit-student/:id" element={<Protected ><EditStudent /></Protected>} />
        <Route path="/view-student/:id" element={<Protected ><ViewStudent /></Protected>} />
        <Route path="/stu-history-data/:id" element={<Protected ><StudentHistoryData /></Protected>} />
        <Route path="/stu-history-table/:id" element={<Protected ><StudentHistoryTable /></Protected>} />
        <Route path="/teacher" element={<Protected ><Teacher /></Protected>} />
        <Route path="/add-teacher" element={<Protected ><AddTeacher /></Protected>} />
        <Route path="/edit-teacher/:id" element={<Protected ><EditTeacher /></Protected>} />
        <Route path="/view-teacher/:id" element={<Protected ><ViewTeacher /></Protected>} />
        <Route path="/tch-history-data/:id" element={<Protected ><TeacherHistoryData /></Protected>} />
        <Route path="/tch-history-table/:id" element={<Protected ><TeacherHistoryTable /></Protected>} />

        <Route path="/manager" element={<Protected ><Manager /></Protected>} />
        <Route path="/add-manager" element={<Protected ><AddManager /></Protected>} />
        <Route path="/edit-manager/:id" element={<Protected ><EditManager /></Protected>} />
        <Route path="/view-manager/:id" element={<Protected ><ViewManager /></Protected>} />
        <Route path="/mgr-history-data/:id" element={<Protected ><ManagerHistoryData /></Protected>} />
        <Route path="/mgr-history-table/:id" element={<Protected ><ManagerHistoryTable /></Protected>} />

        <Route path="/user" element={<Protected ><User /></Protected>} />
        <Route path="/edit-user/:id" element={<Protected ><EditUser /></Protected>} />
        <Route path="/view-user/:id" element={<Protected ><ViewUser /></Protected>} />
        <Route path="/add-user" element={<Protected ><AddUser /></Protected>} />
        <Route path="/location" element={<Protected ><Location /></Protected>} />
        <Route path="/add-location" element={<Protected ><AddLocation /></Protected>} />
        <Route path="/edit-location/:id" element={<Protected ><EditLocation /></Protected>} />
        <Route path="/view-location/:id" element={<Protected ><ViewLocation /></Protected>} />
        <Route path="/batch" element={<Protected ><Batch /></Protected>} />
        <Route path="/add-batch" element={<Protected ><AddBatch /></Protected>} />
        <Route path="/edit-batch/:id" element={<Protected ><EditBatch /></Protected>} />
        <Route path="/view-batch/:id" element={<Protected ><ViewBatch /></Protected>} />
        <Route path="/level" element={<Protected ><Level /></Protected>} />
        <Route path="/add-Level" element={<Protected ><AddLevel /></Protected>} />
        <Route path="/edit-Level/:id" element={<Protected ><EditLevel /></Protected>} />
        <Route path="/view-Level/:id" element={<Protected ><ViewLevel /></Protected>} />
        <Route path="/question" element={<Protected ><Question /></Protected>} />
        <Route path="/add-question" element={<Protected ><AddQuestion /></Protected>} />
        <Route path="/edit-question/:id" element={<Protected ><EditQuestion /></Protected>} />
        <Route path="/view-question/:id" element={<Protected ><ViewQuestion /></Protected>} />

        <Route path="/slots" element={<Protected ><SlotsTable /></Protected>} />
        <Route path="/add-slot" element={<Protected ><AddSlot /></Protected>} />
        <Route path="/edit-slot/:id" element={<Protected ><EditSlot /></Protected>} />

        <Route path="/ts-project" element={<Protected ><TsProject /></Protected>} />
        <Route path="/add-ts-project" element={<Protected ><TsProjectAdd /></Protected>} />
        <Route path="/edit-ts-project/:id" element={<Protected ><TsProjectEdit /></Protected>} />
        <Route path="/view-ts-project/:id" element={<Protected ><TsProjectView /></Protected>} />

        <Route path="/ts-task" element={<Protected ><TsTask /></Protected>} />
        <Route path="/add-ts-task" element={<Protected ><TsTaskAdd /></Protected>} />
        <Route path="/edit-ts-task/:id" element={<Protected ><TsTaskEdit /></Protected>} />
        <Route path="/view-ts-task/:id" element={<Protected ><TsTaskView /></Protected>} />

        <Route path="/test" element={<Protected ><Test /></Protected>} />
        <Route path="/add-test" element={<Protected ><AddTest /></Protected>} />
        <Route path="/edit-test/:id" element={<Protected ><EditTest /></Protected>} />
        <Route path="/view-test/:id" element={<Protected ><ViewTest /></Protected>} />
        <Route path="/message" element={<Protected ><Message /></Protected>} />
        <Route path="/view-message/:id" element={<Protected ><ViewMessage /></Protected>} />
        <Route path="/location-student-table" element={<Protected ><LocationStudentTable /></Protected>} />
        <Route path="/assign-test" element={<Protected ><AssignTest /></Protected>} />
        <Route path="/upload-report" element={<Protected ><UploadReport /></Protected>} />
        <Route path="/students-test-history" element={<Protected ><StuTestHistory /></Protected>} />
        <Route path="/compose" element={<Protected ><Compose /></Protected>} />
        <Route path="/download-certificate" element={<Protected ><DownloadCertificate /></Protected>} />


        {/* <Route   path="/preference" element={ <Protected ><Preference/>}/</Protected>>
            <Route   path="/add-preference" element={ <Protected ><AddPreference/></Protected>} />
            <Route   path="/edit-preference/:id" element={ <Protected ><EditPreference/></Protected>} />
            <Route   path="/view-preference/:id" element={ <Protected ><ViewPreference/></Protected>} />

            <Route   path="/role" element={ <Protected ><Role/>}/</Protected>>
            <Route   path="/add-role" element={ <Protected ><AddRole/>}/</Protected>>
            <Route   path="/edit-role/:id" element={ <Protected ><EditRole/>}/</Protected>>
            <Route   path="/view-role/:id" element={ <Protected ><ViewRole/>}/</Protected>>
            <Route   path="/landing-page" element={ <Protected ><LandingPage/>}/</Protected>> */}

        <Route path="/abacus" element={<Protected ><Abacus /></Protected>} />
        <Route path="/mind-math" element={<Protected ><MindMath /></Protected>} />
        <Route path="/generator" element={<Protected ><Generator /></Protected>} />
        <Route path="/assignment-preview-and-attend/:level/:type/:rows/:no_of_questions/:test_type" element={<Protected ><AssignmentPreviewAndAttend /></Protected>} />
        <Route path="/test-exam-preview/:test_type/:level" element={<Protected ><GeneratedTestExamPreview /></Protected>} />

        <Route path="/assignment-sheet/:test_name/:level_name/:assignment_type/:page/:test_type/:id/:yt_url/:draft_answer" element={<Protected ><AssignmentSheet /></Protected>} />
        <Route path="/test-sheet/:test_name/:level_name/:test_type/:id/:yt_url" element={<Protected ><TestSheet /></Protected>} />
        <Route path="/new-test-sheet/:id/:yt_url" element={<Protected ><NewTestSheet /></Protected>} />
        <Route path="/exam-sheet/:test_name/:level_name/:test_type/:id/:yt_url" element={<Protected ><ExamSheet /></Protected>} />
        <Route path="/new-exam-sheet/:id/:yt_url" element={<Protected ><NewExamSheet /></Protected>} />

        <Route path="/all-tests" element={<Protected ><AllTestTable /></Protected>} />
        <Route path="/test-history" element={<Protected ><ExamAndTestHistory /></Protected>} />
        <Route path="/tasks-history" element={<Protected ><AssignmentHistory /></Protected>} />
        <Route path="/view-report/:id" element={<Protected ><ViewReport /></Protected>} />

        <Route path="/location-manager-profile" element={<Protected ><LocationManagerProfile /></Protected>} />
        <Route path="/student-profile" element={<Protected ><StudentProfile /></Protected>} />
        <Route path="/teacher-profile" element={<Protected ><TeacherProfile /></Protected>} />

        <Route path="/enquiry" element={<Protected ><Enquiry /></Protected>} />
        <Route path="/view-enquiry/:id" element={<Protected ><ViewEnquiry /></Protected>} />
        <Route path="/edit-enquiry/:id" element={<Protected ><EditEnquiry /></Protected>} />

        <Route path="/inventory" element={<Protected ><Inventory /></Protected>} />
        <Route path="/add-item-inv" element={<Protected ><AddItem /></Protected>} />
        <Route path="/edit-item-inv/:id" element={<Protected ><EditItem /></Protected>} />
        <Route path="/view-item-inv/:id" element={<Protected ><ViewItem /></Protected>} />

        <Route path="/inv-transaction" element={<Protected ><TransactionTable /></Protected>} />
        <Route path="/inv-add-transaction" element={<Protected ><AddTransaction /></Protected>} />
        <Route path="/inv-edit-transaction/:id" element={<Protected ><EditTransaction /></Protected>} />
        <Route path="/inv-view-transaction/:id" element={<Protected ><ViewTransaction /></Protected>} />

        <Route path="/inv-balance" element={<Protected ><Balance /></Protected>} />
        <Route path="/view-balance-history/:item/:location" element={<Protected ><ViewBalanceHistory /></Protected>} />
        <Route path="/balance-history-trans-detail/:id" element={<Protected ><ViewTransactionDetail /></Protected>} />

        <Route path="/timesheet" element={<Protected ><Timesheet /></Protected>} />
        <Route path="/review-timesheet/:id" element={<Protected ><ReviewTimesheet /></Protected>} />
        <Route path="/global-timesheet-summary" element={<Protected ><GlobalSummary /></Protected>} />
       
        <Route path="/act-summary" element={<Protected ><ActSummary /></Protected>} />

        <Route path="/act-trans" element={<Protected ><ActTransaction /></Protected>} />
        <Route path="/add-act-trans" element={<Protected ><AddActTrans /></Protected>} />
        <Route path="/edit-act-trans/:id" element={<Protected ><EditActTrans /></Protected>} />

        <Route path="/act-fee" element={<Protected ><AccountFee /></Protected>} />
        <Route path="/act-fee-add-all" element={<Protected ><AddFeeToAll /></Protected>} />
        <Route path="/add-act-fee" element={<Protected ><AddAccountFee /></Protected>} />
        <Route path="/edit-act-fee/:id" element={<Protected ><EditAccountFee /></Protected>} />

        <Route path="/enq-form" element={<Protected ><GeneralEnquiryForm /></Protected>} />
        <Route path="/enq-dashboard" element={<Protected ><EnquiryDashboard /></Protected>} />

        <Route path="/admission-dashboard" element={<Protected ><AdmissionDashboard /></Protected>} />
        <Route path="/online-admission-form/:id" element={<OnlineAdmissionFormComp />} />

        <Route path="/my-slots" element={<Protected ><MySlots /></Protected>} />

        <Route path="/help" element={<Protected ><Help /></Protected>} />
        <Route path="/activity-log" element={<Protected ><ActivityLog /></Protected>} />
        <Route path="/activity-table" element={<Protected ><ActivityTable /></Protected>} />
        {/* <Route  path="/video-player" element={ <Protected ><VideoPlayer/></Protected>} /> */}
        <Route path="/documentation" element={<Protected ><Document /></Protected>} />
        <Route path="/whatsnew" element={<Protected ><ReleaseNote /></Protected>} />

        <Route path="/help-videos" element={<Protected ><HelpVideos /></Protected>} />

        <Route path="/forget-pass" element={<ForgetPass />} />
        <Route path="/reset-pass/:id" element={<ResetPass />} />
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Error />} />

      </Routes >
    </>
  );
};

export default App;
