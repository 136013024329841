import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Heading from "../../../Common/Components/Heading";
import { devUrl } from "../../../Common/URL";

const HelpComp = () => {

    return (
        <>

            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Help</li>
                    </ol>
                </nav>

                <Heading name="Help" />
                <div className="container-fluid mb-5">

                    <div className="row mb-5">
                        <div className="col-12 mx-auto">
                            <nav>
                                <div className="nav mb-4" id="nav-tab" role="tablist">
                                    <a className="nav_tab nav-link active" data-bs-toggle="tab" href="#nav-help-form" role="tab" aria-controls="nav-help-form" aria-selected="true">Raise Help Tickets</a>
                                    <a className="nav_tab nav-link" data-bs-toggle="tab" href="#nav-help-doc" role="tab" aria-controls="nav-help-doc" aria-selected="false">Help Documentation</a>
                                </div>
                            </nav>

                            <div className="tab-content" id="nav-tabContent">
                                {/* ----------------------Personal- Information------------------------------- */}
                                <div className="tab-pane fade show active" id="nav-help-form" role="tabpanel" aria-labelledby="nav-helpform">
                                    <iframe src="https://helpdesk.guruscool.com/Tickets/AskHelpWidgetForm?formActionUrl=http://helpdesk.guruscool.com/Tickets/CreateWidget&submitBtnCssClass=btn_blue&submitBtnText=Submit&userNameCssClass=&formTitle=U3VwcG9ydCBSZXF1ZXN0&formHeight=700&attachmentActive=true&userNameFieldTitle=Email+Address&userNameFieldId=user-email&productFieldTitle=Product&productFieldId=cayzu_product_name&subjectFieldTitle=Subject&descriptionFieldTitle=Description&formSubmitMessage=VGhhbmsgeW91IGZvciBjb250YWN0aW5nIHN1cHBvcnQuIFdlIHdpbGwgcmV2aWV3IGFuZCBhZGRyZXNzIHlvdXIgcmVxdWVzdCBhdCB0aGUgZWFybGllc3Qu&lang=en-US&token=NEdaVzF3REswbXh5dkhTTXl4bXF6ZWMyUzZyYzF0YTE1UHJYaUdiTW5zcTgvYmw4Z3lSWjk3OHgrcFk0SWkyTQ2&customCssUrl=&formFieldId=widget_form_id&formId=" height="800px" width="100%" frameBorder="0"></iframe>
                                </div>
                                {/* ----------------------Personal-Information-End------------------------------- */}

                                {/* ------------------------Notes-tab-Start------------------------------- */}
                                <div className="tab-pane fade" id="nav-help-doc" role="tabpanel" aria-labelledby="nav-helpdoc">
                                    {sessionStorage.getItem("role") === "Student" && <embed src={`${devUrl}role_pdf/hd_parent_student.pdf`} width="100%" height="2100" />}
                                    {sessionStorage.getItem("role") === "Teacher" && <embed src={`${devUrl}role_pdf/hd_teacher.pdf`} width="100%" height="2100" />}
                                    {sessionStorage.getItem("role") === "Location Manager" && <embed src={`${devUrl}role_pdf/hd_manager.pdf`} width="100%" height="2100" />}
                                    {sessionStorage.getItem("role") === "Superadmin" && <embed src={`${devUrl}role_pdf/hd_superadmin.pdf`} width="100%" height="2100" />}
                                    {sessionStorage.getItem("role") === "Admin" && <embed src={`${devUrl}role_pdf/hd_admin.pdf`} width="100%" height="2100" />}
                                    {sessionStorage.getItem("role") === "Systemadmin" && <embed src={`${devUrl}role_pdf/hd_superadmin.pdf`} width="100%" height="2100" />}
                                </div>
                                {/* --------------------------Notes-Tab-End-------------------------- */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HelpComp;