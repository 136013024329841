import React, { useState, useEffect } from "react";
import Input from "../../Student/Component/Input";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Heading from "../../../../Common/Components/Heading";
import { toast } from "react-toastify";
import axios from "axios";
import url from "../../../../Common/URL";
import { Button, CircularProgress } from "@mui/material";

import StringToTime from "../../../../Common/Functions/StringDateTime";
import { useSelector } from "react-redux";

const EditBatchComp = () => {
    const navigate = useNavigate();

    let message = "";
    const [loc, setLoc] = useState([]);
    // const [lev , setLev] = useState([]);
    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [form_data, setForm_data] = useState(true); //Used to compare if any update is done in form data.

    const { id } = useParams();

    const [state, setState] = useState({
        uname: sessionStorage.getItem("username"),
        batch_name: "", location_name: "", level_name: "", day_of_week: "",
        time_of_day: "", batch_calendar_link: "", remarks: "", batch_id: "",
        batch_calendar_file: "", location_id: "", city: ""
    });

    useEffect(() => {
        loadBatch();
        loadLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----------This function will Fetch all the Existing Locations--------------------
    const loadLocation = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        axios.post(url + "fetch_locations", obj)
            .then((response) => {
                let a = response.data.data;
                //   console.log(a);
                setLoc(a);
            })
            .catch((err) => console.log(err));
    };

    // ------------------------------------------------------
    const loadBatch = () => {
        const obj = {
            uname: state.uname,
            id: id
        };
        axios.post(url + "fetch_single_batch", obj)
            .then((response) => {
                let resData = response.data.data;
                // console.log(resData);
                setForm_data(resData);       //THis will help in checking any update done in form, so we can enable or disable the save form button
                setState(pre => ({
                    ...pre,
                    batch_name: resData[0].batch_name, location_name: resData[0].location_name,
                    location_id: resData[0].location_id, city: resData[0].city,
                    level_name: resData[0].level_name, day_of_week: resData[0].day_of_week,
                    batch_id: resData[0].batch_id, time_of_day: resData[0].time_of_day,
                    batch_calendar_link: resData[0].batch_calendar_link, remarks: resData[0].remarks,
                }));
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setDisable(true);
            });
    };

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        if (form_data[0][name] !== value) {
            setDisable(false);
        } else {
            setDisable(true);
        }

        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            };
        });
    };

    const onLoc_Change = (e) => {
        var data = e.target.value.split("*");
        var loc_name, loc_city = "", loc_id;

        if (data.length === 2) {
            loc_name = data[0];
            loc_id = data[1];
        } else {
            loc_name = data[0];
            loc_city = data[1];
            loc_id = data[2];
        }
        // making save button disable or enable
        if (form_data[0].location_name !== data[0]) {
            setDisable(false);
        } else {
            setDisable(true);
        }
        // console.log(loc_name);console.log(loc_city);console.log(loc_id);

        setState(pre => {
            return {
                ...pre, location_id: loc_id, location_name: loc_name, city: loc_city
            };
        });
    };

    const onSubmit = (e) => {

        e.preventDefault();
        setDisable(true);
        setLoading(true);
        /*---------------Fetching Date in YEAR-MONTH-DATE  format-------------------------- */
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();

        var newdate = year + "-" + month + "-" + day;

        /*---------------Creating object to send data through Axios------------------------- */
        const obj = {
            uname: sessionStorage.getItem("username"), updated_by: sessionStorage.getItem("username"),
            updated_date: newdate, id: id,
            batch_name: `${state.day_of_week} - ${state.city === "" ? state.location_name : `${state.location_name} - ${state.city}`} - ${StringToTime(state.time_of_day)}`,
            location_name: state.location_name, city: state.city,
            location_id: state.location_id,
            level_name: state.level_name, day_of_week: state.day_of_week,
            time_of_day: state.time_of_day, batch_calendar_link: state.batch_calendar_link,
            remarks: state.remarks
        };

        axios.post(url + "edit_batch", obj)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    loadBatch().then(() => {
                        toast.success("Updated successfully");
                    });
                } else {
                    message = res.data.message;
                    toast.error(message);
                }
            })
            .catch((err) => console.log(err)).finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item"><Link to="/batch">Batch List</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Edit Batch</li>
                    </ol>
                </nav>


                <Heading name={`Edit Batch - ${state.batch_name}`} />

                <div className="container-fluid">

                    <form onSubmit={onSubmit}>

                        <div className="row mb-4">
                            {/*-----------------Header------------------------  */}
                            <div className="col-12 d-flex justify-content-between  align-items-end ">
                                <h5>Batch Information</h5>
                                <div>
                                    <Button variant="contained" onClick={() => navigate("/view-batch/" + state.batch_id)} >Back</Button>
                                    <Button variant="contained" type="submit" disabled={disable || loading} className="ms-3">{loading ? <CircularProgress color="inherit" size={20} /> : 'Save'}</Button>
                                </div>
                            </div>
                        </div>

                        <div className="row ">
                            {/* -----------------First Column----------------- */}
                            <div className="col-11 col-md-6 mx-auto">

                                {/* <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Batch ID</label>
                        <div className="col-sm-7 col-md-7 ">
                        <input type="text" readOnly className="form-control bg-light border-0 fw-bold" defaultValue={state.batch_id} />
                        </div>
                    </div> */}

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label">Batch Name</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <input type="text" readOnly className="form-control bg-light border-0 fw-bold" defaultValue={state.batch_name} />
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Location</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select name="location_name"
                                            value={state.city === "" ? `${state.location_name}*${state.location_id}` : `${state.location_name}*${state.city}*${state.location_id}`}
                                            onChange={onLoc_Change} className="form-select" required>
                                            <option value="">Choose</option>
                                            {loc.map((data, i) => (<option key={i} value={data.city === "" ? `${data.name}*${data.location_id}` : `${data.name}*${data.city}*${data.location_id}`}>{data.name}</option>))}
                                        </select>
                                    </div>
                                </div>

                                {/* <div className="mb-3 row">
                    <label className="col-sm-5 col-form-label">Level</label>
                    <div className="col-sm-7 col-md-7 ">
                        <select name="level_name" value={state.level_name} onChange={onChange} className="form-select">
                        <option value="">Choose</option>
                        {lev.map((data , i)=>(<option key={i} value={data.level_name}>{data.level_name}</option>)) } 
                        </select>
                    </div>
                </div> */}

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Day of Week</label>
                                    <div className="col-md-7 col-sm-7">
                                        <select name="day_of_week" value={state.day_of_week} onChange={onChange} className="form-select" required>
                                            <option value="">Choose</option><option value="Monday">Monday</option><option value="Tuesday">Tuesday</option>
                                            <option value="Wednesday">Wednesday</option><option value="Thursday">Thursday</option><option value="Friday">Friday</option>
                                            <option value="Saturday">Saturday</option><option value="Sunday">Sunday</option>
                                        </select>
                                    </div>
                                </div>
                                <Input name="time_of_day" onChange={onChange} value={state.time_of_day} field="Time of day" type="time" className="form-control" required={true} />

                            </div>
                            {/* ------------Second Column----------------- */}
                            <div className="col-11 col-md-6 mx-auto">
                                <div className="mb-3 row">
                                    <label className=" col-sm-5 col-form-label">Batch Calendar Link</label>
                                    <div className="col-sm-7 col-md-7">
                                        <input name="batch_calendar_link" onChange={onChange} value={state.batch_calendar_link} type="text" className="form-control" placeholder="Add link" />
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className=" col-sm-5 col-form-label">Remarks</label>
                                    <div className="col-sm-7 col-md-7">
                                        <textarea name="remarks" onChange={onChange} value={state.remarks} type="text" className="form-control" rows="2" placeholder="Add remarks here..." />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                </div>

            </div>
        </>
    );
};

export default EditBatchComp;