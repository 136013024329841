import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress, DialogActions } from "@mui/material";
import axios from "axios";
import url from "../../Common/URL";
import "./../login.css";

// import { toast } from "react-toastify";
import { Dialog, DialogContent } from "@mui/material";
import Input from '@mui/material/Input';
import { toast } from "react-toastify";

const ForgotPasswordComp = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        email: "",
    });

    const [loading, setLoading] = useState(false);

    const onChange = (e) => {

        const input = e.target;
        const name = input.name;
        const value = input.value;

        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            };
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        /*---------------Creating object to send data through Axios------------------------- */
        const obj = {
            email: state.email
        };
        axios.post(url + "forgot_password", obj)
            .then((res) => {
                // console.log(res) 
                if (res.data.success) {
                    setOpen(true);
                    setState({
                        email: ""
                    });
                } else {
                    console.log(res);
                    toast.error("Given email address is not present in our database.");
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <div className='container-fluid auth-page d-flex flex-column'>
                <Dialog
                    fullWidth={true}
                    maxWidth="sm"
                    open={open}
                    // onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <h4 className="" id="transition-modal-title">Great! You've successfully initiated the password reset process.</h4>
                        <hr />
                        <p id="transition-modal-description" className="">
                            Check your email. We've just sent you an email with instructions on how to reset your password. Please follow the steps outlined in the email to securely set up your new password.
                        </p>
                        <hr />
                        <p className="">
                            If you don't see the email in your inbox, please check your spam folder. It might take a few minutes to arrive. If you encounter any issues, feel free to contact our support team for assistance.
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" className="me-3 mb-3" onClick={() => {
                            setOpen(false);
                            navigate('/');
                        }}  >Okay
                        </Button>
                    </DialogActions>
                </Dialog>
                <div className='row justify-content-center forgot-password-form my-auto '>
                    <div className='card col-auto col-md-4  p-4 px-md-5 shadow forgot-password-form '>
                        <h2 className="text-center mb-3 mt-2 forgot-password-form-heading">Forgot Password</h2><hr />
                        <h6 className="text-center mb-3">Please enter your email address below and we will help you to change your password.</h6>

                        <form onSubmit={onSubmit}>
                            <div className="mb-3 mt-2 d-flex flex-column align-items-start">
                                {/* <label htmlFor="exampleInputEmail1">Email address</label> */}
                                <Input
                                    required
                                    autoFocus
                                    type="email"
                                    className="form-control shadow-sm border-0"
                                    id="uname"
                                    value={state.uname}
                                    placeholder="Email Address"
                                    onChange={onChange}
                                    autoComplete="off"
                                    name="email"
                                />
                                <small id="emailHelp" className="form-text text-muted mt-2 ps-2">We'll never share your email with anyone else.</small>
                                <Button variant="contained" type="submit" className=" mb-3 shadow mt-5 w-50"> {loading ? <CircularProgress size={20} color="inherit" /> : 'Reset Password'}</Button>

                            </div>
                        </form>


                    </div>
                </div>
            </div>
        </>
    );
};
export default ForgotPasswordComp;