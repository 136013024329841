import { DeleteOutline } from "@mui/icons-material";
import { Button, Drawer, IconButton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import MaterialReactTable from 'material-react-table';
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Heading from "../../../../../Common/Components/Heading";
import StringToTime, { ReadableDateTime } from "../../../../../Common/Functions/StringDateTime";
import url from "../../../../../Common/URL";
import AdmissionEnquiryFormComp from "./AdmissionEnquiryFormComp";
import EnquiryDetailsComp from "./EnquiryDetailsComp";
const EnquiryDashboardComp = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const Columns = useMemo(
        () => [
            { header: 'Name', size: 40, id: 'name', accessorFn: rowData => `${rowData.fname} ${rowData.lname}` },
            { header: 'Email', size: 40, accessorKey: 'email' },
            { header: 'Phone', size: 40, accessorKey: 'phone' },
            { header: 'Status', size: 40, accessorKey: 'status' },
            { header: 'Assigned To', size: 40, accessorKey: 'assigned_to' },
            { header: 'Enquired On', size: 40, id: 'created_at', accessorFn: rowData => ReadableDateTime(rowData.created_at) },
        ],
        [],
    );

    useEffect(() => {
        LoadAdmissionEnquiries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----------This function will Fetch all the Existing Locations--------------------

    const LoadAdmissionEnquiries = async (res) => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "adm_enquiries", obj)
            .then((response) => {
                let a = response.data.data ?? [];
                //  console.log(a);

                a.length ? setData(a) : setData([]);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
                res && res();
            });
    };

    const [openAddEnquiryDrawer, setOpenAddEnquiryDrawer] = useState(false);
    const [openEnquiryDetailsDrawer, setOpenEnquiryDetailsDrawer] = useState(false);
    const [selectedEnquiryId, setSelectedEnquiryId] = useState(null);

    return (
        <>

            {/* Side Drawer for Adding Enquiry */}
            <Drawer
                PaperProps={{ style: { width: '95%' } }}
                anchor='right'
                open={openAddEnquiryDrawer}
                onClose={() => setOpenAddEnquiryDrawer(false)}
            >
                <AdmissionEnquiryFormComp setOpenDrawer={setOpenAddEnquiryDrawer} loadParentData={LoadAdmissionEnquiries} />
            </Drawer >


            {/* Side Drawer viewing enquiry details*/}
            <Drawer
                PaperProps={{ style: { width: '95%' } }}
                anchor='right'
                open={openEnquiryDetailsDrawer}
                onClose={() => setOpenEnquiryDetailsDrawer(false)}
            >
                <EnquiryDetailsComp id={selectedEnquiryId} setOpenDrawer={setOpenEnquiryDetailsDrawer} />
            </Drawer >

            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">CRM</li>
                        <li className="breadcrumb-item active" aria-current="page">Enquiry Dashboard</li>
                    </ol>
                </nav>

                <Heading name="Enquiry Forms" />

                <div className="container-fluid mt-3">
                    <div className="row my-4">
                        <div className="col-12 mx-auto d-flex ">
                            <Button variant="contained" onClick={() => setOpenAddEnquiryDrawer(true)} className="ms-auto">
                                Add Admission Enquiry
                            </Button>
                        </div>
                    </div>

                    <div className="row my-3">
                        <div className="col-12">

                            <MaterialReactTable
                                state={{ isLoading: loading }}
                                columns={Columns}
                                data={data}
                                enableRowNumbers
                                initialState={{ density: 'compact' }}
                                enableDensityToggle={false}

                                getRowId={(originalRow) => originalRow.enq_id}

                                renderDetailPanel={({ row }) => (
                                    <Box
                                        sx={{ display: 'grid', margin: 'auto', gridTemplateColumns: '1fr 1fr', width: '100%', }}
                                    >
                                        <Typography>Preferred Location: {row.original.location}</Typography>
                                        <Typography>Preferred Date: {row.original.preferred_date}</Typography>
                                        <Typography>Preferred Time: {StringToTime(row.original.preferred_time)}</Typography>
                                        <Typography>Message: {row.original.message}</Typography>
                                        <Typography>Came through: {row.original.campaign}</Typography>
                                    </Box>
                                )}

                                // enableRowActions
                                renderRowActions={(row, index) => (

                                    <Tooltip title="Delete Enquiry" placeholder="right">
                                        <IconButton onClick={(event) => {
                                            event.stopPropagation();
                                            let rowData = row.row.original;
                                            const obj = { uname: sessionStorage.getItem("username"), id: rowData.enq_id };
                                            console.log(obj);
                                            axios.post(url + "delete_adm_enquiry", obj)
                                                .then(res => {
                                                    console.log(res);
                                                    let promise = new Promise((res, rej) => LoadAdmissionEnquiries(res));
                                                    promise.then(() => toast.info("Enquiry deleted"));
                                                })
                                                .catch(res => toast.error("Error occurred while deleting, please try again later."));
                                        }}>
                                            <DeleteOutline />
                                        </IconButton>
                                    </Tooltip>

                                )}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: () => {
                                        setSelectedEnquiryId(row.id);
                                        setOpenEnquiryDetailsDrawer(true);
                                    },
                                    sx: { cursor: 'pointer' },
                                })}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};
export default EnquiryDashboardComp;