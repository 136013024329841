import React from "react";

const Input = (props) => {
    return (
        <>
            <div className="mb-3 row">
                <label className={props.required === true ? "col-sm-5 col-form-label red-asterisk" : "col-sm-5 col-form-label"}>{props.field}</label>
                <div className="col-sm-7 col-md-7 ">
                    <input type={props.type ?? 'text'}
                        placeholder={props.placeholder ?? '--'}
                        className={props.className}
                        value={props.value ?? ''}
                        name={props.name}
                        onChange={props.onChange}
                        required={props.required}
                        disabled={props.disabled}
                    />
                </div>
            </div>
        </>
    )
}

const ReadonlyInput = (props) => {
    return (
        <>
            <div className="mb-3 row">
                <label className="col-sm-5 col-form-label">{props.field}</label>
                <div className="col-sm-7 col-md-7 ">
                    <input type={props.type ?? 'text'}
                        placeholder={props.placeholder ?? '--'}
                        className={props.className ?? 'form-control bg-light border-0'}
                        value={props.value ?? ''}
                        name={props.name}
                        // onChange={props.onChange}
                        // id="readonly_input_fields"
                        readOnly
                    />
                </div>
            </div>
        </>
    )
}
export default Input;
export { ReadonlyInput };