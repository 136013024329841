import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
    name: 'login',
    initialState: false,
    reducers: {
        SIGN_IN: (state) => {
            return !state;
        },
        LOG_OUT: (state) => {
            return false;
        }
    }
})

export const { SIGN_IN, LOG_OUT } = loginSlice.actions;

export default loginSlice.reducer;