export const Data =[
    {number : 1, name : "How to use abacus", level: 1 , url : "wzx2JddJAPw"},
    {number : 2, name : "Basic addition and subtraction", level: 1 , url : "9Kb-7TkqbX4"},
    {number : 3, name : "Adding 6,7,8,9 using abacus", level: 1 , url : "9HMMs4IpKuI"},
    {number : 4, name : "Adding 1 using 5's complement", level: 1 , url : "iO836ICu8OY"},
    {number : 5, name : "Adding 2 using 5's complement", level: 1 , url : "2WIdzUgAbrI"},
    {number : 6, name : "Adding 3 using 5's complement", level: 1 , url : "0RxsmMCqNnI"},
    {number : 7, name : "Adding 4 using 5's complement", level: 1 , url : "0TvrPTFceAE"},
    {number : 8, name : "Subtracting 1 using 5's complement", level: 1 , url : "eUVVDqN35V0"},
    {number : 9, name : "Subtracting 2 using 5's complement", level: 1 , url : "CDaK-gb2T9g"},
    {number : 10, name : "Subtracting 2 using 5's complement", level: 1 , url : "C2nwa1EoWyc"},

    {number : 11, name : "Subtracting 4 using 5's complement", level: 1 , url : "Kn2FIi1bS7o"},
    {number : 12, name : "Adding 1 using 10's complement", level: 1 , url : "Muj_U5EGYDc"},
    {number : 13, name : "Adding 2 using 10's complement", level: 1 , url : "tpZzCrEfNRc"},
    {number : 14, name : "Adding 3 using 10's complement", level: 1 , url : "PZuIhG0h9Zk"},
    {number : 15, name : "Adding 4 using 10's complement", level: 1 , url : "RJZirhALPts"},
    {number : 16, name : "Adding 5 using 10's complement", level: 1 , url : "boip7IZjLIs"},
    {number : 17, name : "Adding 6 using 10's complement", level: 1 , url : "f6FSUnFzHuo"},
    {number : 18, name : "Adding 7 using 10's complement", level: 1 , url : "bV7Zo05lJOE"},
    {number : 19, name : "Adding 8 using 10's complement", level: 1 , url : "ZBCEPUxko1g"},
    {number : 20, name : "Adding 9 using 10's complement", level: 1 , url : "zhtP-jDTZyI"},

    {number : 21, name : "Subtracting 1 using 10's complement", level: 1 , url : "l7PI27SsNwo"},
    {number : 22, name : "Subtracting 2 using 10's complement", level: 1 , url : "P6LXNRwyP3E"},
    {number : 23, name : "Subtracting 3 using 10's complement", level: 1 , url : "JnsVlMHSIEg"},
    {number : 24, name : "Subtracting 4 using 10's complement", level: 1 , url : "0wCkbt_HDuc"},
    {number : 25, name : "Subtracting 5 using 10's complement", level: 1 , url : "-rw_nmWBK3Y"},
    {number : 26, name : "Subtracting 6 using 10's complement", level: 1 , url : "HTSWZecX400"},
    {number : 27, name : "Subtracting 7 using 10's complement", level: 1 , url : "JGIvHeUsPgc"},
    {number : 28, name : "Subtracting 8 using 10's complement", level: 1 , url : "aD1CjOKJuGM"},
    {number : 29, name : "Subtracting 9 using 10's complement", level: 1 , url : "jb8MOdGgd0g"},
    {number : 30, name : "Plus 6 using mixed complements", level: 2 , url : "7kP9fcZwFjk"},                      

    {number : 31, name : "Plus 7 using mixed complements", level: 2 , url : "xh_ifhEmXXg"},
    {number : 32, name : "Plus 8 using mixed complements", level: 2 , url : "DJIST1CkVoo"},
    {number : 33, name : "Plus 9 using mixed complements", level: 2 , url : "7B8GgY_ay_M"},
    {number : 34, name : "Minus 6 using mixed complements", level: 2 , url : "kQAncdPWJG8"},
    {number : 35, name : "Minus 7 using mixed complements", level: 2 , url : "DsWjcgTNW6U"},
    {number : 36, name : "Minus 8 using mixed complements", level: 2 , url : "JchtCt3gpyg"},
    {number : 37, name : "Minus 9 using mixed complements", level: 2 , url : "thEbto-4618"},
    {number : 38, name : "Adding using formulas in 10's & 100's column", level: 3 , url : "unk1qQumIjQ"},
    {number : 39, name : "Double times single multiplication", level: 3 , url : "N1qjN10fgZU"},
    {number : 40, name : "Triple times single multiplication", level: 3 , url : "iVpoR7bubuU"},

    {number : 41, name : "Single times triple multiplication", level: 4 , url : "n6ywxatJyxQ"},
    {number : 42, name : "Double times double multiplication", level: 4 , url : "FUzKDjGNt4I"},
    // {number : 43, name : "", level: 1 , url : ""},
    // {number : 44, name : "", level: 1 , url : ""},
    // {number : 45, name : "", level: 1 , url : ""},
    // {number : 46, name : "", level: 1 , url : ""},
    // {number : 47, name : "", level: 1 , url : ""},
    // {number : 48, name : "", level: 1 , url : ""},
    // {number : 49, name : "", level: 1 , url : ""},
    // {number : 50, name : "", level: 1 , url : ""},

    // {number : 51, name : "", level: 1 , url : ""},
    // {number : 52, name : "", level: 1 , url : ""},
    // {number : 53, name : "", level: 1 , url : ""},
    // {number : 54, name : "", level: 1 , url : ""},
    // {number : 55, name : "", level: 1 , url : ""},
    // {number : 56, name : "", level: 1 , url : ""},
    // {number : 57, name : "", level: 1 , url : ""},
    // {number : 58, name : "", level: 1 , url : ""},
    // {number : 59, name : "", level: 1 , url : ""},
    // {number : 60, name : "", level: 1 , url : ""},

    // {number : 61, name : "", level: 1 , url : ""},
    // {number : 62, name : "", level: 1 , url : ""},
    // {number : 63, name : "", level: 1 , url : ""},
    // {number : 64, name : "", level: 1 , url : ""},
    // {number : 65, name : "", level: 1 , url : ""},
    // {number : 66, name : "", level: 1 , url : ""},
    // {number : 67, name : "", level: 1 , url : ""},
    // {number : 68, name : "", level: 1 , url : ""},
    // {number : 69, name : "", level: 1 , url : ""},
    // {number : 70, name : "", level: 1 , url : ""},
]


 