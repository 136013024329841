import random from 'random';
import { gcd, lcm, sum, fix, pickRandom, min } from "mathjs";
import NP from 'number-precision';
import assignmentQuestionsData, { abacusUnitRodLogic, fivesComplement, tensComplement } from "./AssignmentQuestionsLogic";
import shuffleArray from '../Functions/ShuffleArray';


/*    firstNumLimit = [start, end, isDecimal] --- For mul, div, per, gcd, lcm questions only
      secondNumLimit = [start, end, isDecimal] --- For mul, div, per, gcd, lcm questions only
      digits = [number of digit, isDecimal] --- For cbrt & sqrt questions only

      numLimit = [start, end, isDecimal]  --- For add_sub questions only
      numLimitSeconds: [start, end, isDecimal], --- For add_sub questions only,  'numLimitSeconds' its an optional limit if any question needs it
         

      firstNumber => This is used in Mixed complements sum, and is used to generate a number to which the add value is added
      limit => This is used in Mixed complements sum, to create a sequence of number of x numbers. 
            Eg. limit = 3 then sequence will be [11,12,13], limit= 4 then sequence will be [11,12,13,14]
      que_type => 0: Its Mind Math Question, 1: Its Abacus Question 
  */

/* 
   logicType = 1 => Non Complement Questions
   logicType = 2 => Complement Questions - 5's OR 10's complement, and random also when 'complement' key is sent null
   logicType = 3 => Mixed Complements Questions
   logicType = 4 => Combination of 5's, 10's & Mixed Complements
   logicType = 5 => Arithmetic Questions - (+, -, /, X, Sqrt., Cbrt., LCM, GCD, Percentage)  
*/

const logic1 = (row, count) => {
    let questions = [], questionsCopy = [], answers = [];
    for (let i = 0; i < count; i++) {
        let questionArray = [pickRandom([1, 2, 3, 4, 5])];

        while (questionArray.length < row) {
            let temp = sum(questionArray);
            let insertNumber = pickRandom(abacusUnitRodLogic[temp]);
            questionArray.push(insertNumber);
        }

        questions.push(questionArray);
        questionsCopy.push(questionArray.slice());
        answers.push(sum(questionArray));
    }
    return [questions, questionsCopy, answers];
};

const logic2 = (object, row, count) => {

    let complementType = object.complement ? object.complement : pickRandom([5, 10]);
    let addValue = object.add;
    let tempValue;  // Used when we have addValue is null
    let questions = [], questionsCopy = [], answers = [];

    let oldNum = 0;
    while (questions.length < count) {
        let addAt = addNumberAtPosition(row);
        if (addValue === null) {
            tempValue = complementType === 5 ?
                pickRandom([1, 2, 3, 4, -1, -2, -3, -4]) :
                pickRandom([1, 2, 3, 4, 5, 6, 7, 8, 9, -1, -2, -3, -4, -5, -6, -7, -8, -9]);
        }
        let numberToBeParted = complementType === 5 ?
            pickRandom(fivesComplement[addValue ? addValue : tempValue]) : pickRandom(tensComplement[addValue ? addValue : tempValue]);
        let partCount = addAt - 1;
        //'partCount' provides the number, which tells how many parts we need to do of a given number

        let generatedCombinationArray = makeParts(numberToBeParted, partCount, complementType, object.level);

        generatedCombinationArray.push(addValue ? addValue : tempValue);

        while (generatedCombinationArray.length < row) {
            let temp = sum(generatedCombinationArray);
            let insertNumber = pickRandom(abacusUnitRodLogic[temp]);
            if (insertNumber !== oldNum) {
                // The If block below restricts creating of questions that students are not familiar with yet
                // the 3rd condition in if block '((addValue || tempValue) > 0)' restricts the block to execute for subtract type 
                // of complement questions, bcoz they don't need the block code to execute for proper functioning
                if (complementType === 10 && (object.level === 1) && ((addValue ? addValue : tempValue) > 0)) {
                    let total = sum([...generatedCombinationArray, insertNumber]);
                    if (total > 10)
                        generatedCombinationArray.push(insertNumber);
                }
                else
                    generatedCombinationArray.push(insertNumber);

            }
            oldNum = insertNumber;
        }

        questions.push(generatedCombinationArray);
        questionsCopy.push(generatedCombinationArray.slice());
        answers.push(sum(generatedCombinationArray));
    }
    return [questions, questionsCopy, answers];
};

const logic3 = (object, row, count) => {
    /*  Mixed complements Logic for Future Reference
        the +6,+7,+8,+9 & -6,-7,-8,-9 can be done only when any number from the respective array is there.
       
        +6 = [5,6,7,8,      15,16,17,18,    25,26,27,28,    35,36,37,38, ...]   ->  firstNumber = 5, limit = 4
        +7 = [5,6,7,        15,16,17,       25,26,27,       35,36,37, ...]      ->  firstNumber = 5, limit = 3
        +8 = [5,6,          15,16,          25,26,          35,36, ...]         ->  firstNumber = 5, limit = 2
        +9 = [5,            15,             25,             35, ...]            ->  firstNumber = 5, limit = 1

        -6 = [11,12,13,14,  21,22,23,24,    31,32,33,34,    41,42,43,44, ...]   ->  firstNumber = 11, limit = 4
        -7 = [12,13,14,     22,23,24,       32,33,34,       42,43,44, ...]      ->  firstNumber = 12, limit = 3
        -8 = [13,14,        23,24,          33,34,          43,44, ...]         ->  firstNumber = 13, limit = 2
        -9 = [14,           24,             34,             44, ...]            ->  firstNumber = 14, limit = 1
    */

    let questions = [], questionsCopy = [], answers = [];
    let addValue = object.add;

    while (questions.length < count) {
        let generatedCombinationArray = [];
        let numberToWhichAddValueWillBeAdded = getNumberForMixedComplementSum(object);

        generatedCombinationArray.push(numberToWhichAddValueWillBeAdded);
        generatedCombinationArray.push(addValue);

        while (generatedCombinationArray.length < row) {
            let temp = sum(generatedCombinationArray);
            const lastDigit2Str = String(temp).slice(-1);
            const lastDigit2Num = Number(lastDigit2Str);
            let insertNumber = pickRandom(abacusUnitRodLogic[lastDigit2Num]);
            generatedCombinationArray.push(insertNumber);
        }

        questions.push(generatedCombinationArray);
        questionsCopy.push(generatedCombinationArray.slice());
        answers.push(sum(generatedCombinationArray));
    }
    return [questions, questionsCopy, answers];

};

const logic4 = (row, count) => {
    let questions = [], questionsCopy = [], answers = [];

    // This threePartArray will tell about the number of questions need to be generated of 5's, 10's & Mixed Complements Respectively
    // threePartArray = [a,b,c]; => 
    // means a = no. of questions of 5's Complement, b = no. of questions of 10's complement, c = no. of questions of Mixed complement 
    let threePartArray = returnThreeParts(count);
    // obj1, obj2, obj3 for 5's, 10's & Mixed complement respectively
    let obj1 = { complement: 5, add: null }, obj2 = { complement: 10, add: null };

    let qSet1 = logic2(obj1, row, threePartArray[0]);
    let qSet2 = logic2(obj2, row, threePartArray[1]);
    let qSet3 = generateRandomMixedComplementQuestions(row, threePartArray[2]);

    questions = [...qSet1[0], ...qSet2[0], ...qSet3[0]];
    questionsCopy = [...qSet1[1], ...qSet2[1], ...qSet3[1]];
    answers = [...qSet1[2], ...qSet2[2], ...qSet3[2]];

    return [questions, questionsCopy, answers];
};

// Below function will generate Questions of type - (+, -, /, X, Sqrt., Cbrt., LCM, GCD, Percentage)
const GenerateMathQuestions = (object, row, count) => {
    let questions = [], questionsCopy = [], answers = [];
    let type = object.type;
    switch (type) {
        case 'add':
            while (questions.length < count) {
                let tempArray = [];
                for (let i = 0; i < row; i++) {
                    tempArray.push(generateNumberBetweenLimits(object.numLimit));
                }
                let ans = NP.plus(...tempArray.map(x => Number(x)));
                answers.push(ans);
                questions.push(tempArray.slice());
                questionsCopy.push(tempArray);
            }
            break;

        case 'add_sub':


            while (questions.length < count) {
                let numLimit;
                let randomLimitIndexArray = [];
                let limitArray;
                // Below if,Else will take care of 'numLimitSecond' if its present
                if (object.numLimitSecond) {
                    //Pushing random 1,0 in array
                    for (let i = 0; i < row; i++) {
                        randomLimitIndexArray.push(random.int(0, 1));
                    }

                    // Making sure that all the array elements are not same, if same then will alter one element value
                    if (areAllNumbersSame(randomLimitIndexArray)) {
                        let index = random.int(0, row - 1);
                        randomLimitIndexArray[index] = randomLimitIndexArray[index] === 0 ? 1 : 0;
                    }
                    limitArray = [object.numLimit, object.numLimitSecond];
                }

                let tempArray = [];
                let totalNegativeTerms = noOfNegativeTermsWeNeed(row);
                // 1 -> means positive number & 0 -> means negative number 
                let arrayOfOneZero = [];
                while (arrayOfOneZero.length < row) { // Filling full arrayOfOneZero with 1
                    arrayOfOneZero.push(1);
                }

                if (row === 2) {
                    // 1 -> means positive number & 0 -> means negative number 
                    arrayOfOneZero[1] = random.int(0, 1);
                } else {
                    while (countNegativeTerms(arrayOfOneZero) < totalNegativeTerms) { // Filling 0 at some places logically in arrayOfOneZero
                        let loc = random.int(2, row);
                        arrayOfOneZero[loc - 1] = 0;
                    }
                }

                for (let i = 0; i < arrayOfOneZero.length; i++) {

                    // Below if,Else will take care of 'numLimitSecond' if its present
                    if (object.numLimitSecond) {
                        numLimit = limitArray[randomLimitIndexArray[i]];
                    } else {
                        numLimit = object.numLimit;
                    }

                    switch (arrayOfOneZero[i]) {
                        case 1: // 1 Means we have to add a positive number in the tempArray
                            tempArray.push(generateNumberBetweenLimits(numLimit));
                            break;
                        case 0: // 0 Means we have to add a negative number in the tempArray
                            if (object.level === 10) {
                                let num = generateNumberBetweenLimits(numLimit);
                                tempArray.push((num * -1).toFixed(precision(numLimit[0])));
                            } else if (sum(tempArray) < numLimit[0]) {
                                // breaking normal flow of adding -ve number and instead adding +ve number
                                // as adding -ve number will lead to -ve intermediate value, which is not 
                                // desired before level 10
                                tempArray.push(generateNumberBetweenLimits(numLimit));
                            } else {
                                // Normal flow of adding -ve number
                                let upperLimit = NP.plus(numLimit[0], sum(tempArray));
                                upperLimit = NP.divide(upperLimit, 2).toFixed(precision(numLimit[0])); //Calculating Average
                                upperLimit = Number(upperLimit) > Number(numLimit[1]) ? numLimit[1] : upperLimit;
                                let num = generateNumberBetweenLimits([numLimit[0], Number(upperLimit), numLimit[2]]);
                                tempArray.push((num * -1).toFixed(precision(numLimit[0])));
                                // Here in the above step we have used toFixed because the multiplication of -1 makes (Ex. 30.0 to 30), 
                                // so it will again convert that 30 to 30.0
                            }

                            break;
                    }
                }
                let ans = NP.plus(...tempArray.map(x => Number(x)));
                answers.push(ans);
                questions.push(tempArray.slice());
                questionsCopy.push(tempArray);
            }
            break;

        case 'sub':
            while (questions.length < count) {
                let tempArray = [];
                tempArray.push(generateNumberBetweenLimits(object.firstNumLimit));
                for (let i = 0; i < (row - 1); i++) {   // here we are doing -1, as we have already added 1 number in above step
                    let num = generateNumberBetweenLimits(object.numLimit) * -1;
                    tempArray.push(num);
                }
                let ans = NP.plus(...tempArray.map(x => Number(x)));
                answers.push(ans);
                questions.push(tempArray.slice());
                questionsCopy.push(tempArray);
            }

            break;

        case 'sqrt':
            while (questions.length < count) {
                let queArray = generateSqrt(object.digits[0]); // This function will return an array ['Sqrt. of', Squared Number, Answer]
                let ans = queArray.pop();
                questions.push(queArray);
                questionsCopy.push(queArray.slice());
                answers.push(ans);
            }
            break;

        case 'cbrt':
            var prevNumber = [];       // this variable prevents creation of duplicate Cbrt questions
            while (questions.length < count) {
                let queArray = generateCbrt(object.digits[0]); // This function will return an array ['Cbrt. of', Cubed Number, Answer]
                if (prevNumber.indexOf(queArray[1]) < 0) {
                    let ans = queArray.pop();
                    questions.push(queArray);
                    questionsCopy.push(queArray.slice());
                    answers.push(ans);
                    prevNumber.push(queArray[1]);
                }

            }
            break;

        case 'lcm':
            while (questions.length < count) {
                let tempArray = [];
                let first = generateNumberBetweenLimits(object.firstNumLimit);  // This function will return a number
                let second = generateNumberBetweenLimits(object.secondNumLimit); // This function will return a number
                let ans = lcm(first, second);
                tempArray.push('LCM');
                tempArray.push(`( ${first}, ${second} )`);
                questions.push(tempArray.slice());
                questionsCopy.push(tempArray);
                answers.push(ans);
            }
            break;

        case 'gcd':
            while (questions.length < count) {
                let tempArray = [];
                let first = generateNumberBetweenLimits(object.firstNumLimit);
                let chosenFactor = pickRandom(getFactorsOfNumber(first));

                let lowerLimit = Math.ceil(object.secondNumLimit[0] / chosenFactor);
                let upperLimit = Math.floor(object.secondNumLimit[1] / chosenFactor);

                let MultipleTerm = generateNumberBetweenLimits([lowerLimit, upperLimit, object.secondNumLimit[3]]);
                let second = NP.times(chosenFactor, MultipleTerm);
                let ans = gcd(first, second);

                tempArray.push('GCD');
                tempArray.push(`( ${first}, ${second} )`);
                questions.push(tempArray.slice());
                questionsCopy.push(tempArray);
                answers.push(ans);
            }
            break;

        case 'per':
            while (questions.length < count) {
                let tempArray = [];
                let first = generateNumberBetweenLimits(object.firstNumLimit);
                let second = generateNumberBetweenLimits(object.secondNumLimit);
                let divide = NP.divide(first, 100);
                let ans = NP.times(divide, second);

                tempArray.push(`${first} % of`, second);
                questions.push(tempArray.slice());
                questionsCopy.push(tempArray);
                answers.push(ans);
            }
            break;

        case 'div':
            if (object.decimalAnswer) {   // This block handles the division with decimal answers
                while (questions.length < count) {
                    let tempArray = [];
                    let bigNum = generateNumberBetweenLimits(object.firstNumLimit);
                    let smallNum = generateNumberBetweenLimits(object.secondNumLimit);
                    let ans = NP.divide(bigNum, smallNum);
                    if (precision(ans) > 2) {
                        if (ans > 1) {
                            // The 'fix' function will restrict the number to two decimal places without rounding
                            ans = fix(ans, 2);
                        } else { // for answers less than 1 (Ex. 0.005, 0.322)
                            ans = fix(ans, 4);
                        }
                    }
                    answers.push(ans);
                    tempArray.push(bigNum, '/', smallNum);
                    questions.push(tempArray.slice());
                    questionsCopy.push(tempArray);
                }
            } else { // This block handles the division with non decimal answers
                while (questions.length < count) {
                    let tempArray = [];
                    let smallNum = generateNumberBetweenLimits(object.secondNumLimit);
                    let lowerLimit = Math.ceil(object.firstNumLimit[0] / smallNum);
                    let upperLimit = Math.floor(object.firstNumLimit[1] / smallNum);
                    let multiplyBy = random.int(lowerLimit, upperLimit);

                    // this multiplyBy variable will make sure that bigNum will get properly divided by smallNum 
                    let bigNum = NP.times(smallNum, multiplyBy);

                    let ans = NP.divide(bigNum, smallNum);
                    answers.push(ans);
                    tempArray.push(bigNum, '/', smallNum);
                    questions.push(tempArray.slice());
                    questionsCopy.push(tempArray);
                }
            }
            break;

        case 'mul':
            while (questions.length < count) {
                let tempArray = [];
                let firstNum = generateNumberBetweenLimits(object.firstNumLimit);
                let secondNum = generateNumberBetweenLimits(object.secondNumLimit);
                let ans = NP.times(firstNum, secondNum);
                // if (precision(ans) > 2) {
                //     if (ans > 1) {
                //         // The 'fix' function will restrict the number to two decimal places without rounding
                //         ans = fix(ans, 2);
                //     } else { // for answers less than 1 (Ex. 0.005, 0.322)
                //         ans = fix(ans, 4);
                //     }
                // }
                answers.push(ans);
                tempArray.push(firstNum, 'X', secondNum);
                questions.push(tempArray.slice());
                questionsCopy.push(tempArray);
            }

            break;
    }
    return [questions, questionsCopy, answers];

};

// This function will generate random questions of Mixed complements, used only in logicType = 4
const generateRandomMixedComplementQuestions = (row, count) => {
    let que = [], queCopy = [], ans = [];

    for (let i = 0; i < count; i++) {
        let randomObj = random.int(3, 10); //This number will be used for randomly selecting a Mixed complement type
        let MixedCompType = assignmentQuestionsData[2][randomObj];
        let queSet = logic3(MixedCompType, row, 1);
        // queSet is of type -> queSet = [ questions[], questionsCopy[], answers[] ] ;
        que.push(...queSet[0]);
        queCopy.push(...queSet[1]);
        ans.push(...queSet[2]);
    }
    return [que, queCopy, ans];
};

const generateSqrt = (digit) => {
    //    3 digit square roots comes from this range  =  [11,31]
    //    4 digit square roots comes from this range  =  [32,99]
    //    5 digit square roots comes from this range  =  [100,316]
    //    6 digit square roots comes from this range  =  [317,999]
    let tempArray = [];
    let num, squared;

    switch (digit) {
        case 3:
            tempArray.push('Sqrt. of');
            num = random.int(10, 31);
            squared = num * num;
            tempArray.push(squared);
            tempArray.push(num);

            break;
        case 4:
            tempArray.push('Sqrt. of');
            num = random.int(32, 99);
            squared = num * num;
            tempArray.push(squared);
            tempArray.push(num);
            break;
        case 5:
            tempArray.push('Sqrt. of');
            num = random.int(100, 316);
            squared = num * num;
            tempArray.push(squared);
            tempArray.push(num);
            break;
        case 6:
            tempArray.push('Sqrt. of');
            num = random.int(317, 999);
            squared = num * num;
            tempArray.push(squared);
            tempArray.push(num);
            break;
    }

    // Returned array is of form => [ 'Sqrt. of' (string), Squared Number (number), Square Root (number) ]
    //  Ex: ['Sqrt. of' , 3025, 55 ]
    return tempArray;
};

const generateCbrt = (digit) => {
    //    4 digit cube roots comes from this range  =  [10,21]
    //    5 digit cube roots comes from this range  =  [22,46]
    //    6 digit cube roots comes from this range  =  [47,99]
    let tempArray = [];
    let num, cubed;

    switch (digit) {
        case 3:
            tempArray.push('Cbrt. of');
            num = random.int(5, 9);
            cubed = num * num * num;
            tempArray.push(cubed);
            tempArray.push(num);
            break;
        case 4:
            tempArray.push('Cbrt. of');
            num = random.int(10, 21);
            cubed = num * num * num;
            tempArray.push(cubed);
            tempArray.push(num);
            break;
        case 5:
            tempArray.push('Cbrt. of');
            num = random.int(22, 46);
            cubed = num * num * num;
            tempArray.push(cubed);
            tempArray.push(num);
            break;
        case 6:
            tempArray.push('Cbrt. of');
            num = random.int(47, 99);
            cubed = num * num * num;
            tempArray.push(cubed);
            tempArray.push(num);
            break;
    }

    // Returned array is of form => [ 'Cbrt. of' (string), Cubed Number (number), Cube Root (number) ]
    //  Ex: ['Sqrt. of' , 8000, 20 ]
    return tempArray;

};


// -----------------********************--------------------

// The below function will check if all the numbers in an array are the same, if same then return true, else false.
const areAllNumbersSame = arr => arr.every(num => num === arr[0]);


// The below function will convert a number into 3 parts, whose sum will be equal to the actual number, this func. is use in logicType = 4 
const returnThreeParts = (numberToBeParted) => {
    var a, b, c;
    var divideByThree = NP.divide(numberToBeParted, 3);

    const returnRandomCeilFloor = (num) => {
        let operation = random.int(0, 1);
        return operation === 0 ? Math.floor(num) : Math.ceil(num);
    };
    a = returnRandomCeilFloor(divideByThree);
    b = returnRandomCeilFloor(divideByThree);
    c = numberToBeParted - (a + b);
    return [a, b, c];
};

// This function will return a number to which the addVale can be added in a Mixed complement sum, used in logicType = 3
const getNumberForMixedComplementSum = (object) => {
    let firstNumber = object.firstNumber;
    let limit = object.limit;
    let array = [];

    // We want numbers less than 50, thats why limit is (0,3)
    let num = random.int(0, 2);
    num = firstNumber + (10 * num);
    for (let i = 0; i < limit; i++) {
        array.push(num + i);
    }
    return pickRandom(array);
};

// This function will return a random index, at which we are going to add the add value
const addNumberAtPosition = (row) => {
    // addAt will remain a fixed value for a particular row count
    // row = 3 => addAt = [2,3];
    // row = 4 => addAt = [2,3,4];
    // row = 5 => addAt = [2,3,4,5];
    // row = 6 => addAt = [2,3,4,5,6];

    switch (row) {
        case 3: return pickRandom([2, 3]);
        case 4: return pickRandom([2, 3, 4]);
        case 5: return pickRandom([2, 3, 4, 5]);
        case 6: return pickRandom([2, 3, 4, 5, 6]);
    }
};

/*  This function will apply multiple conditions to checks whether the generated parts are
    acceptable or not and return true/false accordingly.
    Parameters: 
        mainArray: This is the final question array
        generatedTwoParts: These are the two parts of a single number being generated [a,b] 
        index: it is the index where the generatedTwoParts will be added in the mainArray
 */
const partsNotAcceptable = (mainArray, generatedTwoParts, index) => {

    let condition1 = sum([...mainArray]) >= 9;

    let condition2 = () => {
        if (index === 0) return false;
        let desiredNumber = sum(mainArray.slice(0, index));
        return abacusUnitRodLogic[desiredNumber].indexOf(generatedTwoParts[0]) >= 0 ? false : true;
    };

    // Both the above filters will return 'true' only if the generated numbers are violating the rules
    return condition1 || condition2();
};

// Below function is used in 'makeParts' Function
const generateTwoParts = (number) => {
    let concernedArray = abacusUnitRodLogic[number];
    concernedArray = concernedArray.slice(0, -1); // by using slice method we are removing last element of Array, so the number wont become 0.
    let arrayNumber = pickRandom(concernedArray);
    let a = sum([number, arrayNumber]);
    let b = arrayNumber * -1;  // By multiplying by (-1) we are inverting the sign of variable b
    if (a > 0 && b > 0) return shuffleArray([a, b]);
    else return [a, b];
};

// This function is used in logicType = 2,3,4
const makeParts = (ofNumber, inXParts, complementType, level) => {
    //  This function converts a number into multiple parts  Eg. -> 5 into [2,3], 6 into [2,2,2] etc.
    let array = [ofNumber];

    if (ofNumber === 1) {
        return array;
    }
    while (array.length !== inXParts) {
        let index;
        let num = array.find((x, i) => {
            index = i;
            return x > 1; // getting the number which is != 1 or not less than 1(means no -ve number)
        });

        array.splice(index, 1); // removing that specific number from array
        let tempArray = generateTwoParts(num);
        array.splice(index, 0, ...tempArray);

        // The If block below restricts creating of questions that students are not familiar with yet
        // The below code remove the 2 parts(the 2 numbers generated from 'generateTwoParts' function) from the array 
        // as they are making the sum of array > 9, which is not allowed in the 5's complement sum. 
        // below if block is not used for logicType = 3 (Mixed complements) questions
        if ((complementType && level) && (complementType === 5) && (level === 1)) {
            if (partsNotAcceptable(array, tempArray, index)) {
                array.splice(index, 2);
                array.splice(index, 0, num);
            }
        }

        if (array.length > 1 && array.every(x => x === 1))
            break;
    }

    return array;
};


// Below function will take 3.45675 & return 45675
const getNumbersAfterDecimalPoint = (num) => {
    let decimalPart = Number(num.toString().split('.')[1] ?? 0);
    return decimalPart;
};



// This function returns the number of digits we have after the decimal point... 
// for Ex: 4.244 returns 3, 10.01 returns 2, 0.00014 returns 5
function precision(a) {
    if (!isFinite(a)) return 0;
    var e = 1, p = 0;
    while (Math.round(a * e) / e !== a) { e *= 10; p++; }
    return p;
}

// This function will provide the no. of negative terms we need in 'add_sub' type of sum
const noOfNegativeTermsWeNeed = (rows) => {
    let maxLimit = Math.floor(rows / 2);
    return random.int(0, maxLimit);
};

// This function will generate an array of 1's and 0's for 'add_sub' type of sum 
// 1 means add positive number 
// 0 means add negative number
const generateOneZeroArray = (noOfRows, noOfNegativeNeeded) => {
    let signArray = [];
    while (signArray.length <= noOfRows) {
        signArray.push(1);   // 1 -> means positive number & 0 -> means negative number 
    }

    for (let i = 0; i < noOfNegativeNeeded; i++) {
        let loc = random.int(2, noOfRows);
        signArray[loc] = 0;
    }
};

// This function will return the number of '0' in the provided array
const countNegativeTerms = (array) => {
    let count = array.filter(x => x === 0);
    return count.length;
};

const generateNumberBetweenLimits = (limit) => {
    let isDecimal = limit[2];
    if (isDecimal) {
        let num = random.float(Number(limit[0]), Number(limit[1]));
        let uptoDecimalPlace = precision(limit[0]);
        let a = num.toFixed(uptoDecimalPlace);
        return Number(a).toFixed(uptoDecimalPlace); //The second time toFixed function is used here to convert (Ex. 30 to 30.0 ) if any
    } else {
        // console.log(limit);
        return random.int(limit[0], limit[1]);
    }

};

// This function will return an array of factors of a given Number
const getFactorsOfNumber = number => [...Array(number + 1).keys()].filter(i => number % i === 0);

// -----------------********************--------------------


const matchAnswersLessThanOne = (actualAns, AnsToBeMatched) => {
    console.log(actualAns, AnsToBeMatched);
    if (actualAns === AnsToBeMatched) return true;
    else {
        if (actualAns <= 0.01) { // here actualAns will be - 0.0015, 0.0010, 0.00565, 0.0078
            return fix(actualAns, min(precision(actualAns), 4)) === fix(AnsToBeMatched, min(precision(actualAns), 4)) ? true : false;
        } else if (actualAns <= 0.1) { // here actualAns will be - 0.095, 0.010, 0.0565, 0.0789
            return fix(actualAns, min(precision(actualAns), 3)) === fix(AnsToBeMatched, min(precision(actualAns), 3)) ? true : false;
        } else {
            return fix(actualAns, 2) === fix(AnsToBeMatched, 2) ? true : false;
        }
    }
};

const countOfCorrectIncorrectAttempted = (arr) => {
    /*
        The' arr' array will have 1, 0 & null; 
        1 means student answer is correct at that index and 
        0 means student answer is wrong at that index
        null means student didn't attempted the answer
    */
    let obj = { correct: 0, attempted: 0, incorrect: 0, not_attempted: 0 };

    arr.forEach(x => {
        if (x === 1) {
            obj.correct++;
            obj.attempted++;
        } else if (x === 0) {
            obj.incorrect++;
            obj.attempted++;
        } else {
            obj.not_attempted++;
        }
    });
    return obj;
};


// This function turns normal Questions Array coming from the DB, into an Array 
// which is to be used to fill all the table cells properly
const RearrangeArray = (array) => {
    let rearrangedArray = []; //final array to be returned
    let tempArray = [];

    // this functions checks whether an array's all elements are undefined or not
    const checkUndefinedArray = (x) => x.every(x => x === undefined);

    do {
        tempArray = [];
        for (let i = 0; i < array.length; i++) {
            tempArray.push(array[i].shift());
        }
        //Push the tempArray into rearrangedArray only if all of its elements are not undefined
        !checkUndefinedArray(tempArray) && rearrangedArray.push(tempArray);

    } while (!checkUndefinedArray(tempArray));
    return rearrangedArray;
};

// this will calculate the Accuracy and attempted Percentages
const calculatePercentage = (part, total) => {
    if (total === 0) return 0;
    return Number(((part / total) * 100).toFixed(2));
};

// -----------------********************--------------------


const TableHeaderStyle = { backgroundColor: "#416997", color: "#fff", fontWeight: "bolder", textAlign: 'center' };
const InputFieldStyle = { width: "3em", textAlign: "center" };
const AnswerFieldStyle = { backgroundColor: "#DF9816", color: "#000", fontWeight: "bolder" };

const styleFontWeight = (x) => {
    return ((x === "/") || (x === "X") || (x === "%") || (x === "GCD") || (x === "LCM") || (x === "Sqrt.") || (x === "Cbrt.")) ? "600" : "400";
};
const styleColor = (x) => {
    return ((x === "/") || (x === "X") || (x === "%")) ? "#1e405b" : "black";
};

const wrongStyle = { border: "2px solid red", width: "3em", textAlign: "center" };

const rightStyle = { border: "2px solid green", width: "3em", textAlign: "center" };



export default GenerateMathQuestions;
export {
    RearrangeArray, generateNumberBetweenLimits, generateCbrt, generateSqrt, precision, noOfNegativeTermsWeNeed,
    generateOneZeroArray, countNegativeTerms, calculatePercentage, countOfCorrectIncorrectAttempted,
    TableHeaderStyle, InputFieldStyle, matchAnswersLessThanOne,
    AnswerFieldStyle, styleFontWeight, styleColor, wrongStyle, rightStyle,
    logic1, logic2, logic3, logic4, addNumberAtPosition, generateRandomMixedComplementQuestions,
    generateTwoParts, getNumberForMixedComplementSum, makeParts, partsNotAcceptable, returnThreeParts,

};


