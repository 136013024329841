import React, { useState } from "react";
import Navbar from "../../../Common/Components/Navbar";
import Footer from "../../../Common/Components/Footer";
import Sidebar from "../../../Sidebar/Sidebar";
import Heading from "../../../Common/Components/Heading";
import { Link, useNavigate } from "react-router-dom";
import { Alert, AlertTitle, Button } from "@mui/material";

import { toast } from "react-toastify";
import Axios from "axios";
import url from "../../../Common/URL";
import Input from "../Student/Component/Input";
import { useSelector } from "react-redux";

const AddUser = () => {

  let navigate = useNavigate();
  let message = "";

  const [user, setUser] = useState({
    uname: "",
    password: "",
    email: "",
    firstname: "",
    lastname: ""
  });

  const [checkPassword, setCheckPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const { uname, password, email, firstname, lastname } = user;

  const onInputChange = (e) => {
    // console.log(e.target.value);
    setUser({ ...user, [e.target.name]: e.target.value });
    if (e.target.name === "password") {
      const reg = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
      if (e.target.value === "") {
        setCheckPassword("");
      } else {
        let result = reg.test(e.target.value);
        if (result) setCheckPassword("is-valid");
        else setCheckPassword("is-invalid");
      }
    }
  };


  const onSubmit = async (e) => {
    //console.log(e);
    e.preventDefault();

    const obj = {
      uname: user.email, created_by: sessionStorage.getItem("username"),  // uname and email will be same!
      password: user.password, email: user.email, firstname: user.firstname, lastname: user.lastname
    };
    // console.log(obj)
    await Axios.post(url + "add_user", obj)
      .then((res) => {
        // console.log(res);
        if (res.data.success) {
          toast.success("User Created successfully!");
          navigate("/user");
        } else toast.error("User not created, please try again!");
      }).catch((err) => console.log(err));
  };



  return (
    <>
      <Navbar />
      <div className="d-flex mainContentArea">
        <Sidebar />
        <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-light py-1 mt-2 align-items-center">
              <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
              <li className="breadcrumb-item">Setup</li>
              <li className="breadcrumb-item active" aria-current="page"><Link to="/user">Users</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Add User</li>
            </ol>
          </nav>
          <Heading name="Add User" />

          <div className="container-fluid ">
            <div className="row">
              <div className="col">
                <Alert severity="warning" className="mb-3" >
                  <AlertTitle><strong>General Information:</strong></AlertTitle>
                  Begin by creating the User on this screen. <strong> To Assign Desired Role</strong>, proceed to the <strong>Edit User screen</strong> and complete the role Assignment from there.
                </Alert>
              </div>
              <div className="col">
                <Alert severity="error" >
                  <AlertTitle><strong>Status Information:</strong></AlertTitle>
                  By default, a newly created user has a status of <strong>Inactive</strong>. You need to make it <strong>Active</strong> by going inside the <strong>Edit User</strong> screen.
                </Alert>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-8">
                <form onSubmit={(e) => onSubmit(e)} id="addUserForm">
                  <div className="mb-3">
                    {/* <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      name="uname"
                      value={uname}
                      onChange={onInputChange}
                      required
                    />
                    <br /> */}
                    <Input
                      field="First Name"
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      name="firstname"
                      value={firstname}
                      onChange={onInputChange}
                      required
                    />

                    <Input
                      field="Last Name"
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      name="lastname"
                      value={lastname}
                      onChange={onInputChange}
                      required
                    />

                    <Input
                      field="Email"
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={onInputChange}
                      required
                    />

                    <div className="mb-3 row ">
                      <label className="col-sm-5 col-form-label red-asterisk">Password</label>
                      <div className="col-sm-7 col-md-7 ">
                        <input
                          type={showPassword ? "text" : "password"}
                          className={`form-control ${checkPassword}`}
                          placeholder="Password"
                          name="password"
                          value={password}
                          onChange={onInputChange}
                          required
                        />
                        <div className="invalid-feedback">The password must contain 8 characters, including an uppercase (A,B,C etc.), lowercase (a,b,c etc.) & a special character(@,#,$,% etc.).</div>
                        <div className="mb-3 form-check mt-3">
                          <input type="checkbox" onClick={(e) => setShowPassword(e.target.checked)} className="form-check-input" id="showPassword" />
                          <label className="form-check-label" htmlFor="showPassword">Show Password</label>
                        </div>
                      </div>
                    </div>
                    <div>
                      {message && <div> Username or Email already exist.</div>}{" "}
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-4 d-flex justify-content-end align-items-baseline">
                <Button variant="contained" form="addUserForm" disabled={checkPassword === "is-invalid"} type="submit" >Submit</Button>

              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddUser;
