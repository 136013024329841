import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import Heading from "../../../../Common/Components/Heading";

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import { Button, Drawer, Tooltip } from "@mui/material";
import TextField from '@mui/material/TextField';

import axios from "axios";
import Select from 'react-select';
import url from "../../../../Common/URL";


import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import CloudOffIcon from '@mui/icons-material/CloudOff';

import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ReadableDateTime } from "../../../../Common/Functions/StringDateTime";
import DetailStuTestHistoryComp from "./DetailStuTestHistoryComp";

const TestHistoryComp = () => {

  const [loading, setLoading] = useState(true);

  const [originalData, setOriginalData] = useState([]);

  const [state] = useState({
    uname: sessionStorage.getItem("username"),
    role: sessionStorage.getItem("role"),
  });

  const [searchfield, setSearchfield] = useState({
    location: [], batch: [], level: [], test_type: [], startDate: "", endDate: ""
  });

  const [loc_name, setLoc_name] = useState([]);
  const [batch_name, setBatch_name] = useState([]);
  const [level_name, setLevel_name] = useState([]);


  const Columns = useMemo(
    () => [
      { header: 'Name', size: 40, id: 'name', accessorFn: rowData => `${rowData.fname} ${rowData.lname}` },
      { header: 'Level', size: 40, accessorKey: 'level_name' },
      { header: 'Location', size: 40, accessorKey: 'location_name' },
      { header: 'Batch', size: 40, accessorKey: 'batch_name' },
      {
        header: 'Type',
        id: 'type',
        size: 40,
        accessorFn: rowData => (
          <div className="hstack gap-2">
            <span>{rowData.test_type}</span>
            {rowData.generator_test === 1 && (
              <Tooltip title="Generated from generator" placement="right">
                <SettingsSuggestIcon className="ms-2" style={{ cursor: "pointer", fontSize: '20px' }} color="primary" />
              </Tooltip>
            )}
            {rowData.test_mode === "offline" && (
              <CloudOffIcon style={{ color: "#810000", fontSize: '15px' }} />
            )}
          </div>
        )
      },
      {
        header: 'Test Name', accessorKey: 'test_name', size: 40,
        id: 'test_name',
        accessorFn: rowData => {
          if (rowData.test_type !== "Assignment") {
            return (
              <span>
                {rowData.test_name} &nbsp;
                {rowData.approved === "1" ?
                  <CheckIcon style={{ color: "#49af41", fontSize: '20px' }} /> :
                  <ClearIcon style={{ color: "#810000", fontSize: '15px' }} />
                }
              </span>
            );
          } else {
            return rowData.test_name;
          }
        }
      },
      { header: 'T.T. Mind Math', size: 40, accessorKey: 'time_taken_mm' },
      { header: 'T.T. Abacus', size: 40, accessorKey: 'time_taken_abacus' },
      { header: 'Attempt %', size: 40, accessorKey: 'attempted_percentage' },
      { header: 'Overall %', size: 40, accessorKey: 'overall_percentage' },
      { header: 'Total Accuracy %', size: 40, accessorKey: 'total_accuracy_per' },
      { header: 'Score', size: 40, accessorKey: 'score' },
      { header: 'Date', size: 40, id: 'created_at', accessorFn: rowData => ReadableDateTime(rowData.created_at) },
    ],
    [],
  );

  const [data, setData] = useState([]);



  useEffect(() => {
    load_Scores_LOC_LEV_BATCH();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    SearchSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchfield]);


  // ------------------------Fetching Scores table data-------------------------------------
  const load_Scores_LOC_LEV_BATCH = async () => {

    var location_name_arr = [], batch_name_arr = [], level_name_arr = []; // Variables declared for filtering data process, for teacher   
    // Lower cased array of locations that are assigned to a manager
    var locationManagerLocations = sessionStorage.getItem("role") === "Location Manager" ? sessionStorage.getItem("location").toLowerCase().split(",") : "";
    //================== Getting the location , level , batch for Teacher ===============

    if (sessionStorage.getItem("role") === "Teacher") {

      await axios.post(url + "select_teacher_params", { uname: state.uname })
        .then((res) => {
          // console.log(res)
          if (res.data.success) {
            batch_name_arr = res.data.batch_data.map(a => a.batch_name.toLowerCase());
            location_name_arr = res.data.location_data.map(a => a.location_name.toLowerCase());
            level_name_arr = res.data.level_data.map(a => a.level_name.toLowerCase());

            // console.log(batch_name_arr);console.log(location_name_arr);console.log(level_name_arr);
          } else {
            toast.error("An error occurred, Location , Level & Batch can't be fetched.");
          }
        }).catch(res => console.log(res));

    }

    //======== Normal Flow ========

    axios.post(url + "scores", { uname: state.uname })
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          var f = response.data.data;
          var a = f !== null ? f.reverse() : f;
          //  console.log(a);
          // ====================== Below part if role  equals to Teacher ===================
          if (sessionStorage.getItem("role") === "Teacher") {
            let filterData = a.filter(stu => {
              return location_name_arr.indexOf(stu.location_name.toLowerCase()) >= 0 &&
                batch_name_arr.indexOf(stu.batch_name.toLowerCase()) >= 0 &&
                level_name_arr.indexOf(stu.level_name.toLowerCase()) >= 0;
            });
            setLoading(false);
            filterData.length > 0 ? setOriginalData(filterData) : setOriginalData([]);
            filterData.length > 0 ? setData(filterData) : setData([]);
            // ====================== Below part if role not equals to Teacher ===================
          } else if (sessionStorage.getItem("role") === "Location Manager") {

            let filterData = a.filter(stu => locationManagerLocations.indexOf(stu.location_name.toLowerCase()) >= 0);

            setLoading(false);
            filterData.length > 0 ? setOriginalData(filterData) : setOriginalData([]);
            filterData.length > 0 ? setData(filterData) : setData([]);
          } else {
            a !== null ? setOriginalData(a) : setOriginalData([]);
            a !== null ? setData(a) : setData([]);  // Storing Data for Admin and SuperAdmin
            setLoading(false);
          }
        } else {
          setLoading(false);
          setData([]);
        }
      }).catch((err) => console.log(err));

    // ======================== Fetching Locations, levels, batches for the Search Field ================================

    // ----- Location -----
    axios.post(url + "fetch_locations", { uname: sessionStorage.getItem("username") })
      .then((response) => {
        let a = response.data.data;
        // console.log(a);

        let locations = a.map(a => a.name); //This is converting an Object into an array of strings containing names respectively
        if (sessionStorage.getItem("role") === "Teacher") {

          setLoc_name(location_name_arr);

        } else if (sessionStorage.getItem("role") === "Location Manager") {
          let locationsArray = sessionStorage.getItem("location").split(',');
          setLoc_name(locationsArray);

        } else {
          setLoc_name(locations);
        }

      })
      .catch((err) => console.log(err));

    // ----- Batch -----
    axios.post(url + "fetch_batches", { uname: sessionStorage.getItem("username") })
      .then((response) => {
        var a = response.data.data;

        var batches = a.map(a => a.batch_name); //This is converting an Object into an array of strings containing names respectively

        if (sessionStorage.getItem("role") === "Teacher") {

          setBatch_name(batch_name_arr);

        } else if (sessionStorage.getItem("role") === "Location Manager") {

          let filtered_batches = a.filter(x => locationManagerLocations.indexOf(x.location_name.toLowerCase()) >= 0);
          let filtered_batch_names = filtered_batches.map(a => a.batch_name);

          setBatch_name(filtered_batch_names);

        } else {
          setBatch_name(batches);
        }
      })
      .catch((err) => console.log(err));

    // ---- Level ------   
    axios.post(url + "fetch_levels", { uname: sessionStorage.getItem("username") })
      .then((response) => {
        let a = response.data.data;
        // console.log(a);

        let levels = a.map(a => a.level_name); //This is converting an Object into an array of strings containing names respectively
        if (sessionStorage.getItem("role") === "Teacher") {
          // console.log(level_name_arr);
          setLevel_name(level_name_arr);
        } else {
          setLevel_name(levels);
        }
      })
      .catch((err) => console.log(err));
  };

  // ========================================================================================

  const SearchSubmit = () => {

    //  if(searchfield.test_type.length !== 0){ 


    var location = searchfield.location.map(a => a.value);
    var batch = searchfield.batch.map(a => a.value);
    var level = searchfield.level.map(a => a.value);
    var test_type = searchfield.test_type.map(a => a.value);
    var start_date = searchfield.startDate === "" ? searchfield.startDate : new Date(searchfield.startDate).getTime();
    var end_date = searchfield.endDate === "" ? searchfield.endDate : new Date(searchfield.endDate).getTime();

    let filter_1 = test_type.length === 0 ? originalData : originalData.filter(stu => test_type.includes(stu.test_type)); // Filtering  Test_type
    let filter_2 = location.length === 0 ? filter_1 : filter_1.filter(stu => location.includes(stu.location_name)); // Filtering location
    let filter_3 = batch.length === 0 ? filter_2 : filter_2.filter(stu => batch.includes(stu.batch_name)); // Filtering Batch
    let filter_4 = level.length === 0 ? filter_3 : filter_3.filter(stu => level.includes(stu.level_name)); // Filtering Level

    let filter_5 = start_date === "" ? filter_4 :           // Filtering dates greater than Start Date
      filter_4.filter(stu => {
        let stu_date = stu.created_at.split("T")[0];
        let stu_time = new Date(stu_date).getTime();

        return stu_time >= start_date;
      });

    let filter_6 = end_date === "" ? filter_5 :           // Filtering Date smaller than End Date
      filter_5.filter(stu => {
        let stu_date = stu.created_at.split("T")[0];
        let stu_time = new Date(stu_date).getTime();

        return stu_time <= end_date;
      });

    // console.log(filter_5); console.log(filter_6);
    filter_6.length > 0 ? setData(filter_6) : setData([]);

    // }else{
    //   toast.error("Test Type is mandatory for Searching.")
    // }  
  };

  // ========================================================================================
  const ClearFilter = () => {
    setSearchfield({
      location: [], batch: [], level: [], test_type: [], startDate: "", endDate: ""
    });
    setData(originalData);
  };

  const [selectedId, setSelectedId] = useState(null);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);

  return (
    <>
      {/* Side Drawer for opening Detail view */}
      <Drawer
        PaperProps={{ style: { width: '95%' } }}
        anchor='right'
        open={openDetailsDrawer}
        onClose={() => setOpenDetailsDrawer(false)}
      >
        <DetailStuTestHistoryComp id={selectedId} setOpenDrawer={setOpenDetailsDrawer} loadParentData={load_Scores_LOC_LEV_BATCH} />
      </Drawer >

      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Students</li>
            <li className="breadcrumb-item active" aria-current="page">Test History</li>
          </ol>
        </nav>

        <Heading name="Test History" />
        {!loading &&
          <div className=" container-fluid">

            <h5 className="mb-4">Advanced Filter</h5>

            <div className="row">
              <div className="col-md-3 ">
                <TextField
                  label="From"
                  type="date"
                  value={searchfield.startDate}
                  className="form-control mb-4"
                  onChange={e => {
                    setSearchfield(pre => {
                      return { ...pre, startDate: e.target.value };
                    });
                  }
                  }
                  InputLabelProps={{ shrink: true, }}
                />
              </div>

              <div className=" col-md-3 ">
                <TextField
                  label="To"
                  type="date"
                  value={searchfield.endDate}
                  className="form-control mb-4"
                  onChange={e => {
                    setSearchfield(pre => {
                      return { ...pre, endDate: e.target.value };
                    });
                  }
                  }
                  InputLabelProps={{ shrink: true, }}
                />
              </div>
              <div className="col-auto ms-auto">
                <Button variant="contained" className="btn btn-outline-dark shadow-sm  "
                  onClick={ClearFilter}>Clear Filter</Button>
              </div>

            </div>

            <div className="row " >
              <div className=" col-md-3">
                <Select
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  isClearable={true}
                  isMulti
                  closeMenuOnSelect={false}
                  options={loc_name.map(x => ({ label: x, value: x }))}
                  value={searchfield.location}
                  placeholder="Select Location"
                  onChange={e => setSearchfield({ ...searchfield, location: e })}
                />
              </div>

              <div className=" col-md-3">
                <Select
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  isClearable={true}
                  isMulti
                  closeMenuOnSelect={false}
                  options={[{ label: "Test", value: "Test" }, { label: "Exam", value: "Exam" }, { label: "Assignment", value: "Assignment" }]}
                  value={searchfield.test_type}
                  placeholder="Select Test Type"
                  onChange={e => setSearchfield({ ...searchfield, test_type: e })}
                />
              </div>

              <div className=" col-md-3">
                <Select
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  isClearable={true}
                  isMulti
                  closeMenuOnSelect={false}
                  options={level_name.map(x => ({ label: x, value: x }))}
                  value={searchfield.level}
                  placeholder="Select Level Name"
                  onChange={e => setSearchfield({ ...searchfield, level: e })}
                />
              </div>

              <div className=" col-md-3">
                <Select
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  isClearable={true}
                  isMulti
                  closeMenuOnSelect={false}
                  options={batch_name.map(x => ({ label: x, value: x }))}
                  value={searchfield.batch}
                  placeholder="Select Batch Name"
                  onChange={e => setSearchfield({ ...searchfield, batch: e })}
                />
              </div>
            </div>

            <hr />

            <div className="row mt-4 mb-3 d-flex justify-content-end">
              <div className="col-12 ">
                <div className="hstack gap-3">
                  <div className="p-2 bg-light shadow-sm border"><p style={{ margin: 0, fontWeight: "500" }}><CheckIcon style={{ color: "#49af41" }} />&nbsp; Test Is Approved</p></div>
                  <div className="p-2 bg-light shadow-sm border"><p style={{ margin: 0, fontWeight: "500" }}><ClearIcon style={{ color: "#810000" }} />&nbsp; Test Not Approved</p></div>
                  <div className="p-2 bg-light shadow-sm border"><p style={{ margin: 0, fontWeight: "500" }}><CloudOffIcon style={{ color: "#810000" }} />&nbsp; Test Given Offline</p></div>
                </div>
              </div>
            </div>

          </div>}



        <div className=" container-fluid">

          <div className="row mb-5">
            <div className="col-12">

              <MaterialReactTable
                state={{ isLoading: loading }}

                columns={Columns}
                data={data}
                enableRowNumbers

                initialState={{ density: 'compact' }}
                enableDensityToggle={false}

                getRowId={(originalRow) => originalRow.user_ans_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: () => {
                    setSelectedId(row.id);
                    setOpenDetailsDrawer(true);
                  },
                  sx: { cursor: 'pointer' },
                })}
              />
            </div>
          </div>
        </div>

      </div>
    </>
  );
};
export default TestHistoryComp;