import React from "react"
import Navbar from "../../../../Common/Components/Navbar"
import Footer from "../../../../Common/Components/Footer"
import Sidebar from "../../../../Sidebar/Sidebar"
import EnquiryFormComp  from "./Component/EnquiryFormComp"

const EnquiryForm =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea">
            <Sidebar />
            <EnquiryFormComp />
        </div>
        <Footer />
        </>
    )
}

export default EnquiryForm