import React, { useEffect, useState } from "react";
import Countdown from 'react-countdown';
import { toast } from "react-toastify";

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useConfirm } from 'material-ui-confirm';
import { useNavigate, useParams } from "react-router-dom";
import Abacus from "../../../../Common/Components/Abacus";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from "axios";
import Heading from "../../../../Common/Components/Heading";
import url from "../../../../Common/URL";

import AlertBlockForQuestionPapers from "../../../../Common/Components/AlertBlockForQuestionPapers";
import { AnswerFieldStyle, calculatePercentage, countOfCorrectIncorrectAttempted, matchAnswersLessThanOne, RearrangeArray, styleColor, styleFontWeight, TableHeaderStyle } from "../../../../Common/Question Generator/QuestionGeneratorFunctions";

const NewTestSheetComp = () => {
    let { id, yt_url } = useParams();
    const confirm = useConfirm();
    const navigate = useNavigate();

    const [backdrop, setBackdrop] = useState(true);

    // ------------------ For Iframe video modal ------------------------------------------------

    const [openHelpVideoDialog, setOpenHelpVideoDialog] = useState(false);

    const [mindMathAutoSubmit, setMindMathAutoSubmit] = useState(false);  //This  wil check whether the mind-math part is auto submitted or not

    var total_test_duration_mm = "null", difference_mm,  // this variables are used to calculate the time between 
        total_test_duration_ab = "null", difference_ab;  // start and end of the test for mind math and abacus.

    const [date, setDate] = useState();         // this will start the timer 

    const [testStartTime_mm, setTestStartTime_mm] = useState("");
    const [testEndTime_mm, setTestEndTime_mm] = useState("");

    const [testStartTime_ab, setTestStartTime_ab] = useState("");
    var testEndTime_ab;

    // ====================================================================
    const [isSubmitted, setIsSubmitted] = useState(false);   // this will do toggle between question and Answer parts

    const [show, setShow] = useState({
        mm: true, ab: false
    });
    const [show_mm, setShow_mm] = useState(false);     // this will hide Mind-Math questions till the starting video popup is closed
    const [result, setResult] = useState(false);      // THIS  will show the result after test is submitted


    // This data will be sent to DB 
    const [paperState, setPaperState] = useState({
        total_ab_questions: 0, total_mm_questions: 0,
        test_name: '', test_type: '', level_name: '',
        total_correct: "", total_attempted_percentage: "",
        not_answered: "", overall_percentage: "", wrong_answers: "",
    });


    var mmTableHeader = [...Array(paperState.total_mm_questions).keys()]; // to create header number for a Mind-Math table
    var abTableHeader = [...Array(paperState.total_ab_questions).keys()]; // to create header number for a Abacus table

    let mmInputFieldArray = [...Array(paperState.total_mm_questions).keys()]; // to create input fields for the table
    let abInputFieldArray = [...Array(paperState.total_ab_questions).keys()]; // to create input fields for the table

    var mmActualAnswerFieldArray = [...Array(paperState.total_mm_questions).keys()]; // to create the actual answer field for a Mind-Math table
    var abActualAnswerFieldArray = [...Array(paperState.total_ab_questions).keys()]; // to create the actual answer field for a Abacus table

    // This will store generated questions separately for MM & AB
    const [mmQuestions, setMmQuestions] = useState([]);
    const [abQuestions, setAbQuestions] = useState([]);

    // This is to store input field answers, which will later be used to check right and wrong answers
    const [mmInputAnswers, setMmInputAnswers] = useState([]);
    const [abInputAnswers, setAbInputAnswers] = useState([]);

    // This is to store actual answers
    const [mmAnswersArray, setMmAnswersArray] = useState([]);
    const [abAnswersArray, setAbAnswersArray] = useState([]);

    useEffect(() => {
        FetchTestDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const FetchTestDetails = () => {
        // New Logic
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id,
        };
        axios.post(url + "fetch_single_test", obj)
            .then((response) => {
                let a = response.data.data;
                console.log(a);
                let actual_answer = a[0].actual_answer;
                let actual_question = a[0].actual_question;
                let total_mm_questions = Number(a[0].total_mm_questions);
                let total_ab_questions = Number(a[0].total_ab_questions);

                setPaperState({
                    ...paperState,
                    total_ab_questions, total_mm_questions,
                    test_name: a[0].test_name, test_type: a[0].test_type, level_name: a[0].level_name
                });


                setMmInputAnswers(Array(total_mm_questions).fill(''));
                setAbInputAnswers(Array(total_ab_questions).fill(''));


                // -------- Getting Mind-Math Questions in Proper Format --------
                let mmQuestionsArray = actual_question.slice(0, a[0].total_mm_questions);
                let mmAnswerArray = actual_answer.slice(0, a[0].total_mm_questions);

                var mmSplicedArray = [];
                while (mmQuestionsArray.length > 0) {
                    mmSplicedArray.push(mmQuestionsArray.splice(0, 10));
                }

                for (let i = 0; i < mmSplicedArray.length; i++) {
                    mmQuestionsArray.push(RearrangeArray(mmSplicedArray[i]));
                }

                setMmQuestions(mmQuestionsArray);
                setMmAnswersArray(mmAnswerArray);


                //-------- Getting Abacus Questions in Proper Format --------
                let abQuestionsArray = actual_question.slice(-a[0].total_ab_questions); // -ve sign means we are taking 'N elements' from end of array
                let abAnswerArray = actual_answer.slice(-a[0].total_ab_questions); // -ve sign means we are taking 'N elements' from end of array

                var abSplicedArray = [];
                while (abQuestionsArray.length > 0) {
                    abSplicedArray.push(abQuestionsArray.splice(0, 10));
                }

                for (let i = 0; i < abSplicedArray.length; i++) {
                    abQuestionsArray.push(RearrangeArray(abSplicedArray[i]));
                }

                setAbQuestions(abQuestionsArray);
                setAbAnswersArray(abAnswerArray);

            }).then(() => {
                if (yt_url !== "null") {
                    setOpenHelpVideoDialog(true);
                } else {
                    setShow_mm(true);
                    toast.success("Test loaded successfully");
                    setDate((Date.now() + 1200000));
                    setTestStartTime_mm(new Date().getTime());
                }
                setBackdrop(false);
            }).catch((err) => {
                console.log(err);
                setBackdrop(false);
            });
    };

    const HandleNextStep = () => {
        setShow({
            mm: false, ab: true
        });
        setDate((Date.now() + 2400000));
        setTestEndTime_mm(new Date().getTime());
        setTestStartTime_ab(new Date().getTime());
        window.scrollTo({
            top: 0,
        });
    };

    const onChange = (e, type) => {
        let target = e.target;
        let name = target.name;
        let value = target.value;
        // type = 0 means Mind-Math; type = 1 means Abacus;
        if (type === 0)
            setMmInputAnswers({ ...mmInputAnswers, [name]: value !== '' ? Number(value) : '' });  // Ternary operator prevents Number() from converting empty string into 0.
        else setAbInputAnswers({ ...abInputAnswers, [name]: value !== '' ? Number(value) : '' }); // Ternary operator prevents Number() from converting empty string into 0.
    };

    const checkAnswer = (x, type) => {
        // inputAnswers = Entered answers; 
        // answersArray = Actual Answers;
        // type = 0 means Mind-Math; type = 1 means Abacus;
        // both inputAnswers & answersArray have data-type as "Number"
        if (type === 0) {
            if ((mmAnswersArray[x] != 0) && (mmAnswersArray[x] < 1)) return matchAnswersLessThanOne(mmAnswersArray[x], mmInputAnswers[x]);
            else return (mmInputAnswers[x] === mmAnswersArray[x]) ? true : false;

        } else {

            if ((abAnswersArray[x] != 0) && (abAnswersArray[x] < 1)) return matchAnswersLessThanOne(abAnswersArray[x], abInputAnswers[x]);
            else return (abInputAnswers[x] === abAnswersArray[x]) ? true : false;
        }
    };

    // -------Function to load countdown Timer -------------------------

    const timer_style = {
        border: "2px solid #174873", letterSpacing: "2px", fontWeight: "600", color: "#174873", width: "6.5em", height: "2em"
    };

    /*  This TimerMM is used because we don't want to submit test if timer completes in Mind-math part, 
        rather we want to switch to Abacus part
    */
    const TimerMM = () => {
        return (
            <div className="rounded d-flex justify-content-center align-items-center" style={timer_style}>
                <Countdown
                    date={date}
                    renderer={({ minutes, seconds }) => <span >{minutes}:{seconds}</span>}
                    onComplete={() => {
                        setShow({
                            mm: false, ab: true
                        });
                        setDate((Date.now() + 2400000));
                        setMindMathAutoSubmit(true);          //This will tell us whether the Mindmath part is auto submitted or submitted manually
                        setTestStartTime_ab(new Date().getTime());
                        toast.error("Time over for the Mind-Math part, please now continue with the Abacus part.");
                    }
                    }
                />
            </div>
        );
    };

    const Timer = () => {
        return (
            <div className="rounded d-flex justify-content-center align-items-center" style={timer_style}>
                <Countdown
                    date={date}
                    renderer={({ minutes, seconds }) => <span >{minutes}:{seconds}</span>}
                    onComplete={() => onSubmitTest(true)}
                />
            </div>
        );
    };

    // This function will generate test time in Minutes:Seconds Format ---------
    const millisToMinutesAndSeconds = (millis) => {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return (seconds == 60 ? (minutes + 1) + ":00" : minutes + ":" + (seconds < 10 ? "0" : "") + seconds);
    };

    // This function is used when student submits the generated Assignment/Test/Exam
    const calculateSubmitObjectValues = () => {
        let obj = {
            total_mm_questions: paperState.total_mm_questions, total_mm_attempted: 0, total_mm_correct: 0,
            total_ab_questions: paperState.total_ab_questions, total_ab_attempted: 0, total_ab_correct: 0,
        };

        /*
            Below resultArray array will have 1, 0 & null; 
            1 means student answer is correct at that index and 
            0 means student answer is wrong at that index
            null means student didn't attempted the answer
        */
        let mmResultArray = [];
        for (let i = 0; i < paperState.total_mm_questions; i++) {

            if (mmInputAnswers[i] === '')
                mmResultArray.push(null);
            else if (mmAnswersArray[i] < 1)
                matchAnswersLessThanOne(mmAnswersArray[i], mmInputAnswers[i]) ? mmResultArray.push(1) : mmResultArray.push(0);
            else
                (mmInputAnswers[i] === mmAnswersArray[i]) ? mmResultArray.push(1) : mmResultArray.push(0);

        }

        let abResultArray = [];
        for (let i = 0; i < paperState.total_ab_questions; i++) {

            if (abInputAnswers[i] === '')
                abResultArray.push(null);
            else if (abAnswersArray[i] < 1)
                matchAnswersLessThanOne(abAnswersArray[i], abInputAnswers[i]) ? abResultArray.push(1) : abResultArray.push(0);
            else
                (abInputAnswers[i] === abAnswersArray[i]) ? abResultArray.push(1) : abResultArray.push(0);

        }

        let mmData = countOfCorrectIncorrectAttempted(mmResultArray);
        let abData = countOfCorrectIncorrectAttempted(abResultArray);

        obj.total_mm_attempted = mmData.attempted;
        obj.total_mm_correct = mmData.correct;
        obj.total_ab_attempted = abData.attempted;
        obj.total_ab_correct = abData.correct;

        return obj;
    };

    // This function runs when the Final  Submit button is Clicked
    const onSubmitTest = (autoSubmit = false) => {

        setBackdrop(true);

        // *********************************************************
        var role = sessionStorage.getItem("role");
        var location_name = "";
        var batch_name = "";

        if (role === "Student") {
            location_name = sessionStorage.getItem("location");
            batch_name = sessionStorage.getItem("batch");
        }

        // ------------- Calculating test time for MM & AB ---------

        if (mindMathAutoSubmit) {
            total_test_duration_mm = "20:00";
        } else {
            difference_mm = testEndTime_mm - testStartTime_mm; //Calculating time taken for mind math test 
            total_test_duration_mm = millisToMinutesAndSeconds(difference_mm);
        }

        if (autoSubmit) {
            total_test_duration_ab = "40:00";
        } else {
            testEndTime_ab = new Date().getTime(); // Setting end time for abacus test  
            difference_ab = testEndTime_ab - testStartTime_ab; //Calculating time taken for Abacus test
            total_test_duration_ab = millisToMinutesAndSeconds(difference_ab);
        }


        // *********************************************************

        let x = calculateSubmitObjectValues();
        let total_questions = x.total_ab_questions + x.total_mm_questions;
        let total_attempted = x.total_ab_attempted + x.total_mm_attempted;
        let total_correct = x.total_ab_correct + x.total_mm_correct;
        let total_attempted_percentage = calculatePercentage(total_attempted, total_questions);
        let total_accuracy_percentage = calculatePercentage(total_correct, total_attempted);
        let overall_percentage = Number(((total_correct / total_questions) * 100).toFixed(2) || 0);

        let mmStudentAnswers = Object.keys(mmInputAnswers).map((key) => mmInputAnswers[key]); //converting mmInputAnswers obj into and Array..
        let abStudentAnswers = Object.keys(abInputAnswers).map((key) => abInputAnswers[key]); //converting abInputAnswers obj into and Array..
        let student_answers = [...mmStudentAnswers, ...abStudentAnswers]; // Spreading each in new array

        setPaperState({
            ...paperState,
            total_correct, total_attempted_percentage, overall_percentage,
            not_answered: total_questions - total_attempted,
            wrong_answers: total_attempted - total_correct,
        });

        const obj = {
            uname: sessionStorage.getItem("username"), location_name: location_name, batch_name: batch_name,
            level_name: paperState.level_name, fname: sessionStorage.getItem("userFname"), mname: "",
            lname: sessionStorage.getItem("userLname"),
            test_name: paperState.test_name, test_type: paperState.test_type,
            page: "", test_id: id,

            time_taken_abacus: total_test_duration_ab,
            time_taken_mm: total_test_duration_mm,

            total_ab_questions: x.total_ab_questions,
            total_ab_attempted: x.total_ab_attempted,
            total_ab_correct: x.total_ab_correct,
            total_ab_accuracy: Number(((x.total_ab_correct / x.total_ab_attempted) * 100).toFixed(2) || 0),
            total_ab_attempted_percentage: calculatePercentage(x.total_ab_attempted, x.total_ab_questions),
            total_ab_accuracy_percentage: calculatePercentage(x.total_ab_correct, x.total_ab_attempted),

            total_mm_questions: x.total_mm_questions,
            total_mm_attempted: x.total_mm_attempted,
            total_mm_correct: x.total_mm_correct,
            total_mm_accuracy: Number(((x.total_mm_correct / x.total_mm_attempted) * 100).toFixed(2) || 0),
            total_mm_attempted_percentage: calculatePercentage(x.total_mm_attempted, x.total_mm_questions),
            total_mm_accuracy_percentage: calculatePercentage(x.total_mm_correct, x.total_mm_attempted),

            total_questions,
            total_attempted,
            total_correct,
            total_attempted_percentage,
            total_accuracy_percentage,
            overall_percentage,
            student_answers: student_answers
        };

        console.log(obj);

        axios.post(url + "store_student_answer_of_generator", obj)
            .then((res) => {
                console.log(res);
                if (res.data.success) {
                    if (autoSubmit)
                        toast.error("Time over, test submitted successfully");
                    else
                        toast.success("Test Submitted Successfully");
                    setResult(true);
                    setIsSubmitted(true);
                    setShow({ mm: true, ab: true });
                    setBackdrop(false);
                } else {
                    console.log(res);
                    toast.error("Error occurred while submitting Test, please try later");
                    setBackdrop(false);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error  occurred while submitting  Test, please try later");
                setBackdrop(false);
            });
    };

    // This function will show the Result in place of Submit button on test Submit -----
    function ShowResult() {
        return (
            <div className=" container-fluid mb-4">
                <div className="row">
                    <div className="col-md-12 card shadow  p-4 bg-light ">
                        <div >
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>Results</h4>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate("/all-tests")}
                                >Back To My Tests
                                </Button>
                            </div>
                            <hr />
                            <div className="mb-4">
                                <span className="badge p-2 bg-success me-2">
                                    {paperState.total_correct} Correct Answer(s)
                                </span>
                                <span className="badge p-2 bg-danger me-2">
                                    {paperState.wrong_answers} Wrong Answer(s)
                                </span>
                                <span className="badge p-2 bg-secondary">
                                    Not Answered: {paperState.not_answered}
                                </span>
                            </div>

                            <div className="mb-3">
                                <strong>Attempt Percentage:&nbsp;</strong>
                                {paperState.total_attempted_percentage}%
                            </div>

                            <div className="mb-3">
                                <strong>Overall Percentage:&nbsp;</strong>
                                {paperState.overall_percentage}%
                            </div>
                        </div>

                    </div >
                </div >
            </div >
        );
    }

    return (
        <>
            {/* ========================================================= */}

            <div className="container-fluid">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item">Home</li>
                        <li className="breadcrumb-item">Tests</li>
                        <li className="breadcrumb-item">My Tests</li>
                        <li className="breadcrumb-item active" aria-current="page">Test</li>
                    </ol>
                </nav>


                <Heading name={`( Test ) - ${paperState.test_name}`} />


                {/* ---------------Modal popup for video iframe on click of test generate button----------------------- */}

                <Dialog open={openHelpVideoDialog}
                    maxWidth='auto'>
                    <DialogContent>
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe className="embed-responsive-item" title="1"
                                src={`https://www.youtube.com/embed/${yt_url}?rel=0&modestbranding=1`}
                                width="600" height="300" allow="autoplay;encrypted-media;"
                                allowFullScreen></iframe>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            setOpenHelpVideoDialog(false);
                            setShow_mm(true);
                            toast.success("Test loaded successfully");
                            setDate((Date.now() + 1200000));
                            setTestStartTime_mm(new Date().getTime());
                        }} color='primary'>Close</Button>
                    </DialogActions>
                </Dialog>

                {/* ======================================================================== Test-Table --> Start here ====================================================================================== */}

                <div className="container-fluid mb-5">

                    {!isSubmitted && <div className="row">
                        <div className="col-12 mx-auto ">

                            {/* ----------------------------------------Accordion Tab to show Iframe video--------------------------- */}
                            {yt_url !== "null" && <>
                                <div className='w-100'>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content"  >
                                            <h5 className="my-auto" style={{ color: "#174873" }}>{`${paperState.test_name} Video Help`}</h5></AccordionSummary>
                                        <AccordionDetails className="d-flex justify-content-center align-items-center">
                                            <div >
                                                <iframe width="600" height="300" title="2"
                                                    src={`https://www.youtube.com/embed/${yt_url}`}
                                                    allow="autoplay;encrypted-media;"
                                                    allowFullScreen></iframe>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <hr className="p-0 mt-4 mx-auto" style={{ border: "none", borderBottom: ".4em dotted #174873", width: "10%" }} />
                            </>}
                            {/* ------------------------------------------------------------------------------------------------------------- */}
                        </div>
                    </div>}

                    {isSubmitted &&
                        <div className="row my-4">
                            <div className="col-12 ">
                                <Button variant="contained" onClick={() => navigate("/all-tests")} >Back To My Tests</Button>
                            </div>
                        </div>
                    }

                    {show.mm &&
                        <div className="row">
                            {/* ====================================================================================================== */}
                            {/* ======================================== Mind - Math Part ============================================ */}

                            {!isSubmitted && <div className="row d-flex justify-content-between align-items-end mb-3">
                                <AlertBlockForQuestionPapers />
                                <div className="col-auto ">
                                    {show_mm && <TimerMM />}
                                </div>
                            </div>}

                            <div className="col-12 mx-auto ">
                                <h5><LabelImportantIcon /> Mind-Math</h5>
                                <hr />
                            </div>

                            <div className="col-12 mx-auto ">
                                {mmQuestions.map((x, i) => {
                                    return <TableContainer key={i} component={Paper} className="mb-5">
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>
                                                    {mmTableHeader.splice(0, 10).map((x, i) => <TableCell key={i} style={TableHeaderStyle}>{x + 1}</TableCell>)}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {x.map((x, i) => {
                                                    return <TableRow key={i}>
                                                        {x.map((x, i) => <TableCell key={i} style={{ fontWeight: styleFontWeight(x), color: styleColor(x), textAlign: 'center' }}>{x}</TableCell>)}
                                                    </TableRow>;
                                                })}


                                                <TableRow>
                                                    {mmInputFieldArray.splice(0, 10).map((x, i) => (
                                                        <TableCell key={x} className="text-center">
                                                            <input autoComplete="off" type="number"
                                                                style={{
                                                                    width: "3em", textAlign: "center",
                                                                    border: isSubmitted ? (checkAnswer(x, 0) ? '2px solid green' : '2px solid red') : '2px solid grey'
                                                                }} name={x} onChange={(e) => onChange(e, 0)} value={mmInputAnswers[x]} />
                                                        </TableCell>
                                                    ))}
                                                </TableRow>

                                                {isSubmitted && <TableRow>
                                                    {mmActualAnswerFieldArray.splice(0, 10).map((x, i) => (
                                                        <TableCell key={i} className="text-center" style={AnswerFieldStyle}>
                                                            {mmAnswersArray[x]}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>;
                                })}

                            </div>
                            {(!isSubmitted && show_mm) && <div className="row">
                                <div className="col-12 mx-auto d-flex justify-content-center align-items-center ">

                                    <h6 className="m-0 ">Submit and Proceed to Abacus Part <DoubleArrowIcon /> </h6>
                                    <Button variant="contained" onClick={() => {
                                        confirm({
                                            title: "Submit Confirmation",
                                            description: "Are you sure, you want to submit and proceed to abacus part?",
                                        }).then(() => HandleNextStep());

                                    }}
                                        className=" ms-2">Submit </Button></div>
                            </div>}
                        </div>}

                    {show.ab && <div className="row ">
                        {/* ====================================================================================================== */}
                        {/* ============================================= Abacus Part ============================================ */}
                        {!isSubmitted && <Abacus />}

                        {/* ============================== */}

                        {!isSubmitted && <div className="row d-flex justify-content-between align-items-end mb-3">
                            <AlertBlockForQuestionPapers />
                            <div className="col-auto ">
                                {show_mm && <Timer />}
                            </div>
                        </div>}
                        <div className="col-12 mx-auto ">
                            <h5><LabelImportantIcon /> Abacus</h5>
                            <hr />
                        </div>
                        <div className="col-12 mx-auto ">
                            {abQuestions.map((x, i) => {
                                return <TableContainer key={i} component={Paper} className="mb-5">
                                    <Table>
                                        <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                            <TableRow>
                                                {abTableHeader.splice(0, 10).map((x, i) => <TableCell key={i} style={TableHeaderStyle}>{x + 1}</TableCell>)}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {x.map((x, i) => {
                                                return <TableRow key={i}>
                                                    {x.map((x, i) => <TableCell key={i} style={{ fontWeight: styleFontWeight(x), color: styleColor(x), textAlign: 'center' }}>{x}</TableCell>)}
                                                </TableRow>;
                                            })}


                                            <TableRow>
                                                {abInputFieldArray.splice(0, 10).map((x, i) => (
                                                    <TableCell key={x} className="text-center">
                                                        <input autoComplete="off" type="number"
                                                            style={{
                                                                width: "3em", textAlign: "center",
                                                                border: isSubmitted ? (checkAnswer(x, 1) ? '2px solid green' : '2px solid red') : '2px solid grey'
                                                            }} name={x} onChange={(e) => onChange(e, 1)} value={abInputAnswers[x]} />
                                                    </TableCell>
                                                ))}
                                            </TableRow>

                                            {isSubmitted && <TableRow>
                                                {abActualAnswerFieldArray.splice(0, 10).map((x, i) => (
                                                    <TableCell key={i} className="text-center" style={AnswerFieldStyle}>
                                                        {abAnswersArray[x]}
                                                    </TableCell>
                                                ))}
                                            </TableRow>}
                                        </TableBody>
                                    </Table>
                                </TableContainer>;
                            })}

                        </div>


                        {!isSubmitted && <div className="row my-4">
                            <div className="col-12 mx-auto d-flex justify-content-end ">
                                <Button variant="contained" onClick={() => {
                                    if (sessionStorage.getItem("role") === "Student") {
                                        confirm({
                                            title: "Submit Confirmation",
                                            description: "Are you sure, you want to submit the Test?",
                                        }).then(() => onSubmitTest());
                                    } else toast.error("Only students can submit the test");

                                }} className="btn btn-success ">Submit </Button>
                            </div>
                        </div>}

                    </div>
                    }

                    {/* ================================== */}

                    {/* after clicking  submit button below  Result will be shown on the screen  */}
                    {result && (<ShowResult />)}

                </div>

            </div>
            <Backdrop sx={{ zIndex: 3001, color: '#fff' }} open={backdrop}><CircularProgress style={{ color: "#fff" }} /></Backdrop>
        </>
    );
};

export default NewTestSheetComp;