import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Heading from "../../../../../Common/Components/Heading";
import { ReadonlyInput } from "../../../Student/Component/Input";
import axios from "axios";
import url from "../../../../../Common/URL";
import { Button } from "@mui/material";

import { useConfirm } from 'material-ui-confirm';
import { useSelector } from "react-redux";

// confirm({ description: 'This action is permanent!' })
// .then(() => { /* ... */ })

const TsProjectViewComp = () => {
    const confirm = useConfirm();
    const navigate = useNavigate();
    const { id } = useParams();

    const [field, setField] = useState({
        uname: sessionStorage.getItem("username"),
        project_desc: "",
        project_name: "",
    })


    const LoadProject = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        }
        axios.post(url + "fetch_single_project", obj)
            .then((response) => {
                let resData = response.data.data;
                // console.log(resData);
                setField({
                    project_desc: resData[0].project_desc,
                    project_name: resData[0].project_name,
                })
            })
            .catch((err) => console.log(err));

    }

    useEffect(() => {
        LoadProject();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ------------------------Deleting Project table's data-------------------------------------
    function onDelete() {

        confirm({ title: "Delete Confirmation", description: 'This Project  will be deleted permanently.' })
            .then(() => {
                const delObj = {
                    id: id,
                    uname: sessionStorage.getItem("username"),
                    tokenId: 123,
                };
                axios.post(url + "delete_project", delObj)
                    .then((res) => {
                        // console.log(res)
                        toast.info("Project deleted");
                        navigate("/ts-project");
                    })
                    .catch((err) => console.log(err));
            })

    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item">Timesheet</li>
                        <li className="breadcrumb-item active"><Link to="/ts-project">Projects</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">View Project</li>
                    </ol>
                </nav>

                <Heading name={`View Project - ${field.project_name}`} />

                <div className="container-fluid">

                    <div className="row mb-4">
                        <div className="col-12 d-flex justify-content-between align-items-end ">
                            <h5>Project Information</h5>
                            <div>
                                <Button variant="contained" color="error" onClick={onDelete} >Delete</Button>
                                <Button variant="contained" onClick={() => navigate("/edit-ts-project/" + id)} className="ms-3">Edit</Button>
                            </div>
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col-11 col-md-8 mx-auto mx-md-0">
                            {/* <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Project ID</label>
                        <div className="col-md-7 col-sm-7">
                        <input type="text" readOnly className="form-control bg-light border-0 fw-bold" value={id} />
                        </div>
                    </div> */}

                            <ReadonlyInput field="Project Name"
                                readOnly
                                type="text" placeholder="Add text"
                                className="form-control border-0 bg-light"
                                value={field.project_name} />

                            <ReadonlyInput field="Project Description"
                                readOnly
                                type="text" placeholder="Add text"
                                className="form-control border-0 bg-light"
                                value={field.project_desc} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TsProjectViewComp