import React from "react";

// Function to check Email
const isEmailValid = (mail) =>{
    var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(mail === null || mail === undefined) return false; 
    else if(( mail === "" ||(mail.match(mailFormat)))) return true;
    else return false;
}

// Will generate Error cell color
const error_cell_style = {
    background : "#ff5c5c",
    border : ".5px solid whitesmoke"
}

// Function to generate Table Row Data
const RowCell = (x , required , error = 0) =>{
    var error_cell = (error || (required && (x === null || x === ""))) ? true : false;
    return(
        <td className="text-nowrap" style={error_cell ? error_cell_style : {}}>{x}</td>
    )
}


// ===== Validator Location Checker Function (Used to check array of locations with actual locations) =============

const ValidatorLocationChecker = (_locArray, actualLocations) =>{
    if(!_locArray) return false;
    
    let split_loc = _locArray.toLowerCase().split(',');
    let trimmed_loc = split_loc.map(x => x.trim())

      //  Checking location name, whether it is proper or not.
    return trimmed_loc.every(x=> actualLocations.indexOf(x) >= 0)
  }

// ===== Validator Batch Checker Function (Used to check array of batches with actual batches)==============

  const ValidatorBatchChecker = (_batchArray, actualBatches) =>{
     if(!_batchArray) return false;
        
    let split_batch = _batchArray.toLowerCase().split(',');
    let trimmed_batch = split_batch.map(x => x.trim())

      //  Checking location name, whether it is proper or not.
    return trimmed_batch.every(x=> actualBatches.indexOf(x) >= 0)
  }
// -------------------------------------------------------------------------------------------------


// ------------================ Student Bulk upload Functions   ===============--------------------
const StudentTableHeaders = [
    "fname", "mname", "lname", "addressline1", "addressline2", "city",
    "state", "country", "zip", "gsc", "stud_code", "dob", "acc_name", "acc_number", "mobile_no",
    "home_no", "parent_fname", "parent_lname", "parent_mobile_no", "location_name", "level_name",
    "batch_name", "email", "parent_email", "parent_email_2", "parent_mobile_no_2", "join_date", "leave_date"
]

const StudentTableRow =  (x , i, loc_exists, batch_exists) =>{
    // If loc_exists is true means location name is proper and can be inserted to db else Error will be shown   
    // If batch_exists is true means batch name is proper and can be inserted to db  else error will be shown  
    return (
        <tr key={i} >
            <th scope="row">{i+1}</th>{RowCell(x[0], 1)} {RowCell(x[1], 0)} {RowCell(x[2], 1)} {RowCell(x[3], 1)} 
            {RowCell(x[4], 0)} {RowCell(x[5], 1)} {RowCell(x[6], 1)} {RowCell(x[7], 1)} {RowCell(x[8], 1)} 
            {RowCell(x[9], 0)} {RowCell(x[10], 0)} {RowCell(x[11], 1)} {RowCell(x[12], 1)} {RowCell(x[13], 1)} 
            {RowCell(x[14], 1)} {RowCell(x[15], 0)} {RowCell(x[16], 1)} {RowCell(x[17], 1)}
            {RowCell(x[18], 1)} 
            {/*  for third parameter "0 means no error" and "1 means error". */}
            {RowCell(x[19], 1, loc_exists ? 0 : 1)} 
            {RowCell(x[20], 1)} 
            {/* for third parameter '0 means no error" and "1 means error". */}
            {RowCell(x[21], 1, batch_exists ? 0 : 1)}
            {/* // Email checking  */}
            {RowCell(x[22], 1 , isEmailValid(x[22]) ? 0 : 1 )}  
            {/* // Email checking  */}
            {RowCell(x[23], 1 , isEmailValid(x[23]) ? 0 : 1 )}  
            {/* // Email checking  */}
            {RowCell(x[24], 0 , isEmailValid( x[24] ? x[24] : "" ) ? 0 : 1)} 
            {RowCell(x[25], 0)} {RowCell(x[26], 1)} {RowCell(x[27], 0)}
        </tr>
    )
}

// ------------================ Teacher Bulk upload Functions ===============--------------------
const TeacherTableHeaders = [
        "fname", "mname", "lname","addressline1", "addressline2", "city",
        "state", "country", "zip", "designation", "education", "experience", "registration_number", 
        "effective_from", "effective_to","email", "kvk_number", "mobile_no", "home_no",
        "location_name", "level_name", "batch_name","probation_period", "contract_period", 
        "contract_renewal_date", "join_date", "leave_date"
]	 	 	 	 	 	 	 	 	 	 	 	 	 	 

const TeacherTableRow =  (x , i, loc_exists, batch_exists) =>{
    // If loc_exists is true means location name is proper and can be inserted to db else Error will be shown   
    // If batch_exists is true means batch name is proper and can be inserted to db  else error will be shown  
    return (
        <tr key={i} >
            <th scope="row">{i+1}</th>{RowCell(x[0], 1)} {RowCell(x[1], 0)} {RowCell(x[2], 1)} {RowCell(x[3], 1)} 
            {RowCell(x[4], 0)} {RowCell(x[5], 1)} {RowCell(x[6], 1)} {RowCell(x[7], 1)} {RowCell(x[8], 1)} 
            {RowCell(x[9], 0)} {RowCell(x[10], 0)} {RowCell(x[11], 0)} {RowCell(x[12], 0)} 
            {RowCell(x[13], 0)} {RowCell(x[14], 0)} 
            {/* // Email checking  */}
            {RowCell(x[15], 1 , isEmailValid(x[15]) ? 0 : 1 )}  
            {RowCell(x[16], 1)} {RowCell(x[17], 1)} {RowCell(x[18], 0)} 
            {/*  for third parameter "0 means no error" and "1 means error". */}
            {RowCell(x[19], 1, loc_exists ? 0 : 1)} 
            {RowCell(x[20], 1)} 
            {/* for third parameter '0 means no error" and "1 means error". */}
            {RowCell(x[21], 1, batch_exists ? 0 : 1)}
            {RowCell(x[22], 0)} {RowCell(x[23], 0)} 
            {RowCell(x[24], 0)} {RowCell(x[25], 1)} {RowCell(x[26], 0)}
        </tr>
    )
}

// ------------================ Question Bulk upload Functions ===============--------------------
const QuestionTableHeaders = [
    "field1", "field2", "field3", "field4", "field5", "field6",
    "field7", "field8", "field9", "field10", "field11", "field12", "field13", "field14",
    "field15", "field16", "answer", "level_name", "assignment_type", "category_name", "que_type", "page"
]
	 	 	 	 	 	 	 	 	 	 	 	 	 	 	 	 	 
    // a_f_r - Assignment fields are Required if a_f_r = 1
    // te_f_r - Exam & Test fields are Required if te_f_r = 1
const QuestionTableRow =  (x , i , a_f_r , te_f_r ,que_type_error ) =>{
    // If loc_exists is true means location name is proper and can be inserted to db else Error will be shown   
    // If batch_exists is true means batch name is proper and can be inserted to db  else error will be shown  
return (
    <tr key={i} >
        <th scope="row">{i+1}</th>
        {RowCell(x[0], 1)} {RowCell(x[1], 1)}  {RowCell(x[2], 0)} {RowCell(x[3], 0)}
        {RowCell(x[4], 0)} {RowCell(x[5], 0)}  {RowCell(x[6], 0)} {RowCell(x[7], 0)}
        {RowCell(x[8], 0)} {RowCell(x[9], 0)}  {RowCell(x[10], 0)} {RowCell(x[11], 0)}
        {RowCell(x[12], 0)} {RowCell(x[13], 0)}  {RowCell(x[14], 0)} {RowCell(x[15], 0)}
        {RowCell(x[16], 1)} {RowCell(x[17], 1)}  {RowCell(x[18], a_f_r)} {RowCell(x[19], te_f_r)}
        {RowCell(x[20], 1, que_type_error)} {RowCell(x[21], a_f_r)}  
    </tr>
)
}

// ===============================================================================================================

export {StudentTableHeaders, StudentTableRow, TeacherTableHeaders,
            TeacherTableRow, QuestionTableHeaders, QuestionTableRow,
            isEmailValid, ValidatorBatchChecker, ValidatorLocationChecker};


	 	 	 	 	 	 	 	 	 	 	 
