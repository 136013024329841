import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import Heading from "../../../../Common/Components/Heading";
import url from "../../../../Common/URL";

import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";

const ViewBalanceHistoryComp = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const { item, location } = useParams();

    // const [state ,setState]= useState({
    //     item_name:"", description:"", location:"", created_by:"", quantity:"", attachments:"",
    //     comments:"", trans_date:"", transaction_type:"", assigned_to:""
    // })


    const Columns = useMemo(
        () => [
            { header: 'Product', accessorKey: 'item_name' },
            { header: 'Quantity', accessorKey: 'quantity' },
            { header: 'Location', accessorKey: 'location' },
            { header: 'Done by', accessorKey: 'created_by' },
            { header: 'Type', accessorKey: 'transaction_type' },
            { header: 'Done on', accessorKey: 'trans_date' },
        ],
        [],
    );

    useEffect(() => {
        // LoadTransactionDetail();
        LoadPreviousTransaction();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const LoadPreviousTransaction = () => {

        const obj = { uname: sessionStorage.getItem("username"), item_name: item, location: location }
        axios.post(url + "select_transaction_log", obj)
            .then((response) => {
                // console.log(response)
                if (response.data.success) {
                    let a = response.data.data;
                    // console.log(a);
                    a !== null ? setData(a) : setData([]);
                    setLoading(false)
                } else {
                    setData([]);
                    setLoading(false)
                }
            })
            .catch((err) => console.log(err));

    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'} >
                {/*-----------------------Bread crumb------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Inventory</li>
                        <li className="breadcrumb-item active"><Link to="/inv-balance">Inventory Balance</Link></li>
                        <li className="breadcrumb-item active">Previous Transaction(s)</li>
                    </ol>
                </nav>
                <Heading name="Previous Transaction(s)" />
                {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}

                <div className="container-fluid my-5 px-4">


                    <div className="row">
                        <div className="col-12 mx-auto">
                            <h5 className="mb-4">{`${item}`}</h5>

                            <MaterialReactTable
                                state={{ isLoading: loading }}

                                columns={Columns}
                                data={data}
                                enableRowNumbers
                                initialState={{ density: 'compact' }}
                                enableDensityToggle={false}
                                
                                getRowId={(originalRow) => originalRow.inventory_trans_id}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: (event) => navigate("/balance-history-trans-detail/" + row.id),
                                    sx: { cursor: 'pointer' },
                                })}

                            />
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}
export default ViewBalanceHistoryComp

