import React, { useState, useEffect } from "react";
import Input from "../../Student/Component/Input";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import Heading from "../../../../Common/Components/Heading";
import axios from "axios";
import url from "../../../../Common/URL";
import { Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";


const EditTestComp = () => {
    let message = "";
    const navigate = useNavigate();

    const { id } = useParams();
    const [lev, setLev] = useState([]);
    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [form_data, setForm_data] = useState(true); //Used to compare if any update is done in form data.

    const [state, setState] = useState({
        uname: sessionStorage.getItem("username"), test_id: "", page: "", assignment_type: "",
        test_name: "", test_type: "", level_name: "", test_desc: "", youtube_link: "",
        generator_test: ""
    });
    useEffect(() => {
        select_Tests();
        loadLevel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Below function will fetch the select test values on which we have clicked for editing 
    const select_Tests = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        };
        axios.post(url + "fetch_single_test", obj)
            .then((response) => {
                let resData = response.data.data;
                console.log(resData);
                setForm_data(resData);       //THis will help in checking any update done in form, so we can enable or disable the save form button

                setState((pre) => {
                    return {
                        ...pre, test_name: resData[0].test_name, test_desc: resData[0].test_desc, assignment_type: resData[0].assignment_type,
                        test_type: resData[0].test_type, level_name: resData[0].level_name, page: resData[0].page,
                        test_id: resData[0].test_id, youtube_link: resData[0].youtube_link,
                        generator_test: resData[0].generator_test
                    };
                });
            })
            .catch((err) => console.log(err)).finally(() => {
                setDisable(true);
            });
    };
    // -----------This function will Fetch all the Existing Levels--------------------
    const loadLevel = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        axios.post(url + "fetch_levels", obj)
            .then((response) => {
                let a = response.data.data;
                // console.log(a);
                setLev(a);
            })
            .catch((err) => console.log(err));
    };

    // this function will set the input values to state variables , and will make all the inputs into controlled inputs

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        if (form_data[0][name] !== value) {
            setDisable(false);
        } else {
            setDisable(true);
        }

        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            };
        });
    };
    // This function will submit the form 
    const onSubmit = (e) => {
        e.preventDefault();
        setDisable(true);
        setLoading(true);
        /*---------------Fetching Date in YEAR-MONTH-DATE  format-------------------------- */
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();

        var newdate = year + "-" + month + "-" + day;

        /*---------------Creating object to send data through Axios------------------------- */
        const obj = {
            uname: sessionStorage.getItem("username"), id: id,
            updated_by: sessionStorage.getItem("username"), updated_date: newdate,
            test_name: state.test_name, test_type: state.test_type, page: state.page, assignment_type: state.assignment_type,
            level_name: state.level_name, test_desc: state.test_desc, youtube_link: state.youtube_link,
        };
        axios.post(url + "edit_test", obj)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    select_Tests().then(() => {
                        toast.success("Updated successfully!");
                    });
                } else {
                    message = res.data.message;
                    toast.error(message);
                }
            })
            .catch((err) => console.log(err)).finally(() => {
                setLoading(false);
            });

    };
    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item"><Link to="/test">Tests</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Edit Test</li>
                    </ol>
                </nav>
                <Heading name={`Edit Test - ${state.test_name}`} />

                <div className="container-fluid">



                    <form onSubmit={onSubmit} >
                        <div className="row mb-4 ">
                            <div className="col-12 d-flex justify-content-between align-items-end">
                                <h5>Test Information</h5>
                                <div>
                                    <Button variant="contained" onClick={() => navigate("/view-test/" + state.test_id)} >Back</Button>
                                    <Button variant="contained" type="submit" disabled={disable || loading} className="ms-3">{loading ? <CircularProgress color="inherit" size={20} /> : 'Save'}</Button>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-11 col-md-7 mx-auto mx-md-0">

                                {/* <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label">Test ID</label>
                                    <div className="col-md-6 col-sm-7">
                                    <input type="text" readOnly className="form-control bg-light border-0 fw-bold" value={state.test_id} />
                                    </div>
                                </div>    */}

                                <Input field="Test Name" type="text" placeholder="Enter Name" className="form-control" onChange={onChange} name="test_name" value={state.test_name} required={true} />

                                {state.generator_test === 1 && <div className="row ">
                                    <div className="alert alert-warning py-1 px-2" role="alert">
                                        The <strong>Test Type</strong> and <strong>Level</strong> cannot be changed since the <strong>{`${state.test_type}`}</strong>  was created using Generator.
                                    </div>
                                </div>}

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Test Type</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select disabled={state.generator_test === 1} name="test_type" value={state.test_type} onChange={onChange} className="form-select" required>
                                            <option value="">Select Test</option>
                                            <option value="Assignment">Assignment</option>
                                            <option value="Test">Test</option>
                                            {state.generator_test === 1 && <option value="Practice Test">Practice Test</option>}
                                            <option value="Exam">Exam</option>
                                            {state.generator_test === 1 && <option value="Practice Exam">Practice Exam</option>}
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Level</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select disabled={state.generator_test === 1} name="level_name" value={state.level_name} onChange={onChange} className="form-select" required>
                                            <option value="">Choose</option>
                                            {lev.map((data, i) => (<option key={i} value={data.level_name}>{data.level_name}</option>))}
                                        </select>
                                    </div>
                                </div>

                                {state.test_type === "Assignment" &&
                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label red-asterisk">Assignment Type</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <select name="assignment_type" value={state.assignment_type} onChange={onChange} className="form-select" required>
                                                <option value="">--</option>
                                                <option value="A">Class Work</option><option value="B">Home Work</option>
                                            </select>
                                        </div>
                                    </div>
                                }

                                {state.test_type === "Assignment" &&
                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label red-asterisk">Page</label>
                                        <div className="col-sm-7 col-md-7 ">

                                            <input name="page" value={state.page} onChange={onChange} className="form-control" required />
                                        </div>
                                    </div>
                                }

                                <Input field="Description" type="text" placeholder="Add Description" className="form-control" onChange={onChange} name="test_desc" value={state.test_desc} />


                                <Input field="Youtube Link" type="text" placeholder="Add Link" className="form-control" onChange={onChange} name="youtube_link" value={state.youtube_link} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default EditTestComp;