import { Close } from "@mui/icons-material";
import { AppBar, Button, IconButton, Toolbar, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import url from "../../../../Common/URL";

const ContactParentComp = ({ id, setOpenDrawer }) => {

    const [state, setState] = useState({
        uname: sessionStorage.getItem("username"), mail1: "", mail2: "", message: "", fname: ""
    });

    const onChange = (e) => {
        const input = e.target;
        const value = input.value;
        const name = input.name;
        setState(pre => {
            return {
                ...pre, [name]: value
            };
        });
    };

    useEffect(() => {
        LoadParentEMail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const LoadParentEMail = () => {
        const obj = {
            uname: state.uname,
            id: id
        };
        axios.post(url + "fetch_single_student", obj)
            .then((response) => {
                let resData = response.data.data;
                // console.log(resData);
                setState({
                    fname: resData[0].fname,
                    mail1: resData[0].parent_email,
                    mail2: resData[0].parent_email_2,
                });
            })
            .catch((err) => console.log(err));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (state.mail2 !== "" || state.mail1 !== "") {
            const obj = { uname: sessionStorage.getItem("username"), mail1: state.mail1, mail2: state.mail2, message: state.message };
            axios.post(url + "parent_mail", obj)
                .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                        toast.success("Email sent successfully");
                    } else {
                        toast.error("Error occurred while sending mail, please try again later.");
                    }
                })
                .catch(res => {
                    console.log(res);
                    toast.error("Error occurred while sending mail, please try again later.");
                });
        } else {
            toast.error("Email can't be sent, Parent Emails are not available.");
        }
        setState((pre) => {
            return {
                ...pre, message: ""
            };
        });
    };

    return (

        <>
            <AppBar color="transparent" position="static">
                <Toolbar>
                    <IconButton
                        onClick={() => setOpenDrawer(false)}
                        color="inherit"
                        aria-label="close"
                        sx={{ mr: 2 }}
                    >
                        <Close />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Contact Parent
                    </Typography>
                </Toolbar>
            </AppBar>

            <div className="container-fluid p-4" >
                <form onSubmit={onSubmit}>
                    <div className="mb-3 ">
                        <label className=" form-label" >To</label>
                        <div className="col-12 mt-1">
                            <input type="text" readOnly className="form-control bg-white" value={state.fname === "" ? "" : `${state.fname}'s Parent`} />
                        </div>
                    </div>
           
                    <div className="mb-3 ">
                        <label className=" form-label" >Message</label>
                        <div className="col-12 mt-1">
                            <textarea type="text" required name="message" className="form-control" value={state.message} onChange={onChange} rows="6" placeholder="Add your message here..." />
                        </div>
                    </div>
                    <div className="mt-5">
                        <Button variant="contained" type="submit" >Send</Button>
                    </div>

                </form>
            </div>

            {/* </div> */}
        </>
    );
};

export default ContactParentComp;