import { createSlice } from '@reduxjs/toolkit'

export const profileNameSlice = createSlice({
    name: 'profileName',
    initialState: { value: `${sessionStorage.getItem("userFname")} ${sessionStorage.getItem("userLname")}` },
    reducers: {
        LOGGED_IN: (state, action) => {
            state.value = action.payload;
        },
        LOGGED_OUT: (state, action) => {
            state.value = action.payload;
        },
    },
})

export const { LOGGED_IN, LOGGED_OUT } = profileNameSlice.actions;

export default profileNameSlice.reducer;