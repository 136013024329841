import { isEmailValid, ValidatorBatchChecker, ValidatorLocationChecker } from "../Functions/BulkUploadHelper";

/*
[
    0: "fname", 1: "mname", 2: "lname", 3: "addressline1", 4: "addressline2", 5: "city",
    6: "state", 7: "country", 8:"zip", 9: "designation", 10:"education", 11:"experience", 12:"registration_number", 
    13: "effective_from", 14:"effective_to", 15:"email", 16: "kvk_number", 17:"mobile_no", 18:"home_no",
    19: "location_name", 20: "level_name", 21: "batch_name", 22: "probation_period", 23: "contract_period", 
    24: "contract_renewal_date", 25: "join_date", 26: "leave_date"
]
*/	

const TeacherUploadValidator = (arrayOfArray, loc, batch) => {

    var errorArray = [];

    const GenerateError = (string) =>{
        errorArray.push(string);
    }

    // ======= Teacher File Validator ============================ 
    const TestValidator = (arr, index) =>{

        // The if operator returns false for null, undefined & blank variables.
        // So if var x is equal to null, undefined or an empty string than if(x) will return false and if(!x) will return true.
        // So by using if(!x) we will generate the error message. 

      
        if( !arr[0] ) GenerateError(`First name is required on row ${index +1}.`)
        if( !arr[2] ) GenerateError(`Last name is required on row ${index +1}.`)
        if( !arr[3] ) GenerateError(`Address line 1 is required on row ${index +1}.`)
        if( !arr[5] ) GenerateError(`City name is required on row ${index +1}.`)
        if( !arr[6] ) GenerateError(`State name is required on row ${index +1}.`)
        if( !arr[7] ) GenerateError(`Country name is required on row ${index +1}.`)
        if( !arr[8] ) GenerateError(`Zip is required on row ${index +1}.`)

        if( !arr[15] ) GenerateError(`Email address is required on row ${index +1}.`)
        else if( !isEmailValid(arr[15]) ){
            GenerateError(`The email is incorrect on row no. ${index+1}.`)
        }

        if( !arr[16] ) GenerateError(`KVK number is required on row ${index +1}.`)
        if( !arr[17] ) GenerateError(`Mobile number is required on row ${index +1}.`)

        if( !arr[19] ) GenerateError(`Location name is required on row ${index +1}.`)
        else if( !ValidatorLocationChecker(arr[19] , loc) ){
            GenerateError(`There is a problem with the location name field in row number ${index+1}, where some or all of the location name(s) are incorrect. Check existing locations within the Portal to resolve the issue.`)
        }
        
        if( !arr[20] ) GenerateError(`Level name is required on row ${index +1}.`)
   
        if( !arr[21] ) GenerateError(`Batch name is required on row ${index +1}.`)
        else if( !ValidatorBatchChecker(arr[21] , batch)  ){
            GenerateError(`There is a problem with the batch name field in row number ${index+1}, where some or all of the batch name(s) are incorrect. Check existing batches within the Portal to resolve the issue.`)
        }

        if( !arr[25] ) GenerateError(`Join date is required on row ${index +1}.`)
    }

    // ======= Step 1 ====================================
    arrayOfArray.map((x, i) => TestValidator(x, i));
    

    return errorArray;
}

export default TeacherUploadValidator;

  