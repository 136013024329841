import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

import { Alert, AppBar, Button, Card, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Toolbar, Tooltip, Typography } from "@mui/material";

import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

import { Close } from "@mui/icons-material";
import MaterialReactTable from 'material-react-table';
import { useConfirm } from "material-ui-confirm";
import { toast } from "react-toastify";
import { CurrentDate } from "../../../../../Common/Functions/StringDateTime";
import url from "../../../../../Common/URL";
import Input from "../../../../Setup/Student/Component/Input";

const AdmissionDetailsComp = ({ id, setOpenDrawer, loadParentData }) => {

    const confirm = useConfirm();

    const [loc, setLoc] = useState([]);
    const [batch, setBatch] = useState([]);
    const [lev, setLev] = useState([]);

    const [paymentStatues, setPaymentStatues] = useState([]);
    const [admissionStatues, setAdmissionStatues] = useState([]);
    const [coordinatorList, setCoordinatorList] = useState([]);

    const [logMessage, setLogMessage] = useState('');

    const [logList, setLogList] = useState([]);

    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({});

    const [admissionFormData, setAdmissionFormData] = useState({
        payment_mode: 'online', payable_amount: '', parent_email: '',
        admission_id: id, created_by: sessionStorage.getItem('username')
    });

    const [admLoading, setAdmLoading] = useState(false);
    const [openSendAdmissionFormDialog, setOpenSendAdmissionFormDialog] = useState(false);

    const [offlinePaymentFormData, setOfflinePaymentFormData] = useState({
        admission_id: id, created_by: sessionStorage.getItem('username'),
        fee_type: 2, fee_amount: '', payment_taken_date: '',
    });
    const [offlinePaymentLoading, setOfflinePaymentLoading] = useState(false);
    const [openOfflinePaymentDialog, setOpenOfflinePaymentDialog] = useState(false);

    const [totalAdmissionPaymentDone, setTotalAdmissionPaymentDone] = useState(0);

    // State to indicate whether the admission process for a student is completed,
    // i.e., when the total payment is done and the student record is created.
    const [isAdmissionCompleted, setIsAdmissionCompleted] = useState(true);

    const Columns = useMemo(
        () => [
            { header: 'Payment Mode', accessorKey: 'fees_mode' },
            { header: 'Amount (Euros)', accessorKey: 'fee' },
            { header: 'Received On', accessorKey: 'paymentDate', type: "date" },
        ],
        [],
    );

    const [paymentsTableData, setPaymentsTableData] = useState([]);

    useEffect(() => {
        loadAllAsyncTasks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const loadAllAsyncTasks = () => {
        toast.info('Loading...', { autoClose: false, closeOnClick: false });
        setLoading(true);
        let allCompleted = Promise.all([
            loadLocation(),
            loadBatch(),
            loadLevel(),
            // loadPaymentStatues(),
            // loadAdmissionStatues(),
            // loadAdmissionCoordinators(),
            fetchAdmissionDetails(),
            fetchLogMessages(),
            fetchPaymentLogs(),
        ]);
        allCompleted.then(() => {
            toast.dismiss();
            setLoading(false);
        });
    };

    // -----------This function will Fetch all the Existing Locations--------------------
    const loadLocation = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "fetch_locations", obj)
            .then((response) => {
                let a = response.data.data;
                // console.log(a);
                setLoc(a);
            })
            .catch((err) => console.log(err));
    };

    // -----------  This function will Fetch all the Existing Batch--------------------
    const loadBatch = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "fetch_batches", obj)
            .then((response) => {
                let a = response.data.data;
                //   console.log(a);
                setBatch(a);
            })
            .catch((err) => console.log(err));
    };

    // -----------  This function will Fetch all the Existing Levels--------------------
    const loadLevel = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "fetch_levels", obj)
            .then((response) => {
                let a = response.data.data;
                //   console.log(a);
                setLev(a);
            })
            .catch((err) => console.log(err));
    };


    // -----------This function will Fetch all the Existing Payment Statues--------------------
    const loadPaymentStatues = async () => {

        await axios.get(url + "lk_payment_status")
            .then((response) => {
                let a = response.data;
                // console.log(a);
                setPaymentStatues(a);
            })
            .catch((err) => console.log(err));
    };

    // -----------This function will Fetch all the Existing Admission Coordinators--------------------
    const loadAdmissionCoordinators = async () => {

        await axios.get(url + "admission_coordinator_list")
            .then((response) => {
                let a = response.data;
                // console.log(a);
                setCoordinatorList(a);
            })
            .catch((err) => console.log(err));
    };

    // -----------This function will Fetch all the Existing Admission Statues--------------------
    const loadAdmissionStatues = async () => {

        await axios.get(url + "lk_admission_status")
            .then((response) => {
                let a = response.data;
                // console.log(a);
                setAdmissionStatues(a);
            })
            .catch((err) => console.log(err));
    };

    // ------------This function will fetch admission details ----------------------------
    const fetchAdmissionDetails = async () => {
        const obj = {
            admission_id: id,
        };

        await axios.post(url + "view_admission_form", obj)
            .then((response) => {
                let a = response.data;
                console.log(a);
                setState(a[0]);
                let admissionDone = a[0].student_admission_done === 1 ? true : false;
                setIsAdmissionCompleted(admissionDone);
                setAdmissionFormData({ ...admissionFormData, payable_amount: a[0].payable_amount, parent_email: a[0].parent_email });
            })
            .catch((err) => console.log(err));
    };

    // ====================== OnChange =========================
    const onChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        setState(pre => {
            return { ...pre, [name]: value };
        });
    };

    const updateAdmissionForm = async () => {

        if (Number(state.payable_amount) < totalAdmissionPaymentDone) {
            toast.error(`Please ensure the Payable Amount is equal to or greater than the total admission payment already made (${totalAdmissionPaymentDone} EUR).`);
            return;
        }

        setLoading(true);
        state.updated_by = sessionStorage.getItem('username');
        console.log(state);
        await axios
            .patch(url + "edit_admission_form", state)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    fetchAdmissionDetails().then(() => {
                        toast.success("Form updated");
                    });
                } else {
                    toast.error("An error occurred while updating form, please try again later!");
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            });
    };

    const handleAddLog = async () => {
        if (logMessage === '') {
            toast.error("Please enter a log message.");
            return;
        }

        setLoading(true);
        toast.info('Loading...', { autoClose: false, closeOnClick: false });

        const obj = {
            admission_id: id, log_message: logMessage, uname: sessionStorage.getItem('username'),
        };
        console.log(obj);

        await axios.post(url + "add_adm_log", obj)
            .then(async (response) => {
                console.log(response);
                if (response.data.success) {
                    fetchLogMessages().then(() => {
                        setLogMessage('');
                        toast.dismiss();
                        toast.success('Log Added');
                    });
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchPaymentLogs = async () => {
        await axios.post(url + 'student_fees_log', { admission_id: id }).then((res) => {
            console.log("Payment Logs:", res);
            setPaymentsTableData(res.data ?? []);

            // Calculate the sum of 'fee' values
            const sumOfFees = res.data.reduce((accumulator, currentObject) => {
                return accumulator + Number(currentObject.fee);
            }, 0);

            // Update the state with the calculated sum
            setTotalAdmissionPaymentDone(sumOfFees);
        });
    };


    const fetchLogMessages = async () => {
        await axios.post(url + 'adm_log_fetch', { admission_id: id }).then((res) => {
            // console.log(res);
            setLogList(res.data ?? []);
        });
    };

    const deleteLogMessage = async (adm_log_id) => {
        toast.info('Loading...', { autoClose: false, closeOnClick: false });
        console.log(adm_log_id);
        await axios.post(url + "adm_log_delete", { adm_log_id })
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    fetchLogMessages().then(() => {
                        toast.dismiss();
                        toast.info('Log Deleted');
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    const handleSendAdmissionForm = async (e) => {
        e.preventDefault();
        setAdmLoading(true);
        console.log(admissionFormData);

        toast.info('Loading...', { autoClose: false, closeOnClick: false });
        await axios.post(url + "send_admission_form", admissionFormData)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    fetchAdmissionDetails().then(() => {
                        toast.success('Admission form sent');
                    });
                } else {
                    toast.error('An error occurred while sending form, please try again later');
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error('An error occurred while sending form, please try again later');

            }).finally(() => {
                toast.dismiss();
                setOpenSendAdmissionFormDialog(false);
            });
        setAdmLoading(false);
    };

    const handleOfflinePayment = async (e) => {
        e.preventDefault();
        // console.log(offlinePaymentFormData);

        if (isNaN(offlinePaymentFormData.fee_amount)) {
            toast.error("Please enter a valid amount.");
            return;
        }
        if (offlinePaymentFormData.fee_amount > calculateRemainingAmount()) {
            toast.error("Amount exceeds remaining amount.");
            return;
        }

        setOfflinePaymentLoading(true);
        await axios.post(url + "record_manual_payment", offlinePaymentFormData)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    fetchPaymentLogs().then(() => {
                        toast.success('Payment added successfully');
                    });
                } else {
                    toast.error('An error occurred while adding payment, please try again later');
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error('An error occurred while adding payment, please try again later');

            }).finally(() => {
                setAdmLoading(false);
                setOfflinePaymentLoading(false);
                setOfflinePaymentFormData({ ...offlinePaymentFormData, fee_amount: '', payment_taken_date: '' });
                setOpenOfflinePaymentDialog(false);
            });
    };

    // ----------functions for Submitting Country and state --------------
    const selectCountry = (val) => {

        setState((preValue) => {
            return {
                ...preValue,
                country: val
            };
        });
    };

    const selectRegion = (val) => {

        setState((preValue) => {
            return {
                ...preValue,
                state: val
            };
        });
    };



    // Function to calculate remaining amount
    const calculateRemainingAmount = () => {
        // Assuming admissionFormData.payable_amount is a string
        const payableAmount = Number(admissionFormData.payable_amount);
        const remainingAmount = payableAmount - totalAdmissionPaymentDone;
        return remainingAmount;
    };


    const createStudentRecord = async (e) => {
        e.preventDefault();
        confirm({ title: "Confirm New Student Record Creation", description: 'Are you sure you want to proceed and create a new student record with the provided data? This action cannot be undone. Please review the information carefully before confirming.' })
            .then(() => {
                setLoading(true);

                const newDate = CurrentDate();

                // ------------ Storing form data in "obj" object fetched from various input fields-------------
                const obj = {
                    adm_id: state.adm_id,
                    fname: state.fname, mname: state.mname, lname: state.lname, uname: sessionStorage.getItem("username"),
                    city: state.city, zip: state.zip, mobile_no: state.mobile_no,
                    home_no: state.home_no, email: state.email, parent_fname: state.parent_fname, join_date: state.join_date,
                    parent_lname: state.parent_lname, parent_mobile_no: state.parent_mobile_no, parent_email: state.parent_email,
                    parent_mobile_no_2: state.parent_mobile_no_2, parent_email_2: state.parent_email_2,
                    created_at: newDate, approved_date: state.approved_date, leave_date: state.leave_date, addressline1: state.addressline1, addressline2: state.addressline2,
                    gsc: state.gsc, stud_code: state.stud_code, dob: state.dob, acc_name: state.acc_name, acc_number: state.acc_number,

                    active: true, photo_consent: state.photo_consent, disclaimer_signed: state.disclaimer_signed,

                    state: state.state, country: state.country, location_name: state.location_name, batch_name: state.batch_name,
                    level_name: state.level_name, user_name: state.email, status: 'Active'
                };

                // ------------ Sending form data stored in "obj" object to the add_student file using AXIOS-------------

                console.log(obj);

                axios.post(url + "add_student", obj)
                    .then((res) => {
                        // console.log(res)
                        if (res.data.success) {
                            fetchAdmissionDetails().then(() => {
                                loadParentData().then(() => {
                                    if (res.data.mail_sent === 1) {
                                        toast.success("Student record created");
                                    } else toast.warning("Student added, email notification failed.");
                                });
                            });
                        } else if (res.data.show_error_message === 1) {
                            toast.error(res.data.message);
                        } else {
                            toast.error("Student not created!");
                        }
                    })
                    .catch((err) => console.log(err))
                    .finally(() => {
                        setLoading(false);
                    });
            });
    };

    const updatePayableAmount = async () => {

        if (Number(state.payable_amount) < totalAdmissionPaymentDone) {
            toast.error(`Please ensure the Payable Amount is equal to or greater than the total admission payment already made (${totalAdmissionPaymentDone} EUR).`);
            return;
        }
        setLoading(true);

        let obj = {
            "adm_id": state.adm_id,
            "payable_amount": state.payable_amount
        };

        await axios
            .post(url + "edit_payable_amount", obj)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    fetchAdmissionDetails().then(() => {
                        toast.success("Total payable Amount updated");
                    });
                } else {
                    toast.error("An error occurred  please try again later!");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("An error occurred  please try again later!");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {/* ########################################### Dialog to send Admission form ###################################### */}

            <Dialog fullWidth={true} maxWidth="sm" open={openSendAdmissionFormDialog}>
                <DialogTitle id="alert-dialog-title">Send Admission Form</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSendAdmissionForm} id="sendAdmissionForm" >
                        {/* &&&&&&&&&&&&&&&&&&&&&&&& We wont allow to change the payment mode &&&&&&&&&&&&&&&&&&&&&&&& */}

                        {/* <FormControl className="mb-3">
                            <FormLabel id="demo-row-radio-buttons-group-label">Payment Mode</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="payment_mode"
                                value={admissionFormData.payment_mode}
                                onChange={(e) => {
                                    setAdmissionFormData({ ...admissionFormData, payment_mode: e.target.value })
                                }}
                            >
                                <FormControlLabel value="online" control={<Radio />} label="Online" />
                                <FormControlLabel value="offline" control={<Radio />} label="Offline (Manual)" />

                            </RadioGroup>
                        </FormControl> */}
                        <div className="mb-3">
                            <label>Parent Email</label>
                            <input className="form-control" required name="parent_email" value={admissionFormData.parent_email}
                                onChange={(e) => {
                                    setAdmissionFormData({ ...admissionFormData, parent_email: e.target.value });
                                }} />
                            <p className="form-text">The admission form will be sent to this email-address</p>
                        </div>
                        {admissionFormData.payment_mode === 'online' && <div className="mb-3">
                            <label>Payable Amount (In Euros)</label>
                            <input className="form-control" required name="payable_amount" value={admissionFormData.payable_amount}
                                onChange={(e) => {
                                    setAdmissionFormData({ ...admissionFormData, payable_amount: e.target.value });
                                }} />
                        </div>}

                    </form>
                </DialogContent>

                <DialogActions>
                    <Button color="primary" disabled={admLoading} onClick={() => {
                        setOpenSendAdmissionFormDialog(false);
                        setAdmissionFormData({ ...admissionFormData, payment_mode: 'online' });
                    }}>Close</Button>
                    <Button type="submit" form="sendAdmissionForm" color="primary" > {admLoading ? <CircularProgress size={20} /> : "Send Form"}</Button>
                </DialogActions>
            </Dialog>
            {/* ################################################################################################################################## */}

            {/* ########################################### Dialog to Record Offline payment ###################################### */}

            <Dialog fullWidth={true} maxWidth="xs" open={openOfflinePaymentDialog}>
                <DialogTitle id="alert-dialog-title">Record Offline Payment</DialogTitle>
                <DialogContent>

                    <div className="vstack gap-2">
                        <div className="hstack">
                            <span className="fw-semibold me-auto">Total Payable Amount:</span>
                            <span className=" fw-semibold text-success">{admissionFormData.payable_amount} EUR</span>
                        </div>
                        <div className="hstack">
                            <span className="fw-semibold me-auto">Remaining Amount:</span>
                            <span className=" fw-semibold text-danger">{calculateRemainingAmount()} EUR</span>
                        </div>
                    </div>
                    <hr />

                    <form onSubmit={handleOfflinePayment} id="handleOfflinePaymentForm" >
                        <div className="mb-3">
                            <label>Amount (In Euros)</label>
                            <input className="form-control" required name="fee_amount" value={offlinePaymentFormData.fee_amount}
                                onChange={(e) => {
                                    setOfflinePaymentFormData({ ...offlinePaymentFormData, fee_amount: e.target.value });
                                }} />
                        </div>

                        <div className="mb-3">
                            <label>Received On</label>
                            <input className="form-control" required name="payment_taken_date" type='date' value={offlinePaymentFormData.payment_taken_date}
                                onChange={(e) => {
                                    setOfflinePaymentFormData({ ...offlinePaymentFormData, payment_taken_date: e.target.value });
                                }} />
                            <p className="form-text">Enter the date on which you have received the payment</p>
                        </div>
                    </form>
                </DialogContent >

                <DialogActions>
                    <Button color="primary" onClick={() => {
                        setOpenOfflinePaymentDialog(false);
                        setOfflinePaymentFormData({ ...offlinePaymentFormData, fee_amount: '', payment_taken_date: '' });
                    }}>Close</Button>
                    <Button type="submit" form="handleOfflinePaymentForm" color="primary" > {offlinePaymentLoading ? <CircularProgress size={20} /> : "Add Payment"}</Button>
                </DialogActions>
            </Dialog >
            {/* ################################################################################################################################## */}


            <AppBar color="transparent" position="static">
                <Toolbar>
                    <IconButton
                        onClick={() => setOpenDrawer(false)}
                        color="inherit"
                        aria-label="close"
                        sx={{ mr: 2 }}
                    >
                        <Close />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Admission Form Details
                    </Typography>
                </Toolbar>
            </AppBar>


            <form onSubmit={createStudentRecord}>
                <div className="container-fluid p-4">
                    <div className="border-bottom pt-2">
                        <nav>
                            <div className="nav " id="nav-tab" role="tablist">
                                <a className="nav_tab nav-link active" data-bs-toggle="tab" href="#nav-student-info" role="tab" aria-controls="nav-student-info" aria-selected="true"> Student Information
                                </a>
                                <a className="nav_tab nav-link" data-bs-toggle="tab" href="#nav-payment-info" role="tab" aria-controls="nav-payment-info" aria-selected="false">Payment Information
                                </a>
                            </div>
                        </nav>
                    </div>
                    {/* -----------------*********** Two tab pane **********-------------- */}
                    <div className="tab-content px-2">
                        {/* ------==== Pane 1 =====----------- */}
                        <div className="tab-pane fade show active" id="nav-student-info" role="tabpanel">

                            <div className="row mt-3">
                                <div className='col-auto'>
                                    {!loading && isAdmissionCompleted &&
                                        <Alert severity="success" className="border shadow-sm">As admission is completed and a student record is created, no further modifications can be made on this page. Please navigate to the Setup/Students menu to update or make any changes to the student record.</Alert>
                                    }

                                    {!loading && !isAdmissionCompleted && ((admissionFormData.payable_amount && (calculateRemainingAmount() <= 0)) ?
                                        <Alert severity="success" className="border shadow-sm">Payment complete. Proceed to create a new student record by clicking the <strong>CREATE STUDENT RECORD</strong> button below.</Alert> :
                                        <Alert color="error">The <strong>CREATE STUDENT RECORD</strong> button will only be enabled once Total Payment is completed in the <strong>Payment Information Tab</strong>.</Alert>
                                    )}
                                </div>
                            </div>


                            <div className="row my-4">
                                <div className="col-12 d-flex align-items-center">
                                    <Button disabled={isAdmissionCompleted || loading} variant="contained" color="secondary" type='button' onClick={() => setOpenSendAdmissionFormDialog(true)} className="me-auto">Send Admission Form To Parent</Button>
                                    <Button className="me-3"
                                        variant="contained" color="success" type='submit'
                                        disabled={isAdmissionCompleted || loading || !(admissionFormData.payable_amount && (calculateRemainingAmount() <= 0))}
                                    >{loading ? <CircularProgress size={20} color="inherit" /> : 'Create Student Record'}</Button>

                                    <Button disabled={isAdmissionCompleted || loading} variant="contained" type="button" onClick={updateAdmissionForm}>{loading ? <CircularProgress size={20} color="inherit" /> : 'Update'}</Button>
                                </div>
                            </div>

                            <div className="row">

                                {/* ----------------------- Column-1 for Inputs ------------------------- */}
                                <div className="col-11 col-md-6">

                                    <h5 className="mb-4">Personal Information</h5>

                                    <Input name="fname" onChange={isAdmissionCompleted ? null : onChange} field="First Name" type="text" placeholder="Add text" className="form-control" value={state.fname} required={true} />

                                    <Input name="mname" onChange={isAdmissionCompleted ? null : onChange} field="Middle Name" type="text" placeholder="Add text" className="form-control" value={state.mname} required={false} />

                                    <Input name="lname" onChange={isAdmissionCompleted ? null : onChange} field="Last Name" type="text" placeholder="Add text" className="form-control" value={state.lname} required={true} />

                                    <Input name="dob" onChange={isAdmissionCompleted ? null : onChange} field="Date of Birth" type="date" placeholder="Add DOB" className="form-control" value={state.dob} required={true} />

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label  red-asterisk">Address Line 1</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <textarea name="addressline1" onChange={isAdmissionCompleted ? null : onChange} className="form-control" placeholder="Add text" value={state.addressline1 ?? ""} required></textarea>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label ">Address Line 2</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <textarea name="addressline2" onChange={isAdmissionCompleted ? null : onChange} className="form-control" placeholder="Add text" value={state.addressline2 ?? ""} ></textarea>
                                        </div>
                                    </div>

                                    <Input name="city" onChange={isAdmissionCompleted ? null : onChange} field="City" type="text" placeholder="Add text" className="form-control" value={state.city} required={true} />

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label red-asterisk">Country</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <CountryDropdown
                                                disabled={isAdmissionCompleted}
                                                className="form-select"
                                                name="country"
                                                value={state.country ?? ""}
                                                onChange={(val) => selectCountry(val)} />
                                        </div>
                                    </div>


                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label red-asterisk">Province</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <RegionDropdown
                                                disabled={isAdmissionCompleted}
                                                className="form-select"
                                                name="state"
                                                country={state.country}
                                                value={state.state ?? ""}
                                                onChange={(val) => selectRegion(val)} />
                                        </div>
                                    </div>


                                    <Input name="zip" onChange={isAdmissionCompleted ? null : onChange} field="Zip" type="text" placeholder="Add text" className="form-control" value={state.zip} required={true} />

                                    <Input name="mobile_no" onChange={isAdmissionCompleted ? null : onChange} field="Mobile Number" type="text" placeholder="Add text" className="form-control" value={state.mobile_no} required={true} />

                                    <Input name="home_no" onChange={isAdmissionCompleted ? null : onChange} field="Home Phone" type="text" placeholder="Add text" className="form-control" value={state.home_no} required={false} />

                                    <Card variant="outlined" className="mb-4">
                                        <CardContent>
                                            <Input name="email" onChange={isAdmissionCompleted ? null : onChange} field="Email" type="email" placeholder="Add text" className="form-control" value={state.email} required={true} />
                                            <p className="alert alert-info mb-0">Please enter a unique email address for this child. It will serve as their username and will be used for logging in after admission. <br />Each student should have their own individual email address, especially if the parent has more than one child.</p>
                                        </CardContent>
                                    </Card>

                                    <h5 className="mb-4">Parent Information</h5>

                                    <Input name="parent_fname" onChange={isAdmissionCompleted ? null : onChange} field="Parent First Name" type="text" placeholder="Add text" className="form-control" value={state.parent_fname} required={true} />

                                    <Input name="parent_lname" onChange={isAdmissionCompleted ? null : onChange} field="Parent Last Name" type="text" placeholder="Add text" className="form-control" value={state.parent_lname} required={true} />

                                    <Input name="parent_mobile_no" onChange={isAdmissionCompleted ? null : onChange} field="Parent Mobile Number" type="text" placeholder="Add text" className="form-control" value={state.parent_mobile_no} required={true} />

                                    <Input name="parent_email" onChange={isAdmissionCompleted ? null : onChange} field="Parent Email" type="email" placeholder="Add text" className="form-control" value={state.parent_email} required={true} />

                                    <Input name="parent_mobile_no_2" onChange={isAdmissionCompleted ? null : onChange} field="Parent Mobile Number 2" type="text" placeholder="Add text" className="form-control" value={state.parent_mobile_no_2} />

                                    <Input name="parent_email_2" onChange={isAdmissionCompleted ? null : onChange} field="Parent Email 2" type="email" placeholder="Add text" className="form-control" value={state.parent_email_2} />

                                </div>
                                {/* -------------Column -2----------------- */}

                                <div className="col-11 col-md-6 ">

                                    <h5 className="mb-4">Batch Information</h5>

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label red-asterisk">Location</label>
                                        <div className="col-sm-7 col-md-7 ">

                                            <select required name="location_name" value={state.location_name ?? ''} disabled={isAdmissionCompleted} onChange={onChange} className="form-select">
                                                <option value="" >Choose</option>
                                                {loc.map((data, i) => (<option key={i} value={data.name}>{data.name}</option>))}
                                            </select>

                                        </div>
                                    </div>


                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label red-asterisk">Batch</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <select required name="batch_name" value={state.batch_name ?? ''} disabled={isAdmissionCompleted} onChange={onChange} className="form-select">
                                                <option value="" >--</option>
                                                {batch.map((data, i) => {
                                                    if (data.location_name === state.location_name) {
                                                        return <option key={i} value={data.batch_name}>{data.batch_name}</option>;
                                                    }
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label red-asterisk">Level</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <select required name="level_name" value={state.level_name ?? ''} disabled={isAdmissionCompleted} onChange={onChange} className="form-select">
                                                <option value="">Choose</option>
                                                {lev.map((data, i) => (<option key={i} value={data.level_name}>{data.level_name}</option>))}
                                            </select>
                                        </div>
                                    </div>

                                    <h5 className="mt-5 mb-4">Other Information</h5>

                                    <Input name="gsc" onChange={isAdmissionCompleted ? null : onChange} field="Global Student Code" type="text" placeholder="Add code" className="form-control" value={state.gsc} />

                                    <Input name="stud_code" onChange={isAdmissionCompleted ? null : onChange} field="Student Code" type="text" placeholder="Add code" className="form-control" value={state.stud_code} />

                                    <Input name="acc_name" onChange={isAdmissionCompleted ? null : onChange} field="Account Name" type="text" placeholder="Add Account Name" className="form-control" value={state.acc_name} required={true} />

                                    <Input name="acc_number" onChange={isAdmissionCompleted ? null : onChange} field="Account Number" type="text" placeholder="Add Account Number" className="form-control" value={state.acc_number} required={true} />

                                    <Input name="join_date" onChange={isAdmissionCompleted ? null : onChange} field="Join Date" type="date" placeholder="Add text" className="form-control" value={state.join_date} required={true} />

                                    {/* <h5 className="mt-5 mb-4">Admission Information</h5> */}


                                    {/* <div className="w-100 d-flex">
                                            <select name="admission_status_id" value={state.admission_status_id ?? ''} placeholder="Location" onChange={onChange} className="form-select mb-3 me-3">
                                                <option value="">Admission Status </option>
                                                {admissionStatues.map((data, i) => (<option key={i} value={data.admission_type_id}>{data.admission_type}</option>))}
                                            </select>
                                            <select name="payment_status_id" value={state.payment_status_id ?? ''} placeholder="Location" onChange={onChange} className="form-select mb-3">
                                                <option value="">Payment Status </option>
                                                {paymentStatues.map((data, i) => (<option key={i} value={data.payment_status_id}>{data.payment_status}</option>))}
                                            </select>
                                        </div> */}

                                    {/* <div className="w-100 d-flex">
                                            <select name="location_id" value={state.location_id ?? ''} placeholder="Location" onChange={onChange} className="form-select mb-3 me-3">
                                                <option value="">Location </option>
                                                {loc.map((data, i) => (<option key={i} value={data.location_id}>{data.name}</option>))}
                                            </select>
                                            <select name="coordinator_id" value={state.coordinator_id ?? ''} placeholder="Location" onChange={onChange} className="form-select mb-3">
                                                <option value="">Admission Coordinator </option>
                                                {coordinatorList.map((data, i) => (<option key={i} value={data.id}>{`${data.first_name} ${data.last_name}`}</option>))}
                                            </select>
                                        </div> */}
                                </div>
                            </div>
                        </div>
                        {/* ------==== Pane 2 =====----------- */}
                        <div className="tab-pane fade " id="nav-payment-info" role="tabpanel">
                            <div className="row my-4">

                                {/*-----------------------Column-1 for Inputs------------------------- */}
                                <div className="col-12 col-md-7">
                                    <h5 className="mb-3">Total Payable Amount</h5>

                                    <Stack direction='row' justifyContent='space-between' alignItems='start'>
                                        <Input name="payable_amount" onChange={isAdmissionCompleted ? null : onChange} field="Total Payable Amount (Euros)" placeholder="Add Amount" className="form-control" value={state.payable_amount} />
                                        {state.payable_amount !== admissionFormData.payable_amount && admissionFormData.payable_amount && (
                                            <Tooltip title="Restore Original Amount">
                                                <IconButton size="small" onClick={() => {
                                                    setState({ ...state, payable_amount: admissionFormData.payable_amount });
                                                }} aria-label="Restore Original Amount">
                                                    <SettingsBackupRestoreIcon />
                                                </IconButton>
                                            </Tooltip>

                                        )}
                                        <Button variant="contained" size='small' color="warning" disabled={isAdmissionCompleted || loading || !state.payable_amount} type="button" onClick={updatePayableAmount}>
                                            {`${!admissionFormData.payable_amount ? 'Add' : 'Update'} Payable Amount`}
                                        </Button>
                                    </Stack>


                                    {!admissionFormData.payable_amount &&
                                        <Alert severity="error" className="border shadow-sm mb-3">Please add a payable amount above to initiate payments.</Alert>}

                                    {admissionFormData.payable_amount && <hr className="m-0 mb-3" />}

                                    <h5 className="mb-3">Payment History</h5>

                                    {!isAdmissionCompleted && <div className="d-flex align-items-center mb-3">

                                        {(admissionFormData.payable_amount && calculateRemainingAmount() <= 0) &&
                                            <Alert severity="success" className="border shadow-sm">Payment complete. Proceed to <strong>Create A New Student Record</strong> in the Student Information tab.</Alert>}
                                        {(admissionFormData.payable_amount && calculateRemainingAmount() > 0) && <Button

                                            className="ms-auto"
                                            variant="contained"
                                            size='small'
                                            type='button'
                                            onClick={() => setOpenOfflinePaymentDialog(true)}
                                        >Record Offline Payment</Button>}
                                    </div>}

                                    <MaterialReactTable
                                        columns={Columns}
                                        data={paymentsTableData}
                                        enableRowNumbers
                                        // enableRowSelection
                                        initialState={{ density: 'compact' }}
                                        enableDensityToggle={false}
                                        getRowId={(originalRow) => originalRow.id}

                                    />
                                </div>
                                {/* -------------Column -2----------------- */}

                                <div className="col-12 col-md-5 overflow-auto  pb-3" style={{ maxHeight: '70vh' }}>
                                    <h5 className="mb-3">Payment Logs</h5>

                                    <div className="d-flex align-items-end mb-3">
                                        <textarea name="addressline1" className="form-control w-auto flex-grow-1 me-3"
                                            value={logMessage}
                                            placeholder="Add Log Message"
                                            onChange={(e) => setLogMessage(e.target.value)} >
                                        </textarea>
                                        <Button variant="contained" disabled={loading} type='button' onClick={handleAddLog} >Add Log</Button>
                                    </div>

                                    {!logList.length && <Alert severity="info" className="border shadow-sm">No logs to display, add one.</Alert>}
                                    {logList.map((x, i) => {
                                        return <Card className="mb-3" key={i} variant="outlined">
                                            <CardActions className="py-0 border-bottom">
                                                <Typography variant="subtitle2">{`${x.created_by_string} | ${new Date(x.created_at).toLocaleString()}`}</Typography>

                                                <IconButton color="error" aria-label="add to favorites" className="ms-auto" onClick={() => deleteLogMessage(x.adm_log_id)}>
                                                    <DeleteOutlineIcon />
                                                </IconButton>
                                            </CardActions>

                                            <CardContent>
                                                <Typography variant="subtitle2" color="text.secondary">
                                                    {x.comment_log}
                                                </Typography>
                                            </CardContent>
                                        </Card>;
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </form >
        </>
    );
};
export default AdmissionDetailsComp;