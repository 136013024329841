import Add from '@mui/icons-material/Add';
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../../../Common/Components/Heading";


import axios from "axios";
import { toast } from "react-toastify";
import url from "../../../../Common/URL";

import { Delete } from "@mui/icons-material";
import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { useConfirm } from 'material-ui-confirm';
import { useSelector } from "react-redux";


const LevelComp = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    uname: sessionStorage.getItem("username"),
  })

  const Columns = useMemo(
    () => [
      { header: 'Level', accessorKey: 'level_name' },
      { header: 'Description', accessorKey: 'level_desc' },
      {
        header: 'Active', accessorKey: 'level_status',
        accessorFn: (row) => row.level_status === 'Active' ? 'Active' : 'Inactive',
        id: 'level_status',
      }
    ],
    [],
  );
  const [data, setData] = useState([]);


  // ------------------------Fetching Level table's data-------------------------------------
  useEffect(() => {
    loadLevels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadLevels = () => {
    const obj = { uname: state.uname };
    axios.post(url + "fetch_levels", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
         !a.length ? setData([]) : setData(a);
          setLoading(false)
        } else {
          setData([]);
          setLoading(false)
        }
      })
      .catch((err) => console.log(err));
  }
  // ------------------------Deleting Level table's data-------------------------------------

  const onDelete = (id, resolve) => {
    const delObj = {
      id: id,
      uname: state.uname,
      tokenId: 123,
    };
    axios.post(url + "delete_level", delObj)
      // .then((res) => console.log(res))
      .then((res) => {
        resolve();
      })
      .catch((err) => console.log(err));
  }


  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item active" aria-current="page">Levels</li>
          </ol>
        </nav>

        <Heading name="Levels" />

        <div className="container-fluid">
          <div className="row my-4">
            <div className="col-12 mx-auto d-flex justify-content-end">
              <Button variant="contained" onClick={() => navigate("/add-level")} >Add Level&nbsp;<Add /></Button>
            </div>
          </div>
          <div className="row ">
            <div className="col-12 mx-auto">

              <MaterialReactTable
                state={{ isLoading: loading }}

                columns={Columns}
                data={data}
                enableRowNumbers
                enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                
                getRowId={(originalRow) => originalRow.level_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/view-level/" + row.id),
                  sx: { cursor: 'pointer' },
                })}

                renderTopToolbarCustomActions={({ table }) => (
                  <Button variant="contained" color="error" startIcon={<Delete />}
                    disabled={table.getSelectedRowModel().rows.length ? false : true}
                    onClick={() => {
                      confirm({ title: "Delete Confirmation", description: 'This will delete selected data permanently.' })
                        .then(() => {
                          let selectedRows = table.getSelectedRowModel().rows;
                          // console.log(selectedRows);
                          const delPromise = selectedRows.map((row) => {
                            return new Promise((resolve, reject) => {
                              onDelete(row.id, resolve);
                            })
                          })
                          Promise.all(delPromise).then(() => {
                            loadLevels();
                            table.resetRowSelection();
                            toast.info(selectedRows.length + " Row(s) Deleted!")
                          });
                        })
                    }}
                  >Delete
                  </Button>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )

}
export default LevelComp