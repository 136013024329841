import { Close } from "@mui/icons-material";
import { AppBar, Button, Card, CardContent, IconButton, Toolbar, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { toast } from "react-toastify";
import url from "../../../../../Common/URL";
import Input from "../../../../Setup/Student/Component/Input";

const CreateAdmissionComp = ({ setOpenDrawer, loadParentData }) => {

    const [loading, setLoading] = useState(false);
    // const [usr , setUsr] =useState([]);

    const [state, setState] = useState({
        uname: sessionStorage.getItem("username"),
        fname: '', mname: '', lname: '', dob: '',
        primary_address: '', sec_address: '',
        city: '', province: '', country: '', zip: '',
        phone: '', alt_phone: '', email: '',
        parent_fname: '', parent_lname: '', parent_email: '', parent_sec_email: '',
        parent_phone: '', parent_sec_phone: '', account_no: '', account_name: '',
        created_by: sessionStorage.getItem("username"),
    });

    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //---------------One onChange function is used for setting the state of various types of input fields ---------------     

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            };
        });
    };

    // ------------------- -------------------functions for Submitting Country and state- ------------------- -------------------
    const selectCountry = (val) => {

        setState((preValue) => {
            return {
                ...preValue,
                country: val
            };
        });
    };

    const selectRegion = (val) => {

        setState((preValue) => {
            return {
                ...preValue,
                province: val
            };
        });
    };


    //-------------------------- onSubmit function for Submitting the  Forms---------------------------

    const onSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        // ------------Storing form data in "obj" object fetched from various input fields-------------
        const obj = {
            fname: state.fname, mname: state.mname, lname: state.lname, dob: state.dob,
            primary_address: state.primary_address, sec_address: state.sec_address,
            city: state.city, province: state.province, country: state.country, zip: state.zip,
            phone: state.phone, alt_phone: state.alt_phone, email: state.email,
            parent_fname: state.parent_fname, parent_lname: state.parent_lname,
            parent_email: state.parent_email, parent_sec_email: state.parent_sec_email,
            parent_phone: state.parent_phone, parent_sec_phone: state.parent_sec_phone,
            account_no: state.account_no, account_name: state.account_name,
            created_by: sessionStorage.getItem("username"),
        };
        // ------------Sending form data stored in "obj" object to the add_student file uusing AXIOS-------------
        console.log(obj);
        axios.post(url + "create_admission_form", obj)
            .then((res) => {
                console.log(res);
                if (res.data.success) {
                    loadParentData().then(() => {
                        setOpenDrawer(false);
                        toast.success("New admission form added");
                    });
                } else {
                    toast.error('An occurred, please try again later');
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error('An occurred, please try again later');

            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <AppBar color="transparent" position="static">
                <Toolbar>
                    <IconButton
                        onClick={() => setOpenDrawer(false)}
                        color="inherit"
                        aria-label="close"
                        sx={{ mr: 2 }}
                    >
                        <Close />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Create Admission Form
                    </Typography>
                </Toolbar>
            </AppBar>

            <form onSubmit={onSubmit} id="myform">
                <div className="container-fluid p-4">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-end mx-auto">
                            <Button disabled={loading} variant="contained" type="submit">Add</Button>
                        </div>
                    </div>

                    <div className="row">

                        {/*-----------------------Column-1 for Inputs------------------------- */}
                        <div className="col-11 col-md-6 mx-auto">

                            <h5 className="mb-4">Personal Information</h5>


                            <Input name="fname" onChange={onChange} field="Student First Name" type="text" placeholder="Add text" className="form-control" value={state.fname} required={true} />

                            <Input name="mname" onChange={onChange} field="Student Middle Name" type="text" placeholder="Add text" className="form-control" value={state.mname} required={false} />

                            <Input name="lname" onChange={onChange} field="Student Last Name" type="text" placeholder="Add text" className="form-control" value={state.lname} required={true} />

                            <Input name="dob" onChange={onChange} field="Date of Birth" type="date" placeholder="Add DOB" className="form-control" value={state.dob} required={true} />



                            <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label red-asterisk">Address Line 1</label>
                                <div className="col-sm-7 col-md-7 ">
                                    <textarea name="primary_address" onChange={onChange} className="form-control" placeholder="Add text" value={state.primary_address}  ></textarea>
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label ">Address Line 2</label>
                                <div className="col-sm-7 col-md-7 ">
                                    <textarea name="sec_address" onChange={onChange} className="form-control" placeholder="Add text" value={state.sec_address}  ></textarea>
                                </div>
                            </div>

                            <Input name="city" onChange={onChange} field="City" type="text" placeholder="Add text" className="form-control" value={state.city} required={true} />

                            <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label red-asterisk">Country</label>
                                <div className="col-sm-7 col-md-7 ">

                                    <CountryDropdown
                                        className="form-select"
                                        name="country"
                                        value={state.country}
                                        onChange={(val) => selectCountry(val)} />
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label red-asterisk">Province</label>
                                <div className="col-sm-7 col-md-7 ">
                                    <RegionDropdown
                                        className="form-select"
                                        name="province"
                                        country={state.country}
                                        value={state.province}
                                        onChange={(val) => selectRegion(val)} />
                                </div>
                            </div>

                            <Input name="zip" onChange={onChange} field="Zip" type="text" placeholder="Add text" className="form-control" value={state.zip} required={true} />
                            <Input name="phone" onChange={onChange} field="Mobile Number" type="text" placeholder="Add text" className="form-control" value={state.phone} required={true} />
                            <Input name="alt_phone" onChange={onChange} field="Home Phone" type="text" placeholder="Add text" className="form-control" value={state.alt_phone} required={false} />

                            <Card variant="outlined" className="mb-4">
                                <CardContent>
                                    <Input name="email" onChange={onChange} field="Email" type="email" placeholder="Add text" className="form-control" value={state.email} required={true} />
                                    <p className="alert alert-info mb-0">Please enter a unique email address for this child. It will serve as their username and will be used for logging in after admission. <br />Each student should have their own individual email address, especially if the parent has more than one child.</p>
                                </CardContent>
                            </Card>


                        </div>
                        {/*-----------------------Column-2 for Inputs------------------------- */}
                        <div className="col-11 col-md-6 mx-auto">
                            <h5 className="mb-4">Parent Information</h5>
                            <Input name="parent_fname" onChange={onChange} field="Parent First Name" type="text" placeholder="Add text" className="form-control" value={state.parent_fname} required={true} />
                            <Input name="parent_lname" onChange={onChange} field="Parent Last Name" type="text" placeholder="Add text" className="form-control" value={state.parent_lname} required={true} />
                            <Input name="parent_email" onChange={onChange} field="Parent Email" type="email" placeholder="Add text" className="form-control" value={state.parent_email} required={true} />
                            <Input name="parent_sec_email" onChange={onChange} field="Parent Email 2" type="email" placeholder="Add text" className="form-control" value={state.parent_sec_email} />
                            <Input name="parent_phone" onChange={onChange} field="Parent Mobile Number" type="text" placeholder="Add text" className="form-control" value={state.parent_phone} required={true} />
                            <Input name="parent_sec_phone" onChange={onChange} field="Parent Mobile Number 2" type="text" placeholder="Add text" className="form-control" value={state.parent_sec_phone} />

                            <h5 className="mt-5 mb-4">Bank Information</h5>

                            <Input name="account_name" onChange={onChange} field="Account Name" type="text" placeholder="Add Account Name" className="form-control" value={state.account_name} />
                            <Input name="account_no" onChange={onChange} field="Account Number" type="text" placeholder="Add Account Number" className="form-control" value={state.account_no} />
                        </div>

                    </div>
                </div>
            </form>
        </>
    );
};
export default CreateAdmissionComp;