import React from "react"
import Navbar from "../../../Common/Components/Navbar"
import Footer from "../../../Common/Components/Footer"
import Sidebar from "../../../Sidebar/Sidebar"
import AddManagerComp from "./Component/AddManagerComp"


const AddManager = () => {
    return (
        <>
            <Navbar />
            <div className="d-flex mainContentArea">
                <Sidebar />
                <AddManagerComp />
            </div>
            <Footer />
        </>
    )
}

export default AddManager