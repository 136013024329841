import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Input from "../../../Setup/Student/Component/Input";
import Heading from "../../../../Common/Components/Heading";
import axios from "axios";
import url from "../../../../Common/URL";
import { Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";


const EditItemComp = () => {

    const { id } = useParams();
    const [loc, setLoc] = useState([]);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        product_name: "", description: "", make: "", model: "", price: "", location: "", url: "", status: "",
        comments: "", reorder_level: "", reorder_quantity: "",
    })

    useEffect(() => {
        loadLocation();
        LoadItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----------This function will Fetch all the Existing Locations--------------------
    const loadLocation = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "fetch_locations", obj)
            .then((response) => {
                let a = response.data.data;
                //   console.log(a);
                setLoc(a);
            })
            .catch((err) => console.log(err));
    }

    // -----------This function will Fetch all the Item data--------------------
    const LoadItem = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        };

        await axios.post(url + "fetch_single_inventory", obj)
            .then((response) => {
                let a = response.data.data;
                setState({
                    product_name: a[0].product_name, description: a[0].description, make: a[0].make, model: a[0].model,
                    price: a[0].price, location: a[0].location, url: a[0].url, status: a[0].status,
                    comments: a[0].comments, reorder_level: a[0].reorder_level, reorder_quantity: a[0].reorder_quantity,
                })
            }).catch((err) => console.log(err));
    }


    //---------------One onChange function is used for setting the state of various types of input fields ---------------     

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }


    //-------------------------- onSubmit function for Submitting the  Forms---------------------------

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        // ------------Storing form data in "obj" object fetched from various input fields-------------
        const obj = {
            id: id,
            product_name: state.product_name, description: state.description, make: state.make, model: state.model, price: state.price,
            location: state.location, url: state.url, status: state.status, comments: state.comments, reorder_level: state.reorder_level,
            reorder_quantity: state.reorder_quantity, updated_by: sessionStorage.getItem("username"), uname: sessionStorage.getItem("username")
        }
        // ------------Sending form data stored in "obj" object to the add_student file uusing AXIOS-------------

        axios.post(url + "edit_inventory", obj)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    toast.success("Updated successfully!");
                } else {
                    toast.error("Item not updated, please try again later!")
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            })

    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'} >
                {/*-----------------------Bread crumb------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item "><Link to="/inventory">Inventory</Link></li>
                        <li className="breadcrumb-item "><Link to={`/view-item-inv/${id}`}>View Item</Link></li>
                        <li className="breadcrumb-item active">Edit Item</li>
                    </ol>
                </nav>
                <Heading name="Edit Item" />
                {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}
                <form onSubmit={onSubmit} id="myform">
                    <div className="container-fluid mt-3">
                        <div className="row my-4">
                            <div className="col-12 d-flex justify-content-end mx-auto">
                                <Button variant="contained" disabled={loading} type="submit" >{loading ? <CircularProgress color="inherit" size={20} /> :'Save'}</Button>
                            </div>
                        </div>

                        <div className="row">

                            {/*-----------------------Column-1 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto">

                                <h5 className="mb-4">Item Information</h5>


                                <Input name="product_name" onChange={onChange} field="Product Name" type="text" placeholder="Add Name" className="form-control" value={state.product_name} required={true} />

                                <Input name="model" onChange={onChange} field="Model" type="text" placeholder="Add Model" className="form-control" value={state.model} required={false} />

                                <Input name="make" onChange={onChange} field="Make" type="text" placeholder="Add Make detail" className="form-control" value={state.make} required={true} />

                                <Input name="description" onChange={onChange} field="Description" type="text" placeholder="Add description" className="form-control" value={state.description} required={true} />

                                <Input name="price" onChange={onChange} field="Price" type="text" placeholder="Add Price" className="form-control" value={state.price} required={true} />

                                {/* <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label red-asterisk">Location</label>
                                <div className="col-sm-7 col-md-7 ">
                                    <select required name="location" value={state.location}  onChange={onChange} className="form-select">
                                    <option value="" >Choose</option>
                                    {loc.map((data , i)=>(<option key={i} value={data.name}>{data.name}</option>)) } 
                                    </select>
                                </div>
                            </div> */}

                            </div>
                            {/*-----------------------Column-2 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto">

                                <h5 className="mb-4">Other Information</h5>

                                <Input name="url" onChange={onChange} field="URL" type="text" placeholder="Add URL" className="form-control" value={state.url} />

                                <Input name="reorder_level" onChange={onChange} field="Re-Order Level" type="number" placeholder="Add Level" className="form-control" value={state.reorder_level} />

                                <Input name="reorder_quantity" onChange={onChange} field="Re-Order Quantity" type="number" placeholder="Add Quantity" className="form-control" value={state.reorder_quantity} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label ">Comments</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <textarea name="comments" onChange={onChange} className="form-control" placeholder="Add Comment" value={state.comments}  ></textarea>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Status</label>
                                    <div className="col-sm-7 col-md-7 align-items-center d-flex ">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="status"
                                                id="inlineRadio1"
                                                value="Active"
                                                checked={state.status === "Active"}
                                                onChange={onChange}
                                                required
                                            />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Active</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="status"
                                                id="inlineRadio2"
                                                value="Inactive"
                                                checked={state.status === "Inactive"}
                                                onChange={onChange}
                                                required
                                            />

                                            <label className="form-check-label" htmlFor="inlineRadio2">Inactive</label>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </form>
            </div>

        </>
    )
}
export default EditItemComp  