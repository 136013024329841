import React from "react"
import Navbar from "../../../Common/Components/Navbar"
import Footer from "../../../Common/Components/Footer"
// import Sidebar from "../../../Sidebar/Sidebar"
import ExamSheetComp from "./Component/ExamSheetComp"

const ExamSheet =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea">
            {/* <Sidebar /> */}
             <ExamSheetComp/>
        </div>
        <Footer />
        </>
    )
}

export default ExamSheet