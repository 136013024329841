import { isEmailValid } from "../Functions/BulkUploadHelper";

// === BELOW commented object shows the key value pair Student object, of which keys are used below to generate errors.
/*
{   0 :"fname", 1:"mname", 2:"lname", 3:"addressline1", 4:"addressline2", 5:"city", 6:"state", 
    7:"country", 8:"zip", 9:"gsc", 10:"stud_code",11:"dob", 12:"acc_name", 
    13:"acc_number", 14:"mobile_no", 15:"home_no", 16:"parent_fname", 17:"parent_lname", 18:"parent_mobile_no",
    19:"location_name", 20:"level_name", 21:"batch_name", 22:"email", 23:"parent_email", 24:"parent_email_2", 
    25:"parent_mobile_no_2",26:"join_date", 27:"leave_date"
}
*/
const StudentUploadValidator = (arrayOfArray, loc, batch) => {

    var errorArray = [];

        const GenerateError = (string) =>{
            errorArray.push(string);
        }

        // ======= Student File Validator ============================ 
        const TestValidator = (arr, index) =>{

            // The if operator returns false for null, undefined & blank variables.
            // So if var x is equal to null, undefined or an empty string than if(x) will return false and if(!x) will return true.
            // So by using if(!x) we will generate the error message. 

            if( !arr[0] ) GenerateError(`First name is required on row ${index +1}.`)
            if( !arr[2] ) GenerateError(`Last name is required on row ${index +1}.`)
            if( !arr[3] ) GenerateError(`Address line 1 is required on row ${index +1}.`)
            if( !arr[5] ) GenerateError(`City name is required on row ${index +1}.`)
            if( !arr[6] ) GenerateError(`State name is required on row ${index +1}.`)
            if( !arr[7] ) GenerateError(`Country name is required on row ${index +1}.`)
            if( !arr[8] ) GenerateError(`Zip is required on row ${index +1}.`)
            if( !arr[11] ) GenerateError(`Date of birth is required on row ${index +1}.`)
            if( !arr[12] ) GenerateError(`Account name is required on row ${index +1}.`)
            if( !arr[13] ) GenerateError(`Account number is required on row ${index +1}.`)
            if( !arr[14] ) GenerateError(`Mobile number is required on row ${index +1}.`)
            if( !arr[16] ) GenerateError(`Parent first name is required on row ${index +1}.`)
            if( !arr[17] ) GenerateError(`Parent last name is required on row ${index +1}.`)
            if( !arr[18] ) GenerateError(`Parent's mobile number is required on row ${index +1}.`)

            if( !arr[19] ) GenerateError(`Location name is required on row ${index +1}.`)
            else if( !(loc.indexOf(arr[19].trim().toLowerCase()) >= 0) ){
                GenerateError(`The location name on row no. ${index+1} doesn't match any existing location names in Portal.`)
            }

            if( !arr[20] ) GenerateError(`Level name is required on row ${index +1}.`)
            if( !arr[22] ) GenerateError(`Email address is required on row ${index +1}.`)
            else if( !isEmailValid(arr[22]) ){
                GenerateError(`The email is incorrect on row no. ${index+1}.`)
            }

            if( !arr[21] ) GenerateError(`Batch name is required on row ${index +1}.`)
            else if( !(batch.indexOf(arr[21].trim().toLowerCase()) >= 0) ){
                GenerateError(`The batch name on row no. ${index+1} doesn't match any existing batch names in Portal.`)
            }

            if( !arr[23] ) GenerateError(`Parent email is required on row ${index +1}.`)
            else if( !isEmailValid(arr[23]) ){
                GenerateError(`The parent email is incorrect on row no. ${index+1}.`)
            }

            if( !isEmailValid(arr[24] ? arr[24] : "") ){   // here we are sending "blank" as parameter, if arr[24] is null or undefined
                GenerateError(`The parent email 2 is incorrect on row no. ${index+1}.`)
            }

            if( !arr[26] ) GenerateError(`Join date is required on row ${index +1}.`)
    
        }

        // ======= Step 1 ====================================
        arrayOfArray.map((x, i) => TestValidator(x, i));
    

    return errorArray;
}

export default StudentUploadValidator;