import React from "react"
import Navbar from "../../../Common/Components/Navbar"
import Footer from "../../../Common/Components/Footer"
import Sidebar from "../../../Sidebar/Sidebar"
import ViewBalanceHistoryComp  from "./Component/ViewBalanceHistoryComp"

const ViewBalanceHistory =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea">
            <Sidebar />
            <ViewBalanceHistoryComp />
        </div>
        <Footer />
        </>
    )
}

export default ViewBalanceHistory