import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Heading from "../../../../Common/Components/Heading";

import Tooltip from '@mui/material/Tooltip';

import Add from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

import { Delete } from "@mui/icons-material";
import Button from '@mui/material/Button';
import axios from "axios";
import MaterialReactTable from 'material-react-table';
import { useConfirm } from 'material-ui-confirm';
import { useSelector } from "react-redux";
import url from "../../../../Common/URL";
import { Drawer } from "@mui/material";
import RenderTestExamAssignmentPreviewComponent from "../../../../Common/Components/RenderTestExamAssignmentPreviewComponent";

const TestTable = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    uname: sessionStorage.getItem("username"),
  });

  const [openTestExamAssignmentPreviewDrawer, setOpenTestExamAssignmentPreviewDrawer] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showStudentAnswers, setShowStudentAnswers] = useState(false);

  const Columns = useMemo(
    () => [
      { header: 'Test Name', accessorKey: 'test_name' },
      {
        header: 'Test Type', size: 40, id: 'test_type', accessorFn: rowData => rowData.generator_test === 1 ?
          <div className="hstack gap-2">
            <span>{rowData.test_type}</span>
            <Tooltip title="Generated from generator" placement="right" >
              <SettingsSuggestIcon className="ms-2" style={{ cursor: "pointer", fontSize: '20px' }} color="primary" fontSize="50" />
            </Tooltip>
          </div> : rowData.test_type
      },
      { header: 'Assignment Type', size: 60, id: "assignment_type", accessorFn: rowData => (rowData.assignment_type === '' || rowData.assignment_type === null) ? '--' : rowData.assignment_type },
      { header: 'Page', size: 40, id: "page", accessorFn: rowData => (rowData.page === '' || rowData.page === null) ? '--' : rowData.page },
      { header: 'Level', size: 80, accessorKey: 'level_name' },
      { header: 'From Generator', id: 'generator_test', accessorFn: rowData => (rowData.generator_test === 1 ? 'Yes' : 'No') },
      {
        header: 'Action', size: 40, id: 'action', enableColumnActions: false, enableSorting: false, accessorFn: rowData => {
          return (
            rowData.status === 1 ?
              <Tooltip title="Preview" placement="left" >
                <VisibilityIcon style={{ padding: 0 }} onClick={(e) => {
                  e.stopPropagation();
                  setSelectedRowData(rowData);
                  setShowStudentAnswers(false);
                  setOpenTestExamAssignmentPreviewDrawer(true);
                }} />
              </Tooltip> :
              <Tooltip title="Questions unavailable" placement="left" >
                <VisibilityIcon style={{ padding: 0, color: "#D3D3D3" }} onClick={(e) => e.stopPropagation()} />
              </Tooltip>
          );
        }
      }
    ],
    [],
  );
  const [data, setData] = useState([]);

  // ------------------------Fetching Role table data-------------------------------------
  useEffect(() => {
    loadTests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTests = () => {
    const obj = { uname: state.uname };
    axios.post(url + "fetch_tests", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          console.log(a);
          !a.length ? setData([]) : setData(a);
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }

      })
      .catch((err) => console.log(err));
  };

  // ------------------------Deleting Role table's data-------------------------------------
  function onDelete(id, resolve) {
    const delObj = {
      id: id,
      uname: state.uname,
      tokenId: 123,
    };
    axios.post(url + "delete_test", delObj)
      .then((res) => {
        // console.log(res);
        resolve();
      })
      .catch((err) => console.log(err));
  }

  return (
    <>

      {/* Side Drawer for Previewing TEST/EXAM/ASSIGNMENT Answers given by the student */}
      <Drawer
        PaperProps={{ style: { width: '95%' } }}
        anchor='right'
        open={openTestExamAssignmentPreviewDrawer}
        onClose={() => setOpenTestExamAssignmentPreviewDrawer(false)}
      >
        <RenderTestExamAssignmentPreviewComponent data={selectedRowData} showStudentAnswers={showStudentAnswers} setOpenDrawer={setOpenTestExamAssignmentPreviewDrawer} />
      </Drawer >


      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item active" aria-current="page">Test list</li>
          </ol>
        </nav>

        <Heading name="Test" />

        <div className="container-fluid">
          <div className="row my-4">
            <div className="col-12 mx-auto d-flex justify-content-end">
              <Button variant="contained" onClick={() => navigate("/add-test")} >Add Test <Add className="text-center" /></Button>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 mx-auto">

              <MaterialReactTable
                state={{ isLoading: loading }}

                columns={Columns}
                data={data}
                enableRowNumbers
                enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}

                getRowId={(originalRow) => originalRow.test_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/view-test/" + row.id),
                  sx: { cursor: 'pointer' },
                })}

                renderTopToolbarCustomActions={({ table }) => (
                  <Button variant="contained" color="error" startIcon={<Delete />}
                    disabled={table.getSelectedRowModel().rows.length ? false : true}
                    onClick={() => {
                      confirm({ title: "Delete Confirmation", description: 'This will delete selected data permanently.' })
                        .then(() => {
                          let selectedRows = table.getSelectedRowModel().rows;
                          // console.log(selectedRows);
                          const delPromise = selectedRows.map((row) => {
                            return new Promise((resolve, reject) => {
                              onDelete(row.id, resolve);
                            });
                          });
                          Promise.all(delPromise).then(() => {
                            loadTests();
                            table.resetRowSelection();
                            toast.info(selectedRows.length + " Row(s) Deleted!");
                          });
                        });
                    }}
                  >Delete
                  </Button>
                )}
              />
            </div>
          </div>

        </div>
      </div>

    </>
  );

};
export default TestTable




