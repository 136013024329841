import React from "react"
import Navbar from "../../../../Common/Components/Navbar"
import Footer from "../../../../Common/Components/Footer"
import Sidebar from "../../../../Sidebar/Sidebar"
import TsTaskComp from "./Component/TsTaskComp"


const TsTask =()=>{
    return(
        <>
        <Navbar />
        <div className="d-flex mainContentArea">
            <Sidebar />
            <TsTaskComp />
        </div>
        <Footer />
        </>
    )
}

export default TsTask