import React from "react"
import Navbar from "../../Common/Components/Navbar"
import Footer from "../../Common/Components/Footer"
import Sidebar from "../../Sidebar/Sidebar"
import ReviewTimesheetComp from "./Component/ReviewTimesheetComp"

const ReviewTimesheet = () => {
    return (
        <>
            <Navbar />
            <div className="d-flex mainContentArea">
                <Sidebar />
                <ReviewTimesheetComp />
            </div>
            <Footer />
        </>
    )
}

export default ReviewTimesheet