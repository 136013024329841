import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Input from "./Input";
import Heading from "../../../../Common/Components/Heading";
import axios from "axios";
import { Button, CircularProgress } from "@mui/material";
import url from "../../../../Common/URL";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useSelector } from "react-redux";
const AddStudentComp = () => {

    let navigate = useNavigate();
    const [loc, setLoc] = useState([]);
    const [batch, setBatch] = useState([]);
    const [lev, setLev] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [usr , setUsr] =useState([]);

    const [state, setState] = useState({
        uname: sessionStorage.getItem("username"),
        // ------------normal input fields--------------
        fname: "", mname: "", lname: "", city: "", zip: "", mobile_no: "", home_no: "", email: "",
        parent_fname: "", parent_lname: "", parent_mobile_no: "", parent_email: "", parent_mobile_no_2: "",
        parent_email_2: "", join_date: "", leave_date: "", dob: "", gsc: "", stud_code: "", acc_name: "", acc_number: "",
        // ------------Textarea input fields--------------
        addressline1: "", addressline2: "",

        // ------------Checkbox input fields--------------
        active: true, photo_consent: "", disclaimer_signed: "", status: "",

        // ------------Select inputs fields--------------
        state: "", country: "",
        location_name: sessionStorage.getItem("role") !== "Location Manager" ? "" : sessionStorage.getItem("location"),
        batch_name: "", level_name: "", user_name: "",
    });

    const [pic, setPic] = useState(null);

    useEffect(() => {
        loadLocation();
        loadBatch();
        loadLevel();
        // loadUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----------This function will Fetch all the Existing Locations--------------------
    const loadLocation = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "fetch_locations", obj)
            .then((response) => {
                let a = response.data.data;
                //   console.log(a);
                setLoc(a);
            })
            .catch((err) => console.log(err));
    };

    // -----------  This function will Fetch all the Existing Batch--------------------
    const loadBatch = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "fetch_batches", obj)
            .then((response) => {
                let a = response.data.data;
                //   console.log(a);
                setBatch(a);
            })
            .catch((err) => console.log(err));
    };

    // -----------  This function will Fetch all the Existing Levels--------------------
    const loadLevel = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "fetch_levels", obj)
            .then((response) => {
                let a = response.data.data;
                //   console.log(a);
                setLev(a);
            })
            .catch((err) => console.log(err));
    };



    //---------------One onChange function is used for setting the state of various types of input fields ---------------     

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            };
        });
    };

    // ------------------- -------------------functions for Submitting Country and state- ------------------- -------------------
    const selectCountry = (val) => {

        setState((preValue) => {
            return {
                ...preValue,
                country: val
            };
        });
    };
    const selectRegion = (val) => {

        setState((preValue) => {
            return {
                ...preValue,
                state: val
            };
        });
    };

    //-------------------------- onSubmit function for Submitting the  Forms---------------------------

    const onSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        /*---------------Fetching Date in YEAR-MONTH-DATE  format-------------------------- */
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();

        var newdate = year + "-" + month + "-" + day;

        // ------------Storing form data in "obj" object fetched from various input fields-------------
        const obj = {
            profile_pic: pic,
            fname: state.fname, mname: state.mname, lname: state.lname, uname: sessionStorage.getItem("username"),
            city: state.city, zip: state.zip, mobile_no: state.mobile_no,
            home_no: state.home_no, email: state.email, parent_fname: state.parent_fname, join_date: state.join_date,
            parent_lname: state.parent_lname, parent_mobile_no: state.parent_mobile_no, parent_email: state.parent_email,
            parent_mobile_no_2: state.parent_mobile_no_2, parent_email_2: state.parent_email_2,
            created_at: newdate, approved_date: state.approved_date, leave_date: state.leave_date, addressline1: state.addressline1, addressline2: state.addressline2,
            gsc: state.gsc, stud_code: state.stud_code, dob: state.dob, acc_name: state.acc_name, acc_number: state.acc_number,

            active: state.active, photo_consent: state.photo_consent, disclaimer_signed: state.disclaimer_signed,

            state: state.state, country: state.country, location_name: state.location_name, batch_name: state.batch_name,
            level_name: state.level_name, user_name: state.email, status: state.status
        };
        // ------------Sending form data stored in "obj" object to the add_student file uusing AXIOS-------------

        axios.post(url + "add_student", obj)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    if (res.data.mail_sent === 1) {
                        toast.success("New student added");
                    } else toast.warning("New student added, email notification failed.");
                    navigate("/student");
                } else if (res.data.show_error_message === 1) {
                    toast.error(res.data.message);
                } else {
                    toast.error("Student not created!");
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'} >
                {/*-----------------------Bread crumb------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item "><Link to="/student">Students list</Link></li>
                        <li className="breadcrumb-item active">Add Student</li>
                    </ol>
                </nav>
                <Heading name="Add Student" />
                {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}
                <form onSubmit={onSubmit} id="myform">
                    <div className="container-fluid mt-3">
                        <div className="row my-4">
                            <div className="col-12 d-flex justify-content-end mx-auto">
                                <Button variant="contained" disabled={loading} type="submit" >{loading ? <CircularProgress color="inherit" size={20} /> : 'Save'}</Button>
                            </div>
                        </div>

                        <div className="row">

                            {/*-----------------------Column-1 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto">

                                <h5 className="mb-4">Personal Information</h5>


                                <Input name="fname" onChange={onChange} field="First Name" type="text" placeholder="Add text" className="form-control" value={state.fname} required={true} />

                                <Input name="mname" onChange={onChange} field="Middle Name" type="text" placeholder="Add text" className="form-control" value={state.mname} required={false} />

                                <Input name="lname" onChange={onChange} field="Last Name" type="text" placeholder="Add text" className="form-control" value={state.lname} required={true} />

                                <Input name="dob" onChange={onChange} field="Date of Birth" type="date" placeholder="Add DOB" className="form-control" value={state.dob} required={true} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Address Line 1</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <textarea name="addressline1" onChange={onChange} className="form-control" placeholder="Add text" value={state.addressline1} required ></textarea>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label ">Address Line 2</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <textarea name="addressline2" onChange={onChange} className="form-control" placeholder="Add text" value={state.addressline2}  ></textarea>
                                    </div>
                                </div>

                                <Input name="city" onChange={onChange} field="City" type="text" placeholder="Add text" className="form-control" value={state.city} required={true} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Country</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <CountryDropdown
                                            className="form-select"
                                            name="country"
                                            value={state.country}
                                            onChange={(val) => selectCountry(val)} />

                                    </div>
                                </div>


                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Province</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <RegionDropdown
                                            className="form-select"
                                            name="state"
                                            country={state.country}
                                            value={state.state}
                                            onChange={(val) => selectRegion(val)} />
                                    </div>
                                </div>


                                <Input name="zip" onChange={onChange} field="Zip" type="text" placeholder="Add text" className="form-control" value={state.zip} required={true} />

                                <Input name="mobile_no" onChange={onChange} field="Mobile Number" type="text" placeholder="Add text" className="form-control" value={state.mobile_no} required={true} />

                                <Input name="home_no" onChange={onChange} field="Home Phone" type="text" placeholder="Add text" className="form-control" value={state.home_no} required={false} />

                                <Input name="email" onChange={onChange} field="Email" type="email" placeholder="Add text" className="form-control" value={state.email} required={true} />

                                <h5 className="mt-5 mb-4">Parent Information</h5>

                                <Input name="parent_fname" onChange={onChange} field="Parent First Name" type="text" placeholder="Add text" className="form-control" value={state.parent_fname} required={true} />

                                <Input name="parent_lname" onChange={onChange} field="Parent Last Name" type="text" placeholder="Add text" className="form-control" value={state.parent_lname} required={true} />

                                <Input name="parent_mobile_no" onChange={onChange} field="Parent Mobile Number" type="text" placeholder="Add text" className="form-control" value={state.parent_mobile_no} required={true} />

                                <Input name="parent_email" onChange={onChange} field="Parent Email" type="email" placeholder="Add text" className="form-control" value={state.parent_email} required={true} />

                                <Input name="parent_mobile_no_2" onChange={onChange} field="Parent Mobile Number 2" type="text" placeholder="Add text" className="form-control" value={state.parent_mobile_no_2} />

                                <Input name="parent_email_2" onChange={onChange} field="Parent Email 2" type="email" placeholder="Add text" className="form-control" value={state.parent_email_2} />

                            </div>
                            {/*-----------------------Column-2 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto">

                                <h5 className="mb-4">Batch Information</h5>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Location</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        {sessionStorage.getItem("role") !== "Location Manager" ?
                                            <select required name="location_name" value={state.location_name} onChange={onChange} className="form-select">
                                                <option value="" >Choose</option>
                                                {loc.map((data, i) => (<option key={i} value={data.name}>{data.name}</option>))}
                                            </select>
                                            :
                                            <input className="bg-light border-0 form-control" type="text" value={state.location_name} readOnly />}
                                    </div>
                                </div>


                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Batch</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="batch_name" value={state.batch_name} onChange={onChange} className="form-select">
                                            <option value="" >--</option>
                                            {batch.map((data, i) => {
                                                if (data.location_name === state.location_name) {
                                                    return <option key={i} value={data.batch_name}>{data.batch_name}</option>;
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Level</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="level_name" value={state.level_name} onChange={onChange} className="form-select">
                                            <option value="">Choose</option>
                                            {lev.map((data, i) => (<option key={i} value={data.level_name}>{data.level_name}</option>))}
                                        </select>
                                    </div>
                                </div>

                                <h5 className="mt-5 mb-4">Other Information</h5>

                                {/* <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">User</label>
                        <div className="col-sm-7 col-md-7 ">
                            <select name="user_name"  defaultValue={state.user_name} onChange={onChange} className="form-select">
                            <option selected>Choose</option>
                            {usr.map((data , i)=>(<option key={i} value={data.username}>{data.username}</option>)) } 
                            </select>
                        </div>
                    </div> */}

                                <Input name="gsc" onChange={onChange} field="Global Student Code" type="text" placeholder="Add code" className="form-control" value={state.gsc} />

                                <Input name="stud_code" onChange={onChange} field="Student Code" type="text" placeholder="Add code" className="form-control" value={state.stud_code} />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Status</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="status" value={state.status} onChange={onChange} className="form-select">
                                            <option value="">Choose</option>
                                            <option value="Active" >Active</option> <option value="Inactive" >Inactive</option>
                                            <option value="On Leave" >On Leave</option>
                                            <option value="Graduated" >Graduated</option>
                                            <option value="Left" >Left</option>
                                        </select>
                                    </div>
                                </div>

                                {/* <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Active Account</label>
                        <div className="col-sm-7 col-md-7 ps-4">
                            <div className="form-check">
                                <input className="form-check-input" name="active" type="checkbox" checked={state.active}  onChange={onChange}  />
                            </div>
                        </div>
                    </div> */}
                                {/* <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Photography Consent Provided?</label>
                        <div className="col-sm-7 col-md-7 ps-4">
                        <div className="form-check">
                                <input className="form-check-input" name="photo_consent" type="checkbox" checked={state.photo_consent} onChange={onChange}   />
                            </div>
                        </div>
                    </div> */}
                                {/* <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Disclaimer Signed?</label>
                        <div className="col-sm-7 col-md-7 ps-4">
                        <div className="form-check">
                                <input className="form-check-input" name="disclaimer_signed" type="checkbox" checked={state.disclaimer_signed} onChange={onChange} />
                            </div>
                        </div>
                    </div> */}

                                <Input name="acc_name" onChange={onChange} field="Account Name" type="text" placeholder="Add Account Name" className="form-control" value={state.acc_name} required={true} />

                                <Input name="acc_number" onChange={onChange} field="Account Number" type="text" placeholder="Add Account Number" className="form-control" value={state.acc_number} required={true} />

                                <Input name="join_date" onChange={onChange} field="Join Date" type="date" placeholder="Add text" className="form-control" value={state.join_date} required={true} />

                                <Input name="leave_date" onChange={onChange} field="Leave Date" type="date" placeholder="Add text" className="form-control" value={state.leave_date} />

                            </div>

                        </div>
                    </div>
                </form>
            </div>

        </>
    );
};
export default AddStudentComp;