import GenerateMathQuestions, { logic1, logic2, logic3, logic4 } from "./QuestionGeneratorFunctions";


const GenerateTestQuestions = (level) => {

    // Getting all the questions (MM+AB) of a particular level
    let concernedLevel = testQuestionsData[level];
    let totalMmAbQuestions = [...concernedLevel.mm.questions, ...concernedLevel.ab.questions];
    // console.log(totalMmAbQuestions);

    let questions = [], questionsCopy = [], answers = [];

    totalMmAbQuestions.map(object => {
        let data = [];
        // above "data" array variable will hold values like this => [questions, questionsCopy, answers] 

        /* 
            logicType = 1 => Non Complement Questions
            logicType = 2 => Complement Questions - 5's OR 10's complement, and random also when 'complement' key is sent null
            logicType = 3 => Mixed Complements Questions
            logicType = 4 => Combination of 5's, 10's & Mixed Complements
            logicType = 5 => Arithmetic Questions - (+, -, /, X, Sqrt., Cbrt., LCM, GCD, Percentage)  
        */

        switch (object.logicType) {
            case 1:
                data = logic1(object.rows, object.noOfQuestions);
                break;
            case 2:
                data = logic2(object, object.rows, object.noOfQuestions);
                break;
            case 3:
                data = logic3(object, object.rows, object.noOfQuestions);
                break;
            case 4:
                data = logic4(object.rows, object.noOfQuestions);
                break;
            case 5:
                data = GenerateMathQuestions(object, object.rows, object.noOfQuestions);
                break;
        }

        // above "data" array variable will hold values like this => [questions, questionsCopy, answers] 
        questions.push(...data[0]);
        questionsCopy.push(...data[1]);
        answers.push(...data[2]);

    });

    let questionsCount = { ab: concernedLevel.ab.totalQuestions, mm: concernedLevel.mm.totalQuestions };

    return [questions, questionsCopy, answers, questionsCount];

};

/****************************************************************************************************
    link: https://docs.google.com/spreadsheets/d/1oAc7FT5g_qLZm5UbGjLiUlhN50lmZ_9W/edit#gid=642824513
    Sheet: Term Test Model as per Dec Paper
****************************************************************************************************/

const testQuestionsData = {

    /*    firstNumLimit = [start, end, isDecimal] --- For mul, div, per, gcd, lcm questions only
          secondNumLimit = [start, end, isDecimal] --- For mul, div, per, gcd, lcm questions only
          digits = [number of digit, isDecimal] --- For cbrt & sqrt questions only

          numLimit = [start, end, isDecimal]  --- For add_sub questions only
          numLimitSeconds: [start, end, isDecimal], --- For add_sub questions only,  'numLimitSeconds' its an optional limit if any question needs it
             

          firstNumber => This is used in Mixed complements sum, and is used to generate a number to which the add value is added
          limit => This is used in Mixed complements sum, to create a sequence of number of x numbers. 
                Eg. limit = 3 then sequence will be [11,12,13], limit= 4 then sequence will be [11,12,13,14]
          que_type => 0: Its Mind Math Question, 1: Its Abacus Question 
      */

    /* logicType = 1 => Non Complement Questions
        logicType = 2 => Complement Questions - 5's OR 10's complement, and random also when 'complement' key is sent null
        logicType = 3 => Mixed Complements Questions
        logicType = 4 => Combination of 5's, 10's & Mixed Complements
        logicType = 5 => Arithmetic Questions - (+, -, /, X, Sqrt., Cbrt., LCM, GCD, Percentage)  
    */

    0: {
        name: 'Level 0',
        mm: {
            totalQuestions: 15,
            time: 10,
            questions: [
                { logicType: 1, rows: 3, noOfQuestions: 15, complement: null, add: null, decimalAnswer: false, }
            ]
        },
        ab: {
            totalQuestions: 45,
            time: 30,
            questions: [
                { logicType: 1, rows: 4, noOfQuestions: 15, complement: null, add: null, decimalAnswer: false, },
                { logicType: 1, rows: 4, noOfQuestions: 15, complement: null, add: null, decimalAnswer: false, },
                { logicType: 1, rows: 5, noOfQuestions: 15, complement: null, add: null, decimalAnswer: false, },
            ]
        }
    },
    '5s': {
        name: "Level 5's Complement",
        mm: {
            totalQuestions: 15,
            time: 10,
            questions: [
                { logicType: 2, rows: 4, noOfQuestions: 15, complement: 5, add: null, decimalAnswer: false, }
            ]
        },
        ab: {
            totalQuestions: 60,
            time: 30,
            questions: [
                { logicType: 2, rows: 6, noOfQuestions: 15, complement: 5, add: null, decimalAnswer: false, },
                { logicType: 2, rows: 6, noOfQuestions: 15, complement: 5, add: null, decimalAnswer: false, },
                { logicType: 2, rows: 5, noOfQuestions: 15, complement: 5, add: null, decimalAnswer: false, },
                { logicType: 2, rows: 5, noOfQuestions: 15, complement: 5, add: null, decimalAnswer: false, },
            ]
        }
    },
    1: {
        name: 'Level 1',
        mm: {
            totalQuestions: 15,
            time: 10,
            questions: [
                { logicType: 2, rows: 4, noOfQuestions: 15, complement: null, add: null, decimalAnswer: false, }
            ]
        },
        ab: {
            totalQuestions: 60,
            time: 30,
            questions: [
                { logicType: 2, rows: 6, noOfQuestions: 15, complement: null, add: null, decimalAnswer: false, },
                { logicType: 2, rows: 5, noOfQuestions: 15, complement: null, add: null, decimalAnswer: false, },
                { logicType: 2, rows: 4, noOfQuestions: 15, complement: null, add: null, decimalAnswer: false, },
                { logicType: 2, rows: 3, noOfQuestions: 15, complement: null, add: null, decimalAnswer: false, },
            ]
        }
    },
    2: {
        name: 'Level 2',
        mm: {
            totalQuestions: 15,
            time: 10,
            questions: [
                { logicType: 5, type: 'add_sub', numLimit: [1, 9, false], rows: 5, noOfQuestions: 8, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [1, 50, false], rows: 3, noOfQuestions: 7, decimalAnswer: false, },
            ]
        },
        ab: {
            totalQuestions: 40,
            time: 30,
            questions: [
                { logicType: 5, type: 'add_sub', numLimit: [1, 9, false], rows: 7, noOfQuestions: 10, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [1, 99, false], rows: 5, noOfQuestions: 10, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [10, 99, false], rows: 4, noOfQuestions: 10, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [1, 9, false], rows: 5, noOfQuestions: 10, decimalAnswer: false, },
            ]
        }
    },
    3: {
        name: 'Level 3',
        mm: {
            totalQuestions: 20,
            time: 10,
            questions: [
                { logicType: 5, type: 'add_sub', numLimit: [10, 99, false], rows: 4, noOfQuestions: 4, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [100, 200, false], rows: 3, noOfQuestions: 4, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [10, 99, false], secondNumLimit: [1, 9, false], rows: 2, noOfQuestions: 6, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [100, 999, false], secondNumLimit: [1, 9, false], rows: 2, noOfQuestions: 6, decimalAnswer: false, },
            ]
        },
        ab: {
            totalQuestions: 50,
            time: 30,
            questions: [
                { logicType: 5, type: 'add_sub', numLimit: [10, 99, false], rows: 6, noOfQuestions: 10, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [100, 999, false], rows: 4, noOfQuestions: 10, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [10, 99, false], secondNumLimit: [1, 9, false], rows: 2, noOfQuestions: 10, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [100, 999, false], secondNumLimit: [1, 9, false], rows: 2, noOfQuestions: 20, decimalAnswer: false, },
            ]
        }
    },
    4: {
        name: 'Level 4',
        mm: {
            totalQuestions: 20,
            time: 10,
            questions: [
                { logicType: 5, type: 'add_sub', numLimit: [10, 99, false], rows: 4, noOfQuestions: 4, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [100, 999, false], rows: 3, noOfQuestions: 4, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [1, 9, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 4, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [1, 9, false], secondNumLimit: [100, 999, false], rows: 2, noOfQuestions: 4, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [10, 20, false], secondNumLimit: [10, 20, false], rows: 2, noOfQuestions: 4, decimalAnswer: false, },
            ]
        },
        ab: {
            totalQuestions: 50,
            time: 30,
            questions: [
                { logicType: 5, type: 'add_sub', numLimit: [10, 99, false], rows: 6, noOfQuestions: 10, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [100, 999, false], rows: 4, noOfQuestions: 10, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [1, 9, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 10, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [1, 9, false], secondNumLimit: [100, 999, false], rows: 2, noOfQuestions: 10, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 10, decimalAnswer: false, },
            ]
        }
    },
    5: {
        name: 'Level 5',
        mm: {
            totalQuestions: 30,
            time: 10,
            questions: [
                { logicType: 5, type: 'add_sub', numLimit: [10, 99, false], rows: 4, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [100, 999, false], rows: 3, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [1000, 3000, false], rows: 2, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [1, 9, false], secondNumLimit: [100, 999, false], rows: 2, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [10, 30, false], secondNumLimit: [10, 30, false], rows: 2, noOfQuestions: 10, decimalAnswer: false, },
            ]
        },
        ab: {
            totalQuestions: 50,
            time: 30,
            questions: [
                { logicType: 5, type: 'add_sub', numLimit: [10, 99, false], rows: 5, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [100, 999, false], rows: 4, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [1000, 5000, false], rows: 3, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [1, 9, false], secondNumLimit: [100, 999, false], rows: 2, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [1, 9, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'div', firstNumLimit: [100, 999, false], secondNumLimit: [1, 9, false], rows: 2, noOfQuestions: 10, decimalAnswer: false, },
                { logicType: 5, type: 'div', firstNumLimit: [10, 99, false], secondNumLimit: [1, 9, false], rows: 2, noOfQuestions: 10, decimalAnswer: false, },
            ]
        }
    },
    6: {
        name: 'Level 6',
        mm: {
            totalQuestions: 28,
            time: 10,
            questions: [
                { logicType: 5, type: 'add_sub', numLimit: [10, 99, false], rows: 5, noOfQuestions: 4, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [100, 999, false], rows: 3, noOfQuestions: 4, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [1000, 4000, false], rows: 2, noOfQuestions: 4, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [1, 9, false], secondNumLimit: [100, 900, false], rows: 2, noOfQuestions: 4, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 4, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [100, 999, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 3, decimalAnswer: false, },
                { logicType: 5, type: 'div', firstNumLimit: [10, 99, false], secondNumLimit: [1, 9, false], rows: 2, noOfQuestions: 5, decimalAnswer: false, },
            ]
        },
        ab: {
            totalQuestions: 60,
            time: 30,
            questions: [
                { logicType: 5, type: 'add_sub', numLimit: [10, 99, false], rows: 7, noOfQuestions: 10, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [100, 999, false], rows: 4, noOfQuestions: 10, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [1000, 9999, false], rows: 3, noOfQuestions: 10, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [1, 9, false], secondNumLimit: [10.1, 99.9, true], rows: 2, noOfQuestions: 10, decimalAnswer: true, },
                { logicType: 5, type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [1.1, 9.9, true], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'mul', firstNumLimit: [100, 999, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'div', firstNumLimit: [100, 999, false], secondNumLimit: [1, 9, false], rows: 2, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'div', firstNumLimit: [100, 999, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 5, decimalAnswer: false, },
            ]
        }
    },
    7: {
        name: 'Level 7',
        mm: {
            totalQuestions: 28,
            time: 10,
            questions: [
                { logicType: 5, type: 'add_sub', numLimit: [1.1, 9.9, true], rows: 5, noOfQuestions: 4, decimalAnswer: true, },
                { logicType: 5, type: 'add_sub', numLimit: [100, 999, false], rows: 3, noOfQuestions: 4, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [100, 999, false], rows: 4, noOfQuestions: 4, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [100, 200, false], secondNumLimit: [10, 40, false], rows: 2, noOfQuestions: 3, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [10, 60, false], secondNumLimit: [10, 70, false], rows: 2, noOfQuestions: 4, decimalAnswer: false, },
                { logicType: 5, type: 'div', firstNumLimit: [10, 99, false], secondNumLimit: [1, 9, false], rows: 2, noOfQuestions: 4, decimalAnswer: true, },
                { logicType: 5, type: 'div', firstNumLimit: [100, 999, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
            ]
        },
        ab: {
            totalQuestions: 60,
            time: 30,
            questions: [
                { logicType: 5, type: 'add_sub', numLimit: [1.1, 9.9, true], rows: 7, noOfQuestions: 10, decimalAnswer: true, },
                { logicType: 5, type: 'add_sub', numLimit: [100, 999, false], rows: 4, noOfQuestions: 10, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [1000, 9999, false], rows: 3, noOfQuestions: 10, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [10.1, 99.9, true], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [1.1, 9.9, true], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'mul', firstNumLimit: [1000, 9999, false], secondNumLimit: [1, 9, false], rows: 2, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [100, 999, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'div', firstNumLimit: [100, 999, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'div', firstNumLimit: [1000, 9999, false], secondNumLimit: [1, 9, false], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
            ]
        }
    },
    8: {
        name: 'Level 8',
        mm: {
            totalQuestions: 20,
            time: 10,
            questions: [
                { logicType: 5, type: 'add_sub', numLimit: [1.1, 9.9, true], rows: 5, noOfQuestions: 2, decimalAnswer: true, },
                { logicType: 5, type: 'add_sub', numLimit: [100, 999, false], rows: 3, noOfQuestions: 3, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [1000, 9999, false], rows: 2, noOfQuestions: 2, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [100, 999, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 3, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 2, decimalAnswer: false, },
                { logicType: 5, type: 'div', firstNumLimit: [10, 99, false], secondNumLimit: [1, 9, false], rows: 2, noOfQuestions: 3, decimalAnswer: true, },
                { logicType: 5, type: 'div', firstNumLimit: [100, 999, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 1, decimalAnswer: false, },
                { logicType: 5, type: 'div', firstNumLimit: [1000, 2000, false], secondNumLimit: [100, 200, false], rows: 2, noOfQuestions: 1, decimalAnswer: true, },
                { logicType: 5, type: 'div', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [1.1, 9.9, true], rows: 2, noOfQuestions: 3, decimalAnswer: true, },
            ]
        },
        ab: {
            totalQuestions: 55,
            time: 30,
            questions: [
                { logicType: 5, type: 'add_sub', numLimit: [1.1, 9.9, true], rows: 7, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'add_sub', numLimit: [100, 999, false], rows: 4, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [10.01, 99.99, true], rows: 3, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [10.1, 99.9, true], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [1.1, 9.9, true], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'div', firstNumLimit: [1000, 9999, false], secondNumLimit: [100, 999, false], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'mul', firstNumLimit: [100, 999, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'div', firstNumLimit: [100, 999, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'div', firstNumLimit: [1000, 9999, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'gcd', rows: 2, firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'lcm', rows: 2, firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], noOfQuestions: 5, decimalAnswer: false, },
            ]
        }
    },
    9: {
        name: 'Level 9',
        mm: {
            totalQuestions: 25,
            time: 10,
            questions: [
                { logicType: 5, type: 'add_sub', numLimit: [1.1, 9.9, true], rows: 5, noOfQuestions: 2, decimalAnswer: true, },
                { logicType: 5, type: 'add_sub', numLimit: [10.1, 99.9, true], rows: 3, noOfQuestions: 3, decimalAnswer: true, },
                { logicType: 5, type: 'add_sub', numLimit: [1000, 9999, false], rows: 2, noOfQuestions: 2, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [1.1, 9.9, true], rows: 2, noOfQuestions: 3, decimalAnswer: true, },
                { logicType: 5, type: 'mul', firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 2, decimalAnswer: false, },
                { logicType: 5, type: 'div', firstNumLimit: [10.01, 99.99, true], secondNumLimit: [10.1, 99.9, true], rows: 2, noOfQuestions: 3, decimalAnswer: true, },
                { logicType: 5, type: 'div', firstNumLimit: [1000, 9999, false], secondNumLimit: [100, 999, false], rows: 2, noOfQuestions: 2, decimalAnswer: true, },
                { logicType: 5, type: 'div', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [1.1, 9.9, true], rows: 2, noOfQuestions: 3, decimalAnswer: true, },
                { logicType: 5, type: 'gcd', rows: 2, firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], noOfQuestions: 2, decimalAnswer: false, },
                { logicType: 5, type: 'lcm', rows: 2, firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], noOfQuestions: 3, decimalAnswer: false, },
            ]
        },
        ab: {
            totalQuestions: 65,
            time: 30,
            questions: [
                { logicType: 5, type: 'add_sub', numLimit: [1.1, 9.9, true], rows: 7, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'add_sub', numLimit: [100, 999, false], rows: 4, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [10.01, 99.99, true], rows: 3, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [10.1, 99.9, true], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [1.1, 9.9, true], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'div', firstNumLimit: [1000, 9999, false], secondNumLimit: [100, 999, false], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'mul', firstNumLimit: [100, 999, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'div', firstNumLimit: [100, 999, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'div', firstNumLimit: [1000, 9999, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'per', rows: 2, firstNumLimit: [1, 9, false], secondNumLimit: [10, 99, false], noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'sqrt', rows: 1, digits: [3, false], noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'gcd', rows: 2, firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'lcm', rows: 2, firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], noOfQuestions: 5, decimalAnswer: false, },
            ]
        }
    },
    10: {
        name: 'Level 10',
        mm: {
            totalQuestions: 25,
            time: 20,
            questions: [
                { logicType: 5, type: 'add_sub', numLimit: [10, 99, false], rows: 5, noOfQuestions: 2, decimalAnswer: false, },
                { logicType: 5, type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [1.1, 9.9, true], rows: 2, noOfQuestions: 3, decimalAnswer: true, },
                { logicType: 5, type: 'mul', firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 2, decimalAnswer: false, },
                { logicType: 5, type: 'lcm', rows: 2, firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], noOfQuestions: 3, decimalAnswer: false, },
                { logicType: 5, type: 'gcd', rows: 2, firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], noOfQuestions: 2, decimalAnswer: false, },
                { logicType: 5, type: 'div', firstNumLimit: [10.1, 99.9, true], secondNumLimit: [1.1, 9.9, true], rows: 2, noOfQuestions: 3, decimalAnswer: true, },
                { logicType: 5, type: 'div', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [1.1, 9.9, true], rows: 2, noOfQuestions: 2, decimalAnswer: true, },
                { logicType: 5, type: 'sqrt', rows: 1, digits: [3, false], noOfQuestions: 3, decimalAnswer: false, },
                { logicType: 5, type: 'per', rows: 2, firstNumLimit: [1, 9, false], secondNumLimit: [10, 99, false], noOfQuestions: 2, decimalAnswer: false, },
                { logicType: 5, type: 'cbrt', rows: 1, digits: [3, false], noOfQuestions: 3, decimalAnswer: false, },
            ]
        },
        ab: {
            totalQuestions: 65,
            time: 30,
            questions: [
                { logicType: 5, type: 'add_sub', numLimit: [10, 99, false], rows: 7, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [100, 999, false], rows: 4, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'add_sub', numLimit: [10.01, 99.99, true], rows: 3, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [10.1, 99.9, true], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [1.1, 9.9, true], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'div', firstNumLimit: [1000, 9999, false], secondNumLimit: [100, 999, false], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'mul', firstNumLimit: [100, 999, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'div', firstNumLimit: [100, 999, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'div', firstNumLimit: [1000, 9999, false], secondNumLimit: [10, 99, false], rows: 2, noOfQuestions: 5, decimalAnswer: true, },
                { logicType: 5, type: 'per', rows: 2, firstNumLimit: [1, 9, false], secondNumLimit: [10, 99, false], noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'sqrt', rows: 1, digits: [3, false], noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'gcd', rows: 2, firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], noOfQuestions: 5, decimalAnswer: false, },
                { logicType: 5, type: 'lcm', rows: 2, firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], noOfQuestions: 5, decimalAnswer: false, },
            ]
        }
    },

};

export default testQuestionsData;
export { GenerateTestQuestions };

