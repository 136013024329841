import React from "react"
import Navbar from "../../../Common/Components/Navbar"
import Footer from "../../../Common/Components/Footer"
import Sidebar from "../../../Sidebar/Sidebar"
import ViewManagerComp from "./Component/ViewManagerComp"


const ViewManager = () => {
    return (
        <>
            <Navbar />
            <div className="d-flex mainContentArea">
                <Sidebar />
                <ViewManagerComp />

            </div>
            <Footer />
        </>
    )
}

export default ViewManager