import { AppBar, Backdrop, Button, CircularProgress, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import url from "../../../../../Common/URL";
import Input from "../../../../Setup/Student/Component/Input";

import { Close, DeleteOutline } from "@mui/icons-material";
import MaterialReactTable from 'material-react-table';
import { toast } from "react-toastify";
import StringToTime from "../../../../../Common/Functions/StringDateTime";

const AdmissionEnquiryFormComp = ({ setOpenDrawer, loadParentData }) => {

    const [loc, setLoc] = useState([]);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        fname: "", lname: "", email: "", phone: "", preferred_time: "", location_id: '',
        location: "", message: "", language: "", campaign: "", preferred_date: "", slot_id: ''
    });

    const [slots, setSlots] = useState([]);
    const [open, setOpen] = useState(false);

    // Student Table Data 
    const [tableData, setTableData] = useState([
    ]);

    const Columns = useMemo(
        () => [
            { header: 'First Name', accessorKey: 'fname', size: 80 },
            { header: 'Last Name', accessorKey: 'lname', size: 80 },
            { header: 'Age', accessorKey: 'age', size: 80 },
            {
                header: '', id: "index", size: 30, enableEditing: false, enableColumnActions: false, enableSorting: false, accessorFn: (rowData, index) => {
                    return (
                        <Tooltip title="Delete" >
                            <IconButton onClick={() => {
                                tableData.splice(index, 1);
                                setTableData([...tableData]);
                            }}>
                                <DeleteOutline />
                            </IconButton>
                        </Tooltip>
                    );
                }
            }
        ],
        [],
    );


    const addStudentRow = () => {
        setTableData([...tableData, { fname: "", lname: "", age: "" }]);
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        tableData[row.index] = values;
        //send/receive api updates here, then refetch or update local table data for re-render
        setTableData([...tableData]);
        exitEditingMode(); //required to exit editing mode and close modal
    };

    useEffect(() => {
        loadLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----------This function will Fetch all the Existing Locations--------------------

    const loadLocation = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "fetch_locations", obj)
            .then((response) => {
                let a = response.data.data;
                //   console.log(a);
                setLoc(a);
            })
            .catch((err) => console.log(err));
    };

    // ====================== OnChange =========================

    const onChange = (e) => {
        let target = e.target;
        let value = target.value;
        let name = target.name;
        setState(pre => {
            return { ...pre, [name]: value };
        });
    };

    const onLocationChange = (e) => {
        setOpen(true);
        let target = e.target;
        let value = target.value;
        value = value.split(',');
        let name = target.name;
        setState(pre => {
            return { ...pre, [name]: value[1], location_id: value[0] };
        });
        loadFilteredSlots(value[0]);

    };

    let loadFilteredSlots = async (id) => {
        await axios.post(url + "location_filtered_slots", { location_id: id })
            .then((response) => {
                let a = response.data;
                console.log(a);
                a = a.filter(x => x.slot_type === 0);
                a.length === 0 ? setSlots([]) : setSlots(a);
                setLoading(false);
            }).then(() => setOpen(false))
            .catch((err) => console.log(err));
    };


    const onSubmit = async (e) => {
        e.preventDefault();

        if (tableData.length === 0) {
            toast.error('Please add at least one student in the form');
            return;
        }

        setLoading(true);
        const obj = {
            fname: state.fname, lname: state.lname, email: state.email, phone: state.phone,
            preferred_time: state.preferred_time,
            location: state.location, message: state.message, slot_id: state.slot_id,
            language: state.language, campaign: state.campaign, preferred_date: state.preferred_date,
            student_info: tableData.map(({ index, ...rest }) => rest) // removed the index property which was creating error
        };

        console.log(obj);

        await axios.post(url + "add_admission_enquiry", obj)
            .then((response) => {
                // console.log(response);
                if (response.data.success) {
                    loadParentData().then(() => {
                        setOpenDrawer(false);
                        toast.success('Enquiry added successfully');
                    });

                } else {
                    toast.error('Error occurred while adding enquiry, please try later');
                }
            })
            .then(() => {
                setState({
                    fname: "", lname: "", email: "", phone: "", preferred_time: "",
                    category: "", location: "", language: '', message: "", campaign: "", preferred_date: ""
                });
                setTableData([]);
            })
            .catch((err) => {
                console.log(err);
                toast.error('Error occurred while adding enquiry, please try later');
            })
            .finally(() => {
                setLoading(false);
            });

    };

    return (
        <>

            <AppBar color="transparent" position="static">
                <Toolbar>
                    <IconButton
                        onClick={() => setOpenDrawer(false)}
                        color="inherit"
                        aria-label="close"
                        sx={{ mr: 2 }}
                    >
                        <Close />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Admission Enquiry Form
                    </Typography>
                </Toolbar>
            </AppBar>


            <form onSubmit={onSubmit} >
                <div className="container-fluid p-4">
                    <div className="row mb-4">
                        <div className="col-12 d-flex justify-content-end mx-auto">
                            <Button variant="contained" disabled={loading} type="submit" >Submit Enquiry</Button>
                        </div>
                    </div>

                    <div className="row">

                        {/*-----------------------Column-1 for Inputs------------------------- */}
                        <div className="col-11 col-md-6 mx-auto">

                            <h5 className="mb-4">Parent Information</h5>

                            <Input name="fname" value={state.fname} onChange={onChange} field="First Name" type="text" placeholder="Add text" className="form-control" required={true} />
                            <Input name="lname" value={state.lname} onChange={onChange} field="Last Name" type="text" placeholder="Add text" className="form-control" required={false} />
                            <Input name="phone" value={state.phone} onChange={onChange} field="Phone Number" type="text" placeholder="Add text" className="form-control" required={false} />
                            <Input name="email" value={state.email} onChange={onChange} field="Email" type="text" placeholder="Add text" className="form-control" required={false} />

                            <h5 className="mb-4">Student Information</h5>

                            <MaterialReactTable
                                columns={Columns}
                                data={tableData}
                                initialState={{ density: 'compact' }}
                                enableDensityToggle={false}
                                // enableRowSelection
                                enablePagination={false}
                                editingMode="row"
                                enableEditing
                                enableBottomToolbar={false}
                                onEditingRowSave={handleSaveRowEdits}
                                renderTopToolbarCustomActions={({ table }) => (
                                    <Button
                                        type="button"
                                        onClick={() => addStudentRow()}
                                        variant="contained"
                                    >Add Children
                                    </Button>
                                )}
                            />
                        </div>
                        {/*-----------------------Column-2 for Inputs------------------------- */}
                        <div className="col-11 col-md-6 mx-auto">

                            <h5 className="mb-4">Trial Class</h5>

                            <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label red-asterisk">Preferred Language</label>
                                <div className="col-sm-7 col-md-7 ">
                                    <select required name="language" value={state.language} onChange={onChange} className="form-select">
                                        <option value="">Choose</option>
                                        <option value="English">English</option>
                                        <option value="Dutch">Dutch</option>
                                    </select>
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label red-asterisk">Location</label>
                                <div className="col-sm-7 col-md-7 ">
                                    <select required name="location" onChange={onLocationChange} className="form-select">
                                        <option value="" >Choose</option>
                                        {loc.map((data, i) => (<option key={i} value={[data.location_id, data.name]}>{data.name}</option>))}
                                    </select>
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Select Slot</label>
                                <div className="col-sm-7 col-md-7 ">
                                    <select disabled={slots.length < 1} name="slot_id" value={state.slot_id ?? ''} onChange={onChange} className="form-select">
                                        <option value="" >Choose</option>
                                        {slots.map((data, i) => (<option key={i} value={data.slot_id}>{`${data.slot_date} at ${StringToTime(data.slot_time)}`}</option>))}
                                    </select>
                                </div>
                            </div>

                            {<p className=" alert alert-info">Alternatively, if none of the above slots work for you or if <strong>no slots are available for the selected location</strong>, you can write your preferred date and time below</p>}

                            {/* <Input name="preferred_date" value={state.preferred_date} onChange={onChange} field="Preferred Date" type="date" placeholder="Add Date " className="form-control" />
                                <Input name="preferred_time" value={state.preferred_time} onChange={onChange} field="Preferred Time" type="time" placeholder="Add Time" className="form-control" /> */}


                            <div className="mb-3 row">
                                {/* <label className="col-form-label">Message</label> */}
                                <div className="">
                                    <textarea name="message" onChange={onChange} className="form-control" placeholder="Enter your Query/Message here" value={state.message}  ></textarea>

                                </div>
                            </div>


                            <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label red-asterisk">How did you hear about us?</label>
                                <div className="col-sm-7 col-md-7 ">
                                    <select required name="campaign" value={state.campaign} onChange={onChange} className="form-select">
                                        <option value="">Choose</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="Advertisement">Advertisement</option>
                                        <option value="Friend">Friend</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </form>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
export default AdmissionEnquiryFormComp

