import GenerateMathQuestions, { logic1, logic2, logic3, logic4 } from "./QuestionGeneratorFunctions";

const GenerateAssignmentQuestions = (level, typeID, row, count) => {
    // This function will return an object with 'questions' and 'answers' as property
    // 1. 'questions' will be of type array of array => [ [], [], [] ];
    // 2. 'answers' will be of type array => [ x, y, z ];    
    let questions = [], questionsCopy = [], answers = [];
    let concernedObject = assignmentQuestionsData[level][typeID];
    let logicType = concernedObject.logicType;

    /* 
        logicType = 1 => Non Complement Questions
        logicType = 2 => Complement Questions - 5's OR 10's complement, and random also when 'complement' key is sent null
        logicType = 3 => Mixed Complements Questions
        logicType = 4 => Combination of 5's, 10's & Mixed Complements
        logicType = 5 => Arithmetic Questions - (+, -, /, X, Sqrt., Cbrt., LCM, GCD, Percentage)  
    */

    switch (logicType) {
        case 1:
            [questions, questionsCopy, answers] = logic1(row, count);
            break;
        case 2:
            [questions, questionsCopy, answers] = logic2(concernedObject, row, count);
            break;
        case 3:
            [questions, questionsCopy, answers] = logic3(concernedObject, row, count);
            break;
        case 4:
            [questions, questionsCopy, answers] = logic4(row, count);
            break;
        case 5:
            [questions, questionsCopy, answers] = GenerateMathQuestions(concernedObject, row, count);
            break;
    }

    // For assignments all questions will of type Abacus
    let questionsCount = { ab: count, mm: 0 };

    return [questions, questionsCopy, answers, questionsCount];
};

/*  Below logic provides an 'Array of Numbers' from which we can choose one
    in an Abacus operation without performing any kind of 5's or 10's or Mixed complement calculation
 */
const abacusUnitRodLogic = {
    0: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    1: [1, 2, 3, 5, 6, 7, 8, -1],
    2: [1, 2, 5, 6, 7, -1, -2],
    3: [1, 5, 6, -1, -2, -3],
    4: [5, -1, -2, -3, -4],
    5: [1, 2, 3, 4, -5],
    6: [1, 2, 3, -1, -5, -6],
    7: [1, 2, -1, -2, -5, -6, -7],
    8: [1, -1, -2, -3, -5, -6, -7, -8],
    9: [-1, -2, -3, -4, -5, -6, -7, -8, -9],
    10: [1, 2, 3, 4, 5, 6, 7, 8, 9, -1, -2, -3, -4, -5, -6, -7, -8, -9, -10],
    11: [1, 2, 3, 5, 6, 7, 8, -1, -2, -3, -4, -5, -7, -8, -9, -10, -11],
    12: [1, 2, 5, 6, 7, -1, -2, -3, -4, -5, -8, -9, -10, -11, -12],
    13: [1, 5, 6, -1, -2, -3, -4, -5, -9, -10, -11, -12, -13],
    14: [5, -1, -2, -3, -4, -5, -10, -11, -12, -13, -14],
    15: [1, 2, 3, 4, -1, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15],
    16: [1, 2, 3, -1, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15, -16],
    17: [1, 2, -1, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15, -16, -17],
    18: [1, -1, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15, -16, -17, -18],
    19: [-1, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15, -16, -17, -18, -19],
};

const fivesComplement = {
    // This will tell what numbers are needed to add/subtract the key
    '1': [4],
    '2': [3, 4],
    '3': [2, 3, 4],
    '4': [1, 2, 3, 4],
    '-1': [5],
    '-2': [5, 6],
    '-3': [5, 6, 7],
    '-4': [5, 6, 7, 8],
};

const tensComplement = {
    // This will tell what numbers are needed to add/subtract the key
    '1': [9],
    '2': [8, 9],
    '3': [7, 8, 9],
    '4': [6, 7, 8, 9],
    '5': [5, 6, 7, 8, 9],
    '6': [4, 9],
    '7': [3, 4, 8, 9],
    '8': [2, 3, 4, 7, 8, 9],
    '9': [1, 2, 3, 4, 6, 7, 8, 9],
    '-1': [10],
    '-2': [10, 11],
    '-3': [10, 11, 12],
    '-4': [10, 11, 12, 13],
    '-5': [10, 11, 12, 13, 14],
    '-6': [10, 15],
    '-7': [10, 11, 15, 16],
    '-8': [10, 11, 12, 15, 16, 17],
    '-9': [10, 11, 12, 13, 15, 16, 17, 18],
};

/****************************************************************************************************
    link: https://docs.google.com/spreadsheets/d/1oAc7FT5g_qLZm5UbGjLiUlhN50lmZ_9W/edit#gid=642824513
    Sheet: Assignment Questions
****************************************************************************************************/

const assignmentQuestionsData = {

    /*  firstNumLimit = [start, end, isDecimal]
        secondNumLimit = [start, end, isDecimal]
        digits = [number of digit, isDecimal]
        numLimit = [start, end, isDecimal]
        firstNumber => This is used in Mixed complements sum, and is used to generate a number to which the add value is added
        limit => This is used in Mixed complements sum, to create a sequence of number of x numbers. 
              Eg. limit = 3 then sequence will be [11,12,13], limit= 4 then sequence will be [11,12,13,14]
        que_type => 0: Its Mind Math Question, 1: Its Abacus Question 
    */

    1: {
        1: {
            typeId: 1, level: 1, helpVideoId: "9Kb-7TkqbX4", name: 'Using lower & upper beads on unit rod', decimalAnswer: false,
            complement: null, rows: [3, 4, 5], add: null, logicType: 1, questionsCount: [20, 30]
        },
        2: {
            typeId: 2, level: 1, helpVideoId: "iO836ICu8OY", name: 'Adding 1 using 5\'s Complement', decimalAnswer: false,
            complement: 5, rows: [3, 4, 5], add: 1, logicType: 2, questionsCount: [20, 30]
        },
        3: {
            typeId: 3, level: 1, helpVideoId: "2WIdzUgAbrI", name: 'Adding 2 using 5\'s Complement', decimalAnswer: false,
            complement: 5, rows: [3, 4, 5], add: 2, logicType: 2, questionsCount: [20, 30]
        },
        4: {
            typeId: 4, level: 1, helpVideoId: "0RxsmMCqNnI", name: 'Adding 3 using 5\'s Complement', decimalAnswer: false,
            complement: 5, rows: [3, 4, 5], add: 3, logicType: 2, questionsCount: [20, 30]
        },
        5: {
            typeId: 5, level: 1, helpVideoId: "0TvrPTFceAE", name: 'Adding 4 using 5\'s Complement', decimalAnswer: false,
            complement: 5, rows: [3, 4, 5], add: 4, logicType: 2, questionsCount: [20, 30]
        },
        6: {
            typeId: 6, level: 1, helpVideoId: "eUVVDqN35V0", name: 'Subtracting 1 using 5\'s Complement', decimalAnswer: false,
            complement: 5, rows: [3, 4, 5], add: -1, logicType: 2, questionsCount: [20, 30]
        },
        7: {
            typeId: 7, level: 1, helpVideoId: "CDaK-gb2T9g", name: 'Subtracting 2 using 5\'s Complement', decimalAnswer: false,
            complement: 5, rows: [3, 4, 5], add: -2, logicType: 2, questionsCount: [20, 30]
        },
        8: {
            typeId: 8, level: 1, helpVideoId: "C2nwa1EoWyc", name: 'Subtracting 3 using 5\'s Complement', decimalAnswer: false,
            complement: 5, rows: [3, 4, 5], add: -3, logicType: 2, questionsCount: [20, 30]
        },
        9: {
            typeId: 9, level: 1, helpVideoId: "Kn2FIi1bS7o", name: 'Subtracting 4 using 5\'s Complement', decimalAnswer: false,
            complement: 5, rows: [3, 4, 5], add: -4, logicType: 2, questionsCount: [20, 30]
        },
        10: {
            typeId: 10, level: 1, helpVideoId: null, name: '5\'s Complement Practice', decimalAnswer: false,
            complement: 5, rows: [5], add: null, logicType: 2, questionsCount: [20, 30]
        },
        11: {
            typeId: 11, level: 1, helpVideoId: "Muj_U5EGYDc", name: 'Adding 1 using 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [3, 4, 5], add: 1, logicType: 2, questionsCount: [20, 30]
        },
        12: {
            typeId: 12, level: 1, helpVideoId: "tpZzCrEfNRc", name: 'Adding 2 using 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [3, 4, 5], add: 2, logicType: 2, questionsCount: [20, 30]
        },
        13: {
            typeId: 13, level: 1, helpVideoId: "PZuIhG0h9Zk", name: 'Adding 3 using 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [3, 4, 5], add: 3, logicType: 2, questionsCount: [20, 30]
        },
        14: {
            typeId: 14, level: 1, helpVideoId: "RJZirhALPts", name: 'Adding 4 using 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [3, 4, 5], add: 4, logicType: 2, questionsCount: [20, 30]
        },
        15: {
            typeId: 15, level: 1, helpVideoId: "boip7IZjLIs", name: 'Adding 5 using 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [3, 4, 5], add: 5, logicType: 2, questionsCount: [20, 30]
        },
        16: {
            typeId: 16, level: 1, helpVideoId: "f6FSUnFzHuo", name: 'Adding 6 using 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [3, 4, 5], add: 6, logicType: 2, questionsCount: [20, 30]
        },
        17: {
            typeId: 17, level: 1, helpVideoId: "bV7Zo05lJOE", name: 'Adding 7 using 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [3, 4, 5], add: 7, logicType: 2, questionsCount: [20, 30]
        },
        18: {
            typeId: 18, level: 1, helpVideoId: "ZBCEPUxko1g", name: 'Adding 8 using 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [3, 4, 5], add: 8, logicType: 2, questionsCount: [20, 30]
        },
        19: {
            typeId: 19, level: 1, helpVideoId: "zhtP-jDTZyI", name: 'Adding 9 using 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [3, 4, 5], add: 9, logicType: 2, questionsCount: [20, 30]
        },
        20: {
            typeId: 20, level: 1, helpVideoId: "l7PI27SsNwo", name: 'Subtracting 1 using 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [3, 4, 5], add: -1, logicType: 2, questionsCount: [20, 30]
        },
        21: {
            typeId: 21, level: 1, helpVideoId: "P6LXNRwyP3E", name: 'Subtracting 2 using 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [3, 4, 5], add: -2, logicType: 2, questionsCount: [20, 30]
        },
        22: {
            typeId: 22, level: 1, helpVideoId: "JnsVlMHSIEg", name: 'Subtracting 3 using 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [3, 4, 5], add: -3, logicType: 2, questionsCount: [20, 30]
        },
        23: {
            typeId: 23, level: 1, helpVideoId: "0wCkbt_HDuc", name: 'Subtracting 4 using 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [3, 4, 5], add: -4, logicType: 2, questionsCount: [20, 30]
        },
        24: {
            typeId: 24, level: 1, helpVideoId: "rw_nmWBK3Y", name: 'Subtracting 5 using 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [3, 4, 5], add: -5, logicType: 2, questionsCount: [20, 30]
        },
        25: {
            typeId: 25, level: 1, helpVideoId: "HTSWZecX400", name: 'Subtracting 6 using 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [3, 4, 5], add: -6, logicType: 2, questionsCount: [20, 30]
        },
        26: {
            typeId: 26, level: 1, helpVideoId: "JGIvHeUsPgc", name: 'Subtracting 7 using 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [3, 4, 5], add: -7, logicType: 2, questionsCount: [20, 30]
        },
        27: {
            typeId: 27, level: 1, helpVideoId: "aD1CjOKJuGM", name: 'Subtracting 8 using 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [3, 4, 5], add: -8, logicType: 2, questionsCount: [20, 30]
        },
        28: {
            typeId: 28, level: 1, helpVideoId: "jb8MOdGgd0g", name: 'Subtracting 9 using 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [3, 4, 5], add: -9, logicType: 2, questionsCount: [20, 30]
        },
        29: {
            typeId: 29, level: 1, helpVideoId: null, name: '10\'s Complement Practice', decimalAnswer: false,
            complement: 10, rows: [5], add: null, logicType: 2, questionsCount: [20, 30]
        },

    },
    2: {
        1: {
            typeId: 1, level: 2, helpVideoId: null, name: 'Revision 5\'s Complement', decimalAnswer: false,
            complement: 5, rows: [4, 5, 6], add: null, logicType: 2, questionsCount: [20, 30, 40]
        },

        2: {
            typeId: 2, level: 2, helpVideoId: null, name: 'Revision 10\'s Complement', decimalAnswer: false,
            complement: 10, rows: [4, 5, 6], add: null, logicType: 2, questionsCount: [20, 30, 40]
        },

        3: {
            typeId: 3, level: 2, helpVideoId: "7kP9fcZwFjk", name: 'Mixed Complements - Plus 6', decimalAnswer: false,
            rows: [3, 4, 5], add: 6, logicType: 3, firstNumber: 5, limit: 4, questionsCount: [20, 30, 40]
        },

        4: {
            typeId: 4, level: 2, helpVideoId: "xh_ifhEmXXg", name: 'Mixed Complements - Plus 7', decimalAnswer: false,
            rows: [3, 4, 5], add: 7, logicType: 3, firstNumber: 5, limit: 3, questionsCount: [20, 30, 40]
        },

        5: {
            typeId: 5, level: 2, helpVideoId: "DJIST1CkVoo", name: 'Mixed Complements - Plus 8', decimalAnswer: false,
            rows: [3, 4, 5], add: 8, logicType: 3, firstNumber: 5, limit: 2, questionsCount: [20, 30, 40]
        },

        6: {
            typeId: 6, level: 2, helpVideoId: "7B8GgY_ay_M", name: 'Mixed Complements - Plus 9', decimalAnswer: false,
            rows: [3, 4, 5], add: 9, logicType: 3, firstNumber: 5, limit: 1, questionsCount: [20, 30, 40]
        },

        7: {
            typeId: 7, level: 2, helpVideoId: "kQAncdPWJG8", name: 'Mixed Complements - Minus 6', decimalAnswer: false,
            rows: [3, 4, 5], add: -6, logicType: 3, firstNumber: 11, limit: 4, questionsCount: [20, 30, 40]
        },

        8: {
            typeId: 8, level: 2, helpVideoId: "DsWjcgTNW6U", name: 'Mixed Complements -Minus 7', decimalAnswer: false,
            rows: [3, 4, 5], add: -7, logicType: 3, firstNumber: 12, limit: 3, questionsCount: [20, 30, 40]
        },

        9: {
            typeId: 9, level: 2, helpVideoId: "JchtCt3gpyg", name: 'Mixed Complements - Minus 8', decimalAnswer: false,
            rows: [3, 4, 5], add: -8, logicType: 3, firstNumber: 13, limit: 2, questionsCount: [20, 30, 40]
        },

        10: {
            typeId: 10, level: 2, helpVideoId: "thEbto-4618", name: 'Mixed Complements - Minus 9', decimalAnswer: false,
            rows: [3, 4, 5], add: -9, logicType: 3, firstNumber: 14, limit: 1, questionsCount: [20, 30, 40]
        },

        11: {
            typeId: 11, level: 2, helpVideoId: null, name: 'Revision Of 5\'s, 10\'s & Mixed Complements', decimalAnswer: false,
            rows: [4, 5, 6], logicType: 4, questionsCount: [20, 30, 40, 50]
        },

        12: {
            typeId: 12, level: 2, helpVideoId: "unk1qQumIjQ", name: 'Single Digit Addition / Subtraction', decimalAnswer: false,
            type: 'add_sub', numLimit: [1, 9, false], rows: [6, 7], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        13: {
            typeId: 13, level: 2, helpVideoId: "unk1qQumIjQ", name: 'Single & Double Digit Addition / Subtraction', decimalAnswer: false,
            type: 'add_sub', numLimit: [1, 99, false], rows: [3, 4, 5, 6], logicType: 5, questionsCount: [20, 30, 40, 50]
        },

        14: {
            typeId: 14, level: 2, helpVideoId: "unk1qQumIjQ", name: 'Double Digit Addition / Subtraction', decimalAnswer: false,
            type: 'add_sub', numLimit: [10, 99, false], rows: [3, 4, 5], logicType: 5, questionsCount: [20, 30, 40, 50]
        },

        15: {
            typeId: 15, level: 2, helpVideoId: "unk1qQumIjQ", name: 'Triple Digit Addition / Subtraction - 2 Rows', decimalAnswer: false,
            type: 'add_sub', numLimit: [100, 999, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
    },
    3: {
        1: {
            typeId: 1, level: 3, helpVideoId: "unk1qQumIjQ", name: 'Double Digit Addition / Subtraction', decimalAnswer: false,
            type: 'add_sub', numLimit: [10, 99, false], rows: [4, 5, 6, 7, 9, 10], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        2: {
            typeId: 2, level: 3, helpVideoId: null, name: 'Multiplication double digit X 2', decimalAnswer: false,
            type: 'mul', firstNumLimit: [10, 99, false], secondNumLimit: [2, 2, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        3: {
            typeId: 3, level: 3, helpVideoId: null, name: 'Multiplication double digit X 3', decimalAnswer: false,
            type: 'mul', firstNumLimit: [10, 99, false], secondNumLimit: [3, 3, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        4: {
            typeId: 4, level: 3, helpVideoId: null, name: 'Multiplication double digit X 4', decimalAnswer: false,
            type: 'mul', firstNumLimit: [10, 99, false], secondNumLimit: [4, 4, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        5: {
            typeId: 5, level: 3, helpVideoId: null, name: 'Multiplication double digit X 5', decimalAnswer: false,
            type: 'mul', firstNumLimit: [10, 99, false], secondNumLimit: [5, 5, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        6: {
            typeId: 6, level: 3, helpVideoId: null, name: 'Multiplication double digit X 6', decimalAnswer: false,
            type: 'mul', firstNumLimit: [10, 99, false], secondNumLimit: [6, 6, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        7: {
            typeId: 7, level: 3, helpVideoId: null, name: 'Multiplication double digit X 7', decimalAnswer: false,
            type: 'mul', firstNumLimit: [10, 99, false], secondNumLimit: [7, 7, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        8: {
            typeId: 8, level: 3, helpVideoId: null, name: 'Multiplication double digit X 8', decimalAnswer: false,
            type: 'mul', firstNumLimit: [10, 99, false], secondNumLimit: [8, 8, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        9: {
            typeId: 9, level: 3, helpVideoId: null, name: 'Multiplication double digit X 9', decimalAnswer: false,
            type: 'mul', firstNumLimit: [10, 99, false], secondNumLimit: [9, 9, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        10: {
            typeId: 10, level: 3, helpVideoId: "N1qjN10fgZU", name: 'Multiplication Double Digit x Single', decimalAnswer: false,
            type: 'mul', firstNumLimit: [10, 99, false], secondNumLimit: [2, 9, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        11: {
            typeId: 11, level: 3, helpVideoId: "iVpoR7bubuU", name: 'Multiplication Triple Digit x Single', decimalAnswer: false,
            type: 'mul', firstNumLimit: [100, 999, false], secondNumLimit: [2, 9, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        12: {
            typeId: 12, level: 3, helpVideoId: null, name: 'Triple Digit Addition / Subtraction', decimalAnswer: false,
            type: 'add_sub', numLimit: [100, 999, false], rows: [2, 3, 4], logicType: 5, questionsCount: [20, 30, 40, 50]
        },


    },
    4: {
        1: {
            typeId: 1, level: 4, helpVideoId: null, name: 'Double Digit Addition / Subtraction', decimalAnswer: false,
            type: 'add_sub', numLimit: [10, 99, false], rows: [11, 12, 13, 14, 15], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        2: {
            typeId: 2, level: 4, helpVideoId: null, name: 'Multiplication Single Digit x Double', decimalAnswer: false,
            type: 'mul', firstNumLimit: [1, 9, false], secondNumLimit: [10, 99, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        3: {
            typeId: 3, level: 4, helpVideoId: "n6ywxatJyxQ", name: 'Multiplication Single Digit x Triple', decimalAnswer: false,
            type: 'mul', firstNumLimit: [1, 9, false], secondNumLimit: [100, 999, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        4: {
            typeId: 4, level: 4, helpVideoId: null, name: 'Multiplication Double Digit x Double', decimalAnswer: false,
            type: 'mul', firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        5: {
            typeId: 5, level: 4, helpVideoId: null, name: 'Multiplication Triple Digit x Double', decimalAnswer: false,
            type: 'mul', firstNumLimit: [100, 999, false], secondNumLimit: [10, 99, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        6: {
            typeId: 6, level: 4, helpVideoId: null, name: 'Triple Digit Addition / Subtraction', decimalAnswer: false,
            type: 'add_sub', numLimit: [100, 999, false], rows: [5, 6, 7, 8], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
    },
    5: {
        1: {
            typeId: 1, level: 5, helpVideoId: null, name: 'Triple Digit Addition / Subtraction', decimalAnswer: false,
            type: 'add_sub', numLimit: [100, 999, false], rows: [10, 11, 12, 13, 14, 15], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        2: {
            typeId: 2, level: 5, helpVideoId: null, name: 'Four Digit Addition / Subtraction - Number < 5000', decimalAnswer: false,
            type: 'add_sub', numLimit: [1000, 5000, false], rows: [5], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        3: {
            typeId: 3, level: 5, helpVideoId: null, name: 'Four Digit Addition / Subtraction', decimalAnswer: false,
            type: 'add_sub', numLimit: [1000, 9999, false], rows: [5], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        4: {
            typeId: 4, level: 5, helpVideoId: null, name: 'Multiplication Triple Digit x Triple', decimalAnswer: false,
            type: 'mul', firstNumLimit: [100, 999, false], secondNumLimit: [100, 999, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        5: {
            typeId: 5, level: 5, helpVideoId: null, name: 'Division Double Digit ÷ Single', decimalAnswer: false,
            type: 'div', firstNumLimit: [10, 99, false], secondNumLimit: [2, 9, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        6: {
            typeId: 6, level: 5, helpVideoId: null, name: 'Division Triple Digit ÷ Single', decimalAnswer: false,
            type: 'div', firstNumLimit: [100, 999, false], secondNumLimit: [2, 9, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        7: {
            typeId: 7, level: 5, helpVideoId: null, name: 'Division Four Digit ÷ Single', decimalAnswer: false,
            type: 'div', firstNumLimit: [1000, 9999, false], secondNumLimit: [2, 9, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
    },
    6: {
        1: {
            typeId: 1, level: 6, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (x.x) X (x.x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [1.1, 9.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        2: {
            typeId: 2, level: 6, helpVideoId: null, name: 'Four Digit Addition / Subtraction - Number < 5000', decimalAnswer: false,
            type: 'add_sub', numLimit: [1000, 5000, false], rows: [6], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        3: {
            typeId: 3, level: 6, helpVideoId: null, name: 'Four Digit Addition / Subtraction', decimalAnswer: false,
            type: 'add_sub', numLimit: [1000, 9999, false], rows: [6], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        4: {
            typeId: 4, level: 6, helpVideoId: null, name: 'Single Digit Decimal Addition', decimalAnswer: true,
            type: 'add', numLimit: [1.1, 9.9, true], rows: [4, 5], logicType: 5, questionsCount: [20, 30, 40, 50]
        },

        5: {
            typeId: 5, level: 6, helpVideoId: null, name: 'Single Digit Decimal Subtraction', decimalAnswer: true,
            type: 'sub', numLimit: [1.1, 9.9, true], firstNumLimit: [50.1, 99.9, true], rows: [4, 5], logicType: 5, questionsCount: [20, 30, 40, 50]
        },

        6: {
            typeId: 6, level: 6, helpVideoId: null, name: 'Single Digit Decimal Addition / Subtraction', decimalAnswer: true,
            type: 'add_sub', numLimit: [1.1, 9.9, true], rows: [4, 5], logicType: 5, questionsCount: [20, 30, 40, 50]
        },

        7: {
            typeId: 7, level: 6, helpVideoId: null, name: 'Double Digit Decimal Addition', decimalAnswer: true,
            type: 'add', numLimit: [10.1, 99.9, true], rows: [4, 5], logicType: 5, questionsCount: [20, 30, 40, 50]
        },

        8: {
            typeId: 8, level: 6, helpVideoId: null, name: 'Double Digit Decimal Subtraction', decimalAnswer: true,
            type: 'sub', numLimit: [10.1, 99.9, true], firstNumLimit: [450.1, 999.9, true], rows: [4, 5], logicType: 5, questionsCount: [20, 30, 40, 50]
        },

        9: {
            typeId: 9, level: 6, helpVideoId: null, name: 'Double Digit Decimal Addition / Subtraction', decimalAnswer: true,
            type: 'add_sub', numLimit: [10.1, 99.9, true], rows: [4, 5], logicType: 5, questionsCount: [20, 30, 40, 50]
        },

        10: {
            typeId: 10, level: 6, helpVideoId: null, name: 'Division Triple Digit ÷ Double', decimalAnswer: false,
            type: 'div', firstNumLimit: [100, 999, false], secondNumLimit: [10, 99, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        11: {
            typeId: 11, level: 6, helpVideoId: null, name: 'Division Four Digit ÷ Double', decimalAnswer: false,
            type: 'div', firstNumLimit: [1000, 9999, false], secondNumLimit: [10, 99, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        12: {
            typeId: 12, level: 6, helpVideoId: null, name: 'Division Four Digit ÷ Triple', decimalAnswer: false,
            type: 'div', firstNumLimit: [1000, 9999, false], secondNumLimit: [100, 999, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },

    },
    7: {
        1: {
            typeId: 1, level: 7, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (x.x) X (x.x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [1.1, 9.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        2: {
            typeId: 2, level: 7, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (x.x) X (x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [1, 9, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        3: {
            typeId: 3, level: 7, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (x.xx) X (x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [1.11, 9.99, true], secondNumLimit: [1, 9, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        4: {
            typeId: 4, level: 7, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (0.x) X (0.x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [0.1, 0.9, true], secondNumLimit: [0.1, 0.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        5: {
            typeId: 5, level: 7, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (0.xx) X (0.x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [0.11, 0.99, true], secondNumLimit: [0.1, 0.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        6: {
            typeId: 6, level: 7, helpVideoId: null, name: 'Decimal Multiplication Double Digit x Single - (xx.x) X (x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [10.1, 99.9, true], secondNumLimit: [1, 9, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        7: {
            typeId: 7, level: 7, helpVideoId: null, name: 'Decimal Multiplication Double Digit x Single - (xx.xx) X (x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [10.11, 99.99, true], secondNumLimit: [1, 9, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        8: {
            typeId: 8, level: 7, helpVideoId: null, name: 'Decimal Multiplication Double Digit x Single - (xx.x) X (x.x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [10.1, 99.9, true], secondNumLimit: [1.1, 9.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        9: {
            typeId: 9, level: 7, helpVideoId: null, name: 'Decimal Multiplication Double Digit x Single - (xx.xx) X (x.x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [10.11, 99.99, true], secondNumLimit: [1.1, 9.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        10: {
            typeId: 10, level: 7, helpVideoId: "iVpoR7bubuU", name: 'Decimal Multiplication Triple Digit x Single - (xxx.x) X (x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [111.1, 999.9, true], secondNumLimit: [1, 9, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        11: {
            typeId: 11, level: 7, helpVideoId: null, name: 'Four Digit Addition / Subtraction', decimalAnswer: false,
            type: 'add_sub', numLimit: [1000, 9999, false], rows: [6], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        12: {
            typeId: 12, level: 7, helpVideoId: null, name: 'Double Digit Decimal Addition / Subtraction', decimalAnswer: true,
            type: 'add_sub', numLimit: [10.1, 99.9, true], rows: [5], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        13: {
            typeId: 13, level: 7, helpVideoId: null, name: 'Division Single Digit ÷ Single', decimalAnswer: true,
            type: 'div', firstNumLimit: [1, 9, false], secondNumLimit: [2, 9, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        14: {
            typeId: 14, level: 7, helpVideoId: null, name: 'Division Double Digit ÷ Single', decimalAnswer: true,
            type: 'div', firstNumLimit: [10, 99, false], secondNumLimit: [2, 9, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        15: {
            typeId: 15, level: 7, helpVideoId: null, name: 'Division Four Digit ÷ Single', decimalAnswer: true,
            type: 'div', firstNumLimit: [1111, 9999, false], secondNumLimit: [2, 9, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        16: {
            typeId: 16, level: 7, helpVideoId: null, name: 'Division Four Digit ÷ Double', decimalAnswer: true,
            type: 'div', firstNumLimit: [1111, 9999, false], secondNumLimit: [11, 99, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        17: {
            typeId: 17, level: 7, helpVideoId: null, name: 'Division Four Digit ÷ Triple', decimalAnswer: true,
            type: 'div', firstNumLimit: [1111, 9999, false], secondNumLimit: [111, 999, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        18: {
            typeId: 18, level: 7, helpVideoId: null, name: 'Decimal Division Single Digit ÷ Single - (x.x) / (x.x)', decimalAnswer: true,
            type: 'div', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [1.1, 9.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        19: {
            typeId: 19, level: 7, helpVideoId: null, name: 'Decimal Division Double Digit ÷ Single - (xx.x) / (x)', decimalAnswer: true,
            type: 'div', firstNumLimit: [10.1, 99.9, true], secondNumLimit: [2, 9, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        20: {
            typeId: 20, level: 7, helpVideoId: null, name: 'Decimal Division Double Digit ÷ Single - (xx.x) / (x.x)', decimalAnswer: true,
            type: 'div', firstNumLimit: [10.1, 99.9, true], secondNumLimit: [1.1, 9.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        }
    },
    8: {
        1: {
            typeId: 1, level: 8, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (x.x) X (x.x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [1.1, 9.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        2: {
            typeId: 2, level: 8, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (0.x) X (0.0x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [0.1, 0.9, true], secondNumLimit: [0.01, 0.09, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        3: {
            typeId: 3, level: 8, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (0.0x) X (0.0x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [0.01, 0.09, true], secondNumLimit: [0.01, 0.09, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        4: {
            typeId: 4, level: 8, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (x.x) X (0.0x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [0.01, 0.09, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        5: {
            typeId: 5, level: 8, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (0.x) X (0.00x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [0.1, 0.9, true], secondNumLimit: [0.001, 0.009, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        6: {
            typeId: 6, level: 8, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (0.0x) X (0.00x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [0.01, 0.09, true], secondNumLimit: [0.001, 0.009, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        7: {
            typeId: 7, level: 8, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (x.x) X (0.00x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [0.001, 0.009, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        8: {
            typeId: 8, level: 8, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Double - (x.x) X (xx.x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [10.1, 99.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        9: {
            typeId: 9, level: 8, helpVideoId: null, name: 'Decimal Multiplication Double Digit x Single - (xx.x) X (x.x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [10.1, 99.9, true], secondNumLimit: [1.1, 9.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        10: {
            typeId: 10, level: 8, helpVideoId: null, name: 'Decimal Multiplication Double Digit x Single - (xx.x) X (0.0x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [10.1, 99.9, true], secondNumLimit: [0.01, 0.07, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        11: {
            typeId: 11, level: 8, helpVideoId: null, name: 'Decimal Multiplication Double Digit x Single - (xx.xx) X (0.0x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [10.01, 99.99, true], secondNumLimit: [0.01, 0.09, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        12: {
            typeId: 12, level: 8, helpVideoId: null, name: 'Decimal Multiplication Double Digit x Single - (xx.x) X (0.00x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [10.1, 99.9, true], secondNumLimit: [0.001, 0.009, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        13: {
            typeId: 13, level: 8, helpVideoId: null, name: 'Decimal Multiplication Double Digit x Single - (xx.xx) X (0.00x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [10.01, 99.99, true], secondNumLimit: [0.001, 0.009, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        14: {
            typeId: 14, level: 8, helpVideoId: "FUzKDjGNt4I", name: 'Decimal Multiplication Double Digit x Double - (xx.x) X (xx.x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [10.1, 99.9, true], secondNumLimit: [10.1, 99.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        15: {
            typeId: 15, level: 8, helpVideoId: "FUzKDjGNt4I", name: 'Decimal Multiplication Double Digit x Double - (xx.xx) X (xx.x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [10.01, 99.99, true], secondNumLimit: [10.1, 99.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        16: {
            typeId: 16, level: 8, helpVideoId: null, name: 'Division Four Digit ÷ Double Digit', decimalAnswer: false,
            type: 'div', firstNumLimit: [1000, 9999, false], secondNumLimit: [10, 99, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        17: {
            typeId: 17, level: 8, helpVideoId: null, name: 'Decimal Division Single Digit ÷ Single Digit - x.x / 0.x', decimalAnswer: true,
            type: 'div', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [0.1, 0.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        18: {
            typeId: 18, level: 8, helpVideoId: null, name: 'Decimal Division Single Digit ÷ Single Digit - x.xx / 0.00x', decimalAnswer: true,
            type: 'div', firstNumLimit: [1.01, 9.99, true], secondNumLimit: [0.001, 0.009, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        19: {
            typeId: 19, level: 8, helpVideoId: null, name: 'Decimal Division Double Digit ÷ Single Digit - xx.x / 0.x', decimalAnswer: true,
            type: 'div', firstNumLimit: [10.1, 99.9, true], secondNumLimit: [0.1, 0.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        20: {
            typeId: 20, level: 8, helpVideoId: null, name: 'Decimal Division Double Digit ÷ Single Digit - xx.x / 0.0x', decimalAnswer: true,
            type: 'div', firstNumLimit: [10.1, 99.9, true], secondNumLimit: [0.01, 0.09, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        21: {
            typeId: 21, level: 8, helpVideoId: null, name: 'Greatest Common Divisor (GCD) - (x, x)', decimalAnswer: false,
            type: 'gcd', firstNumLimit: [1, 9, false], secondNumLimit: [1, 9, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        22: {
            typeId: 22, level: 8, helpVideoId: null, name: 'Greatest Common Divisor (GCD) - (x, xx)', decimalAnswer: false,
            type: 'gcd', firstNumLimit: [1, 9, false], secondNumLimit: [10, 90, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        23: {
            typeId: 23, level: 8, helpVideoId: null, name: 'Greatest Common Divisor (GCD) - (xx, xx)', decimalAnswer: false,
            type: 'gcd', firstNumLimit: [10, 90, false], secondNumLimit: [10, 90, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        24: {
            typeId: 24, level: 8, helpVideoId: null, name: 'Greatest Common Divisor (GCD) - (xx, xxx)', decimalAnswer: false,
            type: 'gcd', firstNumLimit: [10, 90, false], secondNumLimit: [100, 900, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        25: {
            typeId: 25, level: 8, helpVideoId: null, name: 'Least Common Multiple (LCM) - (x, x)', decimalAnswer: false,
            type: 'lcm', firstNumLimit: [1, 9, false], secondNumLimit: [1, 9, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        26: {
            typeId: 26, level: 8, helpVideoId: null, name: 'Least Common Multiple (LCM) - (x, xx)', decimalAnswer: false,
            type: 'lcm', firstNumLimit: [1, 9, false], secondNumLimit: [10, 99, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        27: {
            typeId: 27, level: 8, helpVideoId: null, name: 'Least Common Multiple (LCM) - (xx, xx)', decimalAnswer: false,
            type: 'lcm', firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        28: {
            typeId: 28, level: 8, helpVideoId: null, name: 'Least Common Multiple (LCM) - (xx, xxx)', decimalAnswer: false,
            type: 'lcm', firstNumLimit: [10, 99, false], secondNumLimit: [100, 999, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        29: {
            typeId: 29, level: 8, helpVideoId: null, name: 'Double Digits - Decimal Addition / Subtraction', decimalAnswer: true,
            type: 'add_sub', numLimit: [10.1, 99.9, true], rows: [5], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
    },
    9: {
        1: {
            typeId: 1, level: 9, helpVideoId: null, name: 'Greatest Common Divisor (GCD) - (xx, xx)', decimalAnswer: false,
            type: 'gcd', firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        2: {
            typeId: 2, level: 9, helpVideoId: null, name: 'Greatest Common Divisor (GCD) - (xx, xxx)', decimalAnswer: false,
            type: 'gcd', firstNumLimit: [10, 99, false], secondNumLimit: [100, 999, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        3: {
            typeId: 3, level: 9, helpVideoId: null, name: 'Least Common Multiple (LCM) - (xx, xx)', decimalAnswer: false,
            type: 'lcm', firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        4: {
            typeId: 4, level: 9, helpVideoId: null, name: 'Least Common Multiple (LCM) - (xx, xxx)', decimalAnswer: false,
            type: 'lcm', firstNumLimit: [10, 99, false], secondNumLimit: [100, 999, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        5: {
            typeId: 5, level: 9, helpVideoId: null, name: 'Percentages - x % of xx', decimalAnswer: true,
            type: 'per', firstNumLimit: [1, 9, false], secondNumLimit: [10, 99, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        6: {
            typeId: 6, level: 9, helpVideoId: null, name: 'Percentages - x % of xxx', decimalAnswer: true,
            type: 'per', firstNumLimit: [1, 9, false], secondNumLimit: [100, 999, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        7: {
            typeId: 7, level: 9, helpVideoId: null, name: 'Percentages - xx % of xx', decimalAnswer: true,
            type: 'per', firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        8: {
            typeId: 8, level: 9, helpVideoId: null, name: 'Percentages - xx % of xxx', decimalAnswer: true,
            type: 'per', firstNumLimit: [10, 99, false], secondNumLimit: [100, 999, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        9: {
            typeId: 9, level: 9, helpVideoId: null, name: 'Square Roots - 3 Digit Number', decimalAnswer: false,
            type: 'sqrt', digits: [3, false], rows: [1], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        10: {
            typeId: 10, level: 9, helpVideoId: null, name: 'Square Roots - 4 Digit Number', decimalAnswer: false,
            type: 'sqrt', digits: [4, false], rows: [1], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        11: {
            typeId: 11, level: 9, helpVideoId: null, name: 'Square Roots - 5 Digit Number', decimalAnswer: false,
            type: 'sqrt', digits: [5, false], rows: [1], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        12: {
            typeId: 12, level: 9, helpVideoId: null, name: 'Square Roots - 6 Digit Number', decimalAnswer: false,
            type: 'sqrt', digits: [6, false], rows: [1], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        13: {
            typeId: 13, level: 9, helpVideoId: null, name: 'Division Four Digit ÷ Double Digit', decimalAnswer: true,
            type: 'div', firstNumLimit: [1000, 9999, false], secondNumLimit: [10, 99, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        14: {
            typeId: 14, level: 9, helpVideoId: null, name: 'Division Four Digit ÷ Triple Digit', decimalAnswer: true,
            type: 'div', firstNumLimit: [1000, 9999, false], secondNumLimit: [100, 999, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        15: {
            typeId: 15, level: 9, helpVideoId: null, name: 'Decimal Division Single Digit ÷ Single Digit - x.x / 0.0x', decimalAnswer: true,
            type: 'div', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [0.01, 0.09, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        16: {
            typeId: 16, level: 9, helpVideoId: null, name: 'Decimal Division Single Digit ÷ Single Digit - x.x / x', decimalAnswer: true,
            type: 'div', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [2, 9, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        17: {
            typeId: 17, level: 9, helpVideoId: null, name: 'Decimal Division Double Digit ÷ Double Digit - (xx.x) / (xx)', decimalAnswer: true,
            type: 'div', firstNumLimit: [10.1, 99.9, true], secondNumLimit: [10, 99, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        18: {
            typeId: 18, level: 9, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (x.x) X (x.xx)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [1.01, 9.99, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        19: {
            typeId: 19, level: 9, helpVideoId: null, name: 'Decimal Multiplication Double Digit x Single - (xx.x) X (x.xx)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [10.1, 99.9, true], secondNumLimit: [1.01, 9.99, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        20: {
            typeId: 20, level: 9, helpVideoId: "iVpoR7bubuU", name: 'Decimal Multiplication Triple Digit x Single - (xxx.x) X (x.x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [100.1, 999.9, true], secondNumLimit: [1.1, 9.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        21: {
            typeId: 21, level: 9, helpVideoId: null, name: 'Double Digits - Decimal Addition / Subtraction', decimalAnswer: true,
            type: 'add_sub', numLimit: [10.1, 99.9, true], rows: [5], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
    },
    10: {
        1: {
            typeId: 1, level: 10, helpVideoId: null, name: 'Square Roots - 3 Digit Number', decimalAnswer: false,
            type: 'sqrt', digits: [3, false], rows: [1], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        2: {
            typeId: 2, level: 10, helpVideoId: null, name: 'Square Roots - 4 Digit Number', decimalAnswer: false,
            type: 'sqrt', digits: [4, false], rows: [1], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        3: {
            typeId: 3, level: 10, helpVideoId: null, name: 'Square Roots - 5 Digit Number', decimalAnswer: false,
            type: 'sqrt', digits: [5, false], rows: [1], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        4: {
            typeId: 4, level: 10, helpVideoId: null, name: 'Square Roots - 6 Digit Number', decimalAnswer: false,
            type: 'sqrt', digits: [6, false], rows: [1], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        5: {
            typeId: 5, level: 10, helpVideoId: null, name: 'Cube Roots - 4 Digit Number', decimalAnswer: false,
            type: 'cbrt', digits: [4, false], rows: [1], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        6: {
            typeId: 6, level: 10, helpVideoId: null, name: 'Cube Roots - 5 Digit Number', decimalAnswer: false,
            type: 'cbrt', digits: [5, false], rows: [1], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        7: {
            typeId: 7, level: 10, helpVideoId: null, name: 'Cube Roots - 6 Digit Number', decimalAnswer: false,
            type: 'cbrt', digits: [6, false], rows: [1], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        8: {
            typeId: 8, level: 10, helpVideoId: null, name: 'Percentages - x % of xx', decimalAnswer: true,
            type: 'per', firstNumLimit: [1, 9, false], secondNumLimit: [10, 99, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        9: {
            typeId: 9, level: 10, helpVideoId: null, name: 'Percentages - x % of xxx', decimalAnswer: true,
            type: 'per', firstNumLimit: [1, 9, false], secondNumLimit: [100, 999, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        10: {
            typeId: 10, level: 10, helpVideoId: null, name: 'Percentages - xx % of xx', decimalAnswer: true,
            type: 'per', firstNumLimit: [10, 99, false], secondNumLimit: [10, 99, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        11: {
            typeId: 11, level: 10, helpVideoId: null, name: 'Percentages - xx % of xxx', decimalAnswer: true,
            type: 'per', firstNumLimit: [10, 99, false], secondNumLimit: [100, 999, false], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        12: {
            typeId: 12, level: 10, helpVideoId: null, name: 'Decimal Division Single Digit ÷ Single Digit - x.x / 0.x', decimalAnswer: true,
            type: 'div', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [0.1, 0.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        13: {
            typeId: 13, level: 10, helpVideoId: null, name: 'Decimal Division Single Digit ÷ Single Digit - x.xx / 0.00x', decimalAnswer: true,
            type: 'div', firstNumLimit: [1.01, 9.99, true], secondNumLimit: [0.001, 0.009, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        14: {
            typeId: 14, level: 10, helpVideoId: null, name: 'Decimal Division Double Digit ÷ Single Digit xx.x / 0.0x', decimalAnswer: true,
            type: 'div', firstNumLimit: [10.1, 99.9, true], secondNumLimit: [0.01, 0.09, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        15: {
            typeId: 15, level: 10, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (0.x) X (0.0x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [0.1, 0.9, true], secondNumLimit: [0.01, 0.09, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        16: {
            typeId: 16, level: 10, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (0.0x) X (0.0x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [0.01, 0.09, true], secondNumLimit: [0.01, 0.09, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        17: {
            typeId: 17, level: 10, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (x.x) X (0.0x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [0.01, 0.09, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        18: {
            typeId: 18, level: 10, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (x.xx) X (0.0x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [1.01, 9.99, true], secondNumLimit: [0.01, 0.09, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        19: {
            typeId: 19, level: 10, helpVideoId: null, name: 'Decimal Multiplication Double Digit x Single - (xx.x) X (0.0x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [10.1, 99.9, true], secondNumLimit: [0.01, 0.09, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        20: {
            typeId: 20, level: 10, helpVideoId: null, name: 'Decimal Multiplication Double Digit x Single - (xx.xx) X (0.0x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [10.01, 99.99, true], secondNumLimit: [0.01, 0.09, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        21: {
            typeId: 21, level: 10, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Double - (x.x) X (xx.x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [10.1, 99.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        22: {
            typeId: 22, level: 10, helpVideoId: "FUzKDjGNt4I", name: 'Decimal Multiplication Double Digit x Double - (xx.x) X (xx.x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [10.1, 99.9, true], secondNumLimit: [10.1, 99.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        23: {
            typeId: 23, level: 10, helpVideoId: "FUzKDjGNt4I", name: 'Decimal Multiplication Double Digit x Double - (xx.xx) X (xx.x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [10.01, 99.99, true], secondNumLimit: [10.1, 99.9, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        24: {
            typeId: 24, level: 10, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (0.x) X (0.00x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [0.1, 0.9, true], secondNumLimit: [0.001, 0.009, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        25: {
            typeId: 25, level: 10, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (0.0x) X (0.00x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [0.01, 0.09, true], secondNumLimit: [0.001, 0.009, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        26: {
            typeId: 26, level: 10, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (x.x) X (0.00x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [1.1, 9.9, true], secondNumLimit: [0.001, 0.009, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        27: {
            typeId: 27, level: 10, helpVideoId: null, name: 'Decimal Multiplication Single Digit x Single - (x.xx) X (0.00x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [1.01, 9.99, true], secondNumLimit: [0.001, 0.009, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        28: {
            typeId: 28, level: 10, helpVideoId: null, name: 'Decimal Multiplication Double Digit x Single - (xx.x) X (0.00x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [10.1, 99.9, true], secondNumLimit: [0.001, 0.009, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        29: {
            typeId: 29, level: 10, helpVideoId: null, name: 'Decimal Multiplication Double Digit x Single - (xx.xx) X (0.00x)', decimalAnswer: true,
            type: 'mul', firstNumLimit: [10.01, 99.99, true], secondNumLimit: [0.001, 0.009, true], rows: [2], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        30: {
            typeId: 30, level: 10, helpVideoId: null, name: 'Double Digits - Addition / Subtraction', decimalAnswer: false,
            type: 'add_sub', numLimit: [10, 99, false], rows: [5], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        31: {
            typeId: 31, level: 10, helpVideoId: null, name: 'Triple Digits - Addition / Subtraction', decimalAnswer: false,
            type: 'add_sub', numLimit: [100, 999, false], rows: [5], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
        32: {
            typeId: 32, level: 10, helpVideoId: null, name: 'Four Digits - Addition / Subtraction', decimalAnswer: false,
            type: 'add_sub', numLimit: [1000, 9999, false], rows: [5], logicType: 5, questionsCount: [20, 30, 40, 50]
        },
    },
};

export default assignmentQuestionsData;
export {
    GenerateAssignmentQuestions, abacusUnitRodLogic, fivesComplement, tensComplement
};

