import React from "react"
const Heading = (props) => {
    return (
        <>
            <div className="mb-3">
                <div className="row mx-auto bg-light shadow-sm pt-2">
                    <h5 style={{ color: "#174873", fontFamily: 'Lato, sans-serif' }}>{props.name}</h5>
                </div>
            </div>
        </>
    )
}
export default Heading