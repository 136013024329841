/* 
    The purpose of this form is to add the student to the actual student's 
    record during the admission process.The parent will receive an email with a 
    link to this form, after successful payment, this form data will create a new 
    student in the software with an Inactive status. Now that the student's record has been updated, 
    the Admin must make the status Active and add some extra information. 
    By which the student can access the software.
*/

import { Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Heading from "../../../../Common/Components/Heading";
import url from "../../../../Common/URL";
import Input from "../../../Setup/Student/Component/Input";
import { useConfirm } from "material-ui-confirm";

const AddStudentComp = () => {
    const confirm = useConfirm();
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    // Below 2 states are used for 'Admission Payment Process' Dialog
    const [openPaymentProcessDialog, setOpenPaymentProcessDialog] = useState(false);
    const [paymentProcessLoading, setPaymentProcessLoading] = useState(false);

    // Below 2 states are used for 'Offline Payment' Dialog
    const [openOfflinePaymentDialog, setOpenOfflinePaymentDialog] = useState(false);
    const [offlinePaymentLoading, setOfflinePaymentLoading] = useState(false);

    const [restrictPageAccess, setRestrictPageAccess] = useState(true);
    const [closeWindow, setCloseWindow] = useState(false);

    const [otp, setOtp] = useState('');
    const [otpLoading, setOtpLoading] = useState(false);

    const [state, setState] = useState({});

    useEffect(() => {
        loadAllAsyncTasks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadAllAsyncTasks = () => {
        toast.info('Loading...', { autoClose: false, closeOnClick: false });
        setLoading(true);
        let allCompleted = Promise.all([
            fetchAdmissionDetails(),
        ]);
        allCompleted.then(() => {
            toast.dismiss();
            setLoading(false);
        });
    };

    // ------------This function will fetch admission details ----------------------------
    const fetchAdmissionDetails = async () => {
        const obj = {
            admission_id: id,
        };

        await axios.post(url + "view_admission_form", obj)
            .then((response) => {
                let a = response.data;
                console.log(a);
                setState(a[0]);
            })
            .catch((err) => console.log(err));
    };

    //---------------One onChange function is used for setting the state of various types of input fields ---------------     
    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setState((preValue) => {
            return {
                ...preValue,
                [name]: value
            };
        });
    };

    // ------------------- -------------------functions for Submitting Country and state- ------------------- -------------------
    const selectCountry = (val) => {
        setState((preValue) => {
            return {
                ...preValue,
                country: val
            };
        });
    };

    const selectRegion = (val) => {
        setState((preValue) => {
            return {
                ...preValue,
                state: val
            };
        });
    };

    //-------------------------- onSubmit function for Submitting the  Forms---------------------------

    const onSubmit = async e => {
        e.preventDefault();
        if (state.country === '' || state.country === null) {
            toast.warn('Please fill out the Country field');
            return;
        }
        if (state.state === '' || state.state === null) {
            toast.warn('Please fill out the Province field');
            return;
        }

        saveAdmissionFormData();

    };

    const saveAdmissionFormData = async () => {
        setLoading(true);
        setState({ ...state, updated_by: sessionStorage.getItem('username') });
        console.log(state);
        await axios
            .patch(url + "edit_admission_form", state)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    fetchAdmissionDetails().then(() => {
                        toast.success("Form updated");
                    });
                } else {
                    toast.error("An error occurred while updating form, please try again later");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("An error occurred while updating form, please try again later");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const navbar = {
        backgroundColor: "#fff",
        color: "#174873",
        minHeight: "4.5em",
        borderBottom: "5px solid #E89801",
    };

    const style = {
        backgroundColor: '#2C2E30',
        fontFamily: 'Mulish, sans-serif'
    };

    var thisYear = new Date().getFullYear();

    const handleOfflinePayment = async () => {
        setOpenPaymentProcessDialog(false);
        setOpenOfflinePaymentDialog(true);
    };

    const triggerOnlinePayment = async () => {
        toast.info('Under Process');
    };

    const handleOtpCheck = async () => {
        setOtpLoading(true);
        const obj = {
            admission_id: id, otp: otp
        };

        await axios.post(url + "otp_verification", obj)
            .then((response) => {
                if (response.data.success) {
                    toast.success('OTP matched successfully');
                    setRestrictPageAccess(false);
                    setOtpLoading(true);

                } else {
                    toast.success("OTP didn't match");
                    setOtpLoading(true);
                }

            })
            .catch((err) => {
                console.log(err);
                toast.success("OTP didn't match");
                setOtpLoading(true);
            });
    };

    return (
        <>
            {/* ############# 1st Dialog for Checking OTP ######## */}
            <Dialog fullWidth={true} maxWidth="xs" open={restrictPageAccess}>
                <DialogTitle id="alert-dialog-title">Verification Dialog</DialogTitle>
                <DialogContent>
                    <p>Please enter the verification code you received along with the link, in email.</p>
                    <input className="form-control" value={otp} placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)}></input>
                </DialogContent>
                <DialogActions className="px-4">
                    <Button
                        type="button"
                        disabled={otpLoading}
                        variant="contained" color="primary"
                        onClick={handleOtpCheck} >Submit</Button>
                </DialogActions>
            </Dialog >

            {/* ########## 2nd Dialog for choosing Payment Mode ######### */}
            <Dialog onClose={() => setOpenPaymentProcessDialog(false)} fullWidth={true} maxWidth="sm" open={openPaymentProcessDialog} >
                <DialogTitle id="alert-dialog-title">Admission Payment Process</DialogTitle>
                <DialogContent>
                    <p>In order to complete your admissions process, you need to submit your admission fees.
                        The below two button allows you to choose your preferred method of payment, either online or offline.</p>
                    <hr />
                    <p> Payable Amount: <strong>{state.payable_amount} EUR</strong></p>
                </DialogContent>

                <DialogActions className="px-4">
                    <Button disabled={paymentProcessLoading} variant="contained" className="me-auto" type="button" onClick={handleOfflinePayment}>Will Pay Offline</Button>
                    <Button type="button" disabled={paymentProcessLoading} variant="contained" color="secondary" onClick={triggerOnlinePayment} > Proceed To Online Payment</Button>
                </DialogActions>
            </Dialog >

            {/* ########## 3rd Dialog for offline Payment Info & Closing the Admission screen ########### */}
            <Dialog fullWidth={true} maxWidth="sm" open={openOfflinePaymentDialog}>
                <DialogTitle id="alert-dialog-title">Offline Payment</DialogTitle>
                <DialogContent>
                    <p>If you need to make an offline payment, you can come to our office and provide us with the payment. </p>
                </DialogContent>

                <DialogActions className="px-4">
                    <Button
                        className="me-auto"
                        disabled={offlinePaymentLoading}
                        type="button"
                        variant="outlined"
                        color='secondary'
                        onClick={triggerOnlinePayment}>Will Pay Online</Button>
                    <Button
                        type="button"
                        disabled={offlinePaymentLoading}
                        variant="contained" color="primary"
                        onClick={() => {
                            setState({ ...state, payment_mode: "offline" });
                            saveAdmissionFormData().then(() => {
                                setOpenOfflinePaymentDialog(false);
                                setCloseWindow(true);
                                setOfflinePaymentLoading(false);
                            });

                        }} >Okay</Button>
                </DialogActions>
            </Dialog >



            {!restrictPageAccess && <>
                {/* ----------------------- Navbar ----------------------- */}
                <nav className="navbar navbar-expand-lg navbar-light sticky-top shadow" style={navbar} >
                    {/* Mobile Screen Heading Div */}
                    <div className="w-100 m-3 text-end d-md-none" >
                        <h6 className="fw-bolder softwareName">Guruscool - Abacus Training Institute</h6>
                        <Divider />
                    </div >

                    {/* Big Screen Heading */}
                    <p className="navbar-brand fw-bolder mb-0 softwareName d-none d-md-block ms-3" > Guruscool - Abacus Training Institute</p >
                </nav >

                {/* ----------------------- Body ----------------------- */}


                <div className="container-fluid mt-3 px-4">
                    {closeWindow ?
                        <div style={{ height: '85vh' }} className=" d-flex justify-content-center align-items-center" >
                            <div className=" text-center w-50">
                                <h3>Thank You, For Filing Out The Admission Form</h3>
                                <hr />
                                <p>We will keep your information safe. Feel free to contact us with an questions.
                                    <br />   Currently, your admission Status is Pending. We request you to make the payment and complete the admission process</p>
                            </div>
                        </div> :
                        <form onSubmit={onSubmit}  >
                            <Heading name="Online Admission Form" />

                            <div className="row my-4">
                                <div className="col-12 d-flex justify-content-between">
                                    <Button variant="contained" color="warning" disabled={loading} type="button" onClick={() =>
                                        confirm({
                                            title: "Are you sure?",
                                            description: 'You acknowledge that all the information you have entered is correct and that all the mandatory fields have been filled out.'
                                        })
                                            .then(() => {
                                                setOpenPaymentProcessDialog(true);
                                            })
                                    } >Proceed To Next Step</Button>

                                    <Button variant="contained" disabled={loading} type="submit" >{loading ? <CircularProgress color="inherit" size={20} /> : 'Save'}</Button>
                                </div>
                            </div>

                            <div className="row">

                                {/*-----------------------Column-1 for Inputs------------------------- */}
                                <div className="col-11 col-md-6 mx-auto">

                                    <h5 className="mb-4">Personal Information</h5>


                                    <Input name="fname" onChange={onChange} field="First Name" type="text" placeholder="Add text" className="form-control" value={state.fname ?? ''} required={true} />

                                    <Input name="mname" onChange={onChange} field="Middle Name" type="text" placeholder="Add text" className="form-control" value={state.mname ?? ''} required={false} />

                                    <Input name="lname" onChange={onChange} field="Last Name" type="text" placeholder="Add text" className="form-control" value={state.lname ?? ''} required={true} />

                                    <Input name="dob" onChange={onChange} field="Date of Birth" type="date" placeholder="Add DOB" className="form-control" value={state.dob ?? ''} required={true} />

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label red-asterisk">Address Line 1</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <textarea name="addressline1" onChange={onChange} className="form-control" placeholder="Add text" value={state.addressline1 ?? ''} required ></textarea>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label ">Address Line 2</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <textarea name="addressline2" onChange={onChange} className="form-control" placeholder="Add text" value={state.addressline2 ?? ''}  ></textarea>
                                        </div>
                                    </div>

                                    <Input name="city" onChange={onChange} field="City" type="text" placeholder="Add text" className="form-control" value={state.city ?? ''} required={true} />

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label red-asterisk">Country</label>
                                        <div className="col-sm-7 col-md-7 ">

                                            <CountryDropdown
                                                className="form-select"
                                                name="country"
                                                value={state.country ?? ''}
                                                onChange={(val) => selectCountry(val)} />
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label red-asterisk">Province</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <RegionDropdown
                                                className="form-select"
                                                name="state"
                                                country={state.country}
                                                value={state.state ?? ''}
                                                onChange={(val) => selectRegion(val)} />
                                        </div>
                                    </div>

                                    <Input name="zip" onChange={onChange} field="Zip" type="text" placeholder="Add text" className="form-control" value={state.zip ?? ''} required={true} />
                                    <Input name="mobile_no" onChange={onChange} field="Mobile Number" type="text" placeholder="Add text" className="form-control" value={state.mobile_no ?? ''} required={true} />
                                    <Input name="home_no" onChange={onChange} field="Home Phone" type="text" placeholder="Add text" className="form-control" value={state.home_no ?? ''} required={false} />
                                    <Input name="email" onChange={onChange} field="Email" type="email" placeholder="Add text" className="form-control" value={state.email ?? ''} required={true} />

                                    <Card variant="outlined" className="mb-4">
                                        <CardContent>
                                            <Input name="email" onChange={onChange} field="Email" type="email" placeholder="Add text" className="form-control" value={state.email} required={true} />
                                            <p className="alert alert-info mb-0">Please enter a unique email address for this child. It will serve as their username and will be used for logging in after admission. <br />Each student should have their own individual email address, especially if you have more than one child.</p>
                                        </CardContent>
                                    </Card>


                                </div>
                                {/*-----------------------Column-2 for Inputs------------------------- */}
                                <div className="col-11 col-md-6 mx-auto">
                                    <h5 className="mb-4">Parent Information</h5>
                                    <Input name="parent_fname" onChange={onChange} field="Parent First Name" type="text" placeholder="Add text" className="form-control" value={state.parent_fname ?? ''} required={true} />
                                    <Input name="parent_lname" onChange={onChange} field="Parent Last Name" type="text" placeholder="Add text" className="form-control" value={state.parent_lname ?? ''} required={true} />
                                    <Input name="parent_mobile_no" onChange={onChange} field="Parent Mobile Number" type="text" placeholder="Add text" className="form-control" value={state.parent_mobile_no ?? ''} required={true} />
                                    <Input name="parent_email" onChange={onChange} field="Parent Email" type="email" placeholder="Add text" className="form-control" value={state.parent_email ?? ''} required={true} />
                                    <Input name="parent_mobile_no_2" onChange={onChange} field="Parent Mobile Number 2" type="text" placeholder="Add text" className="form-control" value={state.parent_mobile_no_2 ?? ''} />
                                    <Input name="parent_email_2" onChange={onChange} field="Parent Email 2" type="email" placeholder="Add text" className="form-control" value={state.parent_email_2 ?? ''} />

                                    <h5 className="mt-5 mb-4">Other Information</h5>

                                    <Input name="acc_name" onChange={onChange} field="Account Name" type="text" placeholder="Add Account Name" className="form-control" value={state.acc_name ?? ''} />
                                    <Input name="acc_number" onChange={onChange} field="Account Number" type="text" placeholder="Add Account Number" className="form-control" value={state.acc_number ?? ''} />
                                </div>

                            </div>
                        </form>
                    }
                </div>


                {/* ----------------------- Footer ----------------------- */}
                <div className="container-fluid" style={style} >
                    <div className="d-flex container py-2 text-white-50 small"  >
                        <span> &#169; Copyright {thisYear}. All Rights Reserved.</span>
                        <span className="ms-auto">Version 10.5.0 | Development | Designed By
                            <a className="text-white-50 ms-2" target="_blank" rel="noopener noreferrer" href="https://portalwiz.com/">Portalwiz Technologies</a>.
                        </span>
                    </div>
                </div >
            </>}
        </>
    );
};
export default AddStudentComp;