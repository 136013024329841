import { Close } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import { AppBar, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../../Common/Components/LoadingSpinner";
import { AnswerFieldStyle, RearrangeArray, TableHeaderStyle, styleColor, styleFontWeight } from "../../../../Common/Question Generator/QuestionGeneratorFunctions";
import url from "../../../../Common/URL";

const ModernTestExamPreviewComp = ({ rowData, showStudentAnswers, setOpenDrawer }) => {
    // let { test_id, st_ans, user_ans_id } = useParams();

    // Extracting the test ID from 'rowData', which is used to fetch the actual test questions and answers
    const test_id = rowData.test_id;
    //  Flag indicating whether to show student answers
    const st_ans = showStudentAnswers;
    // Extracting the user answer ID from 'rowData', which is used to fetch a test score & actual student answers
    const user_ans_id = rowData.user_ans_id;

    const [loading, setLoading] = useState(true);

    // This data will be sent to DB 
    const [paperState, setPaperState] = useState({
        total_ab_questions: 0, total_mm_questions: 0,
        test_name: '', test_type: ''
    });

    var mmTableHeader = [...Array(paperState.total_mm_questions).keys()]; // to create header number for a Mind-Math table
    var abTableHeader = [...Array(paperState.total_ab_questions).keys()]; // to create header number for a Abacus table

    let mmInputFieldArray = [...Array(paperState.total_mm_questions).keys()]; // to create input fields for the table
    let abInputFieldArray = [...Array(paperState.total_ab_questions).keys()]; // to create input fields for the table

    var mmActualAnswerFieldArray = [...Array(paperState.total_mm_questions).keys()]; // to create the actual answer field for a Mind-Math table
    var abActualAnswerFieldArray = [...Array(paperState.total_ab_questions).keys()]; // to create the actual answer field for a Abacus table

    // This will store generated questions separately for MM & AB
    const [mmQuestions, setMmQuestions] = useState([]);
    const [abQuestions, setAbQuestions] = useState([]);

    // This is to store input field answers, which will later be used to check right and wrong answers
    const [mmInputAnswers, setMmInputAnswers] = useState([]);
    const [abInputAnswers, setAbInputAnswers] = useState([]);

    // This is to store actual answers
    const [mmAnswersArray, setMmAnswersArray] = useState([]);
    const [abAnswersArray, setAbAnswersArray] = useState([]);

    useEffect(() => {
        FetchTestDetails(); // This will fetch actual questions & answers 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const FetchTestDetails = () => {
        // New Logic
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: test_id,
        };

        var total_mm_questions = 0;
        var total_ab_questions = 0;

        axios.post(url + "fetch_single_test", obj)
            .then((response) => {
                let a = response.data.data;
                // console.log(a);
                let actual_answer = a[0].actual_answer;
                let actual_question = a[0].actual_question;

                total_mm_questions = a[0].total_mm_questions;
                total_ab_questions = a[0].total_ab_questions;

                setPaperState({
                    ...paperState,
                    total_ab_questions: Number(a[0].total_ab_questions), total_mm_questions: Number(a[0].total_mm_questions),
                    actual_answer: actual_answer, actual_question: actual_question,
                    test_name: a[0].test_name, test_type: a[0].test_type
                });


                // -------- Getting Mind-Math Questions --------
                let mmQuestionsArray = actual_question.slice(0, a[0].total_mm_questions);
                let mmAnswerArray = actual_answer.slice(0, a[0].total_mm_questions);
                // console.log(mmQuestionsArray);
                // console.log(mmAnswerArray);
                var mmSplicedArray = [];
                while (mmQuestionsArray.length > 0) {
                    mmSplicedArray.push(mmQuestionsArray.splice(0, 10));
                }

                for (let i = 0; i < mmSplicedArray.length; i++) {
                    mmQuestionsArray.push(RearrangeArray(mmSplicedArray[i]));
                }

                setMmQuestions(mmQuestionsArray);
                setMmAnswersArray(mmAnswerArray);


                //-------- Getting Abacus Questions --------
                let abQuestionsArray = actual_question.slice(-a[0].total_ab_questions); // -ve sign means we are taking 'N elements' from end of array
                let abAnswerArray = actual_answer.slice(-a[0].total_ab_questions); // -ve sign means we are taking 'N elements' from end of array

                var abSplicedArray = [];
                while (abQuestionsArray.length > 0) {
                    abSplicedArray.push(abQuestionsArray.splice(0, 10));
                }

                for (let i = 0; i < abSplicedArray.length; i++) {
                    abQuestionsArray.push(RearrangeArray(abSplicedArray[i]));
                }

                setAbQuestions(abQuestionsArray);
                setAbAnswersArray(abAnswerArray);

                // This will fetch student's answers for a particular stud_ans_id
                if (st_ans) {
                    const obj = { uname: sessionStorage.getItem("username"), id: user_ans_id };
                    axios.post(url + "view_score", obj)
                        .then((response) => {
                            if (response.data.success) {
                                let a = response.data.data;
                                let student_answers = a[0].student_answers;
                                // console.log(a);
                                // console.log(student_answers);

                                // Extract the first mmInputAnswers using 'total_mm_questions' count  from the 'student_answers' array
                                let mmInputAnswers = student_answers.slice(0, total_mm_questions);

                                // Extract elements starting from the index 'total_mm_questions' to the end of the 'student_answers' array
                                let abInputAnswers = student_answers.slice(total_mm_questions);
                                // We're taking 'total_mm_questions' here to ensure that 'abInputAnswers' contains the remaining elements 
                                // after 'mmInputAnswers'. This allows us to split the array into two parts effectively.

                                // console.log(total_mm_questions, mmInputAnswers);
                                // console.log(total_ab_questions, abInputAnswers);

                                // Now you can set the state variables
                                setMmInputAnswers(mmInputAnswers);
                                setAbInputAnswers(abInputAnswers);

                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            setOpenDrawer(false);
                            toast.error("Error while fetching student's answers");
                        });
                }

            }).then(() => {
                setLoading(false);
            }).catch((err) => {
                console.log(err);
                setOpenDrawer(false);
            });
    };

    const checkAnswer = (x, type) => {
        // inputAnswers = Entered answers; 
        // answersArray = Actual Answers;
        // type = 0 means Mind-Math; type = 1 means Abacus;
        if (type === 0) {
            return (mmInputAnswers[x] === mmAnswersArray[x]) ? true : false;
        } else {
            return (abInputAnswers[x] === abAnswersArray[x]) ? true : false;
        }
    };

    return (
        <>
            {/* ========================================================= */}

            {/* <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}> */}

            {loading ? <LoadingSpinner /> :
                <div>
                    <AppBar color="transparent" position="static">
                        <Toolbar>
                            <IconButton
                                onClick={() => setOpenDrawer(false)}
                                color="inherit"
                                aria-label="close"
                                sx={{ mr: 2 }}
                            >
                                <Close />
                            </IconButton>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                {paperState.test_name}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className="container-fluid mb-5 p-4">
                        {/* <Heading name={paperState.test_name} /> */}

                        {st_ans && (
                            <div className="row mb-3">
                                <div className="col-12 ">
                                    <div className="hstack gap-3">
                                        <div className="p-2  shadow-sm border border-success"><p style={{ margin: 0, fontWeight: "500" }}><FiberManualRecordIcon style={{ color: "green" }} />  Right Answer</p></div>
                                        <div className="p-2  shadow-sm border border-danger"><p style={{ margin: 0, fontWeight: "500" }}><FiberManualRecordIcon style={{ color: "red" }} />  Wrong Answer</p></div>
                                        <div className="p-2  shadow-sm border border-dark"><p style={{ margin: 0, fontWeight: "500" }}><span className="fw-bolder ms-2 me-1">--</span>  Not Answered</p></div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* ================= Mind-Math Table ================= */}
                        <div className="row">

                            <div className="col-12 mx-auto ">
                                <h5><LabelImportantIcon /> Mind-Math</h5>
                                <hr />
                            </div>

                            <div className="col-12 mx-auto ">
                                {mmQuestions.map((x, i) => {
                                    return <TableContainer key={i} component={Paper} className="mb-5">
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>
                                                    {mmTableHeader.splice(0, 10).map((x, i) => <TableCell key={i} style={TableHeaderStyle}>{x + 1}</TableCell>)}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {x.map((x, i) => {
                                                    return <TableRow key={i}>
                                                        {x.map((x, i) => <TableCell key={i} style={{ fontWeight: styleFontWeight(x), color: styleColor(x), textAlign: 'center' }}>{x}</TableCell>)}
                                                    </TableRow>;
                                                })}

                                                {st_ans && <TableRow>
                                                    {mmInputFieldArray.splice(0, 10).map((x, i) => (
                                                        <TableCell key={x} className="text-center">
                                                            <input readOnly
                                                                style={{
                                                                    width: "3em", textAlign: "center",
                                                                    border: (mmInputAnswers[x] == 0 || mmInputAnswers[x]) ? (checkAnswer(x, 0) ? '2px solid green' : '2px solid red') : '2px solid black'
                                                                }} value={mmInputAnswers[x] ?? '--'} />

                                                        </TableCell>
                                                    ))}
                                                </TableRow>}

                                                <TableRow>
                                                    {mmActualAnswerFieldArray.splice(0, 10).map((x, i) => (
                                                        <TableCell key={i} className="text-center" style={AnswerFieldStyle}>
                                                            {mmAnswersArray[x]}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>;
                                })}

                            </div>
                        </div>

                        {/* ================= Abacus Table ================= */}
                        <div className="row ">
                            <div className="col-12 mx-auto ">
                                <h5><LabelImportantIcon /> Abacus</h5>
                                <hr />
                            </div>
                            <div className="col-12 mx-auto ">
                                {abQuestions.map((x, i) => {
                                    return <TableContainer key={i} component={Paper} className="mb-5">
                                        <Table>
                                            <TableHead style={{ fontWeight: "bolder", background: "#f8f9fa", boxShadow: "5px .1px 5px 0px " }}>
                                                <TableRow>
                                                    {abTableHeader.splice(0, 10).map((x, i) => <TableCell key={i} style={TableHeaderStyle}>{x + 1}</TableCell>)}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {x.map((x, i) => {
                                                    return <TableRow key={i}>
                                                        {x.map((x, i) => <TableCell key={i} style={{ fontWeight: styleFontWeight(x), color: styleColor(x), textAlign: 'center' }}>{x}</TableCell>)}
                                                    </TableRow>;
                                                })}


                                                {st_ans && <TableRow>
                                                    {abInputFieldArray.splice(0, 10).map((x, i) => (
                                                        <TableCell key={x} className="text-center">
                                                            <input readOnly
                                                                style={{
                                                                    width: "3em", textAlign: "center",
                                                                    border: (abInputAnswers[x] == 0 || abInputAnswers[x]) ? (checkAnswer(x, 1) ? '2px solid green' : '2px solid red') : '2px solid black'
                                                                }} value={abInputAnswers[x] ?? '--'} />

                                                        </TableCell>
                                                    ))}
                                                </TableRow>}


                                                <TableRow>
                                                    {abActualAnswerFieldArray.splice(0, 10).map((x, i) => (
                                                        <TableCell key={i} className="text-center" style={AnswerFieldStyle}>
                                                            {abAnswersArray[x]}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>;
                                })}

                            </div>
                        </div>

                        {/* ================================== */}

                    </div>
                </div>


            }
            {/* </div> */}
        </>
    );
};
export default ModernTestExamPreviewComp;