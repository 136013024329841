import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Heading from "../../../Common/Components/Heading";
import LoadingSpinner from "../../../Common/Components/LoadingSpinner";

import axios from "axios";
import url from "../../../Common/URL";

import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";

const ActivityTableComp = () => {
  const [loading, setLoading] = useState(true);

  const Columns = useMemo(
    () => [
      { header: 'Table Name', accessorKey: 'table_name' },
      { header: 'Rows Count', accessorKey: 'count' },
    ],
    [],
  );
  const [data, setData] = useState([]);
  // ------------------------Fetching Level table's data-------------------------------------
  useEffect(() => {
    loadActivityTables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadActivityTables = () => {
    const obj = { uname: sessionStorage.getItem("username") };
    axios.post(url + "tables_count", obj)
      .then((response) => {
        // console.log(response);
        if (response.data.success) {
          let a = response.data.data;
         !a.length ? setData([]) : setData(a);
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>

      {/* ###################################################################################################################################### */}
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item">
              <Link to="/bulletin">Home</Link>
            </li>

            <li className="breadcrumb-item " aria-current="page">
              System Health
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Activity Tables
            </li>
          </ol>
        </nav>

        <Heading name="Activity Tables" />

        <div className="container-fluid mb-5">

          <div className="row">
            <div className="col-12 mx-auto">
              <MaterialReactTable
                state={{ isLoading: loading }}
                columns={Columns}
                data={data}
                enableRowNumbers
                // enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}

                getRowId={(originalRow) => originalRow.id}
              />
            </div>
          </div>
        </div>

      </div>
    </>
  );
};
export default ActivityTableComp;
