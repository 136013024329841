import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import url from "../../../../Common/URL";
import { ReadonlyInput } from "../../../Setup/Student/Component/Input";

import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
import { Close } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RateReviewIcon from '@mui/icons-material/RateReview';
import { AppBar, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, Toolbar, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { useConfirm } from 'material-ui-confirm';
import EditDetailStuTestHistoryComp from "./EditDetailStuTestHistoryComp";
import calculateAccuracy from "../../../../Common/Functions/calculateAccuracy";

const DetailStuTestHistoryComp = ({ id, setOpenDrawer, loadParentData }) => {

    const confirm = useConfirm();
    const [attachment, setAttachment] = useState([]);

    const [field, setField] = useState({
        approved: "", batch_name: "", location_name: "", test_id: "", test_mode: "",
        attempted_percentage: "", created_by: "", created_at: "", level_name: "", test_level: "",
        not_ans: "", overall_percentage: "", ques_id: "", score: "",
        test_name: "", test_type: "", time_taken_abacus: "", time_taken_mm: "",
        user_ans_id: "", user_answer: "", wrong_ans: "", fname: "", lname: "",
        accuracy_ab: '', accuracy_mm: "", accuracy_per_ab: "", accuracy_per_mm: "",
        teacher_comment: "", total_accuracy_per: "", total_attempted_ab: "", total_attempted_mm: "",
        total_attempted_per_ab: "", total_attempted_per_mm: "",
    });

    const [open, setOpen] = useState(false);  // To open the popup , for adding or editing  the Teacher's comment 
    const [backdrop, setBackdrop] = useState(false);

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        comment: "", approved: ""
    });

    const loadScores = async () => {
        setLoading(true);
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        };
        await axios.post(url + "view_score", obj)
            .then((response) => {
                let resData = response.data.data;
                console.log(resData);
                setField({
                    approved: resData[0].approved, batch_name: resData[0].batch_name, location_name: resData[0].location_name,
                    test_id: resData[0].test_id, fname: resData[0].fname, lname: resData[0].lname,
                    created_by: resData[0].created_by, created_at: resData[0].created_at, test_level: resData[0].test_level,
                    level_name: resData[0].level_name, not_ans: resData[0].not_ans, test_mode: resData[0].test_mode,
                    overall_percentage: parseFloat(resData[0].overall_percentage),
                    ques_id: resData[0].ques_id, score: resData[0].score, test_name: resData[0].test_name, test_type: resData[0].test_type,
                    time_taken_abacus: resData[0].time_taken_abacus, time_taken_mm: resData[0].time_taken_mm,
                    user_ans_id: resData[0].user_ans_id, user_answer: resData[0].user_answer, wrong_ans: resData[0].wrong_ans,
                    attempted_percentage: parseFloat(resData[0].attempted_percentage),
                    accuracy_ab: parseFloat(resData[0].accuracy_ab),
                    accuracy_mm: parseFloat(resData[0].accuracy_mm),
                    accuracy_per_ab: parseFloat(resData[0].accuracy_per_ab),
                    accuracy_per_mm: parseFloat(resData[0].accuracy_per_mm), teacher_comment: resData[0].teacher_comment,
                    total_accuracy_per: parseFloat(resData[0].total_accuracy_per),
                    total_attempted_ab: resData[0].total_attempted_ab, total_attempted_mm: resData[0].total_attempted_mm,
                    total_attempted_per_ab: parseFloat(resData[0].total_attempted_per_ab),
                    total_attempted_per_mm: parseFloat(resData[0].total_attempted_per_mm)
                });
                LoadAttachments(resData[0].test_id, resData[0].test_name, resData[0].user_name, resData[0].created_at);
                setData({
                    comment: resData[0].teacher_comment, approved: resData[0].approved
                });
            })
            .catch((err) => console.log(err)).finally(() => {
                setLoading(false);
            });

    };

    //   ###########################################################

    const LoadAttachments = (id, t_name, stu_name, date) => {
        setLoading(true);

        const obj = {
            uname: sessionStorage.getItem("username"),
            test_id: id,
            test_name: t_name,
            user_name: stu_name,
            created_at: date
        };
        axios.post(url + "select_stud_attachment", obj)
            .then((response) => {
                // console.log(response)
                if (response.data.success) {
                    let a = response.data.data;
                    // console.log(a);
                    if (a !== null) {
                        setAttachment(a);
                    } else setAttachment([]);
                } else {
                    setAttachment([]);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error occurred, Try again later.");
            }).finally(() => {
                setLoading(false);
            });
    };



    //   ###########################################################
    useEffect(() => {
        loadScores();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const onChange = (e) => {
        const input = e.target;
        const value = input.value;
        const name = input.name;
        setData({ ...data, [name]: value });
    };

    const OnCommentSubmit = () => {
        setLoading(true);
        const obj = {
            id: field.user_ans_id, uname: sessionStorage.getItem("username"), role: sessionStorage.getItem("role"),
            teacher_comment: data.comment, approved: data.approved
        };
        axios.post(url + "edit_score", obj)
            .then(res => {
                // console.log(res);
                if (res.data.success) {
                    loadScores().then(() => {
                        loadParentData().then(() => {
                            toast.success("Comment uploaded successfully");
                        });
                    });
                } else {
                    toast.error("An Error occurred, please try again later.");
                }
                setOpen(false);
            })
            .catch(res => console.log(res)).finally(() => {
                setLoading(false);
            });
    };

    const onApproveSubmit = (e) => {
        setBackdrop(true);
        const { user_ans_id } = field;
        const { comment } = data;
        const isChecked = e.target.checked;

        const obj = {
            id: user_ans_id,
            uname: sessionStorage.getItem("username"),
            role: sessionStorage.getItem("role"),
            teacher_comment: comment,
            approved: isChecked
        };

        axios.post(url + "edit_score", obj)
            .then(res => {
                console.log(res);
                if (res.data.success) {
                    loadScores().then(() => {
                        loadParentData().then(() => {
                            isChecked ? toast.success("Comment Approved successfully") : toast.success("Comment Disapproved");
                        });
                    });
                } else {
                    toast.error("An error occurred, please try again later.");
                }
            })
            .catch(error => {
                console.error("Error occurred:", error);
                toast.error("An error occurred, please try again later.");
            })
            .finally(() => {
                setBackdrop(false);
            });
    };


    const onDelete = () => {
        confirm({ title: "Delete Confirmation", description: 'This test record will be deleted permanently.' })
            .then(() => {
                const obj = {
                    id: id,
                    uname: sessionStorage.getItem("username"),
                };
                axios.post(url + "delete_score", obj)
                    .then((res) => {
                        //   console.log(res)
                        loadParentData().then(() => {
                            setOpenDrawer(false);
                            toast.info("Test history deleted");;
                        });

                    })
                    .catch((err) => console.log(err));
            });
    };

    const [openEditDrawer, setOpenEditDrawer] = useState(false);

    return (
        <>
            {/* Side Drawer for Opening Edit View */}
            <Drawer
                PaperProps={{ style: { width: '95%' } }}
                anchor='right'
                open={openEditDrawer}
                onClose={() => setOpenEditDrawer(false)}
            >
                <EditDetailStuTestHistoryComp id={id} setOpenDrawer={setOpenEditDrawer} loadParentData={loadScores} />
            </Drawer >

            {/* ############################################ Dialog for Uploading  Teacher's comment ################################################## */}
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={() => setOpen(false)}>
                <DialogTitle id="max-width-dialog-title">Teacher's Comment</DialogTitle>
                <DialogContent>
                    <div className="mb-3 ">
                        <label className="col-form-label">Comment</label>
                        <div>
                            <textarea name="comment" className="form-control" placeholder="Add Comment..." onChange={onChange} value={data.comment ?? ''} rows="3"></textarea>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setOpen(false)}>Close</Button>
                    <Button color="primary" onClick={() => OnCommentSubmit()} disabled={loading}>{loading ? <CircularProgress color="inherit" size={20} /> : 'Save'}</Button>
                </DialogActions>
            </Dialog>


            {/* ############################################################### */}

            <AppBar color="transparent" position="static">
                <Toolbar>
                    <IconButton
                        onClick={() => setOpenDrawer(false)}
                        color="inherit"
                        aria-label="close"
                        sx={{ mr: 2 }}
                    >
                        <Close />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Detail View
                    </Typography>
                </Toolbar>
            </AppBar>


            <div className="container-fluid p-4">

                <div className="row mb-4">
                    <div className="col-12 col-md-12  d-flex justify-content-between align-items-end">
                        <h5>Attempted Test Information</h5>
                        {(field.test_mode === "offline") &&
                            <div>
                                <Button variant="contained" color="error" onClick={onDelete} >Delete</Button>
                                <Button variant="contained" onClick={() => setOpenEditDrawer(true)} className="ms-3">Edit</Button>
                            </div>}
                    </div>
                </div>


                <div className="row">
                    <div className="col-11 col-md-6 mx-auto">


                        <ReadonlyInput field="Student Name" className="form-control bg-light border-0 " value={`${field.fname} ${field.lname}`} />

                        <div className="mb-3 row">
                            <label className="col-sm-5 col-form-label">Test Name</label>
                            <div className="col-sm-7 col-md-7 ">
                                <textarea readOnly className="form-control bg-light border-0 " placeholder="--" value={field.test_name ?? ''} ></textarea>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-sm-5 col-form-label">Test Type</label>
                            <div className="col-sm-7 col-md-7 ">
                                <textarea readOnly className="form-control bg-light border-0 " placeholder="--" value={field.test_type ?? ''}></textarea>
                            </div>
                        </div>

                        <ReadonlyInput field="Student's Level" className="form-control bg-light border-0 " value={field.level_name} />


                        {field.test_mode === "offline" &&
                            <>
                                <hr />
                                <small >The level of the test/exam the student has taken</small>
                                <ReadonlyInput field="Test Level" value={field.test_level} />
                                <hr />
                            </>
                        }
                        <ReadonlyInput field="Exam Submitted On" className="form-control bg-light border-0 " value={field.created_at.split(" ")[0]} />
                        <hr className="p-0 my-4 w-50" />
                        <ReadonlyInput field="Total Attempted Percentage" className="form-control bg-light border-0 " value={(field.attempted_percentage)} />

                        <ReadonlyInput field="Total Accuracy Percentage" className="form-control bg-light border-0 " value={calculateAccuracy(field.accuracy_per_ab, field.accuracy_per_mm)} />



                    </div>

                    <div className="col-11 col-md-6 mx-auto">


                        {(field.test_type === "Exam" || field.test_type === "Practice Exam") &&
                            <>

                                <h6 style={{ letterSpacing: ".5px", fontWeight: "500" }} className="mb-3">MindMath -</h6>
                                <ReadonlyInput field="Time Taken" className="form-control bg-light border-0 " value={field.time_taken_mm} />
                                <ReadonlyInput field="Total Attempted Question %" className="form-control bg-light border-0 " value={field.total_attempted_per_mm.toFixed(1)} />
                                <ReadonlyInput field="Accuracy %" className="form-control bg-light border-0 " value={field.accuracy_per_mm.toFixed(1)} />

                                <h6 style={{ letterSpacing: ".5px", fontWeight: "500" }} className="mb-3 mt-5">Abacus -</h6>
                                <ReadonlyInput field="Time Taken" className="form-control bg-light border-0 " value={field.time_taken_abacus} />
                                <ReadonlyInput field="Total Attempted Question %" className="form-control bg-light border-0 " value={field.total_attempted_per_ab.toFixed(1)} />
                                <ReadonlyInput field="Accuracy %" className="form-control bg-light border-0 " value={field.accuracy_per_ab.toFixed(1)} />

                            </>
                        }


                        {(field.test_type === "Test" || field.test_type === "Practice Test") && <>

                            <h6 style={{ letterSpacing: ".5px", fontWeight: "500" }} className="mb-3">MindMath -</h6>
                            <ReadonlyInput field="Time Taken" className="form-control bg-light border-0 " value={field.time_taken_mm} />
                            <ReadonlyInput field="Total Attempted Question %" className="form-control bg-light border-0 " value={field.total_attempted_per_mm.toFixed(1)} />
                            <ReadonlyInput field="Accuracy %" className="form-control bg-light border-0 " value={field.accuracy_per_mm.toFixed(1)} />

                            <h6 style={{ letterSpacing: ".5px", fontWeight: "500" }} className="mb-3 mt-5">Abacus -</h6>
                            <ReadonlyInput field="Time Taken" className="form-control bg-light border-0 " value={field.time_taken_abacus} />
                            <ReadonlyInput field="Total Attempted Question %" className="form-control bg-light border-0 " value={field.total_attempted_per_ab.toFixed(1)} />
                            <ReadonlyInput field="Accuracy %" className="form-control bg-light border-0 " value={field.accuracy_per_ab.toFixed(1)} />

                        </>
                        }


                        {(field.test_type === "Test" || field.test_type === "Exam") &&
                            <>
                                <hr className="me-5 " />

                                <p>Add/Edit Comment <IconButton className="ms-2" onClick={() => setOpen(true)} ><RateReviewIcon style={{ color: "black" }} className="text-black" /></IconButton></p>

                                <div className="mb-3">
                                    <label className="form-label" style={{ fontWeight: 500 }}>Teacher's Comment</label>
                                    <div className="pe-5">
                                        <textarea readOnly className="form-control bg-light border-0 " placeholder="--" value={field.teacher_comment ?? ''} ></textarea>
                                    </div>
                                </div>

                                {field.test_name !== "" &&
                                    (data.approved === "1" ? <h6 className="d-flex align-items-center"><CheckCircleIcon style={{ color: "#4BB543 " }} />&nbsp;Comment has been Approved by the Admin.</h6> :
                                        <h6 className="d-flex align-items-center"><ErrorIcon color="error" />&nbsp;Comment has not been Approved by the Admin yet.</h6>)
                                }

                                {(sessionStorage.getItem("role") === "Superadmin" || sessionStorage.getItem("role") === "Systemadmin" || sessionStorage.getItem("role") === "Admin" || sessionStorage.getItem("role") === "Location Manager") &&
                                    <>
                                        <hr className="me-5 mb-3" />
                                        <div className="mb-3 row ">
                                            <label className="col-sm-5 col-form-label">Approve Comment</label>
                                            <div className="col-sm-7 col-md-7 ps-4 pt-2">
                                                <div className="form-check ">
                                                    <input className="form-check-input" name="approved" type="checkbox" checked={data.approved} onChange={onApproveSubmit} />
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                            </>}
                        <Backdrop sx={{ zIndex: 3001, color: '#fff' }} open={backdrop} ><CircularProgress style={{ color: "#fff" }} /></Backdrop>
                    </div>

                </div>
            </div>
            {/* </div> */}
        </>
    );
};

export default DetailStuTestHistoryComp

