import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from 'react-tooltip';
import Heading from "../../../../Common/Components/Heading";
import url from "../../../../Common/URL";
import LevelData, { QuestionType } from "./LevelData";

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

import { Tooltip } from '@mui/material';
import axios from "axios";
import { cbrt, divide, evaluate, fix, gcd, lcm, multiply, pickRandom, round, sqrt, sum } from "mathjs";
import random from "random";
import { useSelector } from "react-redux";
import AlertBlockForQuestionPapers from '../../../../Common/Components/AlertBlockForQuestionPapers';
import { precision } from '../../../../Common/Question Generator/QuestionGeneratorFunctions';
import { CustomGenerateRandom, DisplayAnswer, DisplayScreenHeading, GenerateLevelOption, GenerateRowOption, Loader, marks } from './MindMathHelper';



const MindMathComp = () => {

    const [ans_input, SetAns_input] = useState(false);
    const [student_level, setStudent_level] = useState(null);
    const [que_info, setQue_info] = useState({
        start_time: "", end_time: "", actual_ans: "", stu_ans: "", que: "", row: "", level: "", operation: ""
    });

    const [open, setOpen] = useState(false);
    const [state, setState] = useState([]); // This is used to store generated  array of question

    //This state will Store all the setting options
    const [field, setField] = useState({
        operationObject: {}, // It is used to store to object of operation which is currently chosen
        operation_id: "", // It stores the id of operation object
        level: "", after_decimal: "",
        bgColor: "#000000", fontColor: "#FFFFFF",
        flash: 2000, timeout: 2000,
        mul_limit: "", digit: "", row: "",
    });

    // This state is used to store the range of times-table Multiplication questions
    const [timesTableRange, setTimesTableRange] = useState([]);

    // This state will store data related to a particular difficulty level, which is selected
    const [difficultyLevelData, setDifficultyLevelData] = useState({
        id: "", difficulty_state: 1, condition_type: 0, first_number_limit: [], second_number_limit: []
    });

    const [displayNumbers, setDisplayNumbers] = useState(false);     //This will show Randomly Generated Numbers on screen.
    const [replayLoader, setReplayLoader] = useState(false);     //This will make the Loader run again , to make number show again
    const [reset, setReset] = useState(false);     // This will make the  Mind Math Reset
    const [showReplayReset, setShowReplayReset] = useState(false);  // This will show & hide Replay & Check Button

    const [result, setResult] = useState({   //This function will display the Result Div and Icons (Right and Wrong)
        show: false, answer: "", input: "", actual_answer: ""
    });

    const [showRowsOption, setShowRowsOption] = useState(false);
    const [hasDifficultyLevelDropdown, setHasDifficultyLevelDropdown] = useState(false);

    const [rowOptions, setRowOptions] = useState({
        max_row_count: ""
    });

    // This State is used when we are sending attempted question data in the Backend
    const [operationName, setOperationName] = useState('');

    // =================================================================

    useEffect(() => {
        if (sessionStorage.getItem("role") === "Student") {
            StudentLogin();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // =================================================================

    const StudentLogin = () => {
        var Level = sessionStorage.getItem("level_name");
        setStudent_level(Level.split(" ")[1]);
    };

    // ====== GenerateRandom is called by GenerateRandomArray Function  ============================

    const GenerateRandom = (digit, row, after_decimal, start_limit, end_limit) => {
        var random_arr = [];
        switch (digit) {
            case 1:
                for (let i = 0; i < row; i++) {
                    if (field.operationObject.type === "whole") {
                        random_arr.push(random.int(1, 9));
                    } else {
                        random_arr.push(random.float(1, 9).toFixed(after_decimal));
                    }
                }
                return random_arr;

            case 2:
                for (let i = 0; i < row; i++) {
                    if (field.operationObject.type === "whole") {
                        random_arr.push(random.int(start_limit ?? 10, end_limit ?? 99));
                    } else {
                        random_arr.push(random.float(10, 99).toFixed(after_decimal));
                    }
                }
                return random_arr;

            case 3:
                for (let i = 0; i < row; i++) {
                    if (field.operationObject.type === "whole") {
                        random_arr.push(random.int(start_limit ?? 100, end_limit ?? 999));
                    } else {
                        random_arr.push(random.float(100, 999).toFixed(after_decimal));
                    }
                }
                return random_arr;

            case 4:
                for (let i = 0; i < row; i++) {
                    if (field.operationObject.type === "whole") {
                        random_arr.push(random.int(start_limit ?? 1000, end_limit ?? 9999));
                    } else {
                        random_arr.push(random.float(1000, 9999).toFixed(after_decimal));
                    }
                }
                return random_arr;

            case 5:
                for (let i = 0; i < row; i++) {
                    if (field.operationObject.type === "whole") {
                        random_arr.push(random.int(start_limit ?? 10000, end_limit ?? 99999));
                    } else {
                        random_arr.push(random.float(10000, 99999).toFixed(after_decimal));
                    }
                }
                return random_arr;

            default:
                break;
        }
    };

    // ====== GenerateRandomArray is called by Play function  ==========================
    const GenerateRandomArray = () => {
        var RAN_ARR;

        var a_pt, b_pt;        // Here a & b will store how much number we need after a decimal point.
        if (field.after_decimal.split(',').length === 1) {
            a_pt = Number(field.after_decimal.split(',')[0]);
            b_pt = Number(field.after_decimal.split(',')[0]);
        } else {
            a_pt = Number(field.after_decimal.split(',')[0]);
            b_pt = Number(field.after_decimal.split(',')[1]);
        }

        switch (field.operationObject.short_name) {
            case "a_s":

                if (field.digit.split(',').length === 1) {
                    if ((field.operationObject.operation_type_id === 1) && (difficultyLevelData.difficulty_state !== 0)) {
                        RAN_ARR = CustomGenerateRandom(difficultyLevelData.first_number_limit, difficultyLevelData.second_number_limit, difficultyLevelData.condition_type);
                        break;
                    } else
                        RAN_ARR = GenerateRandom(Number(field.digit), Number(field.row), a_pt);
                } else {
                    let x = Math.floor(Number(field.row) / 2);
                    let y = Number(field.row) - x;
                    let a = GenerateRandom(Number(field.digit.split(',')[0]), x, a_pt);
                    let b = GenerateRandom(Number(field.digit.split(',')[1]), y, b_pt);
                    RAN_ARR = [...a, ...b];
                }



                // below code is just to add "negative sign to some numbers" of the generated RAN_ARR

                let count = Math.floor((RAN_ARR.length) / 2);     // This will tell how much max negative numbers we want in array.
                var store = [];

                for (let i = 0; i <= count; i++) {
                    store.push(i);
                }

                var num_of_neg_numbers = store[random.int(0, count)];
                var small_nums_arr = RAN_ARR.sort((a, b) => a - b).slice(0, num_of_neg_numbers);

                for (let i = 0; i < num_of_neg_numbers; i++) {
                    RAN_ARR.shift();
                }

                small_nums_arr = small_nums_arr.map(x => x * (-1));
                RAN_ARR = [...RAN_ARR, ...small_nums_arr];

                break;

            case "mul":

                if ((field.operationObject.operation_type_id === 1) && (difficultyLevelData.difficulty_state !== 0)) {
                    RAN_ARR = CustomGenerateRandom(difficultyLevelData.first_number_limit, difficultyLevelData.second_number_limit, difficultyLevelData.condition_type);
                } else if (field.operationObject.operation_type_id === 3) { // Times-Table Multiplication questions
                    let a = Number(field.digit);
                    //below we are just taking a random number between the timesTableRange
                    let b = GenerateRandom(2, 1, b_pt, timesTableRange[0], timesTableRange[1]);
                    RAN_ARR = [a, ...b];
                } else if (field.mul_limit === null) {
                    let a = GenerateRandom(Number(field.digit.split(',')[0]), 1, a_pt);
                    let b = GenerateRandom(Number(field.digit.split(',')[1]), 1, b_pt);
                    RAN_ARR = [...a, ...b];
                } else {
                    let a = GenerateRandom(Number(field.digit.split(',')[0]), 1, a_pt, undefined, Number(field.mul_limit.split(',')[0]));
                    let b = GenerateRandom(Number(field.digit.split(',')[1]), 1, b_pt, undefined, Number(field.mul_limit.split(',')[1]));
                    RAN_ARR = [...a, ...b];
                }
                break;

            case "div":

                var big_num = [], small_num = [];
                var a_num = Number(field.digit.split(',')[0]);  // Big number
                var b_num = Number(field.digit.split(',')[1]);  // Small number
                // ------------------------------------------------------
                if (field.operationObject.type === "whole") {

                    if (a_num === 2 && b_num === 1) {           // Two digit number divide by 1 digit number
                        small_num.push(random.int(2, 9));
                        let multiple_arr = [];
                        let x, j = 2;

                        do {
                            x = small_num * j;
                            multiple_arr.push(x);
                            j++;
                        } while (small_num * j <= 99);
                        big_num.push(pickRandom(multiple_arr));

                    } else if (a_num === 3 && b_num === 1) {       // Three digit Number divide by 1 digit number
                        small_num.push(random.int(2, 9));
                        let multiple_arr = [];
                        let x, j = 2;

                        do {
                            x = small_num * j;
                            multiple_arr.push(x);
                            j++;
                        } while (small_num * j <= 999);
                        let new_arr = multiple_arr.filter(x => x > 99);
                        big_num.push(pickRandom(new_arr));

                    } else if (a_num === 3 && b_num === 2) {       // Three digit Number divide by 2 digit number
                        small_num.push(random.int(10, 99));
                        let multiple_arr = [];
                        let x, j = 2;

                        do {
                            x = small_num * j;
                            multiple_arr.push(x);
                            j++;
                        } while (small_num * j <= 999);
                        let new_arr = multiple_arr.filter(x => x > 99);
                        big_num.push(pickRandom(new_arr));

                    } else if (a_num === 4 && b_num === 1) {       // 4 digit Number divide by 1 digit number
                        small_num.push(random.int(2, 9));
                        let multiple_arr = [];
                        let x, j = 2;

                        do {
                            x = small_num * j;
                            multiple_arr.push(x);
                            j++;
                        } while (small_num * j <= 9999);
                        let new_arr = multiple_arr.filter(x => x > 1000);
                        big_num.push(pickRandom(new_arr));

                    } else if (a_num === 4 && b_num === 2) {       // 4 digit Number divide by 2 digit number
                        small_num.push(random.int(10, 99));
                        let multiple_arr = [];
                        let x, j = 2;

                        do {
                            x = small_num * j;
                            multiple_arr.push(x);
                            j++;
                        } while (small_num * j <= 9999);
                        let new_arr = multiple_arr.filter(x => x > 1000);
                        big_num.push(pickRandom(new_arr));

                    } else if (a_num === 4 && b_num === 3) {       // 4 digit Number divide by 3 digit number
                        small_num.push(random.int(100, 999));
                        let multiple_arr = [];
                        let x, j = 2;

                        do {
                            x = small_num * j;
                            multiple_arr.push(x);
                            j++;
                        } while (small_num * j <= 9999);
                        let new_arr = multiple_arr.filter(x => x > 1000);
                        big_num.push(pickRandom(new_arr));
                    }

                    // ------------------------------------------------------
                } else {                          //  field.operationObject.type === "decimal"
                    var a_decimal = Number(field.after_decimal.split(',')[0]);  // Big number after decimal number of digits
                    var b_decimal = Number(field.after_decimal.split(',')[1]);  // Small number after decimal number of digits



                    if (b_num === 0.1) {            // Here b_decimal is not used as the small num. here is of form (0.1 , 0.01 , 0.001) 
                        small_num.push(Number(b_num.toString().replace("1", random.int(1, 9))));
                        if (a_num === 1) {
                            big_num.push(round(random.float(1, 9), a_decimal));
                        } else {
                            big_num.push(round(random.float(10, 99), a_decimal));
                        }
                    } else if (b_num === 0.01) {          // Here b_decimal is not used as the small num. here is of form (0.1 , 0.01 , 0.001) 
                        small_num.push(Number(b_num.toString().replace("1", random.int(1, 9))));
                        if (a_num === 1) {
                            big_num.push(round(random.float(1, 9), a_decimal));
                        } else {
                            big_num.push(round(random.float(10, 99), a_decimal));
                        }

                    } else if (b_num === 0.001) {         // Here b_decimal is not used as the small num. here is of form (0.1 , 0.01 , 0.001) 
                        small_num.push(Number(b_num.toString().replace("1", random.int(1, 9))));
                        if (a_num === 1) {
                            big_num.push(round(random.float(1, 9), a_decimal));
                        } else {
                            big_num.push(round(random.float(10, 99), a_decimal));
                        }

                    } else if (a_num === 1 && b_num === 1) {

                        small_num.push(random.float(1, 5).toFixed(b_decimal));
                        let multiple_arr = [];
                        let x, j = 2;

                        do {
                            x = small_num * j;
                            multiple_arr.push(x.toFixed(b_decimal));
                            j++;
                        } while (small_num * j < 10);
                        big_num.push(pickRandom(multiple_arr));
                    } else if (a_num === 2 && b_num === 1) {
                        small_num.push(random.float(1, 9).toFixed(b_decimal));
                        let multiple_arr = [];
                        let x, j = 2;

                        do {
                            x = small_num * j;
                            multiple_arr.push(x.toFixed(a_decimal));
                            j++;
                        } while (small_num * j < 99);
                        big_num.push(pickRandom(multiple_arr));

                    } else {                  // a_num = 3 and b_num = 2

                        small_num.push(random.float(10, 99).toFixed(b_decimal));
                        let multiple_arr = [];
                        let x, j = 2;

                        do {
                            x = small_num * j;
                            multiple_arr.push(x.toFixed(a_decimal));
                            j++;
                        } while (small_num * j < 999);
                        big_num.push(pickRandom(multiple_arr));
                    }

                }

                RAN_ARR = [...big_num, ...small_num];
                break;

            case "lcm":
                RAN_ARR = GenerateRandom(Number(field.digit), 2);
                break;

            case "gcd":
                RAN_ARR = GenerateRandom(Number(field.digit), 2);
                break;

            case "per":

                let a_per = GenerateRandom(Number(field.digit.split(',')[0]), 1);
                let b_per = GenerateRandom(Number(field.digit.split(',')[1]), 1);
                RAN_ARR = [...a_per, ...b_per];
                break;

            case "sqrt":

                var three_digit_sqrt = [100, 121, 144, 169, 196, 225, 256, 289, 324, 361, 400, 441, 484, 529, 576, 625, 676, 729, 784, 841, 900, 961];
                var four_digit_sqrt = [1024, 1089, 1156, 1225, 1296, 1369, 1444, 1521, 1600, 1681, 1764, 1849, 1936, 2025, 2116, 2209, 2304, 2401, 2500,
                    2601, 2704, 2809, 2916, 3025, 3136, 3249, 3364, 3481, 3600, 3721, 3844, 3969, 4096, 4225, 4356, 4489, 4624, 4761, 4900, 5041,
                    5184, 5329, 5476, 5625, 5776, 5929, 6084, 6241, 6400, 6561, 6724, 6889, 7056, 7225, 7396, 7569, 7744, 7921, 8100, 8281, 8464,
                    8649, 8836, 9025, 9216, 9409, 9604, 9801];
                var five_digit_sqrt = [10000, 10201, 10404, 10609, 10816, 11025, 11236, 11449, 11664, 11881, 12100, 12321, 12544, 12769, 12996, 13225, 13456, 13689,
                    13924, 14161, 14400, 14641, 14884, 15129, 15376, 15625, 15876, 16129, 16384, 16641, 16900, 17161, 17424, 17689, 17956, 18225, 18496, 18769,
                    19044, 19321, 19600, 19881, 20164, 20449, 20736, 21025, 21316, 21609, 21904, 22201, 22500, 22801, 23104, 23409, 23716, 24025, 24336, 24649,
                    24964, 25281, 25600, 25921, 26244, 26569, 26896, 27225, 27556, 27889, 28224, 28561, 28900, 29241, 29584, 29929, 30276, 30625, 30976, 31329,
                    31684, 32041, 32400, 32761, 33124, 33489, 33856, 34225, 34596, 34969, 35344, 35721, 36100, 36481, 36864, 37249, 37636, 38025, 38416, 38809,
                    39204, 39601, 40000, 40401, 40804, 41209, 41616, 42025, 42436, 42849, 43264, 43681, 44100, 44521, 44944, 45369, 45796, 46225, 46656, 47089,
                    47524, 47961, 48400, 48841, 49284, 49729, 50176, 50625, 51076, 51529, 51984, 52441, 52900, 53361, 53824, 54289, 54756, 55225, 55696, 56169,
                    56644, 57121, 57600, 58081, 58564, 59049, 59536, 60025, 60516, 61009, 61504, 62001, 62500, 63001, 63504, 64009, 64516, 65025, 65536, 66049,
                    66564, 67081, 67600, 68121, 68644, 69169, 69696, 70225, 70756, 71289, 71824, 72361, 72900, 73441, 73984, 74529, 75076, 75625, 76176, 76729,
                    77284, 77841, 78400, 78961, 79524, 80089, 80656, 81225, 81796, 82369, 82944, 83521, 84100, 84681, 85264, 85849, 86436, 87025, 87616, 88209,
                    88804, 89401, 90000, 90601, 91204, 91809, 92416, 93025, 93636, 94249, 94864, 95481, 96100, 96721, 97344, 97969, 98596, 99225, 99856];

                var arr = [];
                if (Number(field.digit) === 3) {
                    arr.push(pickRandom(three_digit_sqrt));
                    RAN_ARR = arr;
                } else if (Number(field.digit === 4)) {
                    arr.push(pickRandom(four_digit_sqrt));
                    RAN_ARR = arr;
                } else if (Number(field.digit === 5)) {
                    arr.push(pickRandom(five_digit_sqrt));
                    RAN_ARR = arr;
                } else {
                    let start_num = 317;  //This will create smallest 6 digit perfect square
                    let last_num = 999;   //This will create largest 6 digit perfect square
                    let num = random.int(start_num, last_num);
                    arr.push(num * num);
                    RAN_ARR = arr;
                }
                break;

            case "cbrt":
                var three_digit_cbrt = [125, 216, 343, 512, 729];
                var four_digit_cbrt = [1000, 1331, 1728, 2197, 2744, 3375, 4096, 4913, 5832, 6859, 8000, 9261];
                var five_digit_cbrt = [10648, 12167, 13824, 15625, 17576, 19683, 21952, 24389, 27000, 29791, 32768,
                    35937, 39304, 42875, 46656, 50653, 54872, 59319, 64000, 68921, 74088, 79507,
                    85184, 91125, 97336];

                var singleDigitArr = [];
                if (Number(field.digit) === 3) {
                    singleDigitArr.push(pickRandom(three_digit_cbrt));
                    RAN_ARR = singleDigitArr;
                } else if (Number(field.digit === 4)) {
                    singleDigitArr.push(pickRandom(four_digit_cbrt));
                    RAN_ARR = singleDigitArr;
                } else {
                    singleDigitArr.push(pickRandom(five_digit_cbrt));
                    RAN_ARR = singleDigitArr;
                }
                break;

            default:
                break;
        }
        RAN_ARR.push("?");
        setState(RAN_ARR);
    };
    // =================================================================

    const Play = () => {
        SetAns_input(false);
        setResult({ ...result, answer: "", input: "", show: false });
        if (!field.operationObject.short_name) {
            toast.error("To start, choose a Level & Operation in the settings.");
        } else {
            setShowReplayReset(true);
            GenerateRandomArray();
            setTimeout(() => {
                setReset(false);
                setDisplayNumbers(true);   // This makes the Loader start working (means starts displaying number)
            }, 200);
            setQue_info({ ...que_info, start_time: Date.now() });
        }
    };
    // =================================================================

    const Replay = () => {
        setTimeout(() => {
            // This if else block will make the  replay button work for "n" number of times.
            if (displayNumbers === true && replayLoader === false) {
                setDisplayNumbers(false);  // This will make the normal loader hidden
                setReplayLoader(true);     // This will make the another loader(Replay Loader) start 
            } else {
                setDisplayNumbers(true);  // This will make the normal loader start
                setReplayLoader(false);     // This will make the another loader(Replay Loader) hidden 
            }
        }, 300);
    };
    // =================================================================

    const SendQuestionInfo = (end_time, actual_ans, isAnsCorrect) => {
        // below commented code is not used now.. but it can be used in future
        // var dur = end_time - que_info.start_time;
        // var minutes = Math.floor(dur / 60000);
        // var seconds = ((dur % 60000) / 1000).toFixed(0);
        // var duration = minutes === 0 ? seconds :(seconds === 60 ? (minutes+1) + ":00" : minutes + ":" + (seconds < 10 ? "0" : "") + seconds);
        // console.log(duration);

        var obj = {
            student_level: sessionStorage.getItem("level_name"),
            question_level: field.level === "0" ? "1" : field.level,
            question_type: QuestionType.indexOf(field.operationObject.short_name),
            question_specific: operationName,
            result: isAnsCorrect,
            uname: sessionStorage.getItem("username")
        };


        console.log(obj);
        axios.post(url + "add_mm", obj)
            .then((res) => {
                console.log(res);
                if (res.data.success) {
                } else {
                    console.log(res);
                    toast.error("Error While Sending Data");
                }
            })
            .catch((err) => console.log(err));

    };
    // =================================================================

    const Check = () => {
        SetAns_input(true);
        var end_time = Date.now();
        setReset(true);
        setReplayLoader(false);
        setDisplayNumbers(false);
        state.pop();        // This will remove the last "?" from question array

        var calculate;
        switch (field.operationObject.short_name) {
            case "a_s":
                calculate = sum(state);
                break;

            case "mul":
                calculate = multiply(...state);
                break;

            case "div":
                calculate = divide(...state);
                break;

            case "lcm":
                calculate = lcm(...state);
                break;

            case "gcd":
                calculate = gcd(...state);
                break;

            case "per":
                let num_a = state[0];
                let num_b = state[1];
                let scope = { a: num_a, b: num_b };
                calculate = evaluate('(a / 100)*b', scope);
                break;

            case "sqrt":
                calculate = sqrt(...state);
                break;

            case "cbrt":
                calculate = cbrt(...state);
                break;

            default:
                break;
        }

        var new_calculate = calculate;

        if (calculate.toString().includes(".")) {
            if (precision(Number(calculate)) > 2) {
                // The 'fix' function will restrict the number to two decimal places without rounding
                new_calculate = fix(Number(calculate), 2);
            }
        }


        let isAnsCorrect = new_calculate === (Number(result.input));


        setResult(pre => ({
            ...pre,
            actual_answer: new_calculate,
            show: true,
            answer: isAnsCorrect ? 1 : 0
        }));

        if (sessionStorage.getItem("role") === "Student") {
            SendQuestionInfo(end_time, new_calculate, isAnsCorrect);
        }

    };

    // =================================================================
    const onLevelChange = (e) => {

        let input = e.target;
        let name = input.name;
        let value = input.value;

        if (name === "level") {
            setShowRowsOption(false);   // This wil make the row select input hide for every time the level changes in settings modal
            setField({ ...field, operation_id: "" });

            setHasDifficultyLevelDropdown(false);   // This wil make the difficulty level dropdown hide for every time the level changes in settings modal
            setDifficultyLevelData({
                ...difficultyLevelData,
                id: "", difficulty_state: 1, condition_type: 0,
                first_number_limit: [], second_number_limit: []
            });
        }

        setField((pre) => {
            return {
                ...pre, [name]: value
            };
        });
    };
    // =================================================================

    const onOperationChange = (e) => {

        if (e.target.value === "") return;

        var value = e.target.value;
        var level = field.level;  // this will store the selected level
        var level_obj = LevelData.filter(x => x.level === level);  // This will select the correct obj from LevelData 
        var operation_obj = level_obj[0].operations.filter(x => x.id === Number(value));

        // This state will be used, while sending question's data to the backend 
        if (level === "0")
            setOperationName(`${operation_obj[0].name}`);
        else
            setOperationName(`${operation_obj[0].show_digit.join(" & ")} digit [ ${operation_obj[0].type} ]`);

        // Here we are hiding/showing the Difficulty level dropdown
        if (operation_obj[0].operation_type_id === 1) {
            setHasDifficultyLevelDropdown(true);
            setDifficultyLevelData({ ...difficultyLevelData, id: "", difficulty_state: 1 });
        } else setHasDifficultyLevelDropdown(false);


        if (operation_obj[0].row >= 10) {  // Addition & Subtraction Questions 
            // This wil tell how many options do we need in select rows input field (10 or 20)
            setRowOptions({ max_row_count: operation_obj[0].row });

            // Below code will make the "choose rows dropdown" appears when there is no difficulty level present for a particular operation
            ((operation_obj[0].operation_type_id === 1) && difficultyLevelData.difficulty_state !== 0) ? setShowRowsOption(false) : setShowRowsOption(true);

            setField({
                ...field,
                operationObject: operation_obj[0],
                operation_id: Number(value),
                row: 2,
                digit: operation_obj[0].digit.toString(),
                after_decimal: operation_obj[0].after_decimal.toString(),
            });
        } else { // Rest All types of questions (/,*,%,LCM,GCD,Sqrt,Cbrt)
            setShowRowsOption(false);
            setField({
                ...field,
                operationObject: operation_obj[0],
                operation_id: Number(value),
                row: operation_obj[0].row,
                digit: operation_obj[0].digit.toString(),
                after_decimal: operation_obj[0].after_decimal.toString(),
                mul_limit: operation_obj[0].hasOwnProperty("limit") ? operation_obj[0].limit.toString() : null,
            });
        }

    };
    // =================================================================
    const onTimesTableRangeChange = (e) => {
        let range = e.target.value.split(',').map(x => parseInt(x));
        setOperationName(operationName + `, with range ${range[0]} to ${range[1]}`);
        setTimesTableRange(range);
    };

    // =================================================================
    const handleDifficultyLevelChange = (e) => {

        if (e.target.value === "") return;

        var difficultyLevelId = e.target.value;
        var difficultyLevelObj = field.operationObject.difficulty_levels.filter(x => x.id === Number(difficultyLevelId));

        //  difficulty_state = 0 means no difficulty level is applied(hard level) here the the student can select 
        //  any number of rows he want, so that's why showing the "Rows select dropdown". 
        if (difficultyLevelObj[0].difficulty_state === 0 && (field.operationObject.row >= 10)) {
            setShowRowsOption(true);

            setDifficultyLevelData({
                ...difficultyLevelData,
                id: difficultyLevelId, difficulty_state: difficultyLevelObj[0].difficulty_state
            });

        } else {
            setField({ ...field, row: 2 });
            setShowRowsOption(false);
            setDifficultyLevelData({
                ...difficultyLevelData, id: difficultyLevelId, difficulty_state: difficultyLevelObj[0].difficulty_state,
                condition_type: difficultyLevelObj[0].condition_type, first_number_limit: difficultyLevelObj[0].first_number_limit,
                second_number_limit: difficultyLevelObj[0].second_number_limit,
            });
        }

    };

    // =================================================================
    const setSettings = (e) => {
        e.preventDefault();
        setShowReplayReset(false);
        setResult({ ...result, show: false, answer: "", input: "" });
        setOpen(false);
        setDisplayNumbers(false);
    };


    // ==============############********!!!!!%%%%---------++++++++---%%!!!!!*****##############==============
    // ==============############********!!!!!%%%%---------++++++++---%%!!!!!*****##############==============

    return (
        <>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>SETTINGS</DialogTitle>
                <DialogContent className="bg-light py-3">
                    <div className="container-fluid" >
                        <form onSubmit={setSettings} id="SettingsForm">

                            <div className="row ">
                                <div className="col-6 mb-3" >
                                    <select onChange={onLevelChange} value={field.level} name="level" required
                                        className="form-select rounded-0 border-0 shadow-sm">
                                        <option value="">Choose Level</option>
                                        {student_level === null ? GenerateLevelOption(10) : GenerateLevelOption(student_level)}
                                    </select>
                                </div>

                                <div className="col-6 mb-3" >
                                    <select required onChange={onOperationChange} value={field.operation_id}
                                        className="form-select pe-5 rounded-0 border-0 shadow-sm">
                                        <option value="">Choose Operation Type</option>
                                        {LevelData.filter(x => x.level === field.level).map((data) => {
                                            return data.operations.map((x, i) => {
                                                return (
                                                    <option value={x.id} key={i}>
                                                        {x.operation_type_id === 3 ? x.name :
                                                            `${x.name} - ${x.show_digit.join(" & ")} digit [ ${x.type} ]`}
                                                    </option>
                                                );
                                            });
                                        })}
                                    </select>
                                </div>

                                {field.level === '0' &&
                                    <div className="col-6 mb-3" >
                                        <select required onChange={onTimesTableRangeChange}
                                            className="form-select pe-5 rounded-0 border-0 shadow-sm">
                                            <option value="">Choose Range</option>
                                            <option value={[2, 10]}>2-10</option>
                                            <option value={[11, 20]}>11-20</option>
                                            <option value={[21, 30]}>21-30</option>
                                            <option value={[31, 40]}>31-40</option>
                                            <option value={[41, 50]}>41-50</option>
                                            <option value={[51, 60]}>51-60</option>
                                            <option value={[61, 70]}>61-70</option>
                                            <option value={[71, 80]}>71-80</option>
                                            <option value={[81, 90]}>81-90</option>
                                            <option value={[91, 99]}>91-99</option>

                                        </select>
                                    </div>
                                }

                                {hasDifficultyLevelDropdown &&
                                    <div className="col-6 mb-3" >
                                        <select required onChange={handleDifficultyLevelChange} value={difficultyLevelData.id}
                                            className="form-select pe-5 rounded-0 border-0 shadow-sm">
                                            <option value="">Choose Difficulty Level</option>
                                            {
                                                field.operationObject.difficulty_levels.map((x, i) => {
                                                    return (<option key={i} value={x.id} >{x.name}</option>);
                                                })
                                            }
                                        </select>
                                    </div>}

                                {showRowsOption &&
                                    <div className="col-6 mb-3" >
                                        {/* <label className="d-flex align-items-end me-3">Rows</label> */}
                                        <select
                                            onChange={e => setField({ ...field, row: e.target.value })}
                                            value={field.row}
                                            className="form-select pe-5 rounded-0 border-0 shadow-sm">
                                            <option value={2}>Choose Rows Count</option>
                                            {GenerateRowOption(rowOptions.max_row_count)}
                                        </select>
                                    </div>}
                            </div>
                        </form>
                        <hr />

                        {/* ========= Slider Inputs ======== */}

                        <div className="row my-4 "><ReactTooltip effect="solid" delayShow={50} type="info" multiline={true} />
                            <div className="col-4  me-4">
                                <Typography id="discrete-slider" gutterBottom>Flash
                                    <Tooltip
                                        title="Duration for which a number is displayed on the screen">
                                        <HelpOutlineIcon style={{ cursor: "pointer", marginLeft: "1rem" }}
                                            fontSize="small" />
                                    </Tooltip>
                                </Typography>
                                <Slider value={field.flash} onChange={(e, value) => setField({ ...field, flash: value })}
                                    name="flash" aria-labelledby="discrete-slider"
                                    min={1000} step={500}
                                    max={10000} marks={marks}
                                />
                            </div>
                            <div className="col-4">
                                <Typography id="discrete-slider" gutterBottom>Time out
                                    <Tooltip
                                        title="Duration between the display of two numbers">
                                        <HelpOutlineIcon style={{ cursor: "pointer", marginLeft: "1rem" }}
                                            fontSize="small" />
                                    </Tooltip>
                                </Typography>
                                <Slider value={field.timeout} onChange={(e, value) => setField({ ...field, timeout: value })}
                                    name="timeout" aria-labelledby="discrete-slider"
                                    min={1000} step={500}
                                    max={10000} marks={marks}
                                />
                            </div>
                        </div>

                        {/* ========= Background Color & Font Color Inputs ======== */}

                        <div className="row mb-4">
                            <TextField label="Background Color" type="color" name="bgColor" onChange={onLevelChange} value={field.bgColor} className="col-3  me-4"
                            />
                            <TextField label="Font Color" type="color" name="fontColor" onChange={onLevelChange} value={field.fontColor} className="col-3 "
                            />
                        </div>

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button type='submit' form="SettingsForm" color="primary">Proceed</Button>
                </DialogActions>
            </Dialog>

            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Tests</li>
                        <li className="breadcrumb-item active" aria-current="page">Mind Math</li>
                    </ol>
                </nav>

                <Heading name="Mind Math" />
                <div className="container-fluid">

                    <div className="row shadow-sm p-2 align-items-center" >
                        {displayNumbers || replayLoader ?
                            <Button variant="contained" className="col-auto me-2"
                                onClick={() => {
                                    setShowReplayReset(false);
                                    setDisplayNumbers(false);
                                    setReplayLoader(false);
                                }}>Stop</Button> :
                            <Button variant="contained" className="col-auto me-2" onClick={() => Play()}>Play</Button>
                        }
                        {showReplayReset && (reset === false ?
                            <Button variant="contained" className="col-auto me-2" onClick={() => Replay()}>Replay</Button> :
                            <Button variant="contained" className="col-auto me-2"
                                onClick={() => {
                                    SetAns_input(false);
                                    setShowReplayReset(false);
                                    setResult({ actual_answer: "", answer: "", input: "", show: false });
                                    setReplayLoader(false);
                                    setReset(false);
                                }}
                            >Reset</Button>)
                        }
                        {showReplayReset &&
                            <div className='col-auto my-3 my-md-0' >
                                <input className="form-control"
                                    type="text"
                                    placeholder="Enter Answer"
                                    value={result.input}
                                    disabled={ans_input}
                                    ref={input => input && input.focus()}
                                    autoFocus
                                    onKeyPress={(e) => {
                                        if (!ans_input && e.key === "Enter") {
                                            Check();
                                        }
                                    }}
                                    onChange={e => {
                                        e.persist();
                                        var regex = /^-?(\d*)?\.?(\d*)$/g;
                                        if (e.target.value.match(regex) || e.target.value === "") {
                                            setResult(pre => {
                                                return { ...pre, input: e.target.value };
                                            });
                                        }
                                    }}
                                />
                            </div>}

                        {showReplayReset && (result.answer === "" ?
                            <Button variant="contained" disabled={!result.input} className="col-auto" onClick={Check} >Check</Button> :
                            (
                                result.answer === 1 ?
                                    <h6 className="my-auto col-auto"><CheckCircleIcon style={{ color: "green" }} /> Correct</h6> :
                                    <h6 className="my-auto col-auto"><CancelIcon style={{ color: "red" }} /> Incorrect</h6>
                            ))}

                        <Button variant="contained" className="col-auto ms-auto"
                            onClick={() => {
                                setOpen(true);
                                setShowReplayReset(false);
                                setResult({ actual_answer: "", answer: "", input: "", show: false });
                                setReplayLoader(false);
                                setDisplayNumbers(false);
                            }}
                        >Settings</Button>
                    </div>

                    <div className="row my-4 p-2">
                        <AlertBlockForQuestionPapers />
                        <div className="col-12 d-flex justify-content-center align-items-center position-relative"
                            style={{ height: "40vh", width: "100%", background: field.bgColor }}>
                            {displayNumbers &&
                                <>
                                    <DisplayScreenHeading field={field} />
                                    <Loader
                                        array={state}
                                        color={field.fontColor}
                                        flash={field.flash}
                                        timeout={field.timeout}
                                    /></>
                            }
                            {replayLoader &&
                                <>
                                    <DisplayScreenHeading field={field} />
                                    <Loader
                                        array={state}
                                        color={field.fontColor}
                                        flash={field.flash}
                                        timeout={field.timeout}
                                    />
                                </>}
                            {result.show && (result.answer === 1 ?
                                <CheckCircleIcon style={{ fontSize: "10em", color: field.fontColor }} /> :
                                <CancelIcon style={{ fontSize: "10em", color: field.fontColor }} />)
                            }

                        </div>
                    </div>

                    {result.show &&
                        <div className="row my-3 px-3">
                            <div className="col-12 text-center fw-bolder shadow py-2 border-top-0 border-bottom-0 rounded"
                                style={{ border: `5px solid ${field.bgColor}` }}>
                                <DisplayAnswer operation={field.operationObject.short_name} array={state} answer={result.actual_answer} />
                            </div>
                        </div>}

                </div>
            </div>
        </>
    );
};

export default MindMathComp;






