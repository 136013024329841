import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Heading from "../../../../../Common/Components/Heading";

import Add from '@mui/icons-material/Add';

import axios from "axios";
import url from "../../../../../Common/URL";

import { Delete } from "@mui/icons-material";
import Button from '@mui/material/Button';
import MaterialReactTable from 'material-react-table';
import { useConfirm } from 'material-ui-confirm';
import { useSelector } from "react-redux";
import { ReadableDateTime } from "../../../../../Common/Functions/StringDateTime";


const TsTaskComp = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);


  const Columns = useMemo(
    () => [
      { header: 'Project Name', accessorKey: 'project_name' },
      { header: 'Task Name', accessorKey: 'task_name' },
      { header: 'Task Description', accessorKey: 'task_desc' },
      { header: 'Created By', id: 'fname', accessorFn: rowData => `${rowData.fname} ${rowData.lname}` },
      { header: 'Created Date', id: 'created_at', accessorFn: rowData => ReadableDateTime(rowData.created_at)  },
    ],
    [],
  );
  const [data, setData] = useState([]);

  // ------------------------Fetching Task table data-------------------------------------
  useEffect(() => {
    loadTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTasks = () => {
    const obj = { uname: sessionStorage.getItem("username") }
    axios.post(url + "fetch_tasks", obj)
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          let a = response.data.data;
          // console.log(a);
         !a.length ? setData([]) : setData(a);
          setLoading(false)
        } else {
          setData([]);
          setLoading(false)
        }

      })
      .catch((err) => console.log(err));
  }
  // ------------------------Deleting Task table's data-------------------------------------
  function onDelete(id, resolve) {
    const delObj = {
      id: id,
      uname: sessionStorage.getItem("username"),
      tokenId: 123,
    };
    axios.post(url + "delete_task", delObj)
      // .then((res) => console.log(res))
      .then((res) => resolve())
      .catch((err) => console.log(err));
  }

  return (
    <>
      <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
            <li className="breadcrumb-item">Setup</li>
            <li className="breadcrumb-item">Timesheet</li>
            <li className="breadcrumb-item active" aria-current="page">Tasks</li>
          </ol>
        </nav>

        <Heading name="Tasks" />

        <div className="container-fluid">
          <div className="row my-4">
            <div className="col-12 mx-auto d-flex justify-content-end">
              <Button variant="contained" onClick={() => navigate("/add-ts-task")} >Add Task <Add /></Button>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 mx-auto">

              <MaterialReactTable
                state={{ isLoading: loading }}

                columns={Columns}
                data={data}
                enableRowNumbers
                enableRowSelection
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                
                getRowId={(originalRow) => originalRow.task_id}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => navigate("/view-ts-task/" + row.id),
                  sx: { cursor: 'pointer' },
                })}

                renderTopToolbarCustomActions={({ table }) => (
                  <Button variant="contained" color="error" startIcon={<Delete />}
                    disabled={table.getSelectedRowModel().rows.length ? false : true}
                    onClick={() => {
                      confirm({ title: "Delete Confirmation", description: 'This will delete selected data permanently.' })
                        .then(() => {
                          let selectedRows = table.getSelectedRowModel().rows;
                          // console.log(selectedRows);
                          const delPromise = selectedRows.map((row) => {
                            return new Promise((resolve, reject) => {
                              onDelete(row.id, resolve);
                            })
                          })
                          Promise.all(delPromise).then(() => {
                            loadTasks();
                            table.resetRowSelection();
                            toast.info(selectedRows.length + " Row(s) Deleted!")
                          });
                        })
                    }}
                  >Delete
                  </Button>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default TsTaskComp