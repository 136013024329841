import React from "react"
import { useSelector } from "react-redux"
import {Link} from "react-router-dom"
import Heading from "../../../../Common/Components/Heading"

const TeacherProfileComp= ()=>{
  return(
    <>
     <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
        <nav aria-label="breadcrumb">
        <ol className="breadcrumb Breadcrumb align-items-center">
            <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                <li className="breadcrumb-item">My Profile</li>
                <li className="breadcrumb-item active" aria-current="page">Teacher's Profile</li>
            </ol>
        </nav> 

       <Heading name="Teacher's Profile" />
    </div>
    </>
  )
}
export default TeacherProfileComp