import React from "react";
import Navbar from "../Common/Components/Navbar";
// import Footer from "../Common/Footer"
import ForgotPasswordComp from "./Component/ForgotPasswordComp";


const ForgetPass = () => {
    return (
        <>

            <Navbar />
            <div className="d-flex ">
                <ForgotPasswordComp />
            </div>
        </>
    );
};

export default ForgetPass;