import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Heading from "../../../../Common/Components/Heading";
import { ReadonlyInput } from "../../Student/Component/Input";
import axios from "axios";
import url, { storageUrl } from "../../../../Common/URL";
import { Button } from "@mui/material";

import { useConfirm } from 'material-ui-confirm';
import { useSelector } from "react-redux";


const ViewLocationComp = () => {
    const confirm = useConfirm();

    const navigate = useNavigate();

    const { id } = useParams();

    const [field, setField] = useState({
        uname: sessionStorage.getItem("username"), location_type: "", name: "", city: "", state: "", country: "",
        addressline1: "", addressline2: "", zip: "", phone: "", manager_name: "", updated_by: "", updated_date: "",
        location_picture: "", location_file: "", fname: "", lname: ""
    });

    const loadLocations = () => {
        const obj = {
            uname: field.uname,
            id: id
        };
        axios.post(url + "fetch_single_location", obj)
            .then((response) => {
                let resData = response.data.data;
                // console.log(resData);
                setField({
                    location_id: resData[0].location_id, fname: resData[0].fname, lname: resData[0].lname,
                    name: resData[0].name, city: resData[0].city, state: resData[0].state, country: resData[0].country,
                    addressline1: resData[0].addressline1, addressline2: resData[0].addressline2,
                    zip: resData[0].zip, phone: resData[0].phone, location_type: resData[0].location_type,
                    manager_name: resData[0].manager_name, updated_by: resData[0].updated_by, updated_date: resData[0].updated_date,
                    location_file: resData[0].location_file
                });
            })
            .catch((err) => console.log(err));

    };

    useEffect(loadLocations, []);

    // ------------------------Deleting Location table's data--------------------------

    const onDelete = () => {

        confirm({ title: "Delete Confirmation", description: 'This will delete this location permanently.' })
            .then(() => {
                const delObj = {
                    id: id,
                    uname: sessionStorage.getItem("username"),
                    tokenId: 123,
                };
                axios.post(url + "delete_location", delObj)
                    .then((res) => {
                        //   console.log(res);
                        navigate("/location");
                        toast.info("Location deleted ");
                    })
                    .catch((err) => console.log(err));
            });

    };

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item"><Link to="/location">Location List</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">View Location</li>
                    </ol>
                </nav>
                <Heading name={`View Location - ${field.name}`} />

                <div className="container-fluid">

                    <div className="row mb-4">
                        <div className="col-12 col-md-12 d-flex justify-content-between align-items-end ">
                            <h5>Location Information</h5>
                            <div>
                                <Button variant="contained" color="error" onClick={onDelete} >Delete</Button>
                                <Button variant="contained" onClick={() => navigate("/edit-location/" + id)} className="ms-3">Edit</Button>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-11 col-md-6 mx-auto">

                            {/* <Input field="Profile Picture" name="location_picture" type="file" onChange={handleInputChange} className="form-control-file" defaultValue={field.location_picture}/> */}

                            {/* <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Location ID</label>
                        <div className="col-sm-7 col-md-7">
                        <input type="text" readOnly className="form-control bg-light border-0 fw-bold" defaultValue={field.location_id} />
                        </div>
                    </div> */}

                            <ReadonlyInput field="Location Name" className="form-control bg-light border-0 " value={field.name} />

                            <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Address Line 1</label>
                                <div className="col-sm-7 col-md-7 ">
                                    <textarea readOnly className="form-control bg-light border-0 " placeholder="--" defaultValue={field.addressline1} ></textarea>
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Address Line 2</label>
                                <div className="col-sm-7 col-md-7 ">
                                    <textarea readOnly className="form-control bg-light border-0 " placeholder="--" defaultValue={field.addressline2}></textarea>
                                </div>
                            </div>

                            <ReadonlyInput field="City" className="form-control bg-light border-0 " value={field.city} />

                            {/* ---Select Input---- */}
                            <ReadonlyInput field="Province" className="form-control bg-light border-0 " value={field.state} />

                            {/* ---Select Input---- */}
                            <ReadonlyInput field="Country" className="form-control bg-light border-0 " value={field.country} />

                            <ReadonlyInput field="Zip" name="zip" className="form-control bg-light border-0 " value={field.zip} />

                        </div>

                        <div className="col-11 col-md-6 mx-auto">


                            <ReadonlyInput name="phone" field="Location Contact No." className="form-control bg-light border-0 " value={field.phone} />


                            {/* ---Select Input---- */}
                            {/* <ReadonlyInput field="Location Manager" className="form-control bg-light border-0 " value={`${field.fname} ${field.lname}`} /> */}

                            <div className="mb-3 row">
                                <label className="col-sm-5 col-form-label">Location Type</label>
                                <div className="form-check form-check-inline w-auto ms-3">
                                    <input className="form-check-input"
                                        type="radio"
                                        readOnly
                                        id="inlineRadio1"
                                        defaultValue={1}
                                        checked={field.location_type === 1}

                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Direct</label>
                                </div>
                                <div className="form-check form-check-inline w-auto">
                                    <input className="form-check-input"
                                        type="radio"
                                        readOnly
                                        id="inlineRadio2"
                                        defaultValue={0}
                                        checked={field.location_type === 0}

                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio2">Franchise</label>
                                </div>
                            </div>
                            {/* <ReadonlyInput field="Calendar Link"  className="form-control bg-light border-0 " name="calender_link" value={field.calender_link} /> */}


                            <div className="mb-3 row ">
                                <label className="col-sm-5 col-form-label">Location Calendar PDF</label>
                                {field.location_file !== "" ?
                                    <div className="col-sm-7 col-md-7 d-flex align-items-center">
                                        <a target="_blank" rel="noopener noreferrer" href={`${storageUrl}${field.location_file}`}  >Download Calendar PDF </a>
                                    </div> :
                                    <div className="col-sm-7 col-md-7 d-flex align-items-center">
                                        <p className="m-0 bold" style={{ fontWeight: "500" }}>No File Present</p>
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewLocationComp;
