import React from "react"
import Navbar from "../../../Common/Components/Navbar"
import Footer from "../../../Common/Components/Footer"
import Sidebar from "../../../Sidebar/Sidebar"
import EditLevelComp from "./Component/EditLevelComp"


const EditLevel =()=>{
    return(
        <>
       
        <Navbar />
        <div className="d-flex mainContentArea">
            <Sidebar />
            <EditLevelComp />
        </div>
        <Footer />
        </>
    )
}

export default EditLevel