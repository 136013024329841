import React, { useState, useEffect } from "react";
import Input from "../../../../Setup/Student/Component/Input";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Heading from "../../../../../Common/Components/Heading";
import axios from "axios";
import url from "../../../../../Common/URL";
import { Button, CircularProgress } from "@mui/material";

import { CurrentDate } from "../../../../../Common/Functions/StringDateTime";
import { useSelector } from "react-redux";


const EditEnquiryComp = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loc, setLoc] = useState([]);
    const [user, setUser] = useState({
        staff: [], stud: []
    });
    const { id } = useParams();
    const [field, setField] = useState({
        fname: "", lname: "", phone: "", email: "", assigned_to: "", stud_username: "",
        message: "", category: "", location: "", status: "", preferred_time: "", campaign: "", preferred_date: ""
    });

    useEffect(() => {
        EnquiryDetails();
        loadLocation();
        loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----------This function will Fetch all the Existing Locations--------------------
    const loadLocation = () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        axios.post(url + "fetch_locations", obj)
            .then((response) => {
                let a = response.data.data;
                //   console.log(a);
                setLoc(a);
            })
            .catch((err) => console.log(err));
    };
    // ------------------------Fetching users table data-------------------------------------
    const loadUsers = async () => {
        const obj = { uname: sessionStorage.getItem("username") };
        axios.post(url + "fetch_users", obj)
            .then((response) => {
                // console.log(response)
                let a = response.data.data;
                // console.log(a)
                var stu = [], staf = [];
                a.forEach(x => {
                    if (x.role === "Student") {
                        stu.push(x);
                    } else staf.push(x);
                });

                setUser({ stud: stu, staff: staf });
            }).catch((err) => console.log(err));
    };
    // ------------------------------
    const EnquiryDetails = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        };
        await axios.post(url + "select_enq", obj)
            .then((response) => {
                let resData = response.data.data;
                //   console.log(resData);
                setField({
                    fname: resData.fname, lname: resData.lname, category: resData.category,
                    location: resData.location, message: resData.message, assigned_to: resData.assigned_to,
                    email: resData.email, phone: resData.phone, status: resData.status, stud_username: resData.stud_username,
                    preferred_time: resData.preferred_time, campaign: resData.campaign, preferred_date: resData.preferred_date
                });
            })
            .catch((err) => console.log(err));
    };
    //---------------One onChange function is used for setting the state of various types of input fields ---------------     
    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        setField((preValue) => {
            return {
                ...preValue,
                [name]: value
            };
        });
    };

    //-------------------------- onSubmit function for Submitting the  Forms---------------------------
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        var newdate = CurrentDate();

        /*---------------Creating object to send data through Axios------------------------- */
        const obj = {
            uname: sessionStorage.getItem("username"), id: id, updated_date: newdate, updated_by: sessionStorage.getItem("username"),
            fname: field.fname, lname: field.lname, category: field.category, assigned_to: field.assigned_to,
            location: field.location, message: field.message, status: field.status, stud_username: field.stud_username,
            email: field.email, phone: field.phone, preferred_time: field.preferred_time, campaign: field.campaign,
            preferred_date: field.preferred_date
        };

        axios.post(url + "edit_adm_enquiry", obj)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    toast.success("Updated successfully!");
                    EnquiryDetails();
                } else {
                    toast.error("Enquiry not updated, please try again later.");
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                {/*-----------------------Bread crumb------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">CRM</li>
                        <li className="breadcrumb-item "><Link to="/enquiry">Enquiries</Link></li>
                        <li className="breadcrumb-item "><Link to={"/view-enquiry/" + id}>View Enquiry</Link></li>
                        <li className="breadcrumb-item active">Edit Enquiry</li>
                    </ol>
                </nav>
                <Heading name={`Edit Enquiry - ${field.fname}`} />
                {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}
                <form onSubmit={onSubmit}>
                    <div className="container-fluid mt-3">

                        <div className="row my-4">
                            <div className="col-12 d-flex justify-content-between align-items-end ">
                                <h5 >Enquiry Information</h5>
                                <Button variant="contained" onClick={() => navigate("/view-enquiry/" + id)} className="ms-auto">Back</Button>
                                <Button variant="contained" disabled={loading} type="submit" className="ms-3">{loading ? <CircularProgress color="inherit" size={20} /> : 'Save'}</Button>
                            </div>
                        </div>

                        <div className="row">
                            {/*-----------------------Column-1 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto">


                                <Input name="fname" onChange={onChange} field="First Name" className="form-control" value={field.fname} required={true} />

                                <Input name="lname" onChange={onChange} field="Last Name" className="form-control" value={field.lname} required={true} />

                                <Input name="email" onChange={onChange} field="email" type="email" placeholder="--" className="form-control" value={field.email} required={true} />

                                <Input name="phone" onChange={onChange} field="Phone Number" type="tel" placeholder="--" className="form-control" value={field.phone} required={true} />

                                <Input name="preferred_date" onChange={onChange} field="Preferred appointment date" type="date" placeholder="--" className="form-control" value={field.preferred_date} required={false} />

                                <Input name="preferred_time" onChange={onChange} field="Preferred appointment time" type="time" placeholder="--" className="form-control" value={field.preferred_time} required={false} />


                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label">Assigned To</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select name="assigned_to" value={field.assigned_to ?? ''} onChange={onChange} className="form-select">
                                            <option value="">Choose</option>
                                            {user.staff.map((data, i) => (<option key={i} value={data.username}>{`${data.first_name} ${data.last_name}`}</option>))}
                                        </select>
                                    </div>
                                </div>

                            </div>
                            {/*-----------------------Column-2 for Inputs------------------------- */}
                            <div className="col-11 col-md-6 mx-auto">

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Status</label>
                                    <div className="col-sm-7 col-md-7">
                                        <select required name="status" value={field.status ?? ''} onChange={onChange} className="form-select">
                                            <option value="">Choose</option>
                                            <option value="Open">Open</option><option value="In Progress">In Progress</option>
                                            {/* <option value="Need Admission Process">Need admission process from Sreeja</option> */}
                                            <option value="Closed">Closed</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Category</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="category" value={field.category ?? ''} onChange={onChange} className="form-select">
                                            <option value="">Choose</option>
                                            <option value="Admission">Admission</option><option value="General Enquiry">General Enquiry</option><option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>


                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Location</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="location" value={field.location ?? ''} onChange={onChange} className="form-select">
                                            <option value="">Choose</option>
                                            {loc.map((data, i) => (<option key={i} value={data.name}>{data.name}</option>))}
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Message</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <textarea rows="4" required name="message" onChange={onChange} className="form-control" placeholder="--" value={field.message} ></textarea>
                                    </div>
                                </div>


                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label ">Student</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select name="stud_username" value={field.stud_username ?? ''} onChange={onChange} className="form-select">
                                            <option value="">Choose</option>
                                            {user.stud.map((data, i) => (<option key={i} value={`${data.username}#${data.first_name}*${data.last_name}`}>{`${data.first_name} ${data.last_name}`}</option>))}
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </>
    );
};

export default EditEnquiryComp

