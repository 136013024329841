import React from "react";
import Navbar from "../../Common/Components/Navbar";
import Footer from "../../Common/Components/Footer";
import Sidebar from "../../Sidebar/Sidebar";
import GlobalSummaryComp from "./Component/GlobalSummaryComp";

const GlobalSummary = () => {
  return (
    <>
      <Navbar />
      <div className="d-flex mainContentArea">
        <Sidebar />
        <GlobalSummaryComp />
      </div>
      <Footer />
    </>
  );
};

export default GlobalSummary;
