import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import Heading from "../../../../Common/Components/Heading"
import Input from "../../../Setup/Student/Component/Input"
import axios from "axios"
import url from "../../../../Common/URL"
import { Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux"


const UploadReportComp = () => {

    const [stu, setStu] = useState([]);
    const [loc, setLoc] = useState([]);
    const [batch, setBatch] = useState([]);
    const [lev, setLev] = useState([]);
    const [test, setTest] = useState([]);
    const [loading, setLoading] = useState(false);

    const [file, setFile] = useState([]);


    const [field, setField] = useState({
        stu_uname: "", level_name: "", location_name: "", batch_name: "", test_name: "", test_type: "", test_id: "",
        time_taken_abacus: "", time_taken_mm: "", score: "", wrong_ans: "", fname: "", lname: "", total_mm_que: "", total_ab_que: "",
        not_ans: "", attempted_percentage: "", overall_percentage: "", total_attempted_mm: "", total_attempted_per_mm: "",
        accuracy_mm: "", accuracy_per_mm: "", total_attempted_ab: "", total_attempted_per_ab: "", accuracy_ab: "",
        total_ab_correct: "", total_mm_correct: "",
        accuracy_per_ab: "", total_accuracy_per: "", test_mode: "", stud_id: "", created_at: "", teacher_comment: "", test_level: ""
    })

    useEffect(() => {
        loadStudents();
        loadLocation();
        loadBatch();
        loadLevel();
        loadTests();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ------------------------Fetching users table data-------------------------------------
    const loadStudents = async () => {
        const obj = { uname: sessionStorage.getItem("username") };
        axios.post(url + "fetch_students", obj)
            .then((response) => {
                // console.log(response)
                let a = response.data.data;
                // console.log(a)

                setStu(a);
            }).catch((err) => console.log(err));
    }
    // -----------This function will Fetch all the Existing Locations--------------------
    const loadLocation = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "fetch_locations", obj)
            .then((response) => {
                let a = response.data.data;
                //   console.log(a);
                setLoc(a);
            })
            .catch((err) => console.log(err));
    }
    // -----------  This function will Fetch all the Existing Batch--------------------
    const loadBatch = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "fetch_batches", obj)
            .then((response) => {
                let a = response.data.data;
                //   console.log(a);
                setBatch(a);
            })
            .catch((err) => console.log(err));
    }
    // -----------  This function will Fetch all the Existing Levels--------------------
    const loadLevel = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
        };

        await axios.post(url + "fetch_levels", obj)
            .then((response) => {
                let a = response.data.data;
                //   console.log(a);
                setLev(a);
            })
            .catch((err) => console.log(err));
    }
    // ------------------------------
    const loadTests = () => {
        const obj = { uname: sessionStorage.getItem("username") };
        axios.post(url + "fetch_tests", obj)
            .then((response) => {
                let a = response.data.data;
                // console.log(a);
                setTest(a);
            })
            .catch((err) => console.log(err));
    }

    // ====================================

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setField((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    //-------------------------- onSubmit function for Submitting the  Forms---------------------------

    const onSubmit = async (e) => {
        e.preventDefault();


        setLoading(true);

        // ------------Storing form data in "obj" object fetched from various input fields-------------

        const obj1 = {
            uname: field.stu_uname, level_name: field.level_name, location_name: field.location_name, batch_name: field.batch_name,
            test_name: field.test_name, test_type: field.test_type, test_id: field.test_id, fname: field.fname, mname: "", lname: field.lname,
            time_taken_abacus: field.time_taken_abacus,
            time_taken_mm: field.time_taken_mm, score: field.score,
            total_ab_que: field.total_ab_que,
            total_mm_que: field.total_mm_que,
            wrong_ans: field.wrong_ans === "" ? 0 : field.wrong_ans,
            not_ans: field.not_ans === "" ? 0 : field.not_ans,
            total_ab_correct: field.total_ab_correct,
            total_mm_correct: field.total_mm_correct,
            total_attempted_mm: field.total_attempted_mm,
            total_attempted_ab: field.total_attempted_ab,
            test_level: field.test_level,
            created_at: field.created_at,
            test_mode: "offline", teacher_comment: field.teacher_comment,

            stud_id: field.stud_id, user_name: field.stu_uname,
            created_by: sessionStorage.getItem("username"),
        }
        // console.log(obj1)
        const formData = new FormData();
        Object.keys(obj1).forEach(key => formData.append(key, obj1[key]));

        //   for (let i = 0; i < file.length; i++) {
        //       formData.append(`file[${i}]`, file[i])
        //   }

        //   console.log(...formData);
        //   console.log(Object.fromEntries(formData)) // Works if all fields are uniq

        // ------------Sending form data stored in "obj1" object to the add_student file using AXIOS-------------

        await axios.post(url + "add_offline_score", formData)
            .then((res) => {
                console.log(res)
                if (res.data.success) {

                    toast.success("Report uploaded successfully.");
                    setField({
                        stu_uname: "", level_name: "", location_name: "", batch_name: "", test_name: "", test_type: "", page: "", test_id: "",
                        time_taken_abacus: "", time_taken_mm: "", total_mm_que: "", total_ab_que: "", total_que: "", score: "", wrong_ans: "", created_at: "",
                        not_ans: "", attempted_percentage: "", overall_percentage: "", total_attempted_mm: "", total_attempted_per_mm: "", test_level: "",
                        accuracy_mm: "", accuracy_per_mm: "", total_attempted_ab: "", total_attempted_per_ab: "", accuracy_ab: "", fname: "", lname: "",
                        accuracy_per_ab: "", total_accuracy_per: "", test_mode: "", total_mm_correct: "", total_ab_correct: ""
                    })

                } else {
                    toast.error("Report not added, please try again later.")
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            })

    }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Students</li>
                        <li className="breadcrumb-item active" aria-current="page">Upload Report</li>
                    </ol>
                </nav>
                <Heading name={`Upload Report`} />
                <form onSubmit={onSubmit}>
                    <div className="container-fluid">

                        <div className="row my-4">
                            <div className="col-12 d-flex justify-content-end mx-auto">
                                <Button variant="contained" disabled={loading} type="submit" >{loading ? <CircularProgress color="inherit" size={20} /> :'Save'}</Button>
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-11 col-md-6 mx-auto">

                                <h5 className="mb-3">Attempted Test Information</h5>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Location</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="location_name" value={field.location_name} onChange={onChange} className="form-select">
                                            <option value="" >Choose</option>
                                            {loc.map((data, i) => (<option key={i} value={data.name}>{data.name}</option>))}
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Batch</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="batch_name" value={field.batch_name} onChange={onChange} className="form-select">
                                            <option value="" >--</option>
                                            {batch.map((data, i) => {
                                                if (data.location_name === field.location_name) {
                                                    return <option key={i} value={data.batch_name}>{data.batch_name}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Student's Level</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="level_name" value={field.level_name} onChange={onChange} className="form-select">
                                            <option value="">Choose</option>
                                            {lev.map((data, i) => (<option key={i} value={data.level_name}>{data.level_name}</option>))}
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Select Student</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="stu_uname" value={`${field.stu_uname}#${field.stud_id}#${field.fname}#${field.lname}`}
                                            onChange={(e) => {
                                                var data = e.target.value;
                                                var un = data.split("#")[0];
                                                var id = data.split("#")[1];
                                                var fn = data.split("#")[2];
                                                var ln = data.split("#")[3];

                                                setField({ ...field, stu_uname: un, stud_id: id, fname: fn, lname: ln })
                                            }}
                                            className="form-select">
                                            <option value="">Choose</option>
                                            {stu.filter(x => x.location_name === field.location_name && x.batch_name === field.batch_name && x.level_name === field.level_name
                                            ).map((data, i) => <option key={i} value={`${data.user_name}#${data.stud_id}#${data.fname}#${data.lname}`}>{`${data.fname} ${data.lname}`}</option>)}
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Test Level</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select name="test_level" value={field.test_level} onChange={onChange} className="form-select">
                                            <option value="">Choose</option>
                                            {lev.map((data, i) => (<option key={i} value={data.level_name}>{data.level_name}</option>))}
                                        </select>
                                    </div>
                                    <small className="ms-3 mt-2">Choose the level of test that the student has taken</small>
                                </div>

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label red-asterisk">Test Type</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <select required name="test_type" value={field.test_type} onChange={onChange} className="form-select">
                                            <option value="">Choose</option>
                                            <option value="Test">Test</option>
                                            <option value="Exam">Exam</option>
                                            {/* <option value="Assignment">Assignment</option> */}
                                        </select>
                                    </div>
                                </div>

                                <Input name="test_name" onChange={onChange} field="Test/Exam Name" type="text" className="form-control" value={field.test_name} required={true} />

                                <Input name='created_at' onChange={onChange} field="Test Date" type="date" className="form-control" value={field.created_at} required={true} />

                                <hr className="p-0 my-4 w-50" />

                                <div className="mb-3 row">
                                    <label className="col-sm-5 col-form-label ">Teacher's Comment</label>
                                    <div className="col-sm-7 col-md-7 ">
                                        <textarea type="text" name="teacher_comment" className="form-control" rows="2"
                                            placeholder="Add remarks here..." value={field.teacher_comment} onChange={onChange}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="col-11 col-md-6 mx-auto">

                                <h6 style={{ letterSpacing: ".5px", fontWeight: "500" }} className="mb-3 mt-5">Abacus -</h6>

                                <Input name="time_taken_abacus" onChange={onChange} field="Time Taken (Abacus)" type="text" className="form-control" value={field.time_taken_abacus} required={true} placeholder="5:30" />
                                <p className="form-text mb-3">Formats other than MM:SS are not accepted. For example, 05:36 is the right format. Do not add any words/characters (like minutes/seconds) to the start or end of the Time. </p>
                                <Input name="total_ab_que" onChange={onChange} field="Total Abacus Questions" type="text" className="form-control" value={field.total_ab_que} required={true} />
                                <Input name="total_attempted_ab" onChange={onChange} field="Total Attempted Questions" type="text" className="form-control" value={field.total_attempted_ab} required={true} />
                                <Input name="total_ab_correct" onChange={onChange} field="Total Correct Attempted" type="text" className="form-control" value={field.total_ab_correct} required={true} />
                                {/* <Input name="accuracy_per_ab" onChange={onChange} field="Accuracy %" type="text" className="form-control" value={field.accuracy_per_ab} required={true}/> */}
                                <hr />
                                <h6 style={{ letterSpacing: ".5px", fontWeight: "500" }} className="mb-3 mt-5">Mind Math -</h6>

                                <Input name="time_taken_mm" onChange={onChange} field="Time Taken (Mind Math)" type="text" className="form-control" value={field.time_taken_mm} required={true} placeholder="5:30" />
                                <p className="form-text mb-3">Formats other than MM:SS are not accepted. For example, 05:36 is the right format. Do not add any words/characters (like minutes/seconds) to the start or end of the Time. </p>

                                <Input name="total_mm_que" onChange={onChange} field="Total Mind Math Questions" type="text" className="form-control" value={field.total_mm_que} required={true} />
                                <Input name="total_attempted_mm" onChange={onChange} field="Total Attempted Questions" type="text" className="form-control" value={field.total_attempted_mm} required={true} />
                                <Input name="total_mm_correct" onChange={onChange} field="Total Correct Attempted" type="text" className="form-control" value={field.total_mm_correct} required={true} />

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default UploadReportComp
