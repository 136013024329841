import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Heading from "../../../../Common/Components/Heading";
import { ReadonlyInput } from "./Input";
import axios from "axios";
import url, { storageUrl } from "../../../../Common/URL";


import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { useConfirm } from 'material-ui-confirm';

import CircularProgress from '@mui/material/CircularProgress';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";

import MaterialReactTable from 'material-react-table';
import { useSelector } from "react-redux";
import { ReadableDateTime } from "../../../../Common/Functions/StringDateTime";


const ViewStudentComp = () => {
    const confirm = useConfirm();
    const navigate = useNavigate();
    const { id } = useParams();

    const [loading, setLoading] = useState(false);

    // ################################################Below Data for 1st Tab ########################################################

    const [field, setField] = useState({

        uname: sessionStorage.getItem("username"), stud_id: "",
        // ------------normal input fields--------------
        fname: "", mname: "", lname: "", city: "", zip: "", mobile_no: "", home_no: "", email: "",
        parent_fname: "", parent_lname: "", parent_mobile_no: "", parent_email: "", parent_mobile_no_2: "",
        parent_email_2: "", join_date: "", leave_date: "", dob: "", gsc: "", stud_code: "", acc_name: "", acc_number: "",

        // ------------Textarea input fields--------------
        addressline1: "", addressline2: "",

        // ------------Checkbox input fields--------------
        active: false, photo_consent: false, disclaimer_signed: false, status: "",

        // ------------Select inputs fields--------------
        state: "", country: "", location_name: "", batch_name: "", level_name: "", user_name: "",
        picture: "",
    });

    useEffect(() => {
        StudentDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ------------------------------
    const StudentDetail = () => {
        const obj = {
            uname: field.uname,
            id: id
        };
        axios.post(url + "fetch_single_student", obj)
            .then((response) => {
                let resData = response.data.data;
                // console.log(resData);
                setField({
                    fname: resData[0].fname, mname: resData[0].mname, lname: resData[0].lname, city: resData[0].city, zip: resData[0].zip,
                    mobile_no: resData[0].mobile_no, home_no: resData[0].home_no, email: resData[0].email,
                    parent_fname: resData[0].parent_fname, parent_lname: resData[0].parent_lname, parent_mobile_no: resData[0].parent_mobile_no,
                    parent_email: resData[0].parent_email, parent_mobile_no_2: resData[0].parent_mobile_no_2, approved_date: resData[0].approved_date,
                    parent_email_2: resData[0].parent_email_2, join_date: resData[0].join_date, leave_date: resData[0].leave_date,
                    stud_id: resData[0].stud_id,
                    gsc: resData[0].gsc, stud_code: resData[0].stud_code, dob: resData[0].dob, acc_name: resData[0].acc_name, acc_number: resData[0].acc_number,
                    // ------------Textarea input fields--------------
                    addressline1: resData[0].addressline1, addressline2: resData[0].addressline2,

                    // ------------Checkbox input fields--------------
                    active: resData[0].active, photo_consent: resData[0].photo_consent, disclaimer_signed: resData[0].disclaimer_signed,

                    // ------------Select inputs fields--------------
                    state: resData[0].state, country: resData[0].country, location_name: resData[0].location_name, batch_name: resData[0].batch_name,
                    level_name: resData[0].level_name, user_name: resData[0].user_name, status: resData[0].status

                });
                LoadAttachments(resData[0].user_name);
            })
            .catch((err) => console.log(err));
    };

    // --------------------Deleting Student table's data------------=

    const onDelete = () => {
        confirm({ title: "Delete Confirmation", description: 'This will delete the student permanently.' })
            .then(() => {
                const delObj = {
                    id: id,
                    uname: sessionStorage.getItem("username"), stud_email: field.email,
                    tokenId: 123,
                };
                axios.post(url + "delete_student", delObj)
                    .then((res) => {

                        toast.info("Student deleted");
                        navigate("/student");
                    }).catch((err) => console.log(err));
            });

    };

    // ######################################################### Below Data is for 2nd Tab ##################################################

    const Columns = useMemo(
        () => [
            { header: 'Category', accessorKey: 'category' },
            { header: 'Comment', accessorKey: 'comments' },
            { header: 'Created Date', id: 'created_at', accessorFn: rowData => ReadableDateTime(rowData.created_at) },
            { header: 'Document', id: "attach_url", accessorFn: rowData => <a href={`${storageUrl}${rowData.attach_url}`} rel="noopener noreferrer" target="_blank">Document</a> },

            {
                header: 'Action', id: "attach_id", size: 30, enableColumnActions: false, enableSorting: false, accessorFn: rowData => {
                    return (
                        <Tooltip title="Delete Attachment" placement="right" >
                            <IconButton onClick={() => onAttachmentDelete(rowData.attach_id)} >
                                <DeleteOutline />
                            </IconButton>
                        </Tooltip>
                    );
                }
            }
        ],
        [],
    );

    const [open, setOpen] = useState(false);
    const [attachment, setAttachment] = useState([]);



    const [file, setFile] = useState([]);
    const [data, setData] = useState({
        category: "", comment: ""
    });

    const onChange = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.value;
        setData(pre => {
            return {
                ...pre, [name]: value
            };
        });
    };
    // -----------------------------Upload Attachment -----------------------------

    const UploadAttachments = async () => {
        setLoading(true);
        const formData = new FormData();

        for (let i = 0; i < file.length; i++) {
            formData.append(`file[${i}]`, file[i]);
        }

        formData.append("category", data.category);
        formData.append("comments", data.comment);
        formData.append("role", "Student");
        formData.append("uname", field.user_name);
        formData.append("created_by", sessionStorage.getItem("username"));

        // console.log(...formData)

        await axios.post(url + "attachements", formData)
            .then((res) => {
                // console.log(res)
                if (res.data.success) {
                    toast.success("New Attachment Added");
                    LoadAttachments(field.user_name);
                } else {
                    toast.error("Error, Attachment not added please try again later!");
                }
            }).catch((err) => {
                console.log(err);
                toast.error("Error, please try again later!");
            });
        setLoading(false);
        setOpen(false);
        setData({
            category: "", comment: ""
        });
    };

    // ------------------- Load Attachments -----------

    const LoadAttachments = (user_name) => {
        const obj = { uname: user_name };
        axios.post(url + "select_attachments", obj)
            .then((response) => {
                // console.log(response)
                if (response.data.success) {
                    let a = response.data.data;
                    // console.log(a);
                    if (a !== null) {
                        setAttachment(a);
                    } else setAttachment([]);
                } else {
                    setAttachment([]);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error occurred, Try again later.");
            });
    };


    // ------------------------Deleting Attachment data-------------------------------------

    const onAttachmentDelete = (id) => {
        confirm({ title: "Delete Confirmation", description: 'This will delete the attachment permanently.' })
            .then(() => {
                const delObj = {
                    id: id,
                    uname: sessionStorage.getItem("username")
                };
                axios.post(url + "delete_attachments", delObj)
                    .then((res) => {
                        // console.log(res);
                        toast.info("Attachment Deleted");
                    })
                    .catch((err) => console.log(err));

                StudentDetail();

            });
    };

    const sendLoginCredentials = async () => {
        setLoading(true);
        const obj = { uname: field.user_name };
        await axios.post(url + "send_login_credentials", obj)
            .then((response) => {
                console.log(response);
                if (response.data.success && response.data.mail_sent === 1) {
                    toast.success("Login credentials sent successfully");
                } else {
                    toast.error("Error occurred, Try again later.");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error occurred, Try again later.");
            }).finally(() => {
                setLoading(false);
            });
    };



    return (
        <>
            {/* ############################################ Modal for Uploading Attachments ################################################## */}

            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Add Attachment(s)</DialogTitle>
                <DialogContent>
                    <div className="mb-3 row">
                        <label className="col col-form-label">Category</label>
                        <div className="col-sm-7 col-md-8 ">
                            <select name="category" className="form-control" onChange={onChange} value={data.category}>
                                <option value="">Select Category</option>
                                <option value="Admission Form">Admission Form</option>
                                <option value="Contract">Contract</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col col-form-label">Comment</label>
                        <div className="col-sm-7 col-md-8 ">
                            <textarea name="comment" className="form-control" placeholder="Add text" onChange={onChange} value={data.comment} rows="3"></textarea>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label className="col col-form-label">Upload Attachment(s)</label>
                        <div className="col-sm-7 col-md-8 py-3">
                            <input multiple type="file" onChange={(e) => setFile(e.target.files)} className="form-control-file" name="profile_pic" />
                        </div>
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button color="primary" onClick={() => setOpen(false)}>Close</Button>
                    <Button color="primary" onClick={UploadAttachments}>{!loading ? "Save" : <CircularProgress size={20} />}</Button>
                </DialogActions>

            </Dialog>

            {/* ############################################################### */}


            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                {/*-----------------------Bread crumb------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Setup</li>
                        <li className="breadcrumb-item "><Link to="/student">Students list</Link></li>
                        <li className="breadcrumb-item active">View Student</li>
                    </ol>
                </nav>
                <Heading name={"View Student-" + " " + field.fname} />
                {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}

                <div className="container-fluid mt-3">

                    <div className="row my-4">
                        <div className="col-12 d-flex  ">
                            <Button variant="contained" onClick={() => navigate("/stu-history-table/" + id)} >View History</Button>
                            {/* <Button variant="contained" color="error"  onClick={onDelete} className=" ms-auto ">Delete</Button>   */}
                            <Button variant="contained" onClick={sendLoginCredentials} color="secondary" className="ms-auto" disabled={loading}>{loading ? <CircularProgress color="inherit" size={20} /> : 'Send Login Credentials'}</Button>
                            {sessionStorage.getItem("role") !== "Location Manager" && <Button variant="contained" onClick={() => navigate("/edit-student/" + id)} className=" ms-3 ">Edit</Button>}
                        </div>
                    </div>

                    <div className="row border-top border-bottom mb-4 pt-4">
                        <nav>
                            <div className="nav " id="nav-tab" role="tablist">
                                <a className="nav_tab nav-link active " id="nav-info-tab" data-bs-toggle="tab" href="#nav-info" role="tab" aria-controls="nav-info" aria-selected="true">Information</a>
                                <a className="nav_tab nav-link " id="nav-attachment-tab" data-bs-toggle="tab" href="#nav-attachment" role="tab" aria-controls="nav-attachment" aria-selected="false">Attachments</a>
                            </div>
                        </nav>
                    </div>


                    <div className="tab-content" id="nav-tabContent">

                        <div className="tab-pane fade show active" id="nav-info" role="tabpanel" aria-labelledby="nav-info-tab">

                            <div className="row">
                                {/*-----------------------Column-1 for Inputs------------------------- */}
                                <div className="col-11 col-md-6 mx-auto">

                                    <h5 className="mb-4">Personal Information</h5>

                                    {/* <Input name="picture" onChange={handleInputChange} field="Profile Picture" type="file"  className="form-control-file" value={field.picture} /> */}
                                    {/* 
                <div className="mb-3 row">
                    <label className="col-sm-5 col-form-label">Student ID</label>
                    <div className="col-sm-7 col-md-7 ">
                        <input type="text" readOnly className="form-control bg-light border-0 fw-bold" value={field.stud_id} />
                    </div>
                </div>
                 */}
                                    <ReadonlyInput field="First Name" value={field.fname} />

                                    <ReadonlyInput field="Middle Name" value={field.mname} />

                                    <ReadonlyInput field="Last Name" value={field.lname} />

                                    <ReadonlyInput field="Date of Birth" value={field.dob} />

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label">Address Line 1</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <textarea readOnly className="form-control bg-light border-0 " placeholder="--" value={field.addressline1 ?? ''}></textarea>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label">Address Line 2</label>
                                        <div className="col-sm-7 col-md-7 ">
                                            <textarea readOnly className="form-control bg-light border-0 " placeholder="--" value={field.addressline2 ?? ''} ></textarea>
                                        </div>
                                    </div>

                                    <ReadonlyInput field="City" value={field.city} />

                                    {/* ---Select Input---- */}
                                    <ReadonlyInput field="Country" className="form-control bg-light border-0 " value={field.country} />

                                    {/* ---Select Input---- */}
                                    <ReadonlyInput field="Province" className="form-control bg-light border-0 " value={field.state} />

                                    <ReadonlyInput field="Zip" value={field.zip} />

                                    <ReadonlyInput field="Mobile Number" value={field.mobile_no} />

                                    <ReadonlyInput field="Home Phone" value={field.home_no} />

                                    <ReadonlyInput field="Email" type="email" placeholder="--" value={field.email} />

                                    <h5 className="mt-5 mb-4">Parent Information</h5>

                                    <ReadonlyInput field="Parent First Name" value={field.parent_fname} />

                                    <ReadonlyInput field="Parent Last Name" value={field.parent_lname} />

                                    <ReadonlyInput field="Parent Mobile Number" value={field.parent_mobile_no} />

                                    <ReadonlyInput field="Parent Email" value={field.parent_email} />

                                    <ReadonlyInput field="Parent Mobile Number 2" value={field.parent_mobile_no_2} />

                                    <ReadonlyInput field="Parent Email 2" value={field.parent_email_2} />

                                </div>
                                {/*-----------------------Column-2 for Inputs------------------------- */}
                                <div className="col-11 col-md-6 mx-auto">

                                    <h5 className="mb-4">Batch Information</h5>

                                    {/* ---Select Input---- */}
                                    <ReadonlyInput field="Location" className="form-control bg-light border-0 " value={field.location_name} />

                                    {/* ---Select Input---- */}
                                    <ReadonlyInput field="Batch" className="form-control bg-light border-0 " value={field.batch_name} />

                                    {/* ---Select Input---- */}
                                    <ReadonlyInput field="Level" className="form-control bg-light border-0 " value={field.level_name} />


                                    <h5 className="mt-5 mb-4">Other Information</h5>

                                    {/* ---Select Input---- */}
                                    <ReadonlyInput field="User" className="form-control bg-light border-0 " value={field.user_name} />

                                    <ReadonlyInput field="Global Student Code" className="form-control bg-light border-0 " value={field.gsc} />

                                    <ReadonlyInput field="Student Code" className="form-control bg-light border-0 " value={field.stud_code} />

                                    <ReadonlyInput field="Status" className="form-control bg-light border-0 " value={field.status} />

                                    {/* <div className="mb-3 row">
                        <label className="col-sm-5 col-form-label">Active Account</label>
                        <div className="col-sm-7 col-md-7 ps-4">
                            <div className="form-check">
                                <input readOnly className="form-check-input "  type="checkbox" checked={field.active === "1" || field.active === "true" ? true : false}    />
                            </div>
                        </div>
                    </div> */}
                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label">Photography Consent Provided?</label>
                                        <div className="col-sm-7 col-md-7 ps-4">
                                            <div className="form-check">
                                                <input readOnly className="form-check-input " type="checkbox" checked={field.photo_consent === "1" || field.photo_consent === "true" ? true : false} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label className="col-sm-5 col-form-label">Disclaimer Signed?</label>
                                        <div className="col-sm-7 col-md-7 ps-4">
                                            <div className="form-check">
                                                <input readOnly className="form-check-input " name="disclaimer_signed" type="checkbox" checked={field.disclaimer_signed === "1" || field.disclaimer_signed === "true" ? true : false} />
                                            </div>
                                        </div>
                                    </div>

                                    <ReadonlyInput field="Account Name" className="form-control bg-light border-0 " value={field.acc_name} />

                                    <ReadonlyInput field="Account Number" className="form-control bg-light border-0 " value={field.acc_number} />

                                    <ReadonlyInput name="join_date" field="Join Date" type="date" placeholder="--" value={field.join_date} />

                                    <ReadonlyInput name="leave_date" field="Leave Date" type="date" placeholder="--" value={field.leave_date} />
                                </div>
                            </div>

                        </div>

                        <div className="tab-pane fade" id="nav-attachment" role="tabpanel" aria-labelledby="nav-attachment-tab">

                            <div className="row col-12">
                                <Button variant='contained' className="ms-auto col-auto" onClick={() => setOpen(true)}>Add Attachment<Add /></Button>
                            </div>

                            <div className="row mt-3">
                                <div className="col-12 ">
                                    <MaterialReactTable
                                        columns={Columns}
                                        data={attachment}
                                        enableRowNumbers
                                        initialState={{ density: 'compact' }}
                                        enableDensityToggle={false}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewStudentComp

