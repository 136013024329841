import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
// import {toast} from "react-toastify";
import { ReadonlyInput } from "../../../Setup/Student/Component/Input";
import Heading from "../../../../Common/Components/Heading";
import axios from "axios";
import url, { storageUrl } from "../../../../Common/URL";
import { useSelector } from "react-redux";


const ViewTransactionDetail = () => {

    const { id } = useParams();
    const [state, setState] = useState({
        item_name: "", description: "", location: "", created_by: "", quantity: "", attachments: "",
        comments: "", trans_date: "", transaction_type: "", assigned_to: "", transfer_to_location: "",
        fname: "", lname: ""
    });

    useEffect(() => {
        LoadTransactionDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----------This function will Fetch all the Item data--------------------
    const LoadTransactionDetail = async () => {
        const obj = {
            uname: sessionStorage.getItem("username"),
            id: id
        };

        await axios.post(url + "select_inventory_transaction", obj)
            .then((response) => {
                let a = response.data.data;
                // console.log(a);
                setState({
                    item_name: a[0].item_name, description: a[0].description, location: a[0].location, created_by: a[0].created_by, quantity: a[0].quantity,
                    comments: a[0].comments, trans_date: a[0].trans_date, transaction_type: a[0].transaction_type, assigned_to: a[0].assigned_to,
                    attachments: a[0].attachments, transfer_to_location: a[0].transfer_to_location,
                    fname: a[0].fname, lname: a[0].lname,
                });
            }).catch((err) => console.log(err));
    };

    // ------------------------Deleting Student table's data-------------------------------------

    // const onDelete = () => {
    //     confirm({title : "Delete Confirmation" , description: 'This will delete the transaction permanently.' })
    //     .then(() => { 
    //         const delObj = {
    //             id: id,
    //             uname: state.uname,          
    //             };
    //             axios.post(url+"php/inventory_transaction/delete_inventory_transaction.php", delObj)
    //                 .then((res) => {
    //                     console.log(res);
    //                     navigate("/inv-transaction");
    //                     toast.error("Transaction deleted.")
    //                 })
    //                 .catch((err) => console.log(err));
    //      })
    // }

    return (
        <>
            <div className={useSelector(state => state.sidebar.value) ? 'widthWhenSidebarOpen' : 'widthWhenSidebarClose'}>
                {/*-----------------------Bread crumb------------------------- */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb Breadcrumb align-items-center">
                        <li className="breadcrumb-item"><Link to="/bulletin">Home</Link></li>
                        <li className="breadcrumb-item">Inventory</li>
                        <li className="breadcrumb-item active"><Link to="/inv-balance">Inventory Balance</Link></li>
                        <li className="breadcrumb-item active"><Link to={"/view-balance-history/" + state.item_name + "/" + state.location}>Previous Transaction(s)</Link></li>
                        <li className="breadcrumb-item active">Transaction Details</li>
                    </ol>
                </nav>
                <Heading name="Transaction Details" />
                {/* -------------------------------------------------Two Columns for Inputs--------------------------------------- */}

                <div className="container-fluid mt-3 mb-5">
                    <div className="row my-4">
                        <div className="col-12 d-flex justify-content-end mx-auto">
                            {/* <Button variant="contained" color="error" onClick={onDelete} >Delete</Button> */}
                            <Link to={"/view-balance-history/" + state.item_name + "/" + state.location} className="ms-3 btn btn-primary">Back</Link>
                        </div>
                    </div>

                    <div className="row">

                        {/*-----------------------Column-1 for Inputs------------------------- */}
                        <div className="col-11 col-md-6 mx-auto">

                            <h5 className="mb-4">Item Information</h5>


                            <ReadonlyInput name="item_name" field="Item Name" value={state.item_name} />

                            {/* <ReadonlyInput name="description" field="Description"   value={state.description}  /> */}

                            <ReadonlyInput name="price" field="Quantity" value={state.quantity} />

                            <ReadonlyInput name="location" field="Location" value={state.location} />

                            <ReadonlyInput name="comments" field="Comments" value={state.comments} />

                            <div className="mb-3 row ">
                                <label className="col-sm-5 col-form-label">Transaction File</label>
                                {state.attachments !== "" ?
                                    <div className="col-sm-7 col-md-7 d-flex align-items-center">
                                        <a target="_blank" rel="noopener noreferrer" href={`${storageUrl}${state.attachments}`} >View File </a>
                                    </div> :
                                    <div className="col-sm-7 col-md-7 d-flex align-items-center">
                                        <p className="m-0 bold" style={{ fontWeight: "500" }}>No File Present</p>
                                    </div>
                                }
                            </div>
                        </div>
                        {/*-----------------------Column-2 for Inputs------------------------- */}
                        <div className="col-11 col-md-6 mx-auto">

                            <h5 className="mb-4">Other Information</h5>


                            <ReadonlyInput name="transaction_type" field="Transaction Type" value={state.transaction_type} />

                            {/* {state.assigned_to !=="" &&
                            <ReadonlyInput name="assigned_to" field={(state.transaction_type === "Issue" || state.transaction_type === "Sell"  ) ?'To User' : "From User"}   value={state.assigned_to} />}

                        {state.transfer_to_location !=="" &&
                            <ReadonlyInput name="assigned_to" field={state.transaction_type ==="Transfer-Out" ?`To Location`:"From Location"}   value={state.transfer_to_location} />}
                         */}

                            {(state.transaction_type === "Issue" || state.transaction_type === "Return") &&

                                <ReadonlyInput name="assigned_to" field={state.transaction_type === "Issue" ? 'To User' : "From User"} value={`${state.fname} ${state.lname}`} />}

                            {(state.transaction_type === "Sell") &&

                                <ReadonlyInput field="To Student" value={`${state.fname} ${state.lname}`} />}


                            {(state.transaction_type === "Transfer-Out" || state.transaction_type === "Transfer-In") &&
                                <ReadonlyInput name="assigned_to" field={state.transaction_type === "Transfer-Out" ? `To Location` : "From Location"} value={state.transfer_to_location} />}


                            <ReadonlyInput name="trans_date" field="Transaction Date" value={state.trans_date} />



                        </div>

                    </div>
                </div>
            </div>

        </>
    );
};
export default ViewTransactionDetail

